<div class="kyc-wrapper top-index" [class.white-bg]="!company">
  <a *ngIf="company" class="back-btn back detail size inline-block" routerLink='/kyc/details' [queryParams]="{vendor_id: vendorId, email: vendorEmail}"><span class="fa fa-angle-left"></span>Back to Details</a>
  <div *ngIf="!company" class="brand-logo text-center">
    <a routerLink="/"><img src="/assets/images/white_black.svg" alt=""></a>
  </div> 
  <h2>Vendor Registration Form</h2>
  <p>Complete the form given below to sign up as a Vendor. Ensure to fill all required fields.</p>
  <div class="kyc-form-wrap">
    <div class="kyc-head">
      <div class="first-tab" [ngClass]="{ 'active-circle': tabset.tabs[0].active == true, 'completed': companyCompleted }">
        <span></span>
        <label for="first-tab">Company Details & General Info</label>
      </div>
      <div class="second-tab" [ngClass]="{ 'active-circle': tabset.tabs[1].active == true, 'completed': financialCompleted }">
        <span></span>
        <label for="second-tab">Financial Information</label>
      </div>
      <div class="third-tab" [ngClass]="{ 'active-circle': tabset.tabs[2].active == true, 'completed': technicalCompleted }">
        <span></span>
        <label for="third-tab">Technical Capability & Undertaking</label>
      </div>
    </div>
    <tabset #tabset class="kyc-tabs">
      <tab id="0">
        <app-company-details [id]="0" (updatePrev)="updatePrev()" (updateNext)="updateNext()" [vendorId]="vendorId" [vendorEmail]="vendorEmail" [company]="company" [role]="roleId" (vendorEmails)="setVendorEmail($event)" (formFilled)="companyDetails($event)"></app-company-details>
      </tab>
      <tab id="1">
        <app-financial-information [id]="1" (updatePrev)="updatePrev()" (updateNext)="updateNext()" [vendorId]="vendorId" [vendorEmail]="vendorEmail" (formFilled)="financialDetails($event)"></app-financial-information>
      </tab>
      <tab id="2">
        <app-technical-capability [id]="2" (updatePrev)="updatePrev()" (updateNext)="updateNext()" [vendorId]="vendorId" [vendorEmail]="vendorEmail" [company]="company" (formFilled)="technicalDetails($event)"></app-technical-capability>
      </tab>
    </tabset>
  </div> 
</div>