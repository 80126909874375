import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormDataToJsonService {
  constructor() { }
  /**
 * Converts FormData to a JSON object.
 *
 * param {FormData} formData - The FormData to be converted.
 * return {any} A JSON object representation of the FormData.
 */
  convertFormDataToJson(formData: FormData, additionalData = null): any {
    const jsonObject: any = {};
    if (additionalData) {
      for (let key in additionalData) {
        jsonObject[key] = additionalData[key];
      }
    }
    // Use the forEach method to iterate over formData
    formData.forEach((value, key) => {
      // console.log(key, value);
      // Check if the key already exists
      if (jsonObject[key]) {


        // If it already exists, turn it into an array (handle multi-value entries)
        // if (Array.isArray(jsonObject[key])) {
        //   jsonObject[key].push(value);
        // } else {
        //   jsonObject[key] = [jsonObject[key], value];
        // }
      } else {
        // Otherwise, just set the key-value pair
        jsonObject[key] = value;
      }
    });
    return jsonObject;
  }
}
