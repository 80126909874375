import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UserService } from '@app/modules/user/services/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OrderRatingComponent } from '@app/shared/components/order-rating/order-rating.component';
import { ToastrService } from 'ngx-toastr';
import { NewRequisitionQuotationService } from '@app/modules/new-requisition-quotation/services/new-requisition-quotation.service';
import { Router } from '@angular/router';
import { Privilages, UserRoles } from '@app/core/enums/user-roles';
import { Utils } from '@app/shared/service/util';
import { AlertService } from '@app/shared/service/alert.service';
import { SharedService } from '@app/shared/service/shared.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaginationData } from '@app/core/models/response.model';
import { User } from '@app/modules/user/models/user.modal';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.css'],
  providers: [Utils]
})
export class DashboardAdminComponent implements OnInit {
  modalRef: BsModalRef;
  openFilters: boolean;
  vendorRates: any = [];
  filterForm: FormGroup;
  showLoading: boolean = true;
  rfqLoading: boolean = false;
  @ViewChild('orderRating', { static: false }) orderRating: OrderRatingComponent;
  submitted: boolean;
  selectFile: boolean;
  inValidFileSize: boolean;
  isLoading: boolean;
  vendorId: number;
  formData: FormData;
  fileName = '';
  fileNameList = [];
  users = [];
  files;
  count = 0;
  maxDateValue = new Date();
  //showDraft: boolean;
  @Input() shipList = [];
  @Input() portList = [];
  @Input() categoryList = [];
  subAdminPrivilages: [number];
  subAdminUploadFile: boolean = false;
  privilages = Privilages;
  userRole = UserRoles;
  private subscriptions: Subscription = new Subscription()
  role: number;
  rfqReportModal: BsModalRef;
  constructor(
    private userService: UserService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private newRequisitionQuotationService: NewRequisitionQuotationService,
    private router: Router,
    public util: Utils,
    private alertService: AlertService,
    private sharedService: SharedService,
    private http: HttpClient
  ) {
    this.formData = new FormData();
    this.filterForm = this.formBuilder.group({
      sub_admin_user: [null],
      from_date: [null, [Validators.required]],
      to_date: [null, [Validators.required]]
    });
  }

  ngOnInit() {
    this.util.getDashboard();
    //this.showDraft = false;
    /* if (localStorage.getItem('reqQuotationData')) {
      this.showDraft = true;
    } */
    const headers = new HttpHeaders().set(
      'x-filter',
      '{"company_id":[21],"verified":null,"fname":""}'
    );
    this.userService
      .getAll(1, 500, headers)
      .subscribe((userData: PaginationData) => {
        this.users = userData.results.map((user: User) => {
          if (user.user_role) {
            let rowData = {
              id: user.id,
              fname: user.fname,
              lname: user.lname ? user.lname : ''
            };
            rowData.fname = rowData.fname + ' ' + rowData.lname;
            return rowData;
          }
        });
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
      });

    this.role = this.sharedService.getUserRole();
    if (this.role == this.userRole.ROLE_SUBADMIN) {
      this.subAdminPrivilages = this.sharedService.getSubAdminsPrivileges();
      if (this.subAdminPrivilages && this.subAdminPrivilages.length > 0)
        this.subAdminUploadFile = this.subAdminPrivilages.includes(this.privilages.IMPORT_REQUISITION_DOC);
    }
  }

  filterData() {

    if (this.filterForm.get('from_date').value) {
      const fromDate = this.datePipe.transform(this.filterForm.value.from_date, 'yyyy-MM-dd');
      this.filterForm.get('from_date').setValue(fromDate);
    }
    if (this.filterForm.get('to_date').value != null) {
      const toDate = this.datePipe.transform(this.filterForm.value.to_date, 'yyyy-MM-dd');
      this.filterForm.get('to_date').setValue(toDate);
    }

    this.exportReport('xlsx', this.filterForm.get('from_date').value, this.filterForm.get('to_date').value, this.filterForm.get('sub_admin_user').value)
    this.openFilters = false;
  }

  resetForm(count) {
    this.filterForm.reset({
      from_date: null,
      to_date: null,
      sub_admin_user: null
    });
    this.openFilters = true;
  }

  showModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({ backdrop: 'static', class: 'gray modal-lg fit' })
    );
    this.alertService.backModalClose(this.modalRef)
  }
  vendorRate() {
    this.subscriptions.add(
      this.userService.getVendorRating().subscribe(response => {
        this.vendorRates = response;
        this.showLoading = false;
      }))
  }

  openRatingModal(vendorId) {
    this.orderRating.openModal(vendorId);
  }

  selectedfileName(files: FileList) {
    //this.fileNameList = [];
    if (files.length > 0 && files.length <= 5) {
      this.fileName = files[0].name;
      Array.from(files).forEach(arr => {
        this.fileNameList.push(arr);
      });
      this.inValidFileSize = false;
      this.files = files
      Array.from(this.fileNameList).forEach((name, i) => {
        if (name.name) {
          const filesize = files[i].size / 1024 / 1024;
          if (filesize > 5) {
            this.inValidFileSize = true;
          } else {
            this.formData = new FormData;
            //this.formData.append('file', files[i], files[i].name);
            this.selectFile = true;
          }
        }
      })
      /* if (this.fileName) {
        const filesize = files[0].size / 1024 / 1024;
        if (filesize > 5) {
          this.inValidFileSize = true;
        } else {
          this.formData = new FormData();
          this.formData.append('file', files[0], files[0].name);
          this.selectFile = true;
        }
      } */
    } else {
      if (this.fileNameList.length == 0) {
        this.toastr.error('You can upload Minimum 1 file and Maximum 5 files', '',
          {
            closeButton: true,
            timeOut: 2000,
            positionClass: 'toast-bottom-left'
          });
        this.isLoading = false;
      }
    }
  }

  openImportModal(template: TemplateRef<any>) {
    this.fileNameList = [];
    this.modalRef = this.modalService.show(
      template,
      Object.assign({ backdrop: 'static', keyboard: false, class: 'gray modal-small product-modal' })
    );
    this.alertService.backModalClose(this.modalRef)
  }
  uploadReqQuotation() {
    this.submitted = true;
    if (!this.selectFile || this.inValidFileSize) {
      return false;
    }
    if (!this.formData.get('file')) {
      this.formData.append('file', null);
    }
    this.isLoading = true;
    let excelList = []
    const uploadStartTime = new Date();
    Array.from(this.fileNameList).forEach((name, i) => {
      const fileReader = new FileReader();
      if (name.name) {
        const filesize = this.files[i].size / 1024 / 1024;
        if (filesize > 5) {
          this.inValidFileSize = true;
        } else {
          this.formData = new FormData;
          this.formData.append('file', this.files[i], this.files[i].name);
          this.selectFile = true;
          this.subscriptions.add(
            this.newRequisitionQuotationService.uploadRequisitionQuotation(this.formData).subscribe(data => {
              this.modalRef.hide();
              this.toastr.success('Successfully uploaded!', '',
                {
                  closeButton: true,
                  timeOut: 2000,
                  positionClass: 'toast-bottom-left'
                });
              if (data) {
                this.count++;
                let fileStartTime = new Date();
                let fileUploadTime
                fileReader.onload = () => {
                  const fileEndTime = new Date();
                  fileUploadTime = fileEndTime.getTime() - fileStartTime.getTime();
                  fileUploadTime /= 1000
                  console.log(`File ${i + 1} upload time: ${fileUploadTime}/1000sec`);
                };
                fileReader.readAsDataURL(this.files[i]);
                const uploadEndTime = new Date();
                let totalUploadTime = uploadEndTime.getTime() - uploadStartTime.getTime();
                excelList.push({ data: data, newRequisition: i, intime: Math.round(totalUploadTime / 1000) });
                localStorage.setItem('reqQuotationData', JSON.stringify(excelList));
                if (this.count > 0) {
                  if (this.fileNameList.length > 1) {
                    const url = this.router.serializeUrl(this.router.createUrlTree(['/dashboard/new-requisition-quotation'], { queryParams: { i } }));
                    window.open(url, '_blank').focus();
                  } else {
                    this.router.navigate(['/dashboard/new-requisition-quotation'], { queryParams: { i } });
                  }
                }
              }
              this.selectFile = false;
              this.submitted = false;
              this.fileName = null;
              this.isLoading = false;
              this.formData = new FormData();
            }, error => {
              this.toastr.error('Something went wrong!', '',
                {
                  closeButton: true,
                  timeOut: 2000,
                  positionClass: 'toast-bottom-left'
                });
              this.submitted = false;
              this.isLoading = false;
              this.selectFile = false;
              this.formData = new FormData();
            })
          )
        }
      }
    })
  }
  exportReport(type = 'xlsx', from_date = null, to_date = null, sub_admin_user = null) {
    let exports = 1;
    var query = '';
    if (from_date != null) {
      query = query + `&from_date=${from_date}`;
    }
    if (to_date != null) {
      query = query + `&to_date=${to_date}`;
    }
    if (sub_admin_user != null) {
      query = query + `&user_id=${sub_admin_user}`;
    }
    const mime = type === 'pdf' ? 'application/pdf' : 'application/vnd.ms-excel';
    const fileName = ('rfqs_report').replace(/ /g, '_');
    this.rfqLoading = true;
    this.subscriptions.add(
      this.newRequisitionQuotationService.exportRfqReport(exports, query).subscribe(data => {
        if (data) {
          this.sharedService.saveAsBlob(data, mime, `${fileName}.${type}`);
          this.rfqLoading = false;
        }
      })
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  exportRfqreport(template: TemplateRef<any>) {
    this.rfqReportModal = this.modalService.show(
      template,
      Object.assign({ backdrop: 'static', class: 'gray modal-lg' })
    );
    this.alertService.backModalClose(this.rfqReportModal)

  }

  minDate: string | Date = new Date();
  onChangeEta(event) {
    this.minDate = event;
  }
}
