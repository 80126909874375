<ng-template #filterPopupModal>
  <div class="fluid-container">
    <div class="panel-header">
      <h4>Filter Data</h4>
      <button type="button" aria-label="Close" class="close"
      aria-label="Close" (click)="modal.hide()">
        <span aria-hidden="true">&#215;</span>
      </button>
    </div>
    <div class="panel-body">
      <form [formGroup]="dynamicForm">
        <div *ngFor="let control of data;let i=index">
          <div [ngSwitch]="control.filter.type">
            <div *ngSwitchCase="'date_range'">
              <label for=""
                class="tmargin10 form-label label-name">{{control.filter.name|titlecase}}</label>
              <app-general-filter [formData]="control.filter.id" (itemClicked)="handleItemClicked($event)"></app-general-filter>
            </div>
            <div *ngSwitchCase="'text'">
              <label for="">{{ control.filter.name|titlecase}}</label>
              <input type="text"
                [formControlName]="control.filter.id"
                placeholder="{{'Enter ' +control.filter.name}}"
                class="form-control">
            </div>
            <div *ngSwitchCase="'number'">
              <label for="">{{ control.filter.name|titlecase}}</label>
              <input type="text"
                pKeyFilter="pnum"
                [formControlName]="control.filter.id"
                placeholder="{{'Enter ' +control.filter.name}}"
                class="form-control" (change)="changeTopN($event)">
                <label for="" *ngIf="axesAdded">Select Axes<span class="mendatory">*</span></label>
                <ng-select [items]="notPrimaryAxis" *ngIf="axesAdded"
                  formControlName="axes"
                  bindLabel="axis.name"
                  bindValue="axis.name"
                  [multiple]="false"
                  (change)="changeTopP($event)"
                  placeholder="Select Axes"
                  [clearable]="false"
                  class="form-control"></ng-select>
            </div>
            <div *ngSwitchCase="'single_select'">
              <label for="">{{ control.filter.name|titlecase}}</label>
                <ng-select [items]="control.filter_options"
                  bindLabel="name"
                  bindValue="id"
                  [formControlName]="control.filter.id"
                  [multiple]="false"
                  placeholder="{{'Select ' +control.filter.name}}"
                  class="form-control"></ng-select>
            </div>
            <div *ngSwitchCase="'multi_select'">
              <label for="">{{ control.filter.name|titlecase}}</label>
              <ng-select [items]="control.filter_options"
                bindLabel="name"
                bindValue="id"
                [formControlName]="control.filter.id"
                [multiple]="true"
                placeholder="{{'Select ' +control.filter.name}}"
                class="form-control"></ng-select>
            </div>
          </div>
        </div>
        <div class="pull-right" >
          <button type="submit"
            class="btn btn-primary tmargin10 bmargin10" (click)="clearFilter();modal.hide()"> <span class="spinner-border spinner-border-sm"
              *ngIf="clearFiltering"
              role="status"
              aria-hidden="true"></span>
            <span class="sr-only">Loading...</span> Clear</button>
          <button type="submit"
            class="btn btn-primary tmargin10 bmargin10" (click)="fiterFormData(2)" [disabled]="disableFilter">
            <span class="spinner-border spinner-border-sm" *ngIf="filterLoading"
              role="status"
              aria-hidden="true"></span>
            <span class="sr-only">Loading...</span> Apply</button>
        </div>        
      </form>
    </div>
  </div>
</ng-template>
