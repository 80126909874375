<div class="loading"
    *ngIf="loading">
    <img src="/assets/images/loader.gif"
        alt="Loader" />
</div>
<div class="have-middlebox bg-white">
    <div class="login-bg"></div>
    <div class="login-credentials">
        <div>
            <div class="company-logo">
                <a href="https://shipskart.com/"><img src="/assets/images/white_black.svg" /></a>
            </div>
            <div class="wellcome-msg">
                Register as Captain
                <small class="sub-heading">Please fill the form to register</small>
            </div>
            <form [formGroup]="registerForm"
                (ngSubmit)="register()">
                <div class="row form-row">
                    <input type="hidden"
                        formControlName="ship_id"
                        [(ngModel)]="shipId">
                    <input type="hidden"
                        formControlName="company_id"
                        [(ngModel)]="companyId">
                    <input type="hidden"
                        formControlName="source"
                        [(ngModel)]="source">
                    <input type="hidden"
                        formControlName="role"
                        [(ngModel)]="captainRole">

                    <div class="form-group col-md-5 form-right-space"
                        [ngClass]="{error : submitted && form.salutation.errors}">
                        <ng-select formControlName="salutation"
                            [items]="titles"
                            bindLabel="name"
                            [multiple]="false"
                            placeholder="Select Title"
                            class="form-control"
                            [clearable]="false">
                        </ng-select>
                        <input-validation [control]="form.salutation"
                            [submit]="submitted"></input-validation>
                    </div>
                    <div class="form-group col-md-5 form-right-space"
                        [ngClass]="{error : submitted && form.fname.errors}">
                        <span class="p-float-label">
                            <input autocomplete="off"
                                onkeypress="return /[a-z ]/i.test(event.key)"
                                formControlName="fname"
                                class="form-control"
                                type="text"
                                id="fname"
                                pInputText
                                maxlength="45" />
                            <label for="fname">First Name</label>
                        </span>
                        <input-validation [control]="form.fname" [submit]="submitted"></input-validation>
                        <!-- <div class="errorbox"
                            *ngIf="submitted && form.fname.errors">
                            <span *ngIf="!form.fname.valid">First name can not be empty</span>
                        </div> -->
                    </div>
                    <div class="form-group col-md-5 form-right-space">
                        <span class="p-float-label">
                            <input autocomplete="off"
                                onkeypress="return /[a-z ]/i.test(event.key)"
                                formControlName="lname"
                                class="form-control"
                                type="text"
                                id="lname"
                                pInputText
                                maxlength="45" />
                            <label for="lname">Last Name</label>
                        </span>
                    </div>
                    <div class="form-group col-md-5 form-right-space"
                        [ngClass]="{error : submitted && form.email.errors}">
                        <span class="p-float-label">
                            <input autocomplete="off"
                                formControlName="email"
                                class="form-control"
                                type="text"
                                id="email"
                                pInputText />
                            <label for="email">Email</label>
                        </span>
                        <input-validation [control]="form.email" [submit]="submitted"></input-validation>
                        <!-- <div class="errorbox"
                            *ngIf="submitted && form.email.errors">
                            <span *ngIf="form.email.errors.required">Email can not be empty</span>
                            <span *ngIf="form.email.errors.email">Invalid email address</span>
                        </div> -->
                    </div>
                    <div class="form-group col-md-5 form-right-space"
                        [ngClass]="{error : submitted && form.phone.errors}">
                        <span class="p-float-label">
                            <input autocomplete="off"
                                onkeypress="return /[0-9+]/i.test(event.key)"
                                formControlName="phone"
                                class="form-control"
                                type="text"
                                maxlength="16"
                                id="phone"
                                pInputText />
                            <label for="phone">Phone</label>
                        </span>
                        <ng-container *ngIf="registerForm.get('phone').hasError('pattern')">
                            <span class="errorbox">* Invalid mobile number.</span>
                        </ng-container>
                        <input-validation [control]="form.phone" [submit]="submitted"></input-validation>
                        <!-- <div class="errorbox"
                            *ngIf="submitted && form.phone.errors">
                            <span *ngIf="!form.phone.valid">Phone can not be empty</span>
                        </div> -->
                    </div>
                    <div class="form-group col-md-5 form-right-space"
                        [ngClass]="{error : submitted && form.password.errors}">
                        <span class="p-float-label">
                            <input autocomplete="off"
                                formControlName="password"
                                class="form-control password-field"
                                [type]="isTextFieldType ? 'text' : 'password'"
                                id="password"
                                pInputText
                                [(ngModel)]="password"
                                maxlength="50" />
                            <label for="password">Password</label>
                            <button class="eye-icon-btn"
                                (click)="togglemyPasswordFieldType()"
                                type="button">
                                <i [class]="isTextFieldType ? 'fa fa-eye' : 'fa fa-eye-slash'"
                                    aria-hidden="true"></i>
                            </button>
                        </span>
                        <input-validation [control]="form.password" [submit]="submitted"></input-validation>
                        <!-- <div class="errorbox"
                            *ngIf="submitted && form.password.errors">
                            <span *ngIf="!form.password.valid">Please enter password with minimum 6 characters</span>
                        </div> -->
                    </div>
                    <div class="form-group col-md-5 form-right-space"
                        [ngClass]="{error : submitted && form.confirm_password.errors}">
                        <span class="p-float-label">
                            <input autocomplete="off"
                                formControlName="confirm_password"
                                class="form-control password-field"
                                [type]="isTextFieldConfirmType ? 'text' : 'password'"
                                id="confirm_password"
                                pInputText
                                maxlength="50" />
                            <label for="confirm_password">Confirm Password</label>
                            <button class="eye-icon-btn"
                                (click)="togglemyConfirmPasswordFieldType()"
                                type="button">
                                <i [class]="isTextFieldConfirmType ? 'fa fa-eye' : 'fa fa-eye-slash'"
                                    aria-hidden="true"></i>
                            </button>
                        </span>
                        <input-validation [control]="form.confirm_password"
                            [submit]="submitted"></input-validation>
                        <!-- <div class="errorbox" *ngIf="submitted && form.confirm_password.errors?.required">
                            <span>Please enter password to confirm</span>
                        </div>
                        <div class="errorbox" *ngIf="submitted && form.confirm_password.errors?.notSame">
                            <span>Password not matched</span>
                        </div> -->
                    </div>
                    <div class="form-group col-md-10 form-right-space"
                        [ngClass]="{error : submitted && form.company_name.errors}">
                        <span class="p-float-label">
                            <p-autoComplete [style]="{'width':'100%'}"
                                formControlName="company_name"
                                id="company_name"
                                [suggestions]="companySuggestions"
                                (completeMethod)="searchCompany($event)"
                                (onSelect)="selectCompany($event)"
                                [readonly]="companyId != null"
                                maxlength="100">
                                <ng-template let-suggestion
                                    let-i="index"
                                    pTemplate="item"
                                    styleClass="wid100">
                                    <div class="search-ship ui-helper-clearfix"
                                        style="border-bottom:1px solid #D5D5D5;">
                                        <p>{{suggestion.name}}</p>
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                            <i *ngIf="companyId != null"
                                class="fa fa-times-circle remove-icon"
                                aria-hidden="true"
                                (click)="clearCompanySelection()"></i>
                            <label for="company_name">Company</label>
                        </span>
                        <input-validation [control]="form.company_name"
                            [submit]="submitted"></input-validation>
                        <!-- <div class="errorbox"
                            *ngIf="submitted && form.company_name.errors">
                            <span *ngIf="!form.company_name.valid">Company name can not be empty</span>
                        </div> -->
                    </div>
                    <div class="form-group col-md-5 form-right-space"
                        *ngIf="companyId==null"
                        [ngClass]="{error : submitted && form.country_id.errors}">
                        <ng-select formControlName="country_id"
                            [items]="countries"
                            bindLabel="name"
                            bindValue="id"
                            [multiple]="false"
                            placeholder="Select Country"
                            class="form-control"
                            [clearable]="false"
                            (change)="selectCountry($event)">
                        </ng-select>
                        <input-validation [control]="form.country_id"
                        [submit]="submitted"></input-validation>
                        <!--          <span class="ui-float-label">-->
                        <!--              <input autocomplete="off" formControlName="country_id" class="form-control" type="text" id="company" pInputText/>-->
                        <!--              <label for="country">Country</label>-->
                        <!--          </span>-->


                        <!-- <div class="errorbox"
                            *ngIf="submitted && form.country_id.errors">
                            <span *ngIf="!form.country_id.valid">Country can not be empty</span>
                        </div> -->
                    </div>

                    <div class="form-group col-md-5 form-right-space"
                        *ngIf="companyId==null"
                        [ngClass]="{error : submitted && form.currency_code.errors}">
                        <!--          <span class="ui-float-label">-->
                        <!--              <input autocomplete="off" formControlName="currency_code" class="form-control" type="text" id="currency_code" pInputText maxlength="5"/>-->
                        <!--              <label for="currency_code">Currency Code</label>-->
                        <!--          </span>-->
                        <ng-select formControlName="currency_code"
                            [items]="currencies"
                            [multiple]="false"
                            bindLabel="name"
                            bindValue="id"
                            placeholder="Currency"
                            class="form-control"
                            [clearable]="false"
                            [ngModel]="currencyCode">
                        </ng-select>
                        <input-validation [control]="form.currency_code"
                        [submit]="submitted"></input-validation>
                        <!-- <div class="errorbox"
                            *ngIf="submitted && form.currency_code.errors">
                            <span *ngIf="!form.currency_code.valid">Currency code can not be empty</span>
                        </div> -->
                    </div>
                    <div class="form-group col-md-5 form-right-space"
                        [ngClass]="{error : submitted && form.ship_name.errors}">
                        <span class="p-float-label">
                            <p-autoComplete [style]="{'width':'100%'}"
                                formControlName="ship_name"
                                id="ship_name"
                                [suggestions]="shipSuggestions"
                                (completeMethod)="searchShip($event);"
                                (onSelect)="selectShip($event)"
                                [readonly]="shipId != null"
                                maxlength="100">
                                <ng-template let-suggestion
                                    let-i="index"
                                    pTemplate="item"
                                    styleClass="wid100">
                                    <div class="search-ship ui-helper-clearfix"
                                        style="border-bottom:1px solid #D5D5D5;">
                                        <p>{{suggestion.name}}</p>
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                            <label for="ship_name">Ship</label>
                            <i *ngIf="shipId != null"
                                class="fa fa-times-circle remove-icon"
                                aria-hidden="true"
                                (click)="clearShipSelection()"></i>
                        </span>
                        <input-validation [control]="form.ship_name"
                        [submit]="submitted"></input-validation>
                        <!-- <div class="errorbox"
                            *ngIf="submitted && form.ship_name.errors">
                            <span *ngIf="!form.ship_name.valid">Ship name can not be empty</span>
                        </div> -->
                    </div>
                    <div class="form-group col-md-5 form-right-space"
                        [ngClass]="{error : submitted && emptyImo && form.imo_number.errors}"
                        *ngIf="shipId==null">
                        <span class="p-float-label">
                            <input autocomplete="off"
                                pKeyFilter="pnum"
                                formControlName="imo_number"
                                (keyup)="imoCheck($event)"
                                class="form-control"
                                type="text"
                                maxlength="25"
                                id="imo_number"
                                pInputText />
                            <label for="imo_number">IMO Number</label>
                        </span>
                        <input-validation [control]="form.imo_number"
                        [submit]="submitted"></input-validation>
                        <!-- <div class="errorbox"
                            *ngIf="submitted && emptyImo">
                            <span>IMO Number can not be empty</span>
                        </div> -->
                    </div>
                    <div class="form-group col-md-5 form-right-space"
                        [ngClass]="{error : submitted && emptyPrefix}"
                        *ngIf="shipId==null">
                        <span class="p-float-label">
                            <input autocomplete="off"
                                formControlName="prefix"
                                onkeypress="return /[a-zA-Z0-9\s]/i.test(event.key)"
                                (keyup)="prefixCheck($event)"
                                class="form-control"
                                type="text"
                                id="prefix"
                                maxlength="50"
                                pInputText />
                            <label for="prefix">Prefix</label>
                        </span>
                        <div class="errorbox"
                            *ngIf="submitted && emptyPrefix">
                            <span>Enter valid prefix</span>
                        </div>
                    </div>
                    <div class="form-group col-md-5 form-right-space"
                        [ngClass]="{error : submitted && emptyCallSign}"
                        *ngIf="shipId==null">
                        <span class="p-float-label">
                            <input autocomplete="off"
                                formControlName="call_sign"
                                onkeypress="return /[a-zA-Z0-9]/i.test(event.key)"
                                (keyup)="callSignCheck($event)"
                                class="form-control"
                                type="text"
                                id="call_sign"
                                pInputText />
                            <label for="call_sign">Call Sign</label>
                        </span>
                        <div class="errorbox"
                            *ngIf="submitted && emptyCallSign">
                            <span>Call Sign can not be empty</span>
                        </div>
                    </div>
                </div>
                <div class="row form-row">
                    <div class="form-group mt-4">
                        <button class="btn btn-primary"
                            type="submit">Register</button>
                    </div>
                </div>
                <div class="row form-row">
                    <div class="form-group">
                        <a routerLink='/login'>Already registered? Back to login</a>
                    </div>
                </div>
            </form>
            <div class="copy-rights">
                © {{ currentYear }} Shipskart Marine Private Limited. All rights reserved
            </div>
        </div>
    </div>
</div>

<ng-template #accountInProcess
    class="modal-sm">
    <div class="modal-body text-center padding20">
        <img class="mt-4"
            width="100"
            src="/assets/images/account-verify.png"
            alt="image">
        <h4 class="sure-font mt-4">Thank you for registering with ShipsKart!</h4>
        <h6 class="mt-4">
            Your account verification is under process. You will receive an email confirmation once your account has
            been approved.
        </h6>
        <hr />
        <h5 class="small mt-4">
            In case of an urgent requirement, feel free to reach us at <b
                class="text-nowrap">contact@shipskart.com</b>
        </h5>
        <a class="btn btn-block btn-primary mt-4"
            routerLink='/login'
            (click)="modalRef.hide()"
            type="button">Okay</a>
    </div>
</ng-template>
