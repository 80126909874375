<div class="box top-index" *ngIf="util.dashboard">
    <div class="head">
        <h3 class="font-weight-bolder bmargin30">ADMIN DASHBOARD</h3>
        <div>
            <button class="btn btn-primary bmargin30 lmargin10" (click)="showModal(vendorRatingModal);vendorRate();"> <span><img src="/assets/icons/vendor_rating.png" alt=""></span> Vendor Rating</button>
            <button class="btn btn-primary reqQuotation bmargin30 lmargin10" *ngIf="subAdminUploadFile || role == userRole.ROLE_ADMIN" (click)="fileName = null;openImportModal(importRequisitionQuotation);"><span
            class="fa fa-upload" ></span>Import Requirement
                <!-- <ul class="inner-list1 outer"> -->
                    <!-- <li *ngIf="showDraft"><a routerLink="/dashboard/new-requisition-quotation">Saved Draft</a></li> -->
                    <!-- <li><a (click)="fileName = null;openImportModal(importRequisitionQuotation);">New Requirement</a></li> -->
                    <!-- <li><a (click)="openFilters = !openFilters">Export RFQ Report</a></li> -->

                <!-- </ul> -->
            </button>
            <button class="btn btn-primary bmargin30 reqQuotation" (click)="exportRfqreport(rfqReport) "><i class="fa fa-download"></i> RFQ Report
            </button>

            <ng-template #rfqReport>
                <div class="rfq-filteroptions" >
                    <a href="javascript:void(0)" (click)="rfqReportModal.hide()" class="close-filter-btn">&#215;</a>
                    <label class="required-some-data" *ngIf="msg">Please Enter Some Value to Filter Data.</label>
                    <form [formGroup]="filterForm" (ngSubmit)="filterData()">
                        <div class="row">
                            <div class="col-md-6 col-sm-6 bmargin20 form-group">
                                <label for="">Date Range (Import Date)</label>
                                <div class="row">
                                    <div class="col-md-6">
                                        <p-calendar formControlName="from_date" placeholder="From Date" dateFormat="yy-mm-dd" [maxDate]="maxDateValue" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2099" (onSelect)="onChangeEta($event)">
                                        </p-calendar>
                                    </div>
                                    <div class="col-md-6">
                                        <p-calendar formControlName="to_date" placeholder="To Date" dateFormat="yy-mm-dd" [minDate]="minDate" [maxDate]="maxDateValue" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2099" >
                                        </p-calendar>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-6 bmargin20 form-group">
                                <label for="">Sub - Admin</label>
                                <ng-select [items]="users" bindLabel="fname" bindValue="id" [multiple]="false" formControlName="sub_admin_user" [loading]="loading" placeholder="Select User" class="form-control">
                                </ng-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <input class="btn btn-outline-primary" type="button" value="Clear Filter" (click)="resetForm(1)" />
                                <button class="btn btn-primary" [disabled]="false" type="submit" > <span [ngClass]="{'loading-spinner small':rfqLoading}"></span> Apply</button>
                            </div>
                        </div>
                    </form>
                </div>
            </ng-template>


        </div>
    </div>
    <div class="row">
        <div class="col-md-6 bmargin30">
            <div class="card new-card">
                <h5 class="card-header">Requisitions
                    <span class="loading-spinner small" *ngIf="util.loading"></span>
                    <span [class.download-option]="util.loading" class="dropdown dropleft">
            <a class="more-btn" data-toggle="dropdown" aria-haspopup="true"
               aria-expanded="false"><span class="more-dots"></span></a>
                    <div class="dropdown-menu float-right" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" (click)="util.getDashboard(1,'xlsx')">Download as Excel</a>
                        <a class="dropdown-item" (click)="util.getDashboard(1,'pdf')">Download as PDF</a>
                    </div>
                    </span>
                </h5>
                <div class="card-body new-body">
                    <app-piechart [graphData]="util.reqReportData" legendPosition="right" (chartClicked)="util.reqDataClicked($event)"></app-piechart>
                </div>
            </div>
        </div>
        <div class="col-md-6 bmargin30">
            <div class="card new-card orders">
                <h5 class="card-header">Orders
                    <span class="loading-spinner small" *ngIf="util.loadingOrders"></span>
                    <span [class.download-option]="util.loadingOrders" class="dropdown dropleft">
            <a class="more-btn" data-toggle="dropdown" aria-haspopup="true"
               aria-expanded="false"><span class="more-dots"></span></a>
                    <div class="dropdown-menu float-right" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" (click)="util.getDashboard(1,'xlsx', 1)">Download as Excel</a>
                        <a class="dropdown-item" (click)="util.getDashboard(1,'pdf', 1)">Download as PDF</a>
                    </div>
                    </span>
                </h5>
                <div class="card-body new-body">
                    <app-piechart [graphData]="util.orderReportData" legendPosition="right" (chartClicked)="util.orderDataClicked($event)"></app-piechart>
                    <a class="pull-right product-request-label" *ngIf="role!=userRole.ROLE_SUBADMIN" routerLink='/product/request'>New Product Requests <span
            class="count">{{util.newProductRequestCount}}</span></a>
                </div>
            </div>
        </div>
        <div class="col-md-12 tmargin10">
            <app-category-expenditure [portList]="portList" [categoryList]="categoryList" [shipList]="shipList" [role]="1"></app-category-expenditure>
        </div>
        <div class="col-md-12 tmargin40">
            <app-ship-comparison [portList]="portList" [categoryList]="categoryList" [shipList]="shipList"></app-ship-comparison>
        </div>
    </div>
</div>
<ng-template #vendorRatingModal>
    <div class="vendor-popup">
        <h4 class="sure-font text-center font22 weight600 tmargin10 bmargin20"><span><i class="fa fa-star animated-star"></i></span>   Top 20 Vendor Companies <i class="fa fa-star animated-star"></i><a class="popup-close" (click)="modalRef.hide()">&#10006;</a></h4>
        <h4 class="text-center tmargin10 loading" *ngIf="showLoading">Loading...</h4>
        <div class="tabular-detail data-table batch remove-margin" *ngIf="!showLoading">
            <div class="scroll-table">
            <table class="table full-table req-table price update new batch-info">
                <thead>
                    <tr>
                    <th width="50">S.No.</th>
                    <th width="350">Vendor Company Name</th>
                    <th>Vendor Rating</th>
                    <th>Delivery Strength</th>
                    </tr>
                </thead>
                <tbody>
                <tr *ngFor="let vendor of vendorRates; index as i">
                    <td>{{i+1}}.</td>
                    <td><a href="javascript:void(0)" routerLink="/company/details/{{vendor.id}}" (click)="modalRef.hide()">{{vendor.name | titlecase}}</a></td>
                    <td>
                        <div class="avgrating text-center" [ngClass]="{
                            'best' : vendor.average_ratings > 3.5,
                            'good' : (vendor.average_ratings <= 3.5 && vendor.average_ratings >2),
                            'low':  vendor.average_ratings <=2}">
                         <span (click)="openRatingModal(vendor.id)"><span class="weight600">{{vendor.average_ratings}} </span><i class="fa fa-star"></i></span>
                        </div>
                    </td>
                    <td><span class="weight700">{{vendor.delivery_strength}}%</span></td>
                </tr>
                <tr *ngIf="vendorRates.length==0">
                    <td colspan="4">No Vendor Rating Available!</td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>
        <div class="view text-right" *ngIf="vendorRates.length>0"><a routerLink="/company" [queryParams]="{ type: 2 }" (click)="modalRef.hide()">View All</a></div>
    </div>
</ng-template>
<app-order-rating #orderRating></app-order-rating>
<ng-template #importRequisitionQuotation>
    <div class="modal-body padding30 text-center">
        <h4 class="sure-font bmargin20">Import Request for Quotation</h4>
        <div class="text-left form-group" [ngClass]="{error : submitted && !selectFile}">
            <label>Select File<span class="mendatory">*</span></label>
            <div class="select-file drop-file">
                <span class="btn-span">+</span>
                <input type="file" accept=".xlsx,.xls,.docx,.pdf" multiple #inputFile class="form-control" (change)="selectedfileName($event.target.files)" /> {{fileName || 'File Name'}}
            </div>
            <small class="note-color color-000">Note: File type should be Excel(.xlsx, .docx, .pdf)</small><br>
            <small *ngFor="let name of fileNameList; index as i"><b>{{i+1}}</b>. {{name.name}} <br> </small>
            <div class="errorbox" *ngIf="submitted && !selectFile">
                <span>Select a file</span>
            </div>
            <div class="errorbox" *ngIf="inValidFileSize">
                File is larger than <strong>5mb !!</strong>
            </div>
        </div>
        <div class="text-right">
            <button class="btn btn-outline-primary" (click)="modalRef.hide();fileNameList = [];fileName = null;selectFile = false;submitted=false" type="button">Cancel</button>
            <button type="submit" [disabled]="isLoading" (click)="uploadReqQuotation()" class="btn btn-primary text-white">Upload
                  <ng-container *ngIf="isLoading">
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span class="sr-only">Loading...</span>
                  </ng-container>
              </button>
        </div>
    </div>
</ng-template>
