// tslint:disable:variable-name
export class KycDetails {
  company_name?: string;
  email?: string;
  vendor_id?: string;
  company_address_city?: string;
  company_address_country?: string;
  company_address_email?: string;
  company_address_line_one?: string;
  company_address_line_two?: string;
  company_address_isd_code?: string;
  company_address_mobile?: string;
  company_address_telephone?: string;
  company_address_pin_code?: string;
  mailing_address_city?: string;
  mailing_address_country?: string;
  mailing_address_email?: string;
  mailing_address_line_one?: string;
  mailing_address_line_two?: string;
  mailing_address_isd_code?: string;
  mailing_address_mobile?: string;
  mailing_address_telephone?: string;
  mailing_address_pin_code?: string;
  company_website?: string;
  gst_number?: string;
  gst_certificate?: string;
  authorized_agent_certificate_file?: string;
  company_categories?: any;
  nature_of_business?: any;
  type_of_business?: string;
  parent_company_name?: string;
  number_of_full_time_employees?: string;
  registration_number?: string;
  services_offered?: string;
  year_established?: string;
  s3_url?: string;
  kyc_status?: number;
  form_filled?: number;
  contact_person_details?: ContactPersonDetails[];
  country_and_port_of_services?: CountryAndPortOfServices[];
  vendor_detail?: Vendor[];
  currencies?: string;
}

export class ContactPersonDetails {
  name?: string;
  mobile?: string;
  email?: string;
  designation?: string;
  is_primary?: boolean;
}

export class CountryAndPortOfServices {
  country_id: number;
  port_id: number[];
}

export class FinancialDetails {
  vendor_id?: string;
  email?: string;
  beneficiary_name?: string;
  bank_name?: string;
  iban_swift?: string;
  ifsc_code?: string;
  credit_period?: string;
  bank_supporting_documents?: string;
  s3_url?: string;
  annual_sale_details?: AnnualSaleDetails[];
  previous_service_details?: PreviousServiceDetails[];
}

export class AnnualSaleDetails {
  year?: string;
  total_sales?: string;
  currency?: string
}

export class PreviousServiceDetails {
  company_name?: string;
  company_address?: string;
  contact_person?: string;
}

export class TechnicalDetails {
  email?: string;
  vendor_id?: string;
  qa_certificates_detail?: QADetails[];
  representation?: string;
  name?: string;
  designation?: string;
  digital_signature?: string;
  qa_cert_1?: string;
  qa_cert_2?: string;
  qa_cert_3?: string;
  qa_cert_4?: string;
  qa_cert_5?: string;
  s3_url?: string;
}

export class QADetails {
  qa_cert_1?: string;
  qa_cert_2?: string;
  qa_cert_3?: string;
  qa_cert_4?: string;
  qa_cert_5?: string;
  document?: string;
}

export class Vendor {
  id?: string;
  country_id?: string;
  status?: string;
  name?: string;
}

export class KYCRequest {
  created_at: Date;
  vendor_id: number;
  email: string;
  vendor: Vendor;
  number_of_requests_sent: number;
  created_by: string;
  updated_by: string;
}