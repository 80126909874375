<div class="box top-index" *ngIf="util.dashboard">
    <div class="bottom-line bmargin46 vendor-heading" style="min-height: 50px;">
        <div class="sub-vendor">
            <h3 class="font-weight-bolder">DASHBOARD</h3>
            <button class="btn btn-primary bmargin10 reqQuotation pull-right"
                    (click)="navigate()"> RFQ's from Shipskart
                </button>
        </div>        
        <div class="vendor-subhead"
            *ngIf="util.rating != null && util.rating != 0.0">
            <div>
                <span class="rating-text">Rating: <span (click)="openRatingModal(vendorId)"
                        class="rating-box">{{util.rating}} <i class="fa fa-star"></i></span></span>
                <!-- <div class="separator"></div> -->
                <span class="rating-text">Delivery Strength: <span><b>{{util.deliveryStrength}}</b>%</span></span>
            </div>
        </div>
    
    
    </div>

    <div class="row">
        <div class="col-md-6" *ngIf="!util.orderReport">
            <div class="empty-dashboard vendor">
                <img src="/assets/images/empty-report.svg" alt="no-reports">
                <h2><span>Oops!</span>Looks like you haven't received any Order yet?</h2>
            </div>
        </div>
        <div class="col-md-6" *ngIf="util.orderReport">
            <div class="card new-card vendor">
                <h5 class="card-header">Orders
                    <span class="loading-spinner small" *ngIf="util.loading"></span>
                    <span [class.download-option]="util.loading" class="dropdown dropleft">
            <a class="more-btn" data-toggle="dropdown" aria-haspopup="true"
               aria-expanded="false"><span class="more-dots"></span></a>
                    <div class="dropdown-menu float-right" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" (click)="util.getDashboard(1,'xlsx', 1)">Download as Excel</a>
                        <a class="dropdown-item" (click)="util.getDashboard(1,'pdf', 1)">Download as PDF</a>
                    </div>
                    </span>
                </h5>
                <div class="card-body new-body">
                    <app-piechart [graphData]="util.orderReportData" legendPosition="right" (chartClicked)="util.orderDataClicked($event)"></app-piechart>
                    <!-- <a class="pull-right rfq-label" routerLink='/RFQs'>RFQ Received <span
            class="count">{{rfqCount}}</span></a> -->
                </div>
            </div>
        </div>
        <div class="col-md-6 rfq-count-box">
            <div class="card new-card rfq-count">
                <div class="card-header">
                    <h5 class="head">Request For Quotations</h5>
                    <p class="subhead">Received from Ships</p>
                </div>
                <div class="card-body new-body">
                    <h2 class="count">{{util.rfqCount ? util.rfqCount : '0'}}</h2>
                    <button type="button" class="update-all" routerLink='/RFQs'>Update All</button>
                </div>
            </div>
            <div class="card new-card rfq-count rfq-expire">
                <div class="card-header">
                    <h5 class="head">Product Price Expiry</h5>
                    <p class="subhead">Product prices expiring this week</p>
                </div>
                <div class="card-body new-body">
                    <p *ngIf="loaderView1">Loading...</p>
                    <h2 *ngIf="!loaderView1" class="count">{{goingToExpireProduct ? goingToExpireProduct : '0'}}</h2>
                    <button type="button" class="update-all" routerLink="{{goingToExpireProduct ? '/RFQs/products-expired' : '/product/prices'}}">Update Prices</button>
                </div>
            </div>
        </div>
        <div class="col-md-12 tmargin40 mb-3">
            <app-category-expenditure *ngIf="util.orderReport" [role]="2" [shipList]="shipList" [categoryList]="categoryList" [portList]="portList"></app-category-expenditure>
            <!-- chartTitle="Category Wise Revenue" subChartTitle="Subcategory Wise Revenue" -->
        </div>
    </div>
</div>
<app-order-rating #orderRating></app-order-rating>