import {Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({
  selector: '[appCalendarUiCorrection]',
  standalone: true
})
export class CalendarUiCorrectionDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    const pCalendar = this.el.nativeElement;

    // Remove all classes from the p-calendar element
    this.renderer.setAttribute(pCalendar, 'class', '');
    this.renderer.setAttribute(pCalendar, 'appendTo', 'body');

    // Find the input element inside p-calendar
    const inputElement = pCalendar.querySelector('input');

    // Add the form-control class to the input element
    if (inputElement) {
      this.renderer.setAttribute(inputElement, 'class', '');
      this.renderer.addClass(inputElement, 'form-control');
    }
  }
}
