import { UserRoles } from './../../../core/enums/user-roles';
import { SharedService } from '@app/shared/service/shared.service';
import { environment } from '@environment/environment';
import { User } from './../models/user.modal';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
  userRole = UserRoles;
  profileImage = new BehaviorSubject(this.userProfileImage);
  constructor(private http: HttpClient, private sharedService: SharedService) { }

  /**
   * This method used for listing of users(subadmin,captain,vendors and managers).
   * page: use for perpage number.
   * limit: send limit to show number of list to show on listing,
   * is_admin : for user is send is_admin=0 , subadmin list send is_admin=1.*/
  getAll(page: number, limit: number, headers = null, search = '', is_admin = 0, isDropdownlist = 0) {
    return this.http
      .get<{ data }>(
        `${environment.apiUrl}/v1/user/?page=${page}&limit=${limit}&search=${search}&is_admin=${is_admin}&is_dropdown_selection=${isDropdownlist}`, { headers }
      )
      .pipe(
        map(data => {
          return data.data;
        })
      );
  }

  /**
   * ExportAll call to export excel file.
   * headers: pass the applied filter data .*/
  exportAll(headers = null) {
    return this.http
      .get(
        `${environment.apiUrl}/v1/user/export?`,
        { headers, responseType: 'blob' }
      )
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getDesignation(page: number, limit: number) {
    return this.http
      .get<{ data }>(
        `${environment.apiUrl}/v1/role/designation?page=${page}&limit=${limit}`
      )
      .pipe(
        map(data => {
          return data.data;
        })
      );
  }

  /**
   * GetbyId: this method is not using anywhere. Thats why commented at(23 March 2024).*/

  /* getbyId(id) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/user/${id}`).pipe(
      map(dataVal => {
        return dataVal.data;
      })
    );
  } */

  addUser(user: any) {
    // const role = this.sharedService.getUserRole();
    return this.http.post(`${environment.apiUrl}/v1/user/company-member`, user);
  }
  editUser(user: User) {
    return this.http.put(
      `${environment.apiUrl}/v1/user/company-member/${user.id}`,
      user
    );
  }

  assignRole(data) {
    return this.http.post(`${environment.apiUrl}/v1/role/assign`, data);
  }

  getProfile() {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/user/profile`)
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }
  getDashboard(companyId = null, download = 0, format = 'json', orderReport = 0) {
    let params = new HttpParams()
      .append('download', download.toString())
      .append('format', format)
      .append('order_report', orderReport.toString());
    if (companyId != null) {
      params = params.append('company_id', companyId);
    }
    if (download) {
      return this.http.get(`${environment.apiUrl}/v1/user/dashboard`, { params: params, responseType: 'blob' });
    } else {
      return this.http.get<{ data }>(`${environment.apiUrl}/v1/user/dashboard`, { params })
        .pipe(map(dataVal => {
          return dataVal.data;
        }));
    }
  }
  downloadDashboard(formate) {

    const params = new HttpParams()
      .set('download', '1')
      .set('format', formate);
    return this.http
      .get(`${environment.apiUrl}/v1/user/dashboard`, { params, responseType: 'blob' });
  }
  getProfileById(id: number) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/user/profile/${id}`)
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }
  editUserInfo(user: User) {
    return this.http.put(
      `${environment.apiUrl}/v1/user/profile/${user.user_id}`,
      user
    );
  }

  getCompanyMemberAll(
    page: number,
    limit: number,
    companyId: number = null,
    isCaptain: number = 0
  ) {
    let params;

    params = new HttpParams()
      .set('page', `${page}`)
      .set('limit', `${limit}`)
      .set('is_captain', `${isCaptain}`)
      .set('company_id', `${companyId}`);

    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/user/company-member`, { params })
      .pipe(
        map(data => {
          return data.data;
        })
      );
  }

  getCompanyMembers(
    limit: number,
    companyId: number,
    isCaptain: number = -1
  ) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/user/company-member?limit=${limit}&company_id=${companyId}&is_captain=${isCaptain}`)
      .pipe(
        map(data => {
          return data.data;
        })
      );
  }

  changePassword(password, token) {
    return this.http.post(
      `${environment.apiUrl}/v1/user/forget/${token}`,
      password
    );
  }

  requestPassword(email) {
    return this.http.post(
      `${environment.apiUrl}/v1/user/forget`,
      email
    );
  }
  /**
   * getUserSuggetion: This method used for search user and get suggetioons.
   * search param: user for text whatever seraching.
   * companyId param:
   * role param:
   * is_admin : for user is send is_admin=0 , subadmin list send is_admin=1.*/
  getUserSuggestion(search, companyId = null, role = null, is_admin = 0) {
    let url;
    if (role !== this.userRole.ROLE_ADMIN) {
      url = `${environment.apiUrl}/v1/user/search/${search}/${companyId}?is_admin=${is_admin}`;
    } else {
      url = `${environment.apiUrl}/v1/user/search/${search}/0?is_admin=${is_admin}`;
    }
    return this.http.get<{ data }>(url).pipe(
      map(dataVal => {
        return dataVal;
      })
    );
  }

  confirmEmail(token) {
    const url = `${environment.apiUrl}/v1/user/confirm-email?token=${token}`;
    return this.http.post(url, null);
  }

  delete(userId, companyId = null): Observable<any> {
    const url = `${environment.apiUrl}/v1/user/company-member/${userId}` + (companyId != null ? `/company/${companyId}` : ``);
    return this.http.delete<{ data }>(url).pipe(
      map(dataVal => {
        return dataVal.data;
      }));
  }

  uploadProfile(formData): Observable<any> {
    return this.http.post(`${environment.apiUrl}/v1/user/profile-image`, formData);
  }

  removeProfile(id): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/v1/user/profile-image/${id}`);
  }

  getProfilePic(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/v1/user/profile-image/${id}`);
  }

  set userProfileImage(value) {
    this.profileImage.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('userProfileImage', value);
  }

  get userProfileImage() {
    return localStorage.getItem('userProfileImage');
  }

  getVendorRating() {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/company/company_wise_ratings`).pipe(map(data => data.data))
  }

  /**
 * Assigns ships to a user.
 *
 * payload - The payload containing the necessary information for assigning ships.
 * An observable that emits the data containing the assigned ships.
 */
  assignShips(payload) {
    const header = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post<{ data }>(`${environment.apiUrl}/v1/user/assign_ship`, payload, { headers: header }).pipe(map(data => data.data));
  }

  getShipDetails(user_id) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/user/assign_ship?user_id=${user_id}`).pipe(map(data => data.data))
  }

  postFleet(val, check) {
    if (check == 1) {
      val = val.toString()
      return this.http.get<{ data }>(`${environment.apiUrl}/v1/user/assign_ship_filter?fleet_id=${val}`).pipe(map(data => data.data));
    }
    else if (check == 2) {
      val = JSON.stringify(val)
      return this.http.get<{ data }>(`${environment.apiUrl}/v1/user/assign_ship_filter?category_ids=${val}`).pipe(map(data => data.data));
    }
  }

  getLoginInfo(){
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/user/user_activity_login_log`).pipe(map(data => data.data));
  }

  deleteUserLoginDevice(id : number){
    return this.http.delete<{ data }>(`${environment.apiUrl}/v1/user/user_activity_login_log/${id}`).pipe(map(data => data.data));
  }
}
