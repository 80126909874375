import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kyc-form',
  template: `<app-vendor-kyc-form></app-vendor-kyc-form>`
})
export class KycFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
