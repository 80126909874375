import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartRedirectionService {

  constructor() { }

  generateFilterBasedPayload(filter) {
    const appliedFilters = filter.filters.filter(item => item.filter_value);
    if (appliedFilters && appliedFilters.length > 0) {
      let filterPayload = {}
      appliedFilters.forEach(item => {
        switch (item.filter_id) {
          case 4:
            const fromToDate = item.filter_value.split(':');
            filterPayload['from_date'] = fromToDate[0];
            filterPayload['to_date'] = fromToDate[1];
            break;
          case 19:
            filterPayload['top_n'] = item.filter_value;
            break;
          case 21:
            filterPayload['sub_admin'] = item.filter_value;
            break;
          case 20:
            filterPayload['country_ids'] = item.filter_value;
            break;
          case 22:
            filterPayload['created_by'] = item.filter_value;
            break;
          case 18:
            filterPayload['delivery_date'] = item.filter_value;
            break;
          case 23:
            filterPayload['product_code'] = item.filter_value;
            break;
          case 12:
            filterPayload['category_ids'] = item.filter_value;
            break;
          case 6:
            filterPayload['ship_ids'] = item.filter_value;
            break;
          case 5:
            filterPayload['client_ids'] = item.filter_value;
            break;
          case 8:
            filterPayload['vendor_ids'] = item.filter_value;
            break;
          case 7:
            filterPayload['port_ids'] = item.filter_value;
            break;
          case 24:
            filterPayload['purchaser_ids'] = item.filter_value;
            break;
        }
      })
      // localStorage.setItem('filterPayload', JSON.stringify(filterPayload));
    }
  }
}
