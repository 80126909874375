import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.css']
})
export class StarRatingComponent implements OnInit, OnChanges {
  @Input() id: string;
  @Input() readOnly = false;
  @Output() ratingValue = new EventEmitter();

  star1: string;
  star2: string;
  star3: string;
  star4: string;
  star5: string;
  star6: string;
  star7: string;
  star8: string;
  star9: string;
  star10: string;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    this.star1 = 'star1' + this.id;
    this.star2 = 'star2' + this.id;
    this.star3 = 'star3' + this.id;
    this.star4 = 'star4' + this.id;
    this.star5 = 'star5' + this.id;
    this.star6 = 'star6' + this.id;
    this.star7 = 'star7' + this.id;
    this.star8 = 'star8' + this.id;
    this.star9 = 'star9' + this.id;
    this.star10 = 'star10' + this.id;
  }

  getRatings(val) {
    this.ratingValue.emit(val);
  }
}
