import { ActivatedRoute, Router, Params } from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.css'],
})
export class PagingComponent implements OnInit {
  @Input() isLoading: boolean;
  @Input() rowCount: number;
  @Input() pageSize: number;
  @Input() curPage: number;
  @Input() offset: number;
  @Input() table: any;
  @Input() filter: any;

  @Output() page: EventEmitter<any> = new EventEmitter();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  onPageChange($event) {
    const queryParams: Params = { page: $event.page, filter: JSON.stringify(this.filter) };
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });

    this.page.emit($event);
  }
}
