import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Chart } from 'chart.js';
@Component({
  selector: 'app-linechart',
  template: `<div style="width: 100%;">
                <canvas id="canvas" #lineChart></canvas>
              </div>`
})

export class LineChartComponent implements OnInit, AfterViewInit {
  chart: any;
  @Input() months;
  @Input() price;
  prodPrice: any = [];
  tax: number;
  productIncTax: number;
  steps = 3;
  max = 1500;
  @ViewChild("lineChart", { static: false }) chartContainer: ElementRef
  //price= [{month:'jan',price:100},{month:'jan',price:50},{month:'jan',price:200},{month:'jan',price:150}]
  ngOnInit(): void {
    //this.createChart();
  }

  ngAfterViewInit() {
    this.createChart();
  }
  createChart() {
    this.price.forEach(p => {
      this.tax = p.tax;
      this.prodPrice.push(p.price);
      this.productIncTax = (p.tax / 100 * p.price) + p.price;
    })
    this.chart = new Chart(this.chartContainer.nativeElement, {
      type: 'line',
      data: {
        labels: this.months,
        datasets: [
          {
            label: 'Product Price',
            data: this.prodPrice,
            borderColor: 'green',
            fill: false,
            pointBorderColor: 'red',
          }]
      },

      options: {
        plugins: {
          tooltip: {
            mode: 'index',
            intersect: false,
            displayColors: false,
            backgroundColor: '#444',
            callbacks: {
              label: (context) => {
                const { parsed } = context;
                let multistringText = [];
                multistringText.push(`Product Price : ${parsed.y}`);
                multistringText.push(`Tax: ${this.tax}%`);
                multistringText.push(`Price after Tax: ${this.productIncTax.toFixed(2)}`);
                return multistringText;
              }
            }
          },
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Months'
            }
          },
          y: {
            title: {
              display: true,
              text: '(In INR/USD)'  // Updated from labelString to text
            },
            beginAtZero: true,
            ticks: {
              stepSize: 100
            }
          }
        }
      }
    })
  }

}