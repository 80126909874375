import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback',
  template: `
  <div class="container text-center" *ngIf="isSubmitted">
   <div class="center-content">
      <div class="text-center bmargin10">
        <img src="/assets/images/white_black.svg" alt="" width="150">
      </div>
      <div>
        <h5 class='bmargin5'>Thank you for providing your feedback! Your input is invaluable to us.</h5>
        <h6 class='bmargin5'>Visit www.shipskart.com and register with us today!</h6>
      </div>
      <div class="text-center tmargin10">
        <a class="btn btn-primary" href="https://shipskart.com/">Visit Shipskart</a>
      </div>
   </div>
  </div>
  <app-feedback-form  (submitted)="onChildSubmitted($event)"></app-feedback-form>`,
  styles: [`
  .container {
   position: relative;
   height: 100vh;
  }
  .center-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  `]
})
export class FidbackFormComponent implements OnInit {
  isSubmitted = false
  constructor() { }

  ngOnInit() {

  }

  onChildSubmitted(event) {
    this.isSubmitted = true
  }

}