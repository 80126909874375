import { DatePipe } from "@angular/common";
import { HttpHeaders } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DashboardService } from "@app/dashboard/dashboard.service";
import { NewDashboard } from "@app/dashboard/models/newdashboard.model";
import { SharedService } from "@app/shared/service/shared.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Feedback } from "@app/core/enums/feedbackform";
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})

export class FilterComponent implements OnInit {
  modal: any;
  @Input() data: any;
  @Input() otherAxis
  @Input() sharedData;
  notPrimaryAxis
  selectedAxis: number;
  topN: string;
  topNID = Feedback.FILTER_ID;
  dataLength: boolean = true;
  viewDropDown: boolean = false;
  @ViewChild('filterPopupModal', { static: false }) filterPopupModal: TemplateRef<any>;
  dynamicForm: FormGroup;
  @Output() fiterData = new EventEmitter<string>();
  @Output() filterApplied = new EventEmitter<object>();
  axesAdded: boolean = false;
  disableFilter: boolean = false;
  filterLoading: boolean = false;
  constructor(private bsModalService: BsModalService, private fb: FormBuilder, private sharedService: SharedService,
    private dashboardService: DashboardService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    if (this.data && this.data.length > 0) {
      this.dynamicForm = this.buildDynamicForm(this.data);
      this.notPrimaryAxis = this.otherAxis?.axes?.filter(axes => axes.is_main_axis === false);
    }
  }

  openModel() {
    this.dynamicForm.reset();
    if (this.otherAxis?.filters?.length > 0) {
      const patchObject = {};
      this.otherAxis.filters.forEach(filter => {
        if (filter.filter_id && filter.filter_value) {
          if (filter.filter_id === this.topNID) {
            const filterValue = filter.filter_value.split(';');
            this.selectedAxis = parseInt(filterValue[1]);
            patchObject[filter.filter_id] = filterValue[0];
          } else {
            if (filter.filter.type == 'multi_select') {
              // For multi-select, convert values to integers
              let filterValue = filter.filter_value.split(',').map(value => parseInt(value.trim()))
              patchObject[filter.filter_id] = filterValue;
            } else {
              // For single-select, convert value to integer
              patchObject[filter.filter_id] = filter.filter_value;
            }
          }
        }
      })
      // Patch values to the form
      this.dynamicForm.patchValue(patchObject);
    }
    this.modal = this.bsModalService.show(
      this.filterPopupModal,
      Object.assign(
        { backdrop: 'static', class: 'gray modal-md' }
      ))
  }

  private buildDynamicForm(data): FormGroup {
    const formGroupConfig = {};
    // Iterate through the API data and add form controls dynamically
    if (data.length > 0) {
      const formGroupConfig = {};
      data.forEach(control => {
        // Check if the control is for single or multi-select
        if (control.type == 'multi_select') {
          // For multi-select
          formGroupConfig[control.filter_id] = [control.filter_values.map(value => parseInt(value) || null)];
        } else {
          // For single-select
          formGroupConfig[control.filter_id] = [parseInt(control.filter_value) || null];
        }
      });
      // Create and return the form group
      return this.fb.group(formGroupConfig);
    } else {
      // Return an empty form group if there's no data
      return this.fb.group({});
    }

  }

  // Submit filter form 
  fiterFormData(clear) {
    if (this.axesAdded) {
      this.dynamicForm.removeControl('axes')
    }
    if (clear == 1) {
      this.sharedService.setAppliedFilters(this.dynamicForm.value);
      this.getById(this.dynamicForm.value, clear)
    } else {
      if (this.otherAxis.report_type == "table" || this.otherAxis.report_type == "bar_graph" || this.otherAxis.report_type == "bar_graph_group" || this.otherAxis.report_type == "line_graph" || this.otherAxis.report_type == "line_graph_group") {
        if (this.topN) {
          this.dynamicForm.get(`${this.topNID}`).setValue(this.topN);
        }
      }
      this.sharedService.setAppliedFilters(this.dynamicForm.value)
      this.getById(this.dynamicForm.value, clear)
    }
  }

  clearFiltering
  async getById(formData, clear) {
    return new Promise((resolve, reject) => {
      let filter = {};
      this.sharedService.appliedFilters$.subscribe((filters) => {
        if (filters) {
          for (const [key, value] of Object.entries(filters)) {
            if (Array.isArray(value)) {
              filter[key] = value.join(',');
            } else {
              filter[key] = value;
            }
          }
        }
      });

      if (clear == 2) {
        this.filterLoading = true;
      }
      if (clear == 1) {
        this.clearFiltering = true;
      }

      const headers = new HttpHeaders().set('x-filter', JSON.stringify(filter));
      this.dashboardService.getReportData(this.data[0].report_id, headers, 0)
        .then((response: NewDashboard) => {
          if (response) {
            this.modal.hide();
            setTimeout(() => {
              this.filterApplied.emit(response);
              resolve(response); // Resolve the promise with the response
            }, 200);
            this.filterLoading = false;
            this.clearFiltering = false;
          }
        })
        .catch(error => {
          this.modal.hide();
          reject(error); // Reject the promise with the error
        });
    });
  }



  clearFilter() {
    this.dynamicForm.reset();
    this.fiterFormData(1)
  }

  handleItemClicked(clickedControlKey: FormData): void {
    if (clickedControlKey && clickedControlKey.value) {
      const startEndDates = clickedControlKey.value.split(' - ');
      const isFormat1 = /^[a-zA-Z]{3}\s\d{4}$/.test(startEndDates[0]);
      let modifiedValue;
      if (isFormat1) {
        modifiedValue = `${this.formatDateString(startEndDates[0])}:${this.formatDateString(startEndDates[1])}`;
      } else {
        modifiedValue = `${startEndDates[0]}:${startEndDates[1]}`
      }
      this.dynamicForm.get(clickedControlKey.formdata).setValue(modifiedValue);
    }
    console.log("after date selection", this.dynamicForm.value);
  }

  formatDateString(inputDateString: string): string {
    const date = new Date(`${inputDateString} 01`);
    return this.datePipe.transform(date, 'yyyy-MM-dd') || '';
  }

  changeTopP(event) {
    if (event && event.axis_id) {
      this.dynamicForm.get('axes').setValue(event.axis.name);
      let axis = this.dynamicForm.get(`${this.topNID}`).value;
      if (axis.includes(';')) {
        axis = axis.split(';')[0]
      }
      this.topN = `${axis};${event.axis_id}`;
    }
    if (this.dynamicForm.get('axes').value) {
      this.disableFilter = false
    }
  }
  changeTopN(event) {
    if (this.dynamicForm.get(`${this.topNID}`).value) {
      this.axesAdded = true
      this.dynamicForm.addControl('axes', this.fb.control(null, Validators.required));
      if (!this.dynamicForm.get('axes').value) {
        this.disableFilter = true
      }
    } else {
      this.axesAdded = false
      this.dynamicForm.removeControl('axes')
    }
  }
}

interface FormData {
  formdata: any,
  value: any
}