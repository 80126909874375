import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from '@app/authentication/services/loading.service';
import { FinancialDetails } from '@app/modules/kyc/models/kyc.modal';
import { KycService } from '@app/modules/kyc/services/kyc.service';
import { AppConstants } from '@app/shared/constants/app.constants';
import { SharedService } from '@app/shared/service/shared.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-financial-information',
  templateUrl: './financial-information.component.html',
  styleUrls: ['./financial-information.component.css']
})
export class FinancialInformationComponent implements OnInit {
  @Input() vendorId;
  @Input() vendorEmail;
  fileName: string;
  creditPeriods = AppConstants.CREDIT_PERIODS;
  financialDetailsForm: FormGroup;
  submitted: boolean;
  @Output() formFilled: EventEmitter<any> = new EventEmitter();
  @Output() updatePrev: EventEmitter<any> = new EventEmitter();
  @Output() updateNext: EventEmitter<any> = new EventEmitter();
  formData: FormData;
  financialDetails: FinancialDetails;
  inValidBankFileSize: boolean;
  bankDocuments: string;
  currencyDropdown = [];
  //currencies: string[] = [];
  selectedCurrency;

  constructor(
    private formBuilder: FormBuilder,
    private kycService: KycService,
    private sharedService: SharedService,
    private loadingService: LoadingService,
    private toastr: ToastrService,

  ) {
    this.formData = new FormData();
    this.currencyDropdown = this.sharedService.vendorCurrencyDropdown;
  }

  ngOnInit() {
    this.generateFinancialDetailsForm();
    this.getFinancialDetails(this.vendorId, this.vendorEmail);
  }

  generateFinancialDetailsForm() {
    this.financialDetailsForm = this.formBuilder.group({
      email: [''],
      beneficiary_name: ['', [Validators.required]],
      bank_name: ['', [Validators.required]],
      iban_swift: ['',],
      ifsc_code: ['',],
      currencies: ['', [Validators.required]],
      credit_period: [null, [Validators.required]],
      annual_sale_details: this.formBuilder.array([]),
      previous_service_details: this.formBuilder.array([]),
    });
    // Retrieve the company's currency code from the shared service
    let currency_code = this.sharedService.getCompany()?.currency_code;

    // Filter the currency dropdown options to find the one matching the company's currency code
    this.selectedCurrency = this.currencyDropdown.filter(data => {
      return data.name == currency_code;
    })[0].name;

    // Switch statement to handle different currency codes
    switch (this.selectedCurrency) {
      case 'USD': // If the selected currency is USD
        console.log("H");
        this.financialDetailsForm.get('currencies').setValue('USD');
        break;
      case 'INR': // If the selected currency is INR
        this.financialDetailsForm.get('currencies').setValue('INR');
        break;
      case 'JPY': // If the selected currency is JPY
        this.financialDetailsForm.get('currencies').setValue('JPY');
        break;
      case 'EUR': // If the selected currency is EUR
        this.financialDetailsForm.get('currencies').setValue('EUR');
        break;
      case 'SGD': // If the selected currency is SGD
        this.financialDetailsForm.get('currencies').setValue('SGD');
        break;
      case 'GBP': // If the selected currency is GBP
        this.financialDetailsForm.get('currencies').setValue('GBP');
        break;
      default: // If the currency code does not match any of the cases
        this.financialDetailsForm.get('currencies').setValue('USD');
        break;
    }
  }

  get annualSaleDetails(): FormArray {
    return this.financialDetailsForm.get('annual_sale_details') as FormArray;
  }

  get previousServiceDetails(): FormArray {
    return this.financialDetailsForm.get('previous_service_details') as FormArray;
  }

  addAnnualSaleDetails(year, totalSales, currency): FormGroup {
    return this.formBuilder.group({
      year: [year ? year : '', [Validators.required, Validators.minLength(4)]],
      total_sales: [totalSales ? totalSales : '', [Validators.required]],
      currency: [currency ? currency : '']
    });
  }

  addPreviousServiceDetails(companyName, companyAddress, contactPerson): FormGroup {
    return this.formBuilder.group({
      company_name: [companyName ? companyName : ''],
      company_address: [companyAddress ? companyAddress : ''],
      contact_person: [contactPerson ? contactPerson : '']
    });
  }

  addCurrency(currency) {
    this.selectedCurrency = currency?.id;
  }

  addAnnualSaleDetailsItem(year = null, totalSales = null, currency = null) {
    const annualSalesDetails = this.financialDetailsForm.get('annual_sale_details') as FormArray;
    annualSalesDetails.push(this.addAnnualSaleDetails(year, totalSales, currency));
  }

  addPreviousServiceDetailsItem(companyName = '', companyAddress = '', contactPerson = '') {
    const previousServiceDetails = this.financialDetailsForm.get('previous_service_details') as FormArray;
    if (previousServiceDetails && previousServiceDetails.length > 4) {
      this.toastr.error('Maximum 5 reference sources are allowed', 'Can not add reference source');
      return false;
    } else {
      previousServiceDetails.push(this.addPreviousServiceDetails(companyName, companyAddress, contactPerson));
    }
  }

  removePreviousServiceDetailsItem(i) {
    (this.financialDetailsForm.get('previous_service_details') as FormArray).removeAt(i);
  }

  goPrevious() {
    this.updatePrev.emit();
  }

  resetForm() {
    if (this.financialDetails) {
      this.financialDetailsForm.reset();
      const annualSalesDetails = (this.financialDetailsForm.get('annual_sale_details') as FormArray);
      annualSalesDetails.clear();
      const previousServiceDetails = this.financialDetailsForm.get('previous_service_details') as FormArray;
      previousServiceDetails.clear();
      this.fileName = '';
      this.getFinancialDetails(this.vendorId, this.vendorEmail, 1);
    } else {
      this.financialDetailsForm.reset();
      this.fileName = '';
    }
  }

  submitFinancialDetails() {
    this.submitted = true;
    this.financialDetailsForm.get('email').setValue(this.vendorEmail);
    if (!this.financialDetailsForm.valid || (!this.fileName && !this.bankDocuments)) {
      return false;
    } else {
      Object.keys(this.financialDetailsForm.value).forEach(key => {
        this.formData.append(key, this.financialDetailsForm.value[key]);
      });
      const FormValue = this.financialDetailsForm.value;
      this.formData.append('vendor_id', this.vendorId && this.vendorId != '0' ? this.vendorId.toString() : '');
      this.formData.delete('annual_sale_details');
      this.formData.delete('currencies');
      let financeDetail = this.financialDetailsForm.get('annual_sale_details').value;
      financeDetail = financeDetail.filter(data => {
        return data.currency = this.selectedCurrency
      })
      if (FormValue.annual_sale_details != null) {
        this.formData.append('annual_sale_details', JSON.stringify(this.financialDetailsForm.get('annual_sale_details').value));
      }
      this.formData.delete('previous_service_details');
      if (FormValue.previous_service_details != null) {
        const previousServiceObj = [];
        if (this.previousServiceDetails.controls && this.previousServiceDetails.controls.length > 0) {
          this.previousServiceDetails.controls.forEach((x, i) => {
            if (x && this.checkProperties(x.value)) {
              (this.financialDetailsForm.get('previous_service_details') as FormArray).removeAt(i);
            } else {
              previousServiceObj.push(x.value);
            }
          });
        }
        this.formData.append('previous_service_details', JSON.stringify(previousServiceObj));
      }
      this.loadingService.setLoading(true);
      this.kycService.financialDetails(this.formData).subscribe(res => {
        this.loadingService.setLoading(false);
        this.toastr.success('Successfully saved!');
        this.updateNext.emit();
        this.submitted = false;
        this.formData = new FormData();
      }, error => {
        this.loadingService.setLoading(false);
        this.formData = new FormData();
        this.toastr.error('Somthing went wrong, Please try again!');
      });
    }
  }

  getFinancialDetails(vendorId, vendorEmail, hideLoader = 0) {
    if (hideLoader === 0) {
      this.loadingService.setLoading(true);
    }
    const params = new HttpParams()
      .set('vendor_id', vendorId && vendorId != '0' ? vendorId.toString() : '')
      .set('email', vendorEmail ? vendorEmail : '');
    this.kycService.getFinancialDetails(params).subscribe((res: FinancialDetails) => {
      if (res.annual_sale_details.length && res.annual_sale_details[0].currency) {
        this.financialDetailsForm.get('currencies').setValue(res.annual_sale_details[0].currency)
      }
      else {
        // When new vendor fills the kyc form, below data array is assigned so that vendor can fill from scratch.
        let financialArr = [
          { year: '', total_sales: '0', currency: '' },
          { year: '', total_sales: '0', currency: '' },
          { year: '', total_sales: '0', currency: '' }
        ]
        res.annual_sale_details = financialArr
      }
      this.loadingService.setLoading(false);
      this.financialDetails = res;
      this.formFilled.emit(this.financialDetails);
      this.financialDetailsForm.patchValue(res);
      /* if (res.currencies) {
        const currency = res.currencies.split(',');
        this.financialDetailsForm.get('currencies').setValue(currency);
      } */
      if (res.annual_sale_details && res.annual_sale_details.length > 0) {
        res.annual_sale_details.forEach(x => this.addAnnualSaleDetailsItem(x.year, x.total_sales, x.currency));
      }
      if (res.previous_service_details && res.previous_service_details.length > 0) {
        res.previous_service_details.forEach(x => this.addPreviousServiceDetailsItem(x.company_name, x.company_address, x.contact_person));
      } else {
        this.addPreviousServiceDetailsItem();
      }
      if (res.bank_supporting_documents) {
        this.bankDocuments = res.s3_url + res.bank_supporting_documents;
        this.formData.set('bank_supporting_documents', res.bank_supporting_documents);
      }
    }, error => {
      this.loadingService.setLoading(false);
      for (let i = 0; i < 3; i++) { this.addAnnualSaleDetailsItem(); }
      this.addPreviousServiceDetailsItem();
    });
  }

  /* setCurrency(event) {
    this.currencies = [];
    if (event) {
      this.currencies.push(event);
    }
  } */

  selectedBankFileName(files: FileList) {
    this.inValidBankFileSize = false;
    if (files.length > 0) {
      const filesize = files[0].size / 1024 / 1024;
      if (filesize > 3) {
        this.inValidBankFileSize = true;
        this.fileName = '';
      } else {
        this.fileName = files[0].name;
        this.formData.set('bank_supporting_documents', files[0], files[0].name);
      }
    }
  }

  checkProperties(obj) {
    let val = [];
    for (let key in obj) {
      if (obj[key] == null || obj[key] == "") {
        val.push(false);
      }
    }
    return val && val.length === Object.keys(obj).length ? true : false;
  }
}

