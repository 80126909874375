import { CompanyType } from '@app/modules/company/models/company.enum';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'company' })
export class CompanyPipe implements PipeTransform {
  constructor() {}
  transform(value: number, parms: string) {
    return CompanyType[value];
  }
}
