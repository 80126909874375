export class DataTablePagination {
  count: number;
  limit: number;
  offset: number;
  pageSize: number;
}

export interface DataTableColumn {
  prop: string;
  name: string;
  type?: CommonTableTypes;
}

export type CommonTableTypes =
  | 'string'
  | 'check-box'
  | 'text-box'
  | 'currency'
  | 'duration'
  | 'number'
  | 'date'
  | 'link';
