import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { CategoryService } from '@app/modules/category/services/category.service';
import { SharedService } from '@shared/service/shared.service';
import { ShipService } from '@app/modules/ship/services/ship.service';
import { PortService } from '@app/modules/port/port.service';
import { LoadingService } from '@app/authentication/services/loading.service';
import { UserRoles } from '@app/core/enums/user-roles';

@Component({
  selector: 'app-ship-comparison',
  templateUrl: './ship-comparison.component.html',
  styleUrls: ['./ship-comparison.component.css']
})
export class ShipComparisonComponent implements OnInit {
  shipForms: {
    form: FormGroup,
    loading: boolean,
    loading1: boolean,
    loadingShip: boolean;
    total: number,
    total_delivery_charge: number,
    total_launch_charge: number,
    count: number,
    submitted: boolean,
    categoryExpenditure: any[],
    ship: any,
    portList: any[],
    currency: string;
  }[] = [];

  etd = new Date();
  currencyCode = '';

  showTable: Boolean;

  defaultListLoader = [{ id: -1, name: 'Loading...' }];
  @Input() shipList = this.defaultListLoader;
  @Input() categoryList = this.defaultListLoader;
  @Input() portList = [];
  loaderView = false;
  role: number;
  userRoles = UserRoles;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private datePipe: DatePipe,
    private sharedService: SharedService,
    private shipService: ShipService,
    private portService: PortService,
    private categoryService: CategoryService,
    private loadingService: LoadingService
  ) {
    this.addShipToCompare();
    this.addShipToCompare();
    this.role = this.sharedService.getUserRole();
  }

  ngOnInit() {
    //this.getShipList();
    //this.getCategoryList();
    //this.getPortList();
    if (this.role !== this.userRoles.ROLE_ADMIN)
      this.currencyCode = this.sharedService.getCompany().currency_code;
  }

  /* private getShipList() {
    this.shipService.getAll(1, 1000).subscribe(data => {
      this.shipList = data.results;
    }, error => {
      this.toastr.error(error.error.message);
    });
  } */

  private getCategoryList() {
    this.categoryService.getAllV2(1, 20).subscribe(data => {
      this.categoryList = data.data;
    }, error => {
      this.toastr.error(error.error.message, '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
    });
  }

  /* getPortList() {
    const page = 1; // this.page
    // const limit = this.limit;
    this.portService.getPortsForComparision(1, 500, 1).subscribe(res => {
      this.portList = res.results;
    });
  } */

  downloadReports(format: string) {
    let shipValidated = true;
    let dateRangeValidated = true;
    this.shipForms.forEach(item => {
      item.submitted = true;
      if (!item.form.valid) {
        shipValidated = false;
        return;
      }
      if (this.isDateRangeInvalid(item.form)) {
        dateRangeValidated = false;
        return;
      }
    });

    if (!shipValidated) {
      this.toastr.error('Please select all the ships', '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      return;
    }

    if (!dateRangeValidated) {
      this.toastr.error('"From date" can not be grater than "To date"', '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      return;
    }

    const shipIds = [];
    const portIds = [];
    const fromDates = [];
    const toDates = [];
    const categoryIds = [];
    this.shipForms.forEach(shipForm => {
      shipIds.push(shipForm.form.value.ship_id);
      portIds.push(shipForm.form.value.port_id);
      categoryIds.push(shipForm.form.value.category_id);
      fromDates.push(this.datePipe.transform(shipForm.form.value.from_date, 'yyyy-MM-dd'));
      toDates.push(this.datePipe.transform(shipForm.form.value.to_date, 'yyyy-MM-dd'));
    });
    this.loaderView = true;
    this.categoryService.getCategoryExpenditureReportMulti(
      null, shipIds, portIds, fromDates, toDates, categoryIds, 1, format).subscribe((data: any) => {
        const mime = format === 'pdf' ? 'application/pdf' : 'application/vnd.ms-excel';
        this.sharedService.saveAsBlob(
          data,
          mime,
          `ship_comparison.${format}`
        );
        this.loaderView = false;
      }, error => {
        this.toastr.error('Data not found', '',
          {
            closeButton: true,
            timeOut: 2000,
            positionClass: 'toast-bottom-left'
          });
        this.loaderView = false;
      });
  }

  getReport(shipForm: any, download = 0, format = 'json') {
    if (shipForm.loadingReport) {
      return;
    }
    shipForm.submitted = true;
    if (shipForm.form.get('from_date').value != null && shipForm.form.get('to_date').value == null) {
      shipForm.form.get('to_date').setValue(this.etd);
    }
    if (shipForm.form.get('from_date').value == null && shipForm.form.get('to_date').value != null) {
      const fromDate = new Date(2016, 0, 1);
      shipForm.form.value.from_date = this.datePipe.transform(fromDate, 'yyyy-MM-dd');
    }

    if (this.isDateRangeInvalid(shipForm.form)) {
      this.toastr.error('"From date" can not be grater than "To date"', '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      return;
    }
    if (shipForm.portList == null && shipForm.form.value.port_id == null) {
      this.toastr.error('No orders found for this ship', '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      return;
    }
    // if (shipForm.form.value.port_id == null) {
    //   this.toastr.error('Please select the port');
    //   return;
    // }
    shipForm.form.value.from_date = this.datePipe.transform(shipForm.form.value.from_date, 'yyyy-MM-dd');
    shipForm.form.value.to_date = this.datePipe.transform(shipForm.form.value.to_date, 'yyyy-MM-dd');
    shipForm.loading = true;
    shipForm.loading1 = false;
    if (download) {
      shipForm.loadingShip = true;
      shipForm.loading = false;
      shipForm.loading1 = true;
      // this.loaderView = true;
    }
    this.categoryService.getCategoryExpenditureReport(null, null, shipForm.form.value.ship_id,
      shipForm.form.value.port_id, shipForm.form.value.from_date, shipForm.form.value.to_date,
      shipForm.form.value.category_id, download, format).subscribe((data: any) => {
        if (download) {
          const mime = format === 'pdf' ? 'application/pdf' : 'application/vnd.ms-excel';
          this.sharedService.saveAsBlob(
            data,
            mime,
            shipForm.ship ? shipForm.ship.name.toLowerCase().replace(' ', '_') + `.${format}` :
              `report_${this.shipForms.indexOf(shipForm) + 1}.${format}`
          );

          shipForm.loading = false;
          shipForm.loading1 = true;
          shipForm.loadingShip = false;
          // this.loaderView = false;
        } else {
          shipForm.categoryExpenditure = [];
          shipForm.total = 0;
          shipForm.count = 0;
          shipForm.total_delivery_charge = 0;
          shipForm.total_launch_charge = 0;
          if (data.data.length == 0) {
            this.toastr.error('Data not found', '',
              {
                closeButton: true,
                timeOut: 2000,
                positionClass: 'toast-bottom-left'
              });
            shipForm.loading = false;
            shipForm.loading1 = false;
          }
          if (this.userRoles.ROLE_ADMIN && data.data && data.data.length > 0) {
            shipForm.currency = data.data[0].currency;
          }
          data.data.forEach(res => {
            shipForm.categoryExpenditure.push({
              label: res.name,
              id: res.id,
              value: res.count,
              colour: res.color_code ? res.color_code : '#2e5888',
              amount: res.amount,
              delivery_charge: res.delivery_charge,
              launch_charge: res.lounge_charge
            });
            shipForm.total += res.amount;
            shipForm.total_delivery_charge += res.delivery_charge;
            shipForm.total_launch_charge += res.lounge_charge;
            shipForm.count += res.count;
          });
          shipForm.loading = false;
          shipForm.loading1 = true;
        }
      }, error => {
        this.toastr.error('Data not found', '',
          {
            closeButton: true,
            timeOut: 2000,
            positionClass: 'toast-bottom-left'
          });
        shipForm.loading = false;
        shipForm.loading1 = false;
        shipForm.loadingShip = false;
        this.loaderView = false;
        this.loadingService.setLoading(false);
      });
  }

  navigateCategoryOrder(categoryId: number, form: FormGroup) {
    // tslint:disable-next-line:object-literal-key-quotes
    const params = { 'category_id': categoryId, 'status': '4' };
    if (form.value.from_date && form.value.to_date) {
      params['from_date'] = form.value.from_date;
      params['to_date'] = form.value.to_date;
    }
    if (form.value.ship_id) {
      params['ship_id'] = form.value.ship_id;
    }
    if (form.value.port_id) {
      params['port_id'] = form.value.port_id;
    }
    this.router.navigate(['/orders'], { queryParams: params });
  }

  addShipToCompare() {
    if (this.shipForms.length < 10) {
      this.shipForms.push({
        form: this.getShipForm(),
        portList: [],
        loading: false,
        loading1: false,
        loadingShip: false,
        submitted: false,
        total: 0,
        total_delivery_charge: 0,
        total_launch_charge: 0,
        count: 0,
        categoryExpenditure: [],
        ship: null,
        currency: ''
      });
    } else {
      this.toastr.error('Maximum 10 ships are allowed', 'Can not add ship',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
    }
  }

  getShipForm(): FormGroup {
    return this.formBuilder.group({
      ship_id: [null],
      port_id: [null],
      category_id: [[]],
      from_date: [null],
      to_date: [null],
    });
  }

  /*  changeShip(form, event) {
     form.ship = event;
     // form.value.port_id = null;
     // form.value.patchValue({
     //   port_id: null
     // });
     // form.value.port_id = null
 
     this.portService.getByShipId(form.ship.id).subscribe(data => {
       this.portList = null;
       form.portList = data;
     }, error => {
       this.shipForms.forEach(shipForm => {
         shipForm.form.patchValue({
           port_id: null
         });
       });
       form.portList = null;
       form.loading1 = false;
     });
   } */

  isDateRangeInvalid(form: FormGroup) {
    const fromDate = form.value.from_date;
    const toDate = form.value.to_date;
    return fromDate != null && toDate != null && fromDate > toDate;
  }

  resetFromDate(form: FormGroup) {
    const fromDate = form.value.from_date;
    if (fromDate != null) {
      form.patchValue({
        from_date: null
      });
    }
  }

  resetToDate(form: FormGroup) {
    const toDate = form.value.to_date;
    if (toDate != null) {
      form.patchValue({
        to_date: null
      });
    }
  }
}
