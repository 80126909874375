<ng-template #ratingsPopup>
    <div class="modal-body cancelmodal-spc text-left padding40">
        <button type="button"
            class="close pull-right"
            (click)="modalRef.hide();">
            <span>&times;</span></button>
        <h4 class="rating-popup-heading sure-font disable-popup-title bmargin30">
            Vendor's Rating
        </h4>
        
        <div style="padding: 0px"
            class="container bmargin30">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                    <p class="rating-title">Packaging of Products</p>
                    <div class="rating-digit-div">
                        <ng-template #t
                            let-fill="fill">
                            <span class="star1"
                                [class.full]="fill === 100">
                                <span class="half1"
                                    [style.width.%]="fill"><em class="fa fa-star text-warning"></em></span><em
                                    class="fa fa-star text-warning grey cursor"></em>
                            </span>
                        </ng-template>
                        <ngb-rating [(rate)]="totalPackagingRating"
                            [starTemplate]="t"
                            [readonly]="true"
                            max="5"></ngb-rating>
                        <div class="separator"></div>
                        <span>{{ totalPackagingRating | number: '1.1-1' }}</span>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                    <p class="rating-title">Quality of Products</p>
                    <div class="rating-digit-div">
                        <ng-template #t
                            let-fill="fill">
                            <span class="star1"
                                [class.full]="fill === 100">
                                <span class="half1"
                                    [style.width.%]="fill"><em class="fa fa-star text-warning"></em></span><em
                                    class="fa fa-star text-warning grey cursor"></em>
                            </span>
                        </ng-template>
                        <ngb-rating [(rate)]="totalProductQualityRating"
                            [starTemplate]="t"
                            [readonly]="true"
                            max="5"></ngb-rating>
                        <div class="separator"></div>
                        <span>{{ totalProductQualityRating | number: '1.1-1' }}</span>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                    <p class="rating-title">Vendor Services</p>
                    <div class="rating-digit-div">
                        <ng-template #t
                            let-fill="fill">
                            <span class="star1"
                                [class.full]="fill === 100">
                                <span class="half1"
                                    [style.width.%]="fill"><em class="fa fa-star text-warning"></em></span><em
                                    class="fa fa-star text-warning grey cursor"></em>
                            </span>
                        </ng-template>
                        <ngb-rating [(rate)]="totalVendorServiceRating"
                            [starTemplate]="t"
                            [readonly]="true"
                            max="5"></ngb-rating>
                        <div class="separator"></div>
                        <span>{{ totalVendorServiceRating | number: '1.1-1' }}</span>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6">
                    <p class="rating-title">Overall Services</p>
                    <div class="rating-digit-div">
                        <ng-template #t
                            let-fill="fill">
                            <span class="star1"
                                [class.full]="fill === 100">
                                <span class="half1"
                                    [style.width.%]="fill"><em class="fa fa-star text-warning"></em></span><em
                                    class="fa fa-star text-warning grey cursor"></em>
                            </span>
                        </ng-template>
                        <ngb-rating [(rate)]="totalOverallRating"
                            [starTemplate]="t"
                            [readonly]="true"
                            max="5"></ngb-rating>
                        <div class="separator"></div>
                        <span>{{ totalOverallRating | number: '1.1-1' }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container review-box">            
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <h4 class="ratings-h4">
                        {{ totalReviews }} Order Review{{ totalReviews > 1 ? "s" : "" }}
                    </h4>
                    <select class="ratings-sorting"
                        (change)="reviewListSort($event.target.value)">
                        <!-- <option value="default">Sort Reviews By</option> -->
                        <option value="l2o">Latest to Old</option>
                        <option value="o2l">Old to Latest</option>
                        <option value="l2h">Low to High</option>
                        <option value="h2l">High to Low</option>
                    </select>
                </div>
                <div class="col-md-12 reviews no-product loading text-center padding20"
                    *ngIf="isLoading">
                    Loading...
                </div>
                <ng-container *ngIf="!isLoading">
                    <div *ngFor="let review of reviewsList"
                        class="col-md-12 col-sm-12 reviews">
                        <div class="rating-separator-line"></div>
                        <div class="row tmargin30">
                            <div class="col-lg-4 col-md-12 col-sm-12">
                                <p>
                                    <a *ngIf="role !== userRoles.ROLE_COMPANY_ADMIN || (role === userRoles.ROLE_COMPANY_ADMIN && company.type !== 1)"
                                        routerLink="/orders/detail/{{review.order_id}}"
                                        (click)="modalRef.hide()">{{ review.order_details &&
                                        review.order_details.order_name ? review.order_details.order_number + ', ' +
                                        review.order_details.order_name : 'N/A' }}</a><br
                                        *ngIf="role !== userRoles.ROLE_COMPANY_ADMIN || (role === userRoles.ROLE_COMPANY_ADMIN && company.type !== 1)" /><span
                                        class="greyish-color"
                                        *ngIf="role !== userRoles.ROLE_COMPANY_ADMIN || (role === userRoles.ROLE_COMPANY_ADMIN && company.type !== 1)">{{
                                        review.order_details && review.order_details.order_name ?
                                        review.order_details.ship : 'N/A'}}</span><br
                                        *ngIf="role !== userRoles.ROLE_COMPANY_ADMIN || (role === userRoles.ROLE_COMPANY_ADMIN && company.type !== 1)" /><span
                                        class="greyish-color">{{
                                        review.created_at | date: "MMM dd, yyyy, HH:mm:ss" }}</span>
                                </p>
                            </div>
                            <div class="col-lg-5 col-md-12 col-sm-12">
                                <p class="rating-desc">
                                    {{ review.review != null && review.review ? review.review : "No Comment" }}
                                </p>
                            </div>
                            <div class="col-lg-3 col-md-12 col-sm-12">
                                <h4 class="greyish-color">{{ review.rate | number: '1.1-1' }}</h4>
                                <ng-template #t
                                    let-fill="fill">
                                    <span class="star1"
                                        [class.full]="fill === 100">
                                        <span class="half1"
                                            [style.width.%]="fill"><em class="fa fa-star text-warning"></em></span><em
                                            class="fa fa-star text-warning grey cursor"></em>
                                    </span>
                                </ng-template>
                                <ngb-rating [(rate)]="review.rate"
                                    [starTemplate]="t"
                                    [readonly]="true"
                                    max="5"></ngb-rating>
                            </div>
                        </div>
                    </div>
                </ng-container>



                <div class="paging-wrap  place-top fix rating">
                    <span *ngIf="totalReviews"
                    class="total-count">{{limit * page - 9}} -
                    {{limit * page + limit - 10 < totalReviews ? limit * page + limit - 10 : totalReviews}} of {{totalReviews}}
                </span>
                <ngb-pagination
                    [collectionSize]="totalReviews"
                    [(page)]="page"
                    [maxSize]="limit"
                    [rotate]="true"
                    [ellipses]="false"
                    [boundaryLinks]="true"
                    (pageChange)="onPageChange($event)">
                </ngb-pagination>
                </div>
                <div class="paging-wrap place-bottom fix rating"
                    [class.stop-click]="isLoading">  
                    <span *ngIf="totalReviews"
                        class="total-count">{{limit * page - 9}} -
                        {{limit * page + limit - 10 < totalReviews ? limit * page + limit - 10 : totalReviews}} of {{totalReviews}}
                    </span>
                    <ngb-pagination class="pull-left"
                        [collectionSize]="totalReviews"
                        [(page)]="page"
                        [maxSize]="limit"
                        [rotate]="true"
                        [ellipses]="false"
                        [boundaryLinks]="true"
                        (pageChange)="onPageChange($event)">
                    </ngb-pagination>
                </div>
            </div>
        </div>
    </div>
</ng-template>