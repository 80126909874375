import { CommonService } from '@app/shared/helper/common';
import { SharedService } from './../../../../shared/service/shared.service';
import { Designation, Privilages, UserRoles } from './../../../../core/enums/user-roles';
import { UserStatusLabel } from './../../models/user-status';
import { UserColumns } from './../../models/user-column';
import {
  DataTableColumn,
  DataTablePagination
} from './../../../../core/models/data-table';
import { Component, OnInit, ViewChild, TemplateRef, ChangeDetectorRef, AfterContentChecked, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from '@app/modules/user/models/user.modal';
import { Company } from '@app/modules/company/models/company.modal';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from '@app/modules/company/services/company.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserStatus } from '../../models/user-status';
import { PaginationData } from '@app/core/models/response.model';
import { UserService } from '../../services/user.service';
import { HttpHeaders } from '@angular/common/http';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CompanyType, CompanyTypeLabel } from '@app/modules/company/models/company.enum';
import { AlertService } from '@app/shared/service/alert.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit, AfterContentChecked {
  onlyShowDeliveryDrivers: boolean;
  designationId: number;
  constructor(
    private modalService: BsModalService,
    private userService: UserService,
    private router: Router,
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private companyService: CompanyService,
    private tostr: ToastrService,
    private cdref: ChangeDetectorRef,
    private commonService: CommonService,
    private activeRoute: ActivatedRoute,
    private alertService: AlertService
  ) {
    this.page.limit = 10;
    this.page.offset = 0;
    this.page.count = 0;
  }

  get form() {
    return this.ChangePasswordForm.controls;
  }
  rows = new Array<Company>();
  columns: DataTableColumn[] = UserColumns;
  isLoading: boolean;
  page = new DataTablePagination();
  @ViewChild('userTable', { static: false }) table;
  @ViewChild('autocomplete', { static: false }) autocomplete;
  //userForm: FormGroup;
  //userRoleForm: FormGroup;
  submitted = false;
  msg: boolean;
  loading: boolean;
  //toggleUserView = false;
  //user: User[] = [];
  companys: Company[] = [];
  //isEditMode: boolean;
  //total: number;
  limit = 10;
  filterForm: FormGroup;
  openFilters: boolean;
  role: number;
  companyId: number;
  userRole = UserRoles;
  subAdminPrivilage = Privilages;
  @ViewChild('changePassword', { static: false }) disableProductPopup: TemplateRef<any>;
  modalRef: any;
  ChangePasswordForm: FormGroup;
  token: any;
  clickedPage: number;
  selectedAutocomplete = '';
  suggestion: User[] = [];
  verifyStatuses = [{ title: 'Verified', value: true }, { title: 'Not Verified', value: false }];
  canAddUser;
  currentUrl;
  pageCount = 0;
  exporting: boolean;
  isTextFieldType: boolean;
  isTextFieldConfirmType: boolean;
  subAdminPrivilages: [number];
  canSubAdminChangePassword: boolean;
  canAddEditUser: boolean;
  companyIds: [number];
  selectedEmail: string;
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  onPageChange($event, count) {
    this.clickedPage = $event.page;
    this.pageCount = count;
    // if (!this.searchField) {
    //   this.filterForm.reset({ company_id: null, verified: null, fname: '' });
    // }
    // if (!this.searchField) {
    //   this.filterForm.reset({ name: '', company_id: null, verified: null });
    //   // const filter1 = { fname: '', phone: '', email: '', company_id: null, verified: null };
    //   // filter = JSON.stringify(filter1);
    // }
  }

  ngOnInit() {
    this.designationId = this.sharedService.getDesignation();
    this.filterForm = this.formBuilder.group({
      // phone: [''],
      // email: ['', Validators.email],
      company_id: [null],
      verified: [null],
      fname: '',
      email: [''],
      designation_id: [null],
    });
    if (this.designationId === Designation.Vendor) {
      this.filterForm.get('designation_id').setValue([36]);
      this.onlyShowDeliveryDrivers = true;
    }
    this.role = this.sharedService.getUserRole();

    if (this.role == this.userRole.ROLE_SUBADMIN) {
      this.subAdminPrivilages = this.sharedService.getSubAdminsPrivileges();
      this.companyIds = this.sharedService.getSubAdminsCompanies();
      if (this.subAdminPrivilages && this.subAdminPrivilages.length > 0) {
        this.canSubAdminChangePassword = this.subAdminPrivilages.includes(this.subAdminPrivilage.RESET_USERS_PASSWORD);
        this.canAddEditUser = this.subAdminPrivilages.includes(this.subAdminPrivilage.CREATE_AND_EDIT_COMPANY_USER);
      }
    }
    this.companyId = this.sharedService.getCompany().id;
    this.canAddUser = this.sharedService.getUserDetails().can_add_user;
    switch (this.role) {
      case this.userRole.ROLE_SUBADMIN:
      case this.userRole.ROLE_ADMIN:
        this.filterForm.patchValue({ company_id: null });
        break;
      default:
        this.filterForm.patchValue({ company_id: [this.companyId] })
    }
    /* if (this.role !== this.userRole.ROLE_ADMIN) {
      this.filterForm.patchValue({ company_id: [this.companyId] });
    } else {
      this.filterForm.patchValue({ company_id: null });
    } */
    this.activeRoute.queryParams.subscribe(params => {
      if (params.filter) {
        const filter = decodeURIComponent(params.filter);
        if (JSON.parse(filter).fname != '' && JSON.parse(filter).fname != null) {
          this.selectedAutocomplete = JSON.parse(filter).fname;
        }
      } else {
        this.selectedAutocomplete = '';
      }
      this.currentUrl = { page: params.page, filter: params.filter };
      this.companyService.getCurrentUrl(this.currentUrl);
      if (this.pageCount > 0) {
        this.pageCount = 0;
        return;
      } else {
        this.setPage(this.page, true);
      }
    });
    // this.setPage(this.page);
    if (this.role === this.userRole.ROLE_ADMIN || this.role == this.userRole.ROLE_SUBADMIN) {
      this.getCompanies();
    }

    this.ChangePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', { validator: this.checkPasswords }],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true };
  }

  setPage(page: DataTablePagination, searchItem = null) {
    // Server pagination start from 1
    this.page = this.clickedPage ? this.page : this.commonService.CheckPage(this.page);
    let filter: any = this.commonService.CheckFilter(this.filterForm);
    if (filter && JSON.parse(filter).fname) {
      filter = JSON.parse(filter);
      filter.fname = '';
      filter.lname = ''
      filter = JSON.stringify(filter);
    }
    // this.commonService.buildFilterUrlParm(this.filterForm, page.offset + 1);

    // const fil = JSON.parse(filter);
    // if (Object.keys(fil).some(key => fil[key] != null && fil[key] != '')) {
    //   searchItem = true;
    // } else {
    //   searchItem = false;
    // }
    // if (!searchItem) {
    //   const filter1 = { fname: '', phone: '', email: '', company_id: null, verified: null };
    //   filter = JSON.stringify(filter1);
    // }

    // this.filterForm.patchValue({ fname: '', company_id: null, verified: null });
    if (filter) {
      const temp = JSON.parse(filter);
      if (temp) {
        if ((temp.company_id && temp.company_id.length === 0) || temp.company_id === null || temp.company_id === '') {
          temp.company_id = null;
          filter = JSON.stringify(temp);
        }
        else {
          temp.company_id = Array.isArray(temp.company_id) ? temp.company_id.join(',') : temp.company_id;
          filter = JSON.stringify(temp);
        }
      }
    }
    const filterFormValue = JSON.parse(filter);
    if (this.role == this.userRole.ROLE_SUBADMIN && searchItem) {
      //filterFormValue.company_id = this.companyIds
      filter = decodeURIComponent(JSON.stringify(filterFormValue));
    }
    this.filterForm.patchValue(filterFormValue);
    const headers = new HttpHeaders().set(
      'x-filter',
      filter
    );
    this.isLoading = true;
    const pageNo = page.offset + 1;
    this.filterForm.patchValue({ fname: this.selectedAutocomplete });
    this.userService
      .getAll(pageNo, page.limit, headers, this.selectedAutocomplete, 0)
      .subscribe((userData: PaginationData) => {
        this.rows = userData.results.map((user: User) => {
          if (user.user_role) {
            const rowData = {
              id: user.id,
              fname: user.fname,
              lname: user.lname ? user.lname : '',
              phone: user.phone,
              email: user.email,
              company_id: user.company_id,
              created_on: user.created_at,
              // ships: company.ships,
              role: user.user_role[0] && user.user_role[0].designations ? user.user_role[0].designations.name : 'N/A',
              status: this.getStatus(user.status),
              source: user.source,
              token: user.reset_token
            };
            rowData.fname = rowData.fname + ' ' + rowData.lname;
            return rowData;
          }
        });
        if ((this.role == this.userRole.ROLE_SUBADMIN) && this.subAdminPrivilages && this.subAdminPrivilages.length > 0) {
          this.canSubAdminChangePassword = this.subAdminPrivilages.includes(this.subAdminPrivilage.RESET_USERS_PASSWORD);
          this.canAddEditUser = this.subAdminPrivilages.includes(this.subAdminPrivilage.CREATE_AND_EDIT_COMPANY_USER);

        }

        this.page.count = userData.total;
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
        this.rows = [];
      });

    // table scroll on pagination change
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 5);
  }

  // getRole(userRole) {
  //   switch (userRole[0]) {
  //     case UserRoles.ROLE_CAPTAIN:
  //       return UserRolesLabel.ROLE_CAPTAIN;
  //     case UserRoles.ROLE_COMPANY_ADMIN:
  //       return UserRolesLabel.ROLE_COMPANY_ADMIN;
  //     case UserRoles.ROLE_USER:
  //       return UserRolesLabel.ROLE_USER;
  //     default:
  //       return 'N/A';
  //   }
  // }

  onCellClicked(row) {
    this.router.navigate(['/user/details/', row.id]);
  }

  getStatus(status: number) {
    switch (status) {
      case UserStatus.Active:
        return UserStatusLabel.Active;
      case UserStatus.InActive:
        return UserStatusLabel.InActive;
      default:
        return status;
    }
  }

  filterData(count) {
    this.autocomplete.hide()
    this.pageCount = count;
    if (count == 1) {
      this.filterForm.patchValue({ fname: this.selectedAutocomplete, lname: this.selectedAutocomplete, email: this.selectedEmail });
    }
    this.commonService.buildFilterUrlParm(this.filterForm);
    // const isBlank = this.sharedService.isFormBank(this.filterForm);
    // if (isBlank) {
    //   this.msg = true;
    //   return false;
    // }
    this.submitted = true;
    if (this.filterForm.valid) {
      // if (Object.keys(this.filterForm.value).some(key => this.filterForm.value[key] != null && this.filterForm.value[key] !== '')) {
      //   this.searchField = true;
      // } else {
      //   this.searchField = false;
      // }
      this.isLoading = true;
      this.submitted = false;
      setTimeout(() => {
        this.setPage(this.page, true);
      });
    } else {
      return;
    }
    this.openFilters = false;
  }

  resetForm(count) {
    this.pageCount = count;
    if (count > 1) {
      this.filterForm.patchValue({ fname: '', email: '' });
      this.selectedAutocomplete = '';
      this.selectedEmail = ''
    } else {
      this.filterForm.reset();
      switch (this.role) {
        case this.userRole.ROLE_ADMIN:
        case this.userRole.ROLE_SUBADMIN:
          this.filterForm.patchValue({
            company_id: null,
            fname: this.selectedAutocomplete
          });
          break;
        default:
          this.filterForm.patchValue({
            company_id: [this.companyId],
            fname: this.selectedAutocomplete
          });
      }
      /* if (this.role !== (this.userRole.ROLE_ADMIN || this.userRole.ROLE_SUBADMIN)) {
        this.filterForm.patchValue({
          company_id: [this.companyId],
          fname: this.selectedAutocomplete
        });
      } else {
        this.filterForm.patchValue({
          company_id: null,
          fname: this.selectedAutocomplete
        });
      } */
    }
    // const filter1: any = { fname: '', company_id: null, verified: null };
    // this.filterForm = JSON.parse(filter1);
    // const filterFormValue = JSON.parse(filter);
    // this.filterForm.patchValue({ fname: '', company_id: null, verified: null });
    this.commonService.buildFilterUrlParm(this.filterForm);
    this.isLoading = true;
    setTimeout(() => {
      this.setPage(this.page);
    });
    this.openFilters = false;
  }

  getCompanies() {
    this.companyService.getAll(1, 500, 0).subscribe(data => {
      this.companys = data.results;
      if (this.role == this.userRole.ROLE_SUBADMIN && this.companyIds && this.companyIds.length > 0) {
        this.companys = this.companys.filter(company => this.companyIds.includes(company.id) || company.type == 2)
      }
    });
  }

  getCompanyType(type) {
    switch (type) {
      case CompanyType.Buyer:
        return CompanyTypeLabel.Buyer;
      case CompanyType.Seller:
        return CompanyTypeLabel.Seller;
    }
  }

  ChangePasswordModel(template: TemplateRef<any>, token) {
    this.ChangePasswordForm.reset();
    this.submitted = false;
    this.token = token;
    this.modalRef = this.modalService.show(
      template,
      Object.assign({ backdrop: 'static', class: 'gray modal-sm small' })
    );
    this.alertService.backModalClose(this.modalRef);
  }

  onChangedPassword() {
    if (!this.ChangePasswordForm.valid) {
      this.submitted = true;
      return false;
    }
    this.userService.changePassword(this.ChangePasswordForm.value, this.token).subscribe(data => {
      this.modalRef.hide();
      this.tostr.success('Password Change Successfully');
    }, err => {
      this.tostr.error('Something wents Wrong');
    });
  }

  search(event) {
    /*  const page = this.page.offset + 1;
     const limit = this.page.limit; */
    this.userService
      .getUserSuggestion(event.query, this.companyId, this.role, 0)
      .subscribe(data => {
        this.suggestion = data.data;
      }, error => {
        this.suggestion = [];
      });
  }

  showCrossBtn(event) {
    this.selectedAutocomplete = event.target.value;
  }

  doOnSelect(event) {
    this.selectedAutocomplete = `${event.fname} ${event.lname == null || event.lname == '' ? "" : event.lname}`;
    this.selectedEmail = event.email
    // this.filterForm.get('fname').setValue(event.fname);
    this.filterData(1);
    this.suggestion = [];
  }

  exportUsers() {
    const filter = this.commonService.CheckFilter(this.filterForm);
    const filterFormValue = JSON.parse(filter);
    if (this.role == this.userRole.ROLE_SUBADMIN) {
      //filterFormValue.company_id = this.companyIds;
    }
    this.filterForm.patchValue(filterFormValue);
    const headers = new HttpHeaders().set(
      'x-filter',
      filter
    );
    this.exporting = true;
    this.userService
      .exportAll(headers)
      .subscribe(data => {
        this.sharedService.saveAsBlob(
          data,
          'application/vnd.ms-excel',
          `users_list.xlsx`
        );
      }, error => {
        this.tostr.error(error.message);
        this.exporting = false;
      }, () => {
        this.exporting = false;
      });
  }
  togglemyPasswordFieldType() {
    this.isTextFieldType = !this.isTextFieldType;
  }
  togglemyConfirmPasswordFieldType() {
    this.isTextFieldConfirmType = !this.isTextFieldConfirmType;
  }
  getUserPermission() {
    if (this.role == this.userRole.ROLE_USER) {
      return false;
    } else if ((this.role == this.userRole.ROLE_ADMIN) || (this.role == this.userRole.ROLE_COMPANY_ADMIN) || (this.role == this.userRole.ROLE_SUBADMIN && this.canAddEditUser)) {
      return true;
    }
  }

}


export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

