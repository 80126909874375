<div class="card new-card ship">
    <h5 class="card-header">Ship Comparison
        <span class="loading-spinner small" *ngIf="loaderView"></span>
        <span [class.download-option]="loaderView" class="dropdown dropleft">
                 <a class="more-btn" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false"><span class="more-dots"></span></a>
        <div class="dropdown-menu float-right" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" (click)="downloadReports('xlsx');">Download all reports as Excel</a>
            <a class="dropdown-item" (click)="downloadReports('pdf');">Download all reports as PDF</a>
        </div>
        </span>
    </h5>
    <div class="card-body">
        <div class="row">
            <div class="col-md-6 mb-3" *ngFor="let shipForm of shipForms;let i=index">
                <div class="card">
                    <h6 class="card-header">Ship {{i + 1}}
                        <span class="loading-spinner small" *ngIf="shipForm.loadingShip"></span>
                        <span [class.download-option]="shipForm.loadingShip" class="dropdown dropleft">
                 <a class="more-btn" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false"><span class="more-dots"></span></a>
                        <div class="dropdown-menu float-right" aria-labelledby="dropdownMenuButton">
                            <a class="dropdown-item" (click)="getReport(shipForm, 1, 'xlsx');">Download as Excel</a>
                            <a class="dropdown-item" (click)="getReport(shipForm, 1, 'pdf');">Download as PDF</a>
                            <a *ngIf="shipForms.length > 2" class="text-danger dropdown-item" (click)="shipForms.splice(shipForms.indexOf(shipForm),1)">Remove from list</a>
                        </div>
                        </span>
                    </h6>
                    <div class="card-body">
                        <form class="row" [formGroup]="shipForm.form" (submit)="getReport(shipForm)">
                            <div class="form-group custom-form-group mb-2 col-md-6">
                                <ng-select formControlName="ship_id" [items]="shipList" bindLabel="name" bindValue="id" [multiple]="false" placeholder="Select Ship" class="form-control form-control-sm">
                                </ng-select>
                                <!--  (change)="changeShip(shipForm, $event)"
                                      [class.error]="shipForm.submitted && shipForm.form.controls.ship_id.errors"
                                    <div class="errorbox" *ngIf="shipForm.submitted && shipForm.form.controls.ship_id.errors">Please select ship</div> -->
                            </div>
                            <div class="form-group custom-form-group mb-2 col-md-6">
                                <ng-select formControlName="port_id" [items]="shipForm.portList && shipForm.portList.length>0 ? shipForm.portList : portList" bindLabel="name" bindValue="id" [multiple]="false" placeholder="Select Port" class="form-control form-control-sm">
                                </ng-select>
                            </div>
                            <div class="form-group custom-form-group cross-icon mb-2 col-md-6" [class.error]="isDateRangeInvalid(shipForm.form)">
                                <p-calendar formControlName="from_date" placeholder="From Date" dateFormat="dd/mm/yy" [maxDate]="etd" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2030">
                                </p-calendar>
                                <button *ngIf="shipForm.form.get('from_date').value != null" class="close shift" (click)="resetFromDate(shipForm.form)">&#10006;</button>
                            </div>
                            <div class="form-group custom-form-group cross-icon mb-2 col-md-6" [class.error]="isDateRangeInvalid(shipForm.form)">
                                <p-calendar formControlName="to_date" placeholder="To Date" dateFormat="dd/mm/yy" [maxDate]="etd" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2030">
                                </p-calendar>
                                <button *ngIf="shipForm.form.get('to_date').value != null" class="close shift" (click)="resetToDate(shipForm.form)">&#10006;</button>
                            </div>
                            <div class="form-group custom-form-group mb-2 col-md-9">
                                <ng-select formControlName="category_id" [items]="categoryList" bindLabel="name" bindValue="id" [multiple]="true" placeholder="Select Category" class="hide-cross-btn form-control form-control-sm" [closeOnSelect]="false" [clearOnBackspace]="true" [clearSearchOnAdd]="true">
                                </ng-select>
                            </div>
                            <div class="form-group custom-form-group mb-2 col-md-3">
                                <button class="btn btn-primary" style="min-height: 45px; width: 100%;" type="submit">Submit</button>
                            </div>
                        </form>
                        <div class="loading" *ngIf="shipForm.loading">Loading...</div>
                        
                        <ng-container *ngIf="shipForm.loading1">
                            <app-piechart class="mb-3" [graphData]="shipForm.categoryExpenditure"></app-piechart>
                            <table *ngIf="shipForm.categoryExpenditure.length > 0 && shipForm.total !=0 && shipForm.count!=0" class="table table-bordered mt-3">
                                <thead>
                                    <tr>
                                        <th>Category</th>
                                        <th class="text-left">Order Count</th>
                                        <th class="text-right">Order Value ({{ role === userRoles.ROLE_ADMIN ? shipForm.currency : currencyCode }})</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of shipForm.categoryExpenditure">
                                        <td><a href="javascript:void(0)" (click)="navigateCategoryOrder(data.id, shipForm.form)">{{data.label}}</a></td>
                                        <td class="text-left">{{data.value}}</td>
                                        <td class="text-right">
                                            {{data.amount | number:'1.2-2'}}
                                            <ng-container *ngIf="(data.delivery_charge + data.launch_charge) > 0">
                                                <br>
                                                <span style="color: #7e7d82;" class="small">
                  + {{ data.delivery_charge + data.launch_charge | number:'1.2-2' }}
                          <i class="fa fa-info-circle info-icon" title="Launch charge ({{ data.launch_charge | number:'1.2-2'}}) + Delivery charge ({{ data.delivery_charge | number:'1.2-2'}})" aria-hidden="true"></i></span>
                                            </ng-container>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Total</b></td>
                                        <td class="text-left"><b>{{ shipForm.count }}</b></td>
                                        <td class="text-right"><b>
                      {{ shipForm.total  | number:'1.2-2' }}
                      <ng-container *ngIf="shipForm.total_delivery_charge + shipForm.total_launch_charge">
                        <br>
                        <span style="color: #7e7d82;" class="small">
                  + {{ shipForm.total_delivery_charge + shipForm.total_launch_charge | number:'1.2-2' }}
                          <i class="fa fa-info-circle info-icon" title="Launch charge ({{ shipForm.total_launch_charge | number:'1.2-2'}}) + delivery charge ({{ shipForm.total_delivery_charge | number:'1.2-2'}})" aria-hidden="true"></i></span>
                      </ng-container>
                    </b></td>
                                    </tr>
                                </tbody>
                            </table>
                            <!-- <div class="records-not-found" *ngIf="">
                                <img class="bmargin40" src="/assets/images/product_request_image.svg" alt="">
                                <p>No Records Found!</p>
                            </div> -->
                            <div class="col-md-12 tmargin10 bottom-space-mr" *ngIf="!(shipForm.categoryExpenditure.length > 0 && shipForm.total !=0 && shipForm.count!=0)">
                                <div class="records-not-found">
                                    <img class="bmargin40" src="/assets/images/product_request_image.svg" alt="">
                                    <p>No Records Found!</p>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mb-3">
                <button class="btn btn-primary" (click)="addShipToCompare()">Add more ship</button>
            </div>
        </div>
    </div>
</div>