<div class="row">
    <!-- <div class="col-md-12 cart-header" *ngIf="role != userRoles.ROLE_CAPTAIN">
        <h3 class="primary-font inline-block mb-0">Cart of </h3>
        <div class="inline-dropdown">
            <ng-select [(ngModel)]="shipId" [items]="shipList" bindLabel="name" (change)="setShip($event)" bindValue="id" [multiple]="false" placeholder="Select Ship" class="form-control" clearAllText="Clear" [clearable]="false">
            </ng-select>
        </div>
    </div> -->
    <div class="col-md-12 delete-cart-wrapper">
        <div class="upload-wrapper">
            <div class="upload-product-box"
                *ngIf="shipId">
                <h5>Prepare your Cart via Excel Sheets</h5>
                <p>Upload an excel in the <a
                        href="javascript:void(0)"
                        (click)="priscribeFormat()">prescribed format</a> and your cart is ready!</p>
                <button type="button"
                    class="btn cart-btn"
                    (click)="uploadForm.reset();file=null;openModal(uploadPopup);submitted =false">Upload Excel</button>
                <img src="assets/images/microsoftexcel-icon.svg"
                    alt="excel-icon">
            </div>
            <div class="upload-product-box request-place"
                *ngIf="shipId">
                <h5>Can’t find the product in our catalogue?</h5>
                <p>Place a request with us and we will get it delivered to you.</p>
                <button type="button"
                    class="btn cart-btn"
                    (click)="showModal(newProductRequestTemplate)">Add Request</button>
                <img src="assets/images/dropbox-icon.svg"
                    alt="excel-icon">
            </div>
        </div>
        <div class="cart-item-counts">
            <div class="ng-container-dropdown">
                <ng-container class="bmargin20"
                    *ngIf="role != userRoles.ROLE_CAPTAIN">
                    <h3 class="primary-font inline-block mb-0">Cart of </h3>
                    <div class="inline-dropdown select-ship">
                        <ng-select [(ngModel)]="shipId"
                            [items]="shipList"
                            bindLabel="name"
                            (change)="setShip($event)"
                            bindValue="id"
                            [multiple]="false"
                            placeholder="Select Ship"
                            class="form-control cart-form-control"
                            clearAllText="Clear"
                            [clearable]="false">
                        </ng-select>
                    </div>
                </ng-container>
            </div>
            <h2 [ngClass]="role != userRoles.ROLE_CAPTAIN ? 'tmargin10':0"
                class="cart-counter">My {{provisionsSelected ? 'Provisions' : ''}} Cart
                <span>({{cartRequirements.length}}
                    {{carts.length>1 ? 'Requisitions' : 'Requisition'}})</span>
            </h2>
            <ng-container *ngIf="provisionsSelected">
                <label for=""
                    class="select-req">Select Requisition<span class="mendatory">*</span></label>
                <ng-select [(ngModel)]="selectedRequirementName"
                    [multiple]="false"
                    placeholder="Select Requisition"
                    class="form-control select-requirement  cart-form-control select-ng"
                    (change)="selectProvisionRequsition($event)"
                    [clearable]="false">
                    <ng-option *ngFor="let item of cartRequirements"
                        [value]="item"><span [title]="item?.requirement_name"
                            class="requirement-name-span"><b>{{item?.name}}</b> {{item?.category_name}}</span> <br>
                        <em>Created By: </em> <b> {{item?.creator_name? item?.creator_name: ''}} / {{item?.rank?
                            item?.rank: ''}}</b>
                    </ng-option>
                </ng-select>
            </ng-container>
            <div class="tmargin30 price-available-box"
                *ngIf="provisionsSelected">
                <label for="allProductShow"
                    class="cart-option all"
                    [class.active]="allProductShow">
                    <input class="d-none"
                        type="radio"
                        id="allProductShow"
                        name="productPrice"
                        (change)="productWithPrice($event)"
                        checked>
                    <h5 class="d-inline">All</h5>
                </label>
                <label for="price-avail"
                    class="cart-option"
                    [class.active]="!togglePNA">
                    <input type="radio"
                        class="d-none"
                        id="price-avail"
                        name="productPrice"
                        (change)="productWithPrice($event)">
                    <h5 class="d-inline">Prices Available <span class="price-avail"
                            [class.big]="countPA>99">{{countPA>0 ? countPA : '0'}}</span></h5>
                </label>
                <label for="price-not-avail"
                    class="cart-option not-avail"
                    [class.active]="!togglePA">
                    <h5 class="d-inline">Prices not Available <span class="price-not-avail"
                            [class.big]="countPNA>99">{{countPNA>0 ? countPNA : '0'}}</span></h5>
                    <input type="radio"
                        class="d-none"
                        id="price-not-avail"
                        name="productPrice"
                        (change)="productWithPrice($event)">
                </label>
                <span class="small rfq-submitted-label white-bg pull-right"
                    *ngIf="allRfqSubmitted() && countPNA>0">RFQ Submitted</span>
            </div>
        </div>
    </div>
</div>
<div class="loading no-product"
    *ngIf="!shipId">
    Please select Ship
</div>
<div class="loading no-product"
    *ngIf="carts.length == 0 && !loading && shipId">
    No products in cart
</div>
<div class="fullbox"
    *ngIf="category.length > 0">
    <!-- <div class="row tmargin30" *ngIf="provisionsSelected">
        <div class="col-12">
            <div class="price-available-box">
                <label for="allProductShow" class="cart-option all" [class.active]="allProductShow">
                    <input class="d-none" type="radio" id="allProductShow" name="productPrice" (change)="productWithPrice($event)" checked>
                      <h5 class="d-inline">All</h5>
                </label>
                <label for="price-avail" class="cart-option" [class.active]="!togglePNA">
                <input type="radio" class="d-none" id="price-avail" name="productPrice" (change)="productWithPrice($event)">
                  <h5 class="d-inline">Prices Available <span class="price-avail" [class.big]="countPA>99">{{countPA>0 ? countPA : '0'}}</span></h5>
            </label>
                <label for="price-not-avail" class="cart-option not-avail" [class.active]="!togglePA">
                <h5 class="d-inline">Prices not Available  <span class="price-not-avail" [class.big]="countPNA>99">{{countPNA>0 ? countPNA : '0'}}</span></h5>
                <input type="radio" class="d-none" id="price-not-avail" name="productPrice" (change)="productWithPrice($event)">
            </label>
                <span class="small rfq-submitted-label white-bg pull-right" *ngIf="allRfqSubmitted() && countPNA>0">RFQ Submitted</span>
            </div>
        </div>
    </div> -->
    <!-- <h3>Store Product Requirements</h3> -->
    <ng-container *ngFor="let cat of selectedCategory;let i_cat = index;trackBy:trackByFn" >
        <div class="form-container bg-light-gray bmargin20 category-checkbox-label"
            [ngClass]="{'tmargin20': i_cat == 0, 'tmargin35': i_cat > 0}"
            *ngIf="(provisionsSelected && cat.id == PROVISION_CATEGORY_ID) || !provisionsSelected">

            <div class="product-type tab-info clearfix">

                <div [ngClass]="{'category-checkbox-pro':provisionsSelected, 'category-checkbox':!provisionsSelected}">
                    <div class="category-div">
                        <label for="{{i_cat}}"
                            class="box-headfont custom-checkbox category">
                            <!-- <input type="checkbox"
                                    id="{{i_cat}}"
                                    [(ngModel)]="isCheckedCategory[i_cat]"
                                    [value]="cat"
                                    (change)="checkedCategory(isCheckedCategory[i_cat] , cat)" /> --> {{cat.name}}
                            <!--  <span class="shape"></span> --> <span
                                class="product-count">({{cat.requirements.length}} {{cat.requirements.length > 1?
                                'Requisitions':'Requisition'}})</span><span class="cod-subtext"
                                *ngIf="provisionsSelected">Cash On Delivery Order</span>
                        </label>

                        <div class="form-group order-name tmargin10"
                            *ngIf="provisionsSelected">
                            <input type="text"
                                class="fill-order"
                                placeholder="{{provisionsSelected && cat.id == PROVISION_CATEGORY_ID ? 'Enter order name/ no.' : 'Enter requisition name/ no.'}}"
                                maxlength="45"
                                [(ngModel)]="orderName"
                                required>
                            <sup><span class="mendatory">*Required</span></sup>
                        </div>
                    </div>
                    <div class="budget-div"
                        *ngIf="role===userRoles.ROLE_CAPTAIN && cat.id == PROVISION_CATEGORY_ID && provisionsSelected">
                        <label *ngIf="carts[0]?.port?.name">Port: <span>{{carts[0]?.port?.name}}</span></label><label
                            *ngIf="budget!=null">&nbsp;&nbsp;<span class="pipe-seperator">|&nbsp;</span> Budget
                            ({{currencyCode}}): <span>{{budget!=null ? budget : ''}}</span></label> <span
                            *ngIf="budget!=null"
                            class="rightblock relative info-date">
                            <img width="17"
                                src="/assets/images/information.svg"
                                alt="Info">
                            <span class="info-text">
                                This is the budget amount allocated by your company for <b>Provisions</b> for the
                                duration of <b>{{showDateFrom | date:'MMM dd, yyyy'}}</b> to <b>{{showDateTo | date:'MMM
                                    dd, yyyy'}}</b>
                            </span>
                        </span>
                    </div>
                    <!-- <span class="dropdown dropleft">
                        <a class="more-btn" data-toggle="dropdown" aria-haspopup="true"
                           aria-expanded="false"><span class="more-dots"></span></a>
                            <div class="dropdown-menu float-right" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item">Delete Requirement</a>
                            </div>
                    </span> -->
                    <!-- <div class="form-group" *ngIf="cat.id == 0" >
                        <div class="select-category">
                            <ng-select [items]="groupedCategory" bindLabel="name" [ngModel]="catMostProd" (change)="getCategory($event);" bindValue="id" [multiple]="false" placeholder="Select Category" class="form-control"
                                    clearAllText="Clear" [clearable]="false">
                            </ng-select>
                        </div>
                    </div> -->
                </div>
                <div class="tabs">
                    <div class="tab"
                        [ngClass]="{'active':provisionsSelected}"
                        *ngFor="let requirement of cat.requirements;index as reqIndex;trackBy:trackByFn">
                        <!-- <ng-container *ngIf="cat.requirements && cat.requirements.length > 0 && showRequirements(cat.requirements, requirement)"> -->
                        <input type="checkbox"
                            id="tab-{{i_cat}}{{reqIndex}}"> <!-- checked -->
                        <label [ngClass]="{'tab-label':!provisionsSelected}"
                            style="padding: 0.6em 1.1em 2em;"
                            for="tab-{{i_cat}}{{reqIndex}}"> <!--Responsible For Toggling The Requisition-->
                            <label for="req-{{i_cat}}{{reqIndex}}"
                                class="box-headfont custom-checkbox category requirement">
                                <ng-container *ngIf="!provisionsSelected">
                                    <input type="checkbox"
                                        *ngIf="!provisionsSelected"
                                        id="req-{{i_cat}}{{reqIndex}}"
                                        [(ngModel)]="requirement.checked"
                                        [value]="requirement"
                                        (change)="checkedRequirement(requirement.checked, requirement)" />
                                </ng-container>
                                {{requirement.name}} <span
                                    style="font-size: 13px;font-weight: 400;">({{requirement.count}} {{requirement.count
                                    > 1?
                                    'Products':'Product'}})</span>
                                <ng-container *ngIf="!provisionsSelected"><span class="shape Check-box"></span></ng-container>
                                <!-- <span class="product-count">({{cat.count}} {{cat.count > 1? 'items':'item'}})</span> -->
                                <span class="cod-subtext-count">Created By: {{requirement.creator_name}},
                                    {{requirement.rank}}</span>
                                <span class="cod-subtext-last-updated">Last updated at: {{requirement.update_on |
                                    date}}</span>
                            </label>
                        </label>
                        <div [ngClass]="{'tab-pane active' : provisionsSelected, 'tab-content': selectedCategory.length!=1 }">
                            <div class="tab-content-inner">
                                <div class="remove-section"
                                    *ngIf="carts.length > 0">
                                    <ng-container *ngIf="provisionsSelected">
                                        <button *ngIf="showRemove"
                                            class="btn empty-cart cancel item"
                                            (click)="showRemove=false;selectedReq= null;deleteSelected =[]">
                                            Cancel</button>
                                        <button *ngIf="showRemove"
                                            [disabled]="deleteSelected.length <= 0"
                                            (click)="confirmRemove(multiDeletePopup, null,requirement)"
                                            class="btn empty-cart delete-all items lmargin10"><i
                                                class="fa fa-trash text-white"
                                                aria-hidden="true"></i> Delete Selected items</button>
                                        <button *ngIf="!showRemove"
                                            (click)="selectAllToDelete(true,requirement);"
                                            class="btn empty-cart"><i class="fa fa-trash"
                                                aria-hidden="true"></i> Empty Requisition</button>
                                    </ng-container>
                                    <ng-container *ngIf="!provisionsSelected">
                                        <button *ngIf="showRemove && emptyReq"
                                            class="btn empty-cart cancel item"
                                            (click)="showRemove=false;selectedReq= null;deleteSelected =[]">
                                            Cancel</button>
                                        <button *ngIf="showRemove && emptyReq"
                                            [disabled]="deleteSelected.length <= 0"
                                            (click)="confirmRemove(multiDeletePopup, null,requirement)"
                                            class="btn empty-cart delete-all items lmargin10"><i
                                                class="fa fa-trash text-white"
                                                aria-hidden="true"></i> Delete Selected items</button>
                                        <button *ngIf="emptyReq && !showRemove"
                                            (click)="displayModal(emptyCart,requirement);"
                                            class="btn empty-cart"><i class="fa fa-trash"
                                                aria-hidden="true"></i> Empty Requisition</button>
                                    </ng-container>

                                </div>
                                <div class="data-table tmargin5 bmargin0">
                                    <div class="scroll-table">
                                        <table class="table table-striped full-table new-table smallTag">
                                            <tr>
                                                <th width="70">S.No.</th>
                                                <th width="220">Product Name<br>
                                                    <a href="javascript:void(0)"
                                                        class="add-items"
                                                        routerLink="/dashboard/catalogue"><b>Add Products</b></a>
                                                </th>
                                                <th width="150">Vessel's Remark(s)</th>
                                                <th>Product Code</th>
                                                <!-- <th>IMPA Code</th> -->
                                                <th>Unit </th>
                                                <th>Package Information</th>
                                                <th *ngIf="provisionsSelected">Lead Time(Days)</th>
                                                <th *ngIf="provisionsSelected">Vendor's Remark(s)</th>
                                                <!-- <th *ngIf="provisionsSelected">Unit Price ({{currencyCode}})</th> -->
                                                <th *ngIf="provisionsSelected">Amount ({{currencyCode}})</th>
                                                <th style="text-align:center">Quantity</th>
                                                <th>Action</th>

                                            </tr>
                                            <tr *ngFor="let cart of carts;let i_cart = index;trackBy:trackByFn"
                                                [class.removable-product]="deleteSelected.indexOf(cart.id) > -1" [ngClass]="{'rfqsection': ( provisionsSelected && !cart.product.vendor[0]?.price)}">
                                                <ng-template
                                                    [ngIf]="requirement.requirement_id==cart.requirement_id && display_item(cart)">
                                                    <td></td>
                                                    <td>

                                                         <a (click)="openPopup(cart.product)" class="user-select">{{cart.product.name |titlecase}}</a> <br>
                                                         <span *ngIf="cart && cart?.brand_name">Brand : {{cart?.brand_name}}</span>
                                                         <p *ngIf="cart.product.is_requested"
                                                            placement="bottom"
                                                            ngbPopover=""
                                                            triggers="mouseenter:mouseleave"><span
                                                                class="new-product-tag">New Request</span> <br><a
                                                                    (click)="supportAttachementModal(attachementModal,cart,2)" *ngIf="cart.product.images.length>0" class="view"> View Attachements
                                                                </a></p>
                                                    </td>
                                                    <td>
                                                        <input type="hidden"
                                                            class="form-control"
                                                            [(ngModel)]="cart.ship_id">
                                                        <!-- <a (click)="openRemarkModal(remarkFormModal,cart,i_cart)">Enter Text</a> -->
                                                        <!-- <input type="text"
                                                            placeholder="Enter Text"
                                                            maxlength="255"
                                                            [disabled]="disableRemark"
                                                            class="form-control remark"
                                                            [(ngModel)]="cart.remark"
                                                            (change)="addtocart(cart)"> -->
                                                        <a class="remarks"
                                                            *ngIf="!cart.remark && i_cart != rowIndex"
                                                            (click)="openRemarkModal(remarkFormModal,cart,i_cart)">Enter
                                                            Remark(s)</a>
                                                        <i class="fa fa-pencil edit pull-right" style="color:dodgerblue;cursor:pointer"
                                                            (click)="openRemarkModal(remarkFormModal,cart,i_cart)"></i>
                                                        <div class="tooltips">
                                                           <!--  <span class="remark-text tooltips"
                                                                *ngIf="cart.brand_name"><strong>Preffered Brand:</strong>
                                                                {{cart.brand_name}}
                                                            </span> -->
                                                            <!-- <span class="remark-text tooltips"
                                                                *ngIf="cart.pack_type"><strong>Package Information:</strong>
                                                                {{cart.pack_type}}
                                                            </span> -->
                                                            <span class="remark-text"
                                                                *ngIf="cart.remark"><strong>Remark(s):</strong>
                                                                {{cart.remark}}
                                                            </span>
                                                            <div class="tooltiptext">
                                                                <!-- <p *ngIf="cart.brand_name"> <strong>Brand:
                                                                    </strong>{{cart.brand_name}}</p> -->
                                                                <!-- <p *ngIf="cart.pack_type"> <strong>Package Information:
                                                                    </strong>{{cart.pack_type}}</p> -->
                                                                <p *ngIf="cart.remark"> <strong>Remark(s):
                                                                    </strong>{{cart.remark}}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {{cart.product.product_code}}<br>
                                                        <small>IMPA: {{cart.product.impa_code || 'N/A'}}</small>
                                                    </td>
                                                    <!-- <td>
                                                    {{cart.product.impa_code || 'N/A'}}
                                                    </td> -->
                                                    <td>
                                                        <span>
                                                            {{cart.product.unit.name}}
                                                        </span>
                                                        <select *ngIf="false"
                                                            class="form-control"
                                                            [disabled]="true">
                                                            <option [value]="cart.product.product_code">

                                                                {{cart.product.unit.name}}
                                                            </option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        {{cart.product.pack_type}}
                                                    </td>
                                                    <td *ngIf="provisionsSelected">
                                                        {{ cart?.product?.vendor[0]?.deliverable_in ?
                                                        cart.product.vendor[0]?.deliverable_in : 'N/A'}}
                                                    </td>
                                                    <td *ngIf="provisionsSelected">
                                                        <div class="remarkDiv">
                                                        <div class="tooltips">
                                                            <!-- <span><strong>Brand:</strong> {{ cart?.product?.vendor[0]?.brand_name ?
                                                                cart.product.vendor[0]?.brand_name : 'N/A'}}</span><br> -->
                                                            <span><strong>Package Information:</strong> {{ cart?.product?.vendor[0]?.pack_type ?
                                                                cart.product.vendor[0]?.pack_type : 'N/A'}}</span><br>
                                                            <span class="remark-text"><strong>Remark(s):</strong> {{ cart?.product?.vendor[0]?.remark ?
                                                                cart.product.vendor[0]?.remark : 'N/A'}}</span>
                                                            <div class="tooltiptext">
                                                                <p>
                                                                    <strong>Brand:
                                                                    </strong>{{cart?.product?.vendor[0]?.brand_name?cart?.product?.vendor[0]?.brand_name:'N/A'}}
                                                                </p>
                                                                <p>
                                                                    <strong>Package Information:
                                                                    </strong>{{cart?.product?.vendor[0]?.pack_type?cart?.product?.vendor[0]?.pack_type:'N/A'}}</p>
                                                                <p>
                                                                    <strong>Remark(s):
                                                                    </strong>{{cart?.product?.vendor[0]?.remark?cart?.product?.vendor[0]?.remark:'N/A'}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <span class="dropdown dropleft vendor-attach"  style="color: dodgerblue;" *ngIf="cart?.product?.vendor[0].supporting_documents">
                                                            <a class="more-btn"
                                                              data-toggle="dropdown"
                                                              aria-haspopup="true"
                                                              aria-expanded="false" (click)="supportAttachementModal(attachementModal,cart,1)"><img src="../../../../assets/images/attachement.png" alt="" height="15"></a>
                                                            <!-- <div class="dropdown-menu float-right"
                                                              aria-labelledby="dropdownMenuButton">
                                                              <span class="view" (click)="supportAttachementModal(attachementModal,cart,1)"> View Attachement</span>
                                                            </div> -->
                                                          </span>
                                                        </div>
                                                    </td>
                                                    <td *ngIf="provisionsSelected" >
                                                        <ng-container *ngIf="cart.port_id != null">
                                                            <ng-container
                                                                *ngIf="cart.product.vendor[0]?.price == null && cart.product.rfq_submitted">
                                                                <div class="rfq-submitted-label">RFQ Submitted</div>
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="cart.product.vendor[0]?.price == null && cart.product.rfq_submitted == false">
                                                                <label
                                                                    for="rfq-{{ i_cart }}{{ cart.product.vendor[0]?.id }}"
                                                                    class="custom-checkbox">
                                                                    <input type="checkbox"
                                                                        id="rfq-{{ i_cart }}{{ cart.product.vendor[0]?.id }}"
                                                                        (change)="selectRfq(cart.product.vendor[0]?.id, cart.product.id, cart.port_id,0, $event.target.checked, cart.remark,cart.id)" /><span
                                                                        class="shape"></span>
                                                                    RFQ
                                                                </label>
                                                            </ng-container>
                                                            <span
                                                                *ngIf="cart.product.vendor[0]?.price">{{(cart.quantity * (cart.product.vendor[0]?.price) | number:"1.2-2")}}<!--  <i
                                                                    *ngIf="cart.product.vendor[0]?.remark"
                                                                    placement="top"
                                                                    ngbPopover="{{ cart.product.vendor[0]?.remark }}"
                                                                    triggers="mouseenter:mouseleave"
                                                                    aria-hidden="true"
                                                                    class="fa fa-info-circle"
                                                                    style="color: #007ae0"></i> --></span><br>
                                                            <small class="color-999"
                                                                *ngIf="cart.product.vendor[0]?.price">({{(cart.product.vendor[0]?.price | number:"1.2-2")
                                                                + ' per unit'}})</small>
                                                        </ng-container>
                                                        <ng-container *ngIf="cart.port_id == null">
                                                            <div class="port-unavailable-label">No Port Selected
                                                            </div>
                                                        </ng-container>

                                                    </td>
                                                    <!-- <td *ngIf="provisionsSelected">{{ cart.product.vendor[0]?.price ? (cart.product.vendor[0]?.price * cart.quantity)?.toFixed(2) : 'Not Available'}}</td> -->
                                                    <td [class.qty-td]="provisionsSelected">
                                                        <div class="quantity">
                                                            <button type="button"
                                                                class="count decrease-count"
                                                                [ngStyle]="{'pointer-events': processing ? 'none' : 'all'}"
                                                                (click)="decreaseVal(cart)">–</button>
                                                            <input type="text"
                                                                class="valbox"
                                                                [ngStyle]="{'pointer-events': processing ? 'none' : 'all'}"
                                                                pKeyFilter="pnum"
                                                                value="1"
                                                                (change)="updateCart(cart)"
                                                                [(ngModel)]="cart.quantity"
                                                                maxlength="15" />
                                                            <button type="button"
                                                                class="count increase-count"
                                                                [ngStyle]="{'pointer-events': processing ? 'none' : 'all'}"
                                                                (click)="increaseVal(cart)">+</button>
                                                        </div>
                                                    </td>
                                                    <td class="qty-div"
                                                        *ngIf="showRemove">
                                                        <label for="delete-{{cart.id}}"
                                                            class="custom-checkbox custom-checkbox-remove items">
                                                            <input type="checkbox"
                                                                id="delete-{{cart.id}}"
                                                                [checked]="deleteSelected.indexOf(cart.id) > -1"
                                                                (change)="selectItemToDelete(cart, $event)" /><span
                                                                class="shape"></span>
                                                        </label>
                                                    </td>
                                                    <td *ngIf="!showRemove">
                                                        <div class="action">
                                                            <a (click)="confirmRemove(confirmation,cart.id)"
                                                                class="remove-item-icon"><span>&#10006;</span> </a>
                                                        </div>
                                                    </td>
                                                </ng-template>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- </ng-container> -->
                    </div>
                </div>

                <div *ngIf="provisionsSelected"
                    class="pull-left">
                    <label class="custom-checkbox block-item weight500 color-000"
                        for="transport"><input id="transport"
                            [(ngModel)]="transportCharged"
                            type="checkbox"
                            (ngModelChange)="addTransportCharged($event)" /><span class="shape"></span>
                        Select Delivery Charges (If applicable) {{ currencyCode }}:
                        {{carts[0]?.product.vendor[0]?.transport_fee | number: "1.2-2"}}
                        <i *ngIf="(carts[0]?.product.vendor[0]?.transport_fee_tax && carts[0]?.product.vendor[0]?.transport_fee_tax > 0)"
                            placement="top"
                            ngbPopover="{{ 'Tax: '+((carts[0]?.product.vendor[0]?.transport_fee_tax?carts[0]?.product.vendor[0]?.transport_fee_tax:0) | number:'1.2-2')+' %'}}"
                            triggers="mouseenter:mouseleave"
                            aria-hidden="true"
                            class="fa fa-info-circle remark-info"
                            style="color: #007ae0">
                        </i>
                    </label>
                    <label class="custom-checkbox block-item weight500 color-000"
                        for="charges"><input id="charges"
                            [(ngModel)]="launchCharged"
                            type="checkbox"
                            (ngModelChange)="addLaunchCharge($event)" /><span class="shape"></span>
                        Select Launch Charges (If applicable) {{ currencyCode }}:
                        {{carts[0]?.product.vendor[0]?.launch_charge | number: "1.2-2"}}
                        <i *ngIf="(carts[0]?.product.vendor[0]?.launch_charge_tax && carts[0]?.product.vendor[0]?.launch_charge_tax > 0)"
                            placement="top"
                            ngbPopover="{{ 'Tax: '+((carts[0]?.product.vendor[0]?.launch_charge_tax?carts[0]?.product.vendor[0]?.launch_charge_tax:0) | number:'1.2-2')+' %'}}"
                            triggers="mouseenter:mouseleave"
                            aria-hidden="true"
                            class="fa fa-info-circle remark-info"
                            style="color: #007ae0">
                        </i>
                    </label>
                </div>
                <div *ngIf="provisionsSelected && cartTotal && cartTotal>0"
                    class="pull-right cart-total">
                    <h5>Cart Total ({{currencyCode}}): {{cartTotal && cartTotal>0 ? (cartTotal | number :"1.2-2") : ''}}</h5>
                    <div class="loading-spinner small dark"
                        *ngIf="processing && cartTotal>0"></div>
                </div>
            </div>
        </div>
    </ng-container>

    <div class="tabledata-sendaction cart">
        <button class="buttn place" type="button"
            (click)="selectRequisition(selectReq)"><span class="fa fa-download"></span> Export Cart
        </button>
        <div>
            <button *ngIf="rfqData.length > 0"
                class="btn btn-outline-default default rfq"
                (click)="submitRFQ()"
                [disabled]="processing2">
                Submit RFQs<div class="loading-spinner small dark rfq"
                    *ngIf="processing2"></div></button>
            <i *ngIf="!provisionsSelected && role == userRoles.ROLE_USER"
                    placement="top"
                    ngbPopover="You are not authorised to submit this requisition directly to your management. Please inform your Captain to submit it."
                    triggers="mouseenter:mouseleave"
                    aria-hidden="true"
                    class="fa fa-info-circle remark-info mr-2"
                    style="color: #007ae0"
                    ></i>
            <button class="buttn place cart" *ngIf="!provisionsSelected"
                type="button"
                (click)="placeOrderOpen(order,orderForm);submitted = false"
                [disabled]="showRemove || !(!isCanNotPlaceOrder && (!provisionsSelected && assignedCompany()))">Submit Requisition
            </button>
            <button class="buttn place cart"
                type="button"
                *ngIf="(!isCanNotPlaceOrder && provisionsSelected)"
                (click)="placeOrderOpen(order,orderForm,true);submitted = false"
                [disabled]="showRemove">Order Product(s)</button>
        </div>
    </div>
</div>
<ng-template #confirmOrder>
    <div class="modal-body padding40 text-center">
        <h4 class="sure-font">Are you Sure?</h4>
        <div class="text-center tmargin20">
            <button class="btn btn-outline-primary"
                (click)="hideModal();"
                type="button">
                Cancel
            </button>
            <button class="btn btn-primary text-white"
                (click)="placeOrder();modalRef.hide()">Confirm</button>
        </div>
    </div>
</ng-template>
<ng-template #confirmReq>
    <div class="modal-body padding40 text-center">
        <h4 class="sure-font">Are you Sure?</h4>
        <div class="text-center tmargin20">
            <button class="btn btn-outline-primary"
                (click)="hideModal(); onSubmitRequisition=!onSubmitRequisition "
                type="button">
                Cancel
            </button>
            <button class="btn btn-primary text-white"
                (click)="placeRequisition();modalRef.hide()">Confirm</button>
        </div>
    </div>
</ng-template>
<ng-template #orderForm>
    <div class="modal-header">
        <h3 class="modal-title pull-left checkout">{{provisionsSelected ? 'Order' : 'Checkout'}}</h3>
        <button type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="createRequisition(false);hideModal();">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-spc">
        <div *ngIf="!showNextPopup && !provisionsSelected">
            <div class="text-center combine bmargin20">
                <h4 style="font-weight: 600;"
                    class="bmargin5">Do you want to combine the below requisitions <br>into a single requisition?</h4>
                <h4 style="font-weight: 200; font-size: 1.3rem;">(Select the Requisition you want to combine)</h4>
            </div>
            <ng-container *ngFor="let cat of otherCategoryList;index as i_cat;trackBy:trackByFn">
                <div *ngIf="cat.requirements && cat.requirements.length > 1"
                    style="padding: 0px 20px 11px;margin-bottom:20px; border: 2px solid #e0e0e0;">
                    <h3 class="bmargin5"><span class="reqcat">{{cat.name}}</span> <span class="req-text">
                            ({{cat.requirements.length}} {{cat.requirements.length > 1 ?
                            'Requisitions' : 'Requisition'}})</span></h3>
                    <ng-container *ngFor="let requirement of cat.requirements;index as req">
                        <label for="cat-{{i_cat}}{{req}}"
                            class="box-headfont custom-checkbox category requirement combine-check">
                            <input type="checkbox"
                                id="cat-{{i_cat}}{{req}}"
                                (click)="mergeRequirement(requirement, $event.target.checked)"  [value]="requirement.name"/> {{requirement.name}}
                            <span class="creater-name">&nbsp; (Created By: {{requirement.creator_name}},
                                {{requirement.rank}})</span>
                            <span class="shape"></span>
                        </label><br>
                    </ng-container>
                </div>
            </ng-container>
            <div class="store-requirement-box"
                *ngIf="storeList && storeCategoryList && storeCategoryList.length>0">
                <h3 class="bmargin5"><span class="reqcat">IMPA Categories</span> <span class="req-text">
                        ({{storeCategoryList.length}} Categories)</span></h3>
                <p><span class="font-weight-bold">Categories Included in Store:</span> Deck, Engine, Galley, Electrical
                </p>
            </div>
            <div *ngIf="storeList && storeCategoryList && storeCategoryList.length>0"
                style="border:2px solid #e0e0e0;padding: 0px 20px 11px;;margin-bottom:20px;">
                <!-- <h3 class="bmargin5">{{cat.name}} ({{cat.requirements.length}} {{cat.requirements.length > 1 ? 'Requirements' : 'Requirement'}})</h3> -->
                <ng-container *ngFor="let cat of storeCategoryList;index as i_cat;trackBy:trackByFn">
                    <ng-container *ngFor="let requirement of cat.requirements;index as req">
                        <label for="reqr-{{i_cat}}{{req}}"
                            class="box-headfont custom-checkbox category requirement combine-check">
                            <input type="checkbox"
                                id="reqr-{{i_cat}}{{req}}"
                                (click)="mergeRequirement(requirement, $event.target.checked)" /> {{requirement.name}}
                            /
                            <b>{{cat.name}}</b> <span class="creater-name">&nbsp; (Created By:
                                {{requirement.creator_name}}, {{requirement.rank}})</span><span class="shape"></span>
                        </label><br>
                    </ng-container>
                </ng-container>
            </div>
            <div class="d-flex justify-content-center tmargin20"
                *ngIf="!provisionsSelected">
                <button class="btn btn-outline-primary font-weight-bolder"
                    (click)="combineRequirements(false);noCombine=true ">No, <br> Send as seperate REQs</button>
                <button class="btn btn-primary font-weight-bolder"
                    (click)="combineRequirements(true);noCombine=false;">Yes, <br> Combine into single REQ</button>
            </div>
        </div>
        <!-- <div *ngIf="!showNextPopup && provisionsSelected && provisionReqList.length>1">
            <ng-container *ngFor="let cat of otherCategoryList;index as i_cat;trackBy:trackByFn">
                <div *ngIf="cat.requirements && cat.requirements.length > 1" style="padding: 0px 20px 11px;;margin-bottom:20px; border: 2px solid #e0e0e0;">
                    <h3 class="bmargin5">{{cat.name}} ({{cat.requirements.length}} {{cat.requirements.length > 1 ? 'Requisitions' : 'Requisition'}})</h3>
                    <ng-container *ngFor="let requirement of cat.requirements;index as req">
                        <label for="cat-{{i_cat}}{{req}}" class="box-headfont custom-checkbox category requirement">
                            <input type="checkbox"
                                    id="cat-{{i_cat}}{{req}}"
                                    (change)="mergeRequirement(requirement, $event.target.checked)" /> {{requirement.name}} <span class="shape"></span>
                        </label><br>
                    </ng-container>
                </div>
            </ng-container>
            <div class="d-flex justify-content-center tmargin20" *ngIf="provisionReqList.length>1">
                <button class="btn btn-outline-primary" (click)="combineRequirements(false)">No, Send as seperate Orders</button>
                <button class="btn btn-primary" (click)="combineRequirements(true)">Yes, Combine into single Order</button>
            </div>
        </div> -->
        <div *ngIf="showNextPopup && !showRequisitionForm && !provisionsSelected"
            [ngClass]="showRequisitionForm ? 'd-none': '' ">
            <ng-container *ngFor="let req of selectedRequirement;index as reqInd;trackBy:trackByFn">
                <div style="border: 2px solid #e0e0e0;padding: 0px 20px 11px;;margin-bottom:20px;">
                    <h3 class="bmargin5">
                        <!-- <span class="reqcat">{{req.category_name}}</span> -->
                        <!-- <br><small>{{req.name}}</small> -->
                        <!-- ({{cat.requirements.length}} {{cat.requirements.length > 1 ? 'Requirements' : 'Requirement'}}) -->
                    </h3>
                    <ng-container>
                        <!-- *ngFor="let requirement of cat.requirements;index as req" -->
                        <div class="form-group req-name">
                            <ng-container
                                *ngIf="mergeReqCatList && mergeReqCatList.length > 1 && mergeReq(req); else singleCatList">
                                <h4 class="reqcat bmargin10">IMPA Categories</h4>
                                <!-- <h3 class="bmargin5"> -->
                                <!-- <span class="reqcat">{{req.category_name}}</span> -->
                                <!-- <br><small>{{req.name}}</small> -->
                                <!-- ({{cat.requirements.length}} {{cat.requirements.length > 1 ? 'Requirements' : 'Requirement'}}) -->
                                <!-- </h3> -->
                                <p class="bmargin0">Select Category for Requisition <span class="mendatory">*</span></p>
                                <ng-select [items]="mergeReqCatList"
                                    (change)="changeRequirementCategory($event,req)"
                                    [multiple]="false"
                                    [(ngModel)]="combineStore"
                                    placeholder="Select Category"
                                    bindValue="category_id"
                                    bindLabel="category_name"
                                    class="form-control cart-form-control"
                                    clearAllText="Clear"
                                    [clearable]="false"></ng-select><br />
                                <input type="text"
                                    class="form-control fill-req inputfield cart-form-control"
                                    placeholder="{{provisionsSelected && req.category_id == PROVISION_CATEGORY_ID ? 'Enter order name/ no.*' : 'Enter Requisition Name/ No.*'}}"
                                    maxlength="45"
                                    [(ngModel)]="req.name[0]"
                                    required>
                            </ng-container>
                            <!-- <h3 >The below Requisitions have been combined</h3> -->
                            <ng-template #singleCatList>
                                <h4 class="bmargin5">
                                    <span class="reqcat">{{req.category_name}}</span>
                                    <!-- <br><small>{{req.name}}</small> -->
                                    <!-- ({{cat.requirements.length}} {{cat.requirements.length > 1 ? 'Requirements' : 'Requirement'}}) -->
                                </h4>
                                <input type="text" *ngIf="noCombine"
                                    class="form-control cart-form-control fill-req inputfield"
                                    placeholder="{{provisionsSelected && req.category_id == PROVISION_CATEGORY_ID ? 'Enter order name/ no.*' : 'Enter Requisition Name/ No.*'}}"
                                    maxlength="45"
                                    [(ngModel)]="req.name"
                                    required>
                                <input type="text"
                                    *ngIf="!noCombine"
                                    class="form-control cart-form-control fill-req inputfield"
                                    placeholder="{{provisionsSelected && req.category_id == PROVISION_CATEGORY_ID ? 'Enter order name/ no.*' : 'Enter Requisition Name/ No.*'}}"
                                    maxlength="45"
                                    [(ngModel)]="req.name[0]"
                                    required>
                            </ng-template>
                            <!-- <ng-select></ng-select> -->
                            <!-- <sup><span class="mendatory">*Required</span></sup> -->
                        </div>
                        <!-- <p *ngIf="cat.requirements.length > 1">The below Requirements have been combined</p> -->
                        <!-- <p>{{req+1}}. {{requirement.name}}</p> -->
                    </ng-container>
                    <p *ngIf="req.name.length>1 && combinedReq"
                        class="bmargin0 tmargin10">The below Requisitions have been combined</p>
                    <h3 class="bmargin5 reqname">Requisition Name <br /></h3>
                    <ng-container *ngIf="combinedReq">
                        <ng-container *ngFor="let requ of req.name; index as i">
                            <span style="font-weight:400;">{{i+1}}. {{requ}}</span><br />
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!combinedReq">
                        <span style="font-weight:400;">{{req.name}}</span> <span class=""> (Created By:
                            {{req?.creator_name}}, {{req.rank}})</span>
                    </ng-container>
                </div>
            </ng-container>
            <div class="button-grp tmargin20">
                <button *ngIf="!provisionsSelected && combinedReq"
                    class="btn btn-outline-primary"
                    (click)="hideModal();placeOrderOpen(order,orderForm);submitted = false;">Back</button>
                <button class="btn btn-outline-primary"
                    (click)="createRequisition(false);hideModal();">Cancel</button>
                <button class="btn btn-primary"
                    (click)="createRequisition(true)"> {{provisionsSelected ? "Create Order":"Create Requisition"
                    }}</button>
            </div>
        </div>
        <ng-container *ngIf="showRequisitionForm">
            <form [formGroup]="orderData">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group"
                            [ngClass]="{'error' : submitted && oForm.agent_name.errors}">
                            <label for="">Agent Name</label>
                            <input type="hidden"
                                class="form-control"
                                formControlName="requirement_id"
                                [(ngModel)]="selectedRequirement2" />
                            <input type="hidden"
                                formControlName="source"
                                [(ngModel)]="source" />
                            <input type="hidden"
                                class="form-control"
                                formControlName="requirements"
                                [(ngModel)]="selectedRequirement" />
                            <input type="text"
                                maxlength="32"
                                class="form-control cart-form-control"
                                placeholder="Agent Name"
                                formControlName="agent_name" />
                            <input-validation [control]="oForm.agent_name"
                                [submit]="submitted"></input-validation>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group"
                            [ngClass]="{'error' : submitted && oForm.country_id.errors}">
                            <label for="">Country<span class="mendatory">*</span></label>
                            <ng-select formControlName="country_id"
                                [items]="countryList"
                                bindLabel="name"
                                [ngModel]="countryId"
                                (change)="getPort($event.id);cartPortId = null;"
                                bindValue="id"
                                [multiple]="false"
                                placeholder="Select Country"
                                class="form-control cart-form-control order-place-port"
                                clearAllText="Clear"
                                [clearable]="false"
                                [readonly]="provisionsSelected">
                            </ng-select>
                            <input-validation [control]="oForm.country_id"
                                [submit]="submitted"></input-validation>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group"
                                    [ngClass]="{'error' : submitted && oForm.mobile.errors}">
                                    <label for="">Agent Mobile</label>
                                    <input type="text"
                                        class="form-control cart-form-control"
                                        formControlName="mobile_prefix"
                                        [value]="selectedCountry?.isd_code"
                                        readonly />
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="form-group"
                                    [ngClass]="{'error' : submitted && oForm.mobile.errors}">
                                    <input style="margin-top:22px"
                                        type="text"
                                        pKeyFilter="pnum"
                                        maxlength="16"
                                        class="form-control cart-form-control"
                                        formControlName="mobile"
                                        placeholder="99XXXXXXXXX" />
                                    <input-validation [control]="oForm.mobile"
                                        [submit]="submitted"></input-validation>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group"
                            [ngClass]="{'error' : showInvalidEmail}">
                            <label for="">Agent Email</label>
                            <input type="email"
                                maxlength="100"
                                placeholder="abc@example.com"
                                class="form-control cart-form-control"
                                formControlName="agent_email"
                                (change)="checkEmail($event)" />
                            <div class="errorbox"
                                *ngIf="showInvalidEmail">
                                <span>Enter valid email-id</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group"
                            [ngClass]="{'error' : submitted && oForm.port_id.errors}">
                            <label for="">Port of Delivery<span class="mendatory">*</span></label>
                            <ng-select formControlName="port_id"
                                [items]="portlist"
                                [readonly]="provisionsSelected"
                                bindLabel="name"
                                bindValue="id"
                                [multiple]="false"
                                placeholder="Select Port"
                                class="form-control cart-form-control order-place-port"
                                clearAllText="Clear"
                                [clearable]="false"
                                [(ngModel)]="cartPortId">
                            </ng-select>
                            <input-validation [control]="oForm.port_id"
                                [submit]="submitted"></input-validation>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group"
                            [ngClass]="{'error' : submitted && oForm.priority_type.errors}">
                            <label for="">Priority<span class="mendatory">*</span></label>
                            <ng-select formControlName="priority_type"
                                [items]="priority"
                                bindLabel="name"
                                bindValue="value"
                                [multiple]="false"
                                placeholder="Priority"
                                class="form-control cart-form-control"
                                [clearable]="false">
                            </ng-select>
                            <input-validation [control]="oForm.priority_type"
                                [submit]="submitted"></input-validation>
                        </div>
                    </div>
                    <input type="hidden"
                        class="form-control cart-form-control"
                        formControlName="ship_id" />
                        <div class="col-md-6 col-sm-6" *ngIf="role ==userRoles.ROLE_ADMIN || role ==userRoles.ROLE_SUBADMIN">
                            <div class="form-group"
                                [ngClass]="{'error' : submitted && oForm.eta.errors}">
                                <label for="">Date of Enquiry</label>
                                <p-calendar appCalendarUiCorrection #cal3
                                    [(ngModel)]="dateOfEnquiry"
                                    formControlName="date_of_enquiry"
                                    placeholder="Date of Enquiry"
                                    dateFormat="dd M yy"
                                    showTime="true"
                                    hourFormat="24"
                                    (onSelect)="onSelectDateofEnquiry($event)"
                                    [maxDate]="today"
                                    [touchUI]="false"
                                    [timeOnly]="false"
                                    showButtonBar="true"
                                    [numberOfMonths]="1"
                                    timeZone="Asia/Kolkata"
                                    showOn="button">
                                    <p-footer class="cal-footer"> <button pButton
                                            label="Apply"
                                            (click)="cal.overlayVisible = false"></button> </p-footer>
                                </p-calendar>
                                <input-validation [control]="oForm.eta"
                                    [submit]="submitted"></input-validation>
                            </div>
                        </div>
                    <div class="col-md-6 col-sm-6">
                        <div class="form-group"
                            [ngClass]="{'error' : submitted && oForm.eta.errors}">
                            <label for="">ETA<span class="mendatory">*</span></label>
                            <p-calendar appCalendarUiCorrection #cal
                                [(ngModel)]="eta"
                                formControlName="eta"
                                placeholder="Arrival Time"
                                dateFormat="dd M yy"
                                showTime="true"
                                hourFormat="24"
                                [minDate]="today"
                                (onSelect)="onSelectEta($event)"
                                [maxDate]="etd"
                                [touchUI]="false"
                                [timeOnly]="false"
                                showButtonBar="true"
                                [numberOfMonths]="1"
                                timeZone="Asia/Kolkata"
                                showOn="button">
                                <p-footer class="cal-footer"> <button pButton
                                        label="Apply"
                                        (click)="cal.overlayVisible = false"></button> </p-footer>
                            </p-calendar>
                            <input-validation [control]="oForm.eta"
                                [submit]="submitted"></input-validation>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <div class="form-group"
                            [ngClass]="{'error' : submitted && oForm.etd.errors}">
                            <label for="">ETD<span class="mendatory">*</span></label>
                            <p-calendar #cal2
                                formControlName="etd"
                                placeholder="Departure Time"
                                [(ngModel)]="etd"
                                dateFormat="dd M yy"
                                showTime="true"
                                hourFormat="24"
                                [minDate]="minEta"
                                (onSelect)="setSelectedDate($event)"
                                [touchUI]="false"
                                [timeOnly]="false"
                                [readonlyInput]="true"
                                showButtonBar="true"
                                [numberOfMonths]="1"
                                showOn="button">
                                <p-footer class="cal-footer"> <button pButton
                                        label="Apply"
                                        (click)="cal2.overlayVisible = false;"></button> </p-footer>
                            </p-calendar>
                            <input-validation [control]="oForm.etd"
                                [submit]="submitted"></input-validation>
                        </div>
                    </div>
                   <!--  <div class="col-md-6 col-sm-6"
                        *ngIf="!provisionsSelected">
                        <div class="form-group">
                            <label>Currency</label>
                            <ng-select formControlName="currency"
                                [items]="currencyDropdown"
                                bindLabel="name"
                                bindValue="id"
                                [multiple]="false"
                                [closeOnSelect]="true"
                                placeholder="Select Currency"
                                class="form-control"
                                clearAllText="Clear"
                                [clearable]="false">
                            </ng-select>
                        </div>
                    </div> -->
                    <div class="col-md-6 col-sm-6">
                        <div class="form-group">
                            <label>RFQ Number</label>
                            <input type="text" maxlength="30" formControlName="quotation_number" class="form-control cart-form-control" (keyup)="changeQuotationNUmber($event)">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="">Message</label>
                            <textarea class="form-control cart-form-control"
                                placeholder="Message"
                                maxlength="60"
                                formControlName="message"></textarea>
                        </div>
                    </div>
                    <div class="col-md-12 tmargin10 button-grp">
                        <button *ngIf="!provisionsSelected"
                            class="btn btn-primary"
                            (click)="showNextPopup = true; showRequisitionForm = false; onSubmitRequisition = !onSubmitRequisition"
                            [disabled]="isLoading">Back</button>
                        <button *ngIf="!provisionsSelected"
                            class="btn btn-primary"
                            (click)="orderConfirm(confirmReq)"
                            [disabled]="isCanNotPlaceOrder || isLoading ">Submit Requisition<span class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                            *ngIf="onSubmitRequisition"></span>
                        </button>
                        <button *ngIf="provisionsSelected"
                            class="btn btn-primary"
                            (click)="orderConfirm(confirmOrder)"
                            [disabled]="isCanNotPlaceOrder || isLoading">Place Order</button>
                    </div>
                </div>
            </form>
        </ng-container>
    </div>
</ng-template>
<app-product-popup #productPopup
    [product]="productDetail"></app-product-popup>
<ng-template #emptyCart>
    <div class="modal-body cancelmodal-spc empty text-center">
        <button type="button"
            class="close cross-butn light"
            aria-label="Close"
            (click)="modalRef.hide();showRemove=false;selectedReq= null;deleteSelected =[]">
            <span aria-hidden="true">&#215;</span>
        </button>
        <h4 class="sure-font">Are you sure you want to <span>remove all items from your cart?</span></h4>
        <div class="text-center tmargin20">
            <button class="buttn-outline select"
                (click)="selectAllToDelete(true,selectedReq);modalRef.hide();"
                type="button">
                Select Item(s)
            </button>
            <!-- <button class="buttn empty-cart"
                (click)="removeMultiple(true);modalRef.hide()">Empty Cart</button> -->
        </div>
    </div>
</ng-template>
<ng-template #confirmation>
    <div class="modal-body cancelmodal-spc empty text-center">
        <button type="button"
            class="close cross-butn light"
            aria-label="Close"
            (click)="modalRef.hide();">
            <span aria-hidden="true">&#215;</span>
        </button>
        <h4 class="sure-font">Are you sure you want to remove product from the cart?</h4>
        <div class="text-center tmargin20">
            <button class="buttn-outline select"
                (click)="modalRef.hide()"
                type="button">
                Cancel
            </button>
            <button class="buttn empty-cart"
                (click)="remove();modalRef.hide()">Remove Item</button>
        </div>
    </div>
</ng-template>
<ng-template #multiDeletePopup>
    <div class="modal-body cancelmodal-spc empty text-center">
        <button type="button"
            class="close cross-butn light"
            aria-label="Close"
            (click)="modalRef.hide();">
            <span aria-hidden="true">&#215;</span>
        </button>
        <h4 class="sure-font">Are you sure you want to <span>remove all items from your cart?</span></h4>
        <!-- {{deleteSelected.length}} -->
        <div class="text-center tmargin20">
            <button class="btn empty-cart cancel item top"
                (click)="modalRef.hide()"
                type="button">
                Cancel
            </button>
            <button class="btn empty-cart delete-all item"
                (click)="removeMultiple(null,selectedReq);modalRef.hide()">Delete items</button>
        </div>
    </div>
</ng-template>
<ng-template #newProductRequestTemplate>
    <app-product-request (closeModalClicked)="closeModalClicked($event)"
        [shipId]="shipId"
        [portId]="cartPortId"
        [isPlaceOrder]="canPlaceOrder"
        [isProvisionCart]="provisionsSelected"
        [isCatalogue]="false"
        [requisition]="cartRequirementList"
        [roleId]="role"></app-product-request>
</ng-template>

<ng-template #uploadPopup>
    <div class="modal-header">
        <h5 class="modal-title">Upload Products</h5>
    </div>
    <div class="modal-body">
        <form [formGroup]="uploadForm">
            <input type="hidden"
                formControlName="ship_id"
                [(ngModel)]="shipId">
            <div class="form-group"
                *ngIf="canPlaceOrder && provisionsSelected"
                [ngClass]="{'error' : submitted && uploadForms.port_id.errors}">
                <label for="">Select Port <span class="mendatory">*</span></label>
                <ng-select formControlName="port_id"
                    [items]="allPortList"
                    bindLabel="name"
                    bindValue="id"
                    [multiple]="false"
                    placeholder="Select Port"
                    class="form-control cart-form-control"
                    clearAllText="Clear"
                    [clearable]="false">
                </ng-select>
                <input-validation [control]="uploadForms.port_id"
                    [submit]="submitted"></input-validation>
            </div>
            <div class="form-group"
                [ngClass]="{'error' : submitted && uploadForms.requirement_id.errors}">
                <!-- <ng-select formControlName="requirement_id"
                    [items]="cartRequirementList"
                    bindLabel="requirement_name"
                    bindValue="id"
                    [multiple]="false"
                    placeholder="Select Requisition"
                    class="form-control"
                    clearAllText="Clear"
                    [clearable]="false">
                </ng-select> -->
                <label for=""> Select Requisition <span class="mendatory">*</span></label>
                <ng-select formControlName="requirement_id"
                    bindLabel="requirement_name"
                    bindValue="id"
                    [multiple]="false"
                    placeholder="Select Requisition"
                    class="form-control cart-form-control"
                    clearAllText="Clear"
                    [clearable]="false">
                    <ng-option *ngFor="let item of cartRequirementList"
                        [value]="item"><b><span [title]="item?.name"
                                class="requirement-name-span">{{item?.requirement_name}}</span> |</b>
                        {{item?.category?.name}} <br>
                        <em>Created By: </em> <b>{{item?.creator_name? item?.creator_name: ''}} / {{item?.rank ?
                            item?.rank:
                            ''}}</b>
                    </ng-option>
                </ng-select>
                <input-validation [control]="uploadForms.requirement_id"
                    [submit]="submitted"></input-validation>
            </div>
            <div class="form-group tmargin30"
                [ngClass]="{error : submitted && !selectFile}">
                <div class="select-file drop-file text-left">
                    <span class="btn-span">+</span>
                    <input accept=".xlsx,.xls"
                        type="file"
                        class="form-control"
                        (change)="selectedfileName($event.target.files)" /> {{file?.name || 'Select File'}}
                </div>
                <div class="errorbox"
                    *ngIf="submitted && !selectFile">
                    <span>Select a file</span>
                </div>
            </div>
            <!-- <a class="" href="https://shipskart-combined.s3.ap-south-1.amazonaws.com/static/file/cart_upload_format.xlsx" target="_blank">Download file format</a> -->
        </form>
    </div>

    <div class="modal-footer">
        <button class="btn btn-outline-primary"
            (click)="modalRef.hide()"
            type="button">Cancel</button>
        <button type="button"
            class="btn btn-primary"
            (click)="openNewRequirementPopup()"
            *ngIf="role ==userRoles.ROLE_CAPTAIN || role ==userRoles.ROLE_COMPANY_ADMIN || role ==userRoles.ROLE_USER || subAdminPrivilageAssigned">Create
            New Requisition</button>
        <button class="btn btn-primary"
            type="button"
            [disabled]="uploading"
            (click)="uploadProducts();">Upload
            <span class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
                *ngIf="uploading"></span>
        </button>
    </div>
</ng-template>
<ng-template #removeReOrderItems>
    <div class="modal-body padding40 text-center">
        <h4 class="sure-font">Cart contains {{zeroItems}} item(s) of zero quantity, please update the quantity or click
            on proceed button to remove all zero quantity items.</h4>
        <div class="text-center tmargin20">
            <button class="btn btn-outline-primary"
                (click)="modalRef.hide()"
                type="button">Cancel</button>
            <button class="btn btn-primary text-white"
                (click)="removeZeroItems();modalRef.hide()">Proceed</button>
        </div>
    </div>
</ng-template>
<ng-template #selectReq>
    <div class="modal-header">
        <h4 class="sure-font tmargin10 text-center">Select Requisition <span class="mendatory">*</span></h4>
    </div>
    <div class="modal-body">
        <div class="form-group tmargin10">
            <ng-select (change)="changeRequirement($event)"
                class="form-control cart-form-control"
                placeholder="Select Requisition *"
                [multiple]="false"
                clearAllText="Clear">
                <ng-option *ngFor="let item of selectedRequirement"
                    [value]="item"><b><span [title]="item?.name"
                            class="requirement-name-span">{{item?.name}}</span> |</b> {{item?.category_name}} <br>
                    <em>Created By: </em> <b>{{item?.creator_name? item?.creator_name: ''}} / {{item?.rank? item?.rank:
                        ''}}</b>
                </ng-option>
            </ng-select>
        </div>
    </div>
    <div class="modal-footer ">
        <div class="text-center">
            <button class="btn btn-outline-primary"
                (click)="modalRef.hide()"
                type="button">
                Cancel
            </button>
            <button class="btn btn-primary text-white"
                (click)="exportCart()"><span class="fa fa-download"></span> Export Cart
                <span class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    *ngIf="loaderView"></span></button>
        </div>
    </div>
</ng-template>
<ng-template #cartNewRequirement>
    <form [formGroup]="cartNewRequisitionForm"
        (ngSubmit)="createCartRequirement()">
        <div class="modal-header">
            <h5 class="modal-title">Create New Requisition</h5>
        </div>
        <div class="modal-body">
            <div class="form-group"
                [ngClass]="{error : Rform.requirement_name.errors && submitted}">
                <label>Requisition Name<span class="mendatory">*</span></label>
                <input type="text"
                    maxlength="100"
                    class="form-control cart-form-control"
                    placeholder="Requisition Name"
                    formControlName="requirement_name" />
                <input-validation [control]="Rform.requirement_name"
                    [submit]="submitted"></input-validation>
            </div>
            <div class="form-group"
                [ngClass]="{error : Rform.requirement_category_id.errors && submitted}">
                <label>Select Requisition Category<span class="mendatory">*</span></label>
                <ng-select [items]="requirementCategories"
                    formControlName="requirement_category_id"
                    bindLabel="name"
                    bindValue="id"
                    [multiple]="false"
                    placeholder="Select Category"
                    class="form-control cart-form-control"
                    [clearable]="false"></ng-select>
                <input-validation [control]="Rform.requirement_category_id"
                    [submit]="submitted"></input-validation>
            </div>
            <div class="form-group"
                [ngClass]="{error : Rform.creator_name.errors && submitted}">
                <label>Creator Name<span class="mendatory">*</span></label>
                <input type="text"
                    maxlength="50"
                    class="form-control cart-form-control"
                    placeholder="Creator Name"
                    formControlName="creator_name" />
                <input-validation [control]="Rform.creator_name"
                    [submit]="submitted"></input-validation>
            </div>
            <div class="form-group"
                [ngClass]="{error : Rform.rank.errors && submitted}">
                <label>Select Creator Rank<span class="mendatory">*</span></label>
                <ng-select [items]="creatorRank"
                    bindLabel="name"
                    bindValue="name"
                    formControlName="rank"
                    [multiple]="false"
                    placeholder="Select Creator Rank"
                    class="form-control cart-form-control"
                    [clearable]="false"></ng-select>
                <input-validation [control]="Rform.rank"
                    [submit]="submitted"></input-validation>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button"
                class="btn btn-outline-primary"
                (click)="cancelRequirement();">Cancel</button>
            <button type="submit"
                class="btn btn-primary"
                [disabled]="loading">Create
                <ng-container *ngIf="loading">
                    <span class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"></span>
                    <span class="sr-only">Loading...</span>
                </ng-container>
            </button>
        </div>
    </form>
</ng-template>

<ng-template #remarkFormModal>
    <form [formGroup]="remarkForm">
        <div class="modal-header space">
            <h6>Vessel's Remark(s) </h6>
            <a type="button"
                class="close close-btn"
                aria-label="Close"
                (click)="remarkModal.hide();">
                <span aria-hidden="true">&times;</span>
            </a>
        </div>
        <div class="modal-body modal-spc space">
            <div class="row">
                <div class="col-md-12">
                    <p>Product Name and Code : <br><span class="primary user-select">{{productDetails.product.name }}</span><br>
                        <span>{{productDetails.product.product_code}}</span> <span
                            *ngIf="productDetails.product.impa_code">, <strong>IMPA
                                Code:</strong> {{productDetails.product.impa_code}}</span>
                    </p>
                </div>
                <!-- <div class="col-md-12">
                    <label for="">
                        Brand
                    </label>
                    <ng-select formControlName="brand_name"
                        [items]="brands"
                        bindLabel="name"
                        bindValue="name"
                        [multiple]="false"
                        [closeOnSelect]="true"
                        placeholder="Select Brand"
                        class="hide-cross-btn form-control cart-form-control"
                        [clearOnBackspace]="true"
                        [clearSearchOnAdd]="true"
                        addTagText="Create New"
                        [addTag]="true"
                        (change)="changeRemark($event)">
                    </ng-select>
                    <small><strong>Note:</strong> To create new brand, type in the field </small>
                </div> -->
                <!-- <div class="col-md-12">
                    <label for="">
                        Package Information
                    </label>
                    <input type="text"
                        class="form-control"
                        placeholder="Enter Package Information Remark"
                        formControlName="pack_type">
                </div> -->
                <div class="col-md-12">
                    <label for="">
                        Remark(s)
                    </label>
                    <textarea class="form-control cart-form-control"
                        placeholder="Enter Remark(s)"
                        formControlName="remark"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer modal-spc space">
            <button class="btn btn-default"
                style="border:1px solid;color: dodgerblue;"
                (click)="remarkModal.hide();">Cancel</button>
            <button class="btn btn-primary"
                (click)="remarkModal.hide();addtocart(productDetails,remarkForm);disableRemark=true">Submit</button>
        </div>
    </form>
</ng-template>
<ng-template #attachementModal>
    <div class="modal-header space">
        <h6>{{attachementBy}} </h6>
        <a type="button"
            class="close close-btn"
            aria-label="Close"
            (click)="supportModal.hide();">
            <span aria-hidden="true">&times;</span>
        </a>
    </div>
    <div class="modal-body modal-spc space">
        <div class="row">
            <div class="col-md-12">
                <p><span class="font600"> Product Name :</span> <span class="primary user-select"> {{supportAttachements.product.name}}</span> <br>
                    <span>{{supportAttachements.product.product_code}}</span>
                </p>
            </div>
        </div>
    </div>
    <div class="modal-footer modal-spc space">
        <div class="col-md-12" *ngIf="attached==1">
            <ng-container class="d-flex"
                *ngFor="let attachementDoc of supportDoc;let i=index">
                <div class="d-flex">
                    <div class="rmargin10">
                        <img src="../../../../../assets/images/placeholder_image.png"
                            alt=""
                            class="img-thumbnail"
                            width="100"
                            height="100">
                    </div>
                    <div class="attachement">
                        <h6>{{attachementDoc}}</h6>
                        <a class="downloads"
                            (click)="downloadVesselAttachement(attachementDoc,1)"
                            >Download</a>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="col-md-12" *ngIf="attached==2">
            <ng-container class="d-flex"
                *ngFor="let supportDoc of supportAttachements?.product?.images">
                <div class="d-flex">
                    <div class="rmargin10">
                        <img src="../../../../../assets/images/placeholder_image.png"
                            alt=""
                            class="img-thumbnail"
                            width="100"
                            height="100">
                    </div>
                    <div class="attachement">
                        <h6>{{supportDoc.path}}</h6>
                        <a class="downloads"
                            (click)="downloadVesselAttachement(supportDoc.path,2)"
                            target="_blank">Download</a>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>
