import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
import { Company } from '@app/modules/company/models/company.modal';
import { SharedService } from '@app/shared/service/shared.service';
import { TabsetComponent } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-vendor-kyc-form',
  templateUrl: './vendor-kyc-form.component.html',
  styleUrls: ['./vendor-kyc-form.component.css']
})
export class VendorKycFormComponent implements OnInit {
  @ViewChild('tabset', { static: false }) tabset: TabsetComponent;
  company: Company;
  vendorId: number;
  vendorEmail: string;
  roleId: number;
  companyCompleted: boolean;
  financialCompleted: boolean;
  technicalCompleted: boolean;

  constructor(
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private sharedService: SharedService,
    private router: Router,
  ) {
    const currentUserData = this.authenticationService.currentUserValue;
    if (currentUserData && currentUserData.data.user_details) {
      this.company = this.sharedService.getCompany();
      this.roleId = currentUserData.data.user_details.role_id;
    }
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe(queryParam => {
      if (queryParam && Object.keys(queryParam).length > 0) {
        if (!this.company) {
          if (this.router.url.includes('/kyc-form')) {
            let queries;
            Object.keys(queryParam).forEach(key => {
              if (key) {
                queries = JSON.parse('{"' + decodeURI(key.replace(/&/g, '","').replace(/=/g, '":"')) + '"}');
              }
            });
            if (queries && queries.vendor_id || queries.email) {
              this.vendorId = queries.vendor_id;
              this.vendorEmail = queries.email;
            } else {
              this.router.navigate(['/']);
            }
          } else {
            this.router.navigate(['/']);
          }
        } else {
          if (!this.router.url.includes('/kyc-form')) {
            if (queryParam && queryParam.vendor_id || queryParam.email) {
              this.vendorId = queryParam.vendor_id;
              this.vendorEmail = queryParam.email;
              if (this.company.id !== parseInt(queryParam.vendor_id) && (this.roleId !== 1 && this.roleId != 5)) {
                this.router.navigate(['/']);
              }
            } else {
              let queries;
              if (queryParam) {
                Object.keys(queryParam).forEach(key => {
                  if (key) {
                    queries = JSON.parse('{"' + decodeURI(key.replace(/&/g, '","').replace(/=/g, '":"')) + '"}');
                  }
                });
              }
              if (queries && queries.vendor_id || queries.email) {
                this.vendorId = queries.vendor_id;
                this.vendorEmail = queries.email;
                if (this.company.id !== parseInt(queries.vendor_id) && this.roleId !== 1) {
                  this.router.navigate(['/']);
                }
              } else {
                this.router.navigate(['/']);
              }
            }
          } else {
            this.router.navigate(['/']);
          }
        }
      } else {
        if (!this.company) {
          this.router.url.includes('/kyc-form') ? this.router.navigate(['/kyc-form']) : this.router.navigate(['/']);
        } else {
          this.router.navigate(['/kyc']);
        }
      }
    });
  }

  setVendorEmail(event) {
    if (event) {
      this.vendorEmail = event;
    }
  }

  companyDetails(event) {
    this.companyCompleted = true;
  }

  financialDetails(event) {
    this.financialCompleted = true;
  }

  technicalDetails(event) {
    this.technicalCompleted = true;
  }

  updatePrev() {
    const id = this.tabset.tabs.filter(tab => tab.active === true)[0].id;
    if (Number(id) - 1 >= 0) {
      this.tabset.tabs.filter(tab => Number(tab.id) === (Number(id)) - 1);
      this.tabset.tabs[(Number(id)) - 1].active = true;
    }
  }

  updateNext() {
    const count = this.tabset.tabs.length;
    const id = this.tabset.tabs.filter(tab => tab.active === true)[0].id;
    if (Number(id) + 1 < count) {
      this.tabset.tabs.filter(tab => Number(tab.id) === (Number(id)) + 1);
      this.tabset.tabs[(Number(id)) + 1].active = true;
    }
  }

}
