<div class="loading"
    *ngIf="loading">
    <img src="/assets/images/loader.gif"
        alt="Loader" />
</div>
<div class="have-middlebox bg-white">
    <div class="login-bg"></div>
    <div class="login-credentials">
        <div>
            <div class="company-logo">
                <img src="/assets/images/white_black.svg" />
            </div>
            <div class="wellcome-msg">
                Reset password
                <small class="sub-heading"
                    *ngIf="token">Please create your new password below.</small>
                <small class="sub-heading"
                    *ngIf="!token">Please enter your username to get reset password mail.</small>
            </div>
            <form *ngIf="token"
                [formGroup]="ChangePasswordForm"
                (ngSubmit)="onChangedPassword()">
                <div class="form-group"
                    [ngClass]="{error : submitted && form.password.errors}">
                    <span class="p-float-label">
                        <input autocomplete="off"
                            formControlName="password"
                            class="form-control"
                            type="password"
                            id="password"
                            #password
                            pInputText />
                        <label for="password">New Password</label>
                    </span>

                    <!-- (input)="username.value=$event.target.value.toLowerCase()" -->
                    <input-validation [control]="form.password" [submit]="submitted"></input-validation>
                    <!-- <div class="errorbox"
                        *ngIf="submitted && form.password.errors">
                        <span *ngIf="!form.password.valid">Enter valid password</span>
                    </div> -->
                </div>
                <div class="form-group"
                    [ngClass]="{error : submitted && form.confirmPassword.errors}">
                    <span class="p-float-label">
                        <input autocomplete="off"
                            formControlName="confirmPassword"
                            class="form-control"
                            type="password"
                            id="pass"
                            pInputText />
                        <label for="pass">Confirm password</label>
                    </span>
                    <input-validation [control]="form.confirmPassword" [submit]="submitted"></input-validation>
                    <!-- <div class="errorbox"
                        *ngIf="submitted && form.confirmPassword.errors">
                        <span *ngIf="form.confirmPassword.errors.mustMatch">Passwords must match</span>
                    </div> -->
                </div>
                <div class="form-group">
                    <button class="btn btn-primary"
                        type="submit">Reset</button>
                    <a class="lmargin10"
                        routerLink='/login'>Back to login</a>
                </div>

            </form>

            <!-- Reset Requset mail-->
            <form *ngIf="!token"
                [formGroup]="requestPasswordForm"
                (ngSubmit)="requestPasswordMail()">
                <div class="form-group"
                    [ngClass]="{error : submitted && reqForm.email.errors}">
                    <span class="p-float-label">
                        <input autocomplete="off"
                            formControlName="email"
                            class="form-control"
                            type="email"
                            id="pass"
                            pInputText />
                        <label for="pass">Email address</label>
                    </span>
                    <input-validation [control]="reqForm.email" [submit]="submitted"></input-validation>
                    <!-- <div class="errorbox"
                        *ngIf="submitted && reqForm.email.errors">
                        Enter valid Email
                    </div> -->
                </div>
                <div class="form-group">
                    <button class="btn btn-primary"
                        type="submit"
                        [disabled]="!isDisabled">Reset
                        <ng-container *ngIf="!isDisabled">
                            <span class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"></span>
                            <span class="sr-only">Loading...</span>
                        </ng-container>
                    </button>
                    <a class="lmargin10"
                        routerLink='/login'>Back to login</a>
                </div>
            </form>
            <div class="copy-rights">
                © {{ currentYear }} Shipskart Marine Private Limited. All rights reserved
            </div>
        </div>
    </div>
</div>
