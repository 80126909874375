import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  template: '<app-layout></app-layout>'
})
export class DashboardComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
