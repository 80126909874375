import { ActivatedRoute, Router, Params } from '@angular/router';
import { Injectable } from '@angular/core';
import { AppConstants } from '../constants/app.constants';
import { DatePipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class CommonService {
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private datepipe: DatePipe
  ) { }
  
  CheckPage(page) {
    this.activeRoute.queryParams.subscribe(params => {
      if (params.page) {
        page.offset = +params.page - 1;
      }
    });
    return page;
  }

  CheckFilter(filterForm, search = false) {
    let filter = JSON.stringify(filterForm.value);
    // let filter;

    if (!search) {
      this.activeRoute.queryParams.subscribe(params => {
        if (params.filter) {
          filter = decodeURIComponent(params.filter);
        } else {
          filterForm.reset();
          // filterForm.patchValue({ fname: '', company_id: null, verified: null });
        }
      });
    }
    // if (Object.keys(filterForm.value).some(key => filterForm.value[key] != null && filterForm.value[key] !== '')) {
    //   filter = JSON.stringify(filterForm.value);
    // } else {
    //   filter = JSON.stringify({ fname: '', company_id: null, verified: null });
    // }
    return filter;
  }

  buildFilterUrlParm(filterForm, pages = 1) {
    // var queryParams: Params = { page: 1, filter: JSON.stringify(filterForm.value) };
    const queryParams: Params = { page: pages };
    const params = {};
    if (filterForm) {
      for (const key in filterForm.value) {
        if (filterForm.value[key] != null && filterForm.value[key] !== '') {
          params[key] = filterForm.value[key];
        }
      }
      queryParams.filter = encodeURIComponent(JSON.stringify(params));
      this.router.navigate(
        [],
        {
          relativeTo: this.activeRoute,
          queryParams,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }
  }

  validateEmail(email): boolean {
    const regex = AppConstants.EMAIL_PATTERN;
    return regex.test(String(email).toLowerCase().trim());
  }

verifyEta(event, eta?, minEta?) {
    if (eta < new Date()) {
      eta = new Date();
    }
    let newDate: any = this.datepipe.transform(new Date(eta), 'dd MMM yy');
    if ((new Date(eta).getMinutes() + 1) == 60) {
      if (new Date(eta).getHours() == 23) {
        const setMonth = new Date(newDate).getMonth() + 1;
        const LastDate: any = new Date(eta.getFullYear(), setMonth, 0);
        let newDate2: any;

        if (eta.getDate() == LastDate.getDate()) {
          let setMonth2: any;
          if (eta.getMonth() + 1 < 12) {
            setMonth2 = setMonth + 1;
            newDate2 = new Date(eta.getFullYear() + ' ' + (setMonth2) + ' ' + 1);
          } else {
            newDate2 = new Date((eta.getFullYear() + 1) + ' ' + 1 + ' ' + 1);
          }
          newDate = this.datepipe.transform(new Date(newDate2), 'dd MMM yy');
          return minEta = new Date(newDate + ' ' + '00' + ':' + '00' + ':' + '00');
        } else {
          newDate2 = new Date(eta.getFullYear() + ' ' + (setMonth) + ' ' + (eta.getDate() + 1));
          newDate = this.datepipe.transform(new Date(newDate2), 'dd MMM yy');
          return minEta = new Date(newDate + ' ' + '00' + ':' + '00' + ':' + '00');
        }
      } else {
        return minEta = new Date(newDate + ' ' + (new Date(eta).getHours() + 1) + ':' + '00' + ':' + '00');
      }
    } else {
      return minEta = new Date(newDate + ' ' + new Date(eta).getHours() + ':' + (new Date(eta).getMinutes() + 1) + ':' + '00');
    }
  }
}
