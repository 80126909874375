import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utctolocal'
})

export class utcToLocal implements PipeTransform {

  transform(value: any): any {
    return value + "Z";
  }

}