<div>
  <div class="row">
    <div class="filter-box-content col-md-6">
      <div class="more-actions date-dropdown"
        (click)="openInnerList1 = !openInnerList1;">
        <a href="javascript:void(0)"
          class="quick-actions"><img src="/assets/images/calendar-icon-light.svg" height="15"> {{showDate1 || 'From Date'}} - {{showDate2 || 'To Date'}} <a
            *ngIf="showDate1 && showDate2"
            (click)="resetDate()"
            class="reset-icon">&#10006;</a><span class="fa fa-angle-down"></span>
        </a>
      </div>
      <span (click)="openInnerList1 = !openInnerList1"
        class="click-shadow"
        [ngClass]="{'show': openInnerList1}"></span>
      <div class="filter-options top innerList warehouse row"
        [ngClass]="{'show': openInnerList1}">
        <div class="col-md-12">
          <label for="form-label">From Date</label>
              <p-calendar appCalendarUiCorrection [(ngModel)]="rangeDates1" #cal1
                placeholder="Select Date"
                [touchUI]="false"
                [timeOnly]="false"
                [showOtherMonths]="false"
                [selectOtherMonths]="false"
                [ngModelOptions]="{standalone: true}"
                [monthNavigator]="true"
                [yearNavigator]="true"
                yearRange="2016:2050"
                showButtonBar="true"
                inputId="multiplemonths"
                (click)="rangeDateSelected($event, rangeDates1, 1)">
              </p-calendar>
        </div>
        <div class="col-md-12">
          <label for="form-label">To Date</label>
            <p-calendar appCalendarUiCorrection [(ngModel)]="rangeDates2" #cal2
              placeholder="Select Date"
              [touchUI]="false"
              [timeOnly]="false"
              [minDate]="rangeDates1"
              [maxDate]="todayDate"
              [showOtherMonths]="false"
              [selectOtherMonths]="false"
              [ngModelOptions]="{standalone: true}"
              [monthNavigator]="true"
              [yearNavigator]="true"
              yearRange="2016:2050"
              showButtonBar="true"
              inputId="multiplemonths"
              (click)="rangeDateSelected($event, rangeDates2, 2)">
            </p-calendar>
      </div>
      <div class="col-md-12 tmargin10 text-right">
        <button class="btn btn-sm btn-primary" type="button" (click)="submitDate()" [disabled]="!(showDate1 && showDate2)">Apply Date</button>
      </div>
      </div>
    </div>
  </div>
