import { Component, Input, OnInit } from '@angular/core';
import { Utils } from '@app/shared/service/util';

@Component({
  selector: 'app-dashboard-manager',
  templateUrl: './dashboard-manager.component.html',
  styleUrls: ['./dashboard-manager.component.css'],
  providers: [Utils]
})
export class DashboardManagerComponent implements OnInit {
  category = '';
  categoryId: number;
  @Input() shipList = [];
  @Input() portList = [];
  @Input() categoryList = [];
  constructor(
    public util: Utils
  ) {
  }

  ngOnInit() {
    this.util.getDashboard();
  }
}
