import { ProductComponent } from './product.component';
import { ProductDetailsComponent } from './components/details/details.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { ProductFormComponent } from './components/product-form/product-form.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewProductComponent } from '@app/modules/product/components/new-product/new-product.component';
import { VendorPriceComparisonComponent } from './components/vendor-price-comparison/vendor-price-comparison.component';

const routes: Routes = [
  { path: 'add', component: ProductFormComponent },
  { path: '', component: ProductListComponent },
  { path: 'prices', component: ProductListComponent },
  { path: 'edit/:id', component: ProductFormComponent },
  { path: 'details/:id', component: ProductDetailsComponent },
  { path: 'request', component: NewProductComponent },
  { path: 'vendor-price-comparison', component: VendorPriceComparisonComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductRoutingModule {
  static COMPONENT = [
    ProductFormComponent,
    ProductListComponent,
    ProductDetailsComponent,
    ProductComponent,
    NewProductComponent,
    VendorPriceComparisonComponent
  ];
}
