export class AppConstants {
  static readonly CREDIT_PERIODS = [
    { id: 0, value: '30 Days' },
    { id: 1, value: '45 Days' },
    { id: 2, value: '60 Days' },
    { id: 3, value: '90 Days' },
    { id: 4, value: 'Prepayment' }
  ];

  static readonly URL_PATTERN = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  static readonly EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  // static readonly PHONE_PATTERN = /^[6789]{1}[0-9]{9}$/;
  // static readonly URL_PATTERN = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  // (https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?
  // /(https?:\/\/[^ ]*)/
}
