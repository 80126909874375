import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FinancialDetails, KycDetails, TechnicalDetails, Vendor } from '../models/kyc.modal';
import { FormDataToJsonService } from '@app/shared/service/form-data-to-json.service';

@Injectable({
  providedIn: 'root'
})
export class KycService {

  constructor(private http: HttpClient,
    private formDataToJson: FormDataToJsonService
  ) { }

  getAll(params, headers = null) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/company/kyc/list`, { params, headers })
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }
  downloadKyc(params, headers = null) {
    return this.http
      .get(`${environment.apiUrl}/v1/company/kyc/list`, {
        headers,
        params,
        responseType: 'blob'
      })
      .pipe(
        map(dataVal => {
          return dataVal;
        })
      );
  }
  companyDetails(companyDetails) {
    return this.http.post<KycDetails>(`${environment.apiUrl}/v1/company/kyc/company_details`, companyDetails);
  }

  financialDetails(financialDetails) {
    return this.http.post<FinancialDetails>(`${environment.apiUrl}/v1/company/kyc/financial_details`, financialDetails);
  }

  technicalDetails(technicalDetails) {
    return this.http.post<TechnicalDetails>(`${environment.apiUrl}/v1/company/kyc/technical_details`, technicalDetails);
  }

  getCompanyDetails(params): Observable<KycDetails> {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/company/kyc/company_details`, { params })
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }

  getFinancialDetails(params): Observable<FinancialDetails> {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/company/kyc/financial_details`, { params })
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }

  getTechnicalDetails(params): Observable<TechnicalDetails> {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/company/kyc/technical_details`, { params })
      .pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }

  setKycStatus(payload) {
    const formToJson = this.formDataToJson.convertFormDataToJson(payload);
    return this.http.put(`${environment.apiUrl}/v1/company/kyc/update_status`, formToJson);
  }

  sendKycRequest(payload) {
    const formToJson = this.formDataToJson.convertFormDataToJson(payload);
    return this.http.post(`${environment.apiUrl}/v1/company/kyc/request`, formToJson);
  }

  getVendorList() {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/company/kyc_filter/list`)
      .pipe(
        map(dataval => {
          return dataval.data
        })
      );
  }

  getRequestedKyc(params) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/company/kyc/request`, { params }).pipe(map(dataval => dataval.data));
  }
}
