import { DataTableColumn } from '@app/core/models/data-table';

export const UserColumns: DataTableColumn[] = [
  // {
  //   prop: 'fname',
  //   name: 'Name',
  //   type: 'string'
  // },
  // {
  //   prop: 'lname',
  //   name: 'Last Name',
  //   type: 'string'
  // },
  {
    prop: 'email',
    name: 'Email',
    type: 'string'
  },
  {
    prop: 'phone',
    name: 'Phone',
    type: 'number'
  },
  {
    prop: 'role',
    name: 'Designation',
    type: 'string'
  },
  {
    prop: 'status',
    name: 'Status',
    type: 'string'
  },
  {
    prop: 'created_on',
    name: 'Creation Date',
    type: 'date'
  }
];
