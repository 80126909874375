import { ReactiveFormsModule } from '@angular/forms';
import { CatalogueComponent } from '@app/modules/cetalogue/catalogue.component';
import { SharedModule } from '@app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CatelogProductComponent } from './catelog-product/catelog-product.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import {EditorModule} from 'primeng/editor';
import {ProductModule} from '@app/modules/product/product.module';

@NgModule({
  declarations: [CatelogProductComponent, CatalogueComponent],
    imports: [
        CommonModule,
        NgbPaginationModule,
        SharedModule,
        AutoCompleteModule,
        ReactiveFormsModule,
        PaginationModule.forRoot(),
        EditorModule,
        ProductModule
    ],
  exports: [CatelogProductComponent]
})
export class CatelogueModule {}
