import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout.component';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { SharedModule } from '@app/shared/shared.module';
import { DashboardModule } from '@app/dashboard/dashboard.module';

@NgModule({
  declarations: [HeaderComponent, LeftSidebarComponent, LayoutComponent],
  imports: [CommonModule, RouterModule, ReactiveFormsModule, SharedModule, DashboardModule],
  exports: [LayoutComponent]
})
export class LayoutModule { }
