<div class="padding20">
    <div class="row">
        <div class="col-md-4">
            <ng-select [items]="subCategories" bindLabel="name" bindValue="id" (change)="setCategory($event)" [multiple]="false" placeholder="Select Subcategory" class="form-control" clearAllText="Clear" [clearable]="false">
            </ng-select>
        </div>
        <div class="col-md-8">
            <div class="search-categories search-field border-color">
                <form [formGroup]="searchFormREQ">
                    <div class="searchbox" [class.showCross]="selectedAutocomplete">
                        <input type="hidden" class="" [(ngModel)]="selectCategoryId" formControlName="category_id">
                        <div>
                            <div class="ng-autocomplete">
                                <p-autoComplete formControlName="search" placeholder="Search products by name, IMPA code or product code" [suggestions]="suggestion" [disabled]="disableSuggestion" (keyup)="showCrossBtn($event)" (completeMethod)="search($event)" (keyup.enter)="searchProduct()"
                                    [(ngModel)]="selectedAutocomplete" (onSelect)="doOnSelect($event)">
                                    <ng-template let-suggestion pTemplate="item" styleClass="wid100">
                                        <div class="search-product ui-helper-clearfix" style="border-bottom:1px solid #D5D5D5;width: 500px;">
                                            <p class="code">{{suggestion.title | titlecase}}</p>
                                            <p class="code" *ngIf="suggestion.product_code">SK code: {{suggestion.product_code}}</p>
                                            <p class="code ">Impa code: <strong>{{suggestion.impa_code || 'N/A'}}</strong></p>
                                            <p class="code">Unit: <strong>{{suggestion.unit || 'N/A'}}</strong></p>
                                            <p class="code">Pack Type: <strong>{{suggestion.pack_type || 'N/A'}}</strong></p>
                                            <p class="code" *ngIf="suggestion?.brand_name">Brand: <strong>{{suggestion?.brand_name}}</strong></p>
                                        </div>
                                    </ng-template>

                                </p-autoComplete>
                            </div>
                        </div>
                        <button class="find-btn cross-btn" (click)="resetForm()" type="button">&times;</button>
                        <button [disabled]="disableSuggestion" class="find-btn" type="button" (click)="searchProduct()"><span class="fa fa-search"></span></button>
                    </div>
                    <!-- <div class="errorbox"
            *ngIf="submitted && form.search.errors">
            <span *ngIf="!form.search.valid">Please enter something</span>
          </div> -->
                </form>
            </div>
        </div>
    </div>
</div>


<div class="data-table bmargin10" [ngClass]="{tmargin20: total}">
    <table class="table table-striped full-table">
        <tr>
            <th width="200">Name</th>
            <th>Category</th>
            <th>Shipskart code</th>
            <th>IMPA code</th>
            <th>Unit</th>
            <th>Pack Size</th>
            <th>Quantity</th>
        </tr>
        <ng-template [ngIf]="!noProduct && !pageLoading">
            <tr *ngFor="let item of productList">

                <td>
                    <a href="javascript:void(0)" (click)="openPopup(item)" class="user-select">{{ item.name }}</a><br>
                    <span *ngIf="item.brand">Brand: {{ item.brand.name }}</span>
                </td>
                <td>
                    {{ item.sub_category.name }}
                </td>
                <td>
                    {{ item.product_code }}
                </td>
                <td>{{ item.impa_code || 'N/A'}}</td>
                <td>
                    <div class="select-wrap" *ngIf="item.variants?.length > 1 else pack">
                        <i class="fa fa-chevron-down"></i>
                        <select class="select-list form-control" (change)="onPackChange($event.target.value, item.variants, item)">    
                        <option *ngFor="let variant of item.variants" [selected]="variant.id == item.id"
                            [value]="variant.id">{{variant.unit?.name ? variant.unit?.name:''}}
                        </option>
                    </select></div>
                    <ng-template #pack>{{item.unit?.name}}</ng-template>
                </td>
                <td>{{item.pack_type ? item.pack_type : ''}} </td>
                <td class="qty-td2">
                    <div class="quantity">
                        <button type="button" [disabled]="item.quantity == 0" class="count decrease-count" (click)="decreaseVal(item)">–</button>
                        <input type="text" class="valbox" pKeyFilter="pnum" [(ngModel)]="item.quantity" (change)="addItemToCart(item)" maxlength="15" />
                        <button type="button" class="count increase-count" (click)="increaseVal(item)">+</button>
                    </div>
                </td>
            </tr>
        </ng-template>
    </table>
    <div class="no-product loading text-center padding20" *ngIf="productList.length == 0 && !pageLoading && !noProduct">
        Please Select Category First
    </div>
    <div class="no-product loading text-center padding20" *ngIf="noProduct && !pageLoading">
        {{ noProduct }}
    </div>
    <div class="no-product loading text-center padding20" *ngIf="(pageLoading && !noProduct) || (pageLoading && noProduct)">
        Loading...
    </div>
</div>
<div class="add-product padding20 tpadding0 clearfix" *ngIf="productList.length > 0 && !pageLoading && !noProduct">
    <button class="btn btn-primary pull-right lmargin10" (click)="SubmitItems()">Submit</button>
    <button class="btn btn-outline-primary pull-right" (click)="hideModal()">Close</button>
    <div class="paging-wrap" [class.stop-click]="(pageLoading && !noProduct) || (pageLoading && noProduct)">
        <span *ngIf="total" class="total-count">{{limit * page - 9}} -
            {{limit * page + limit - 10 < total ? limit * page + limit - 10 : total}}
            of {{total}}</span>
        <ngb-pagination class="pull-left" [collectionSize]="total" [(page)]="page" [maxSize]="limit" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="onPageChange($event)"></ngb-pagination>
    </div>
    <div class="paging-wrap place-bottom fix" [class.stop-click]="(pageLoading && !noProduct) || (pageLoading && noProduct)">
        <span *ngIf="total" class="total-count">{{limit * page - 9}} -
{{limit * page + limit - 10 < total ? limit * page + limit - 10 : total}}
of {{total}}</span>
        <ngb-pagination class="pull-left" [collectionSize]="total" [(page)]="page" [maxSize]="limit" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="onPageChange($event)"></ngb-pagination>
    </div>
</div>

<app-product-popup #productPopup [product]="productDetail"></app-product-popup>