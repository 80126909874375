import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HelpChatRoutingModule } from './help-chat-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChatfilterPipe } from './chat/chatfilter.pipe';
import { HelpChatComponent } from '@app/modules/help-chat/help-chat.component';
import { SharedModule } from '@app/shared/shared.module';
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
// import {environment} from '@environment/environment';

// const config: SocketIoConfig = { url: environment.socketUrl, options: {transportOptions: {
//       polling: {
//         extraHeaders: {
//           'Authorization': 'Bearer sdfsdf'
//         }
//       }
//     }} };

@NgModule({
  declarations: [HelpChatRoutingModule.COMPONENTS, ChatfilterPipe, HelpChatComponent],
  imports: [
    CommonModule,
    RouterModule,
    HelpChatRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    SharedModule
    // SocketIoModule.forRoot(config)
  ],
})
export class HelpChatModule { }
