import { Pipe, PipeTransform } from '@angular/core';
enum Priority {
  URGENT,
  High,
  Low
}
@Pipe({ name: 'priority' })
export class PriorityPipe implements PipeTransform {
  transform(value: any) {
    return value;
  }
}
