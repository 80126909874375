<a class="back-btn back inline-block bmargin10"
    href="javascript:;"
    (click)="goPreviousUrl()"><span class="fa fa-arrow-left"></span>Back to Dashboard</a>
<div class="view">
    <h3 class="inline-block rs-top-space pad-bottom">{{formId==74 ? "Delivery feedback forms":"View Form Submissions"}}
    </h3>
</div>
<div class="data-table"
    *ngIf="!loading">
    <ngx-datatable class="material paging-table top"
        [class.stop-click]="isLoading"
        #formSubmissionTable
        [rows]="rows"
        [columnMode]="'force'"
        [headerHeight]="40"
        [scrollbarH]="true"
        [rowHeight]="'auto'"
        [footerHeight]="40"
        [externalPaging]="true"
        [loadingIndicator]="isLoading"
        [count]="page.count"
        [offset]="page.offset"
        [limit]="page.limit"
        (page)='setPage($event)'>
        <ngx-datatable-column name="Order No"
            prop=""
            headerClass="no-arrow"
            [width]="115"
            *ngIf="formId==74">
            <ng-template ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-value="value"
                let-row="row">
                {{value.order_number}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Submitted By"
            prop=""
            headerClass="no-arrow"
            [width]="115">
            <ng-template ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-value="value"
                let-row="row">
                <span *ngIf="!value.guestId;else guest">
                    {{value.username}}
                </span>
                <ng-template #guest>
                    <span>Name: {{value.username[0].value |titlecase}}</span><br>
                    <span>Designation: {{value.username[1].value |titlecase}}</span><br>
                    <span>Company Name: {{value.username[2].value |titlecase}}</span>
                </ng-template>

            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Form Name"
            prop=""
            headerClass="no-arrow"
            [width]="115">
            <ng-template ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-value="value"
                let-row="row">
                {{value.form_name}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Created On"
            prop=""
            headerClass="no-arrow"
            [width]="115"
            *ngIf="formId!=74">
            <ng-template ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-value="value"
                let-row="row">
                {{value.created_at|date}}
            </ng-template>
        </ngx-datatable-column>
        <!-- <ngx-datatable-column name="Assigned On"
            prop=""
            headerClass="no-arrow"
            [width]="115"
            *ngIf="formId!=74">
            <ng-template ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-value="value"
                let-row="row">
                {{value.delivery_date|date}}
            </ng-template>
        </ngx-datatable-column> -->
        <!-- <ngx-datatable-column name="Status"
            prop=""
            headerClass="no-arrow"
            [width]="115"
            *ngIf="formId!=74">
            <ng-template ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-value="value"
                let-row="row">
                {{value.form_name}}
            </ng-template>
        </ngx-datatable-column> -->
        <ngx-datatable-column name="Ship Name"
            prop=""
            headerClass="no-arrow"
            [width]="115"
            *ngIf="formId==74">
            <ng-template ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-value="value"
                let-row="row">
                {{value.ship_name}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Port of Delivery"
            prop=""
            headerClass="no-arrow"
            [width]="115"
            *ngIf="formId==74">
            <ng-template ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-value="value"
                let-row="row">
                {{value.port_name}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Company Name"
            prop=""
            headerClass="no-arrow"
            [width]="115"
            *ngIf="formId==74">
            <ng-template ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-value="value"
                let-row="row">
                {{value.company_name | titlecase}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Delivered On"
            prop=""
            headerClass="no-arrow"
            [width]="115"
            *ngIf="formId==74">
            <ng-template ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-value="value"
                let-row="row">
                {{value.delivery_date|date}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Feedback Form"
            prop=""
            headerClass="no-arrow"
            [width]="115">
            <ng-template ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-value="value"
                let-row="row">
                <div class="star-container">
                    <span class="star"
                        *ngFor="let index of starIndexes"
                        [class.filled]="isFilled(index,value.rating_data)"
                        [class.partially-filled]="isPartiallyFilled(index,value.rating_data)">
                        &#9733;
                    </span>
                </div>
                <br>
                <a href="javascript:void(0)"
                    (click)="openModal(value.form_id,value.order_id,value.to_user_id,value.guestId)">View</a>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Attachment"
            prop=""
            headerClass="no-arrow"
            [width]="50">
            <ng-template ngx-datatable-cell-template
                let-rowIndex="rowIndex"
                let-value="value"
                let-row="row">
                <button class="actionBtn btn-sm btn-link" (click)="redirectUser(value.attachment)" type="button" [disabled]="!value.attachment.length"><i class="fa fa-download"></i></button>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-footer>
            <ng-template ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-curPage="curPage"
                let-offset="offset"
                let-isVisible="isVisible">
                <app-paging class="paging"
                    [curPage]="curPage"
                    [pageSize]="pageSize"
                    [rowCount]="rowCount"
                    [offset]="offset"
                    [table]="table"
                    (page)="onPageChange($event, 1)"></app-paging>
                <app-paging class="paging place-bottom"
                    [curPage]="curPage"
                    [pageSize]="pageSize"
                    [rowCount]="rowCount"
                    [offset]="offset"
                    [table]="table"
                    (page)="onPageChange($event, 1)"></app-paging>
            </ng-template>
        </ngx-datatable-footer>
    </ngx-datatable>

</div>

<app-feedback-form #feedbackModal (closeForm)="isFeedbackFormModelOpen=false"></app-feedback-form>
