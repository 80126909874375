import { Component, Input, OnInit } from '@angular/core';
import { CategoryService } from '@app/modules/category/services/category.service';
import { SharedService } from '@shared/service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { PortService } from '@app/modules/port/port.service';
import { ShipService } from '@app/modules/ship/services/ship.service';
import { CompanyService } from '@app/modules/company/services/company.service';
import { UserRoles } from '@core/enums/user-roles';
import { CompanyType, CompanyTypeLabel } from '@app/modules/company/models/company.enum';

@Component({
  selector: 'app-category-expenditure',
  templateUrl: './category-expenditure.component.html',
  styleUrls: ['./category-expenditure.component.css']
})
export class CategoryExpenditureComponent implements OnInit {

  categoryCount = 0;
  categoryTotal = 0;
  subCategoryCount = 0;
  totalDeliveryCharge = 0;
  totalLaunchCharge = 0;
  subCategoryTotal = 0;
  categoryExpenditureData: any[] = [];
  // subCategoryExpenditureData: any[] = [];
  currencyCode: string;
  category: string;
  categoryId: number;
  loadingSubCategoryExpenditure: boolean;
  loadingCategoryExpenditure: boolean;
  loaderView = false;
  colour: string;
  countType = 'Requisitions';

  @Input() chartTitle = 'Orders by Category';
  @Input() subChartTitle = 'Subcategory Wise Expenditure';
  @Input() displayFilter = true;
  @Input() displayDeliveryCharge = true;
  @Input() role: number;
  userRoles = UserRoles;
  filterForm: FormGroup;
  @Input() categoryList = [];
  @Input() shipList = [];
  companyList = [];
  @Input() portList = [];
  userDetails
  etd = new Date();
  //toggleMore: boolean;
  loadingCategories: boolean;
  loadingPorts: boolean;
  loadingShips: boolean;
  loadingCompanies: boolean;
  shipid: number;
  isOrder = false;
  noRecordsFound = false;

  reportType = [
    { title: 'Order Report', value: 'true' },
    { title: 'Requisition Report', value: '' },
  ];

  requisitionStatus = [
    { title: 'Urgent', value: 'urgent' },
    { title: 'Waiting For Approval', value: 'waiting_for_approval' },
    { title: 'Rejected By Manager', value: 'rejected_by_manager' },
    { title: 'Rejected by Purchase Team', value: 'rejected_by_po' }
  ];

  orderStatus = [
    { title: 'Order Placed', value: 'placed' },
    { title: 'Confirm Order', value: 'confirmed' },
    { title: 'Out For Delivery', value: 'out_for_delivery' },
    { title: 'Order Delivered', value: 'delivered' },
    { title: 'Partially Delivered', value: 'partially_delivered' },
    { title: 'Rejected Order', value: 'rejected' }
  ];

  constructor(
    private categoryService: CategoryService,
    private portService: PortService,
    private shipService: ShipService,
    private sharedService: SharedService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private companyService: CompanyService
  ) {
    this.filterForm = this.fb.group({
      ship_id: [null],
      port_id: [null],
      category_id: [[]],
      from_date: [null],
      to_date: [null],
      company_id: [null],
      vendor_id: [null],
      reportType: [null],
      status: [null]
    });
  }

  ngOnInit() {
    this.shipid = this.getShip();
    if (this.role !== this.userRoles.ROLE_ADMIN) {
      this.currencyCode = this.sharedService.getCompany().currency_code;
    }
    this.userDetails = this.sharedService.getUserDetails();
    //this.getCategoryList();
    //this.getShipList();
    //this.getPortList();
    if (this.role === this.userRoles.ROLE_ADMIN) {
      this.getCompanyList();
    }
    if (this.role === this.userRoles.ROLE_CAPTAIN) {
      this.getCategoryWiseOrder(this.shipid);
      this.chartTitle = 'Requisitions by Category';
      this.subChartTitle = 'Subcategory Wise Requisition Report';
    } else {
      this.getCategoryExpenditure();
      this.countType = 'Orders';
    }
  }

  getShip() {
    return this.sharedService.getShip();
  }

  private getShipList() {
    this.loadingShips = true;
    this.shipService.getAll(1, 1000).subscribe(data => {
      this.shipList = data.results;
      this.loadingShips = false;
    }, error => {
      this.toastr.error(error.error.message, '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      this.loadingShips = false;
    });
  }

  private getCompanyList() {
    this.loadingCompanies = true;
    this.companyService.getAll(1, 500, 0, 1, null, 0).subscribe(data => {
      this.companyList = data.results;
      this.loadingCompanies = false;
    }, error => {
      this.toastr.error(error.error.message, '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      this.loadingCompanies = false;
    });
  }

  private getCategoryList() {
    this.loadingCategories = true;
    this.categoryService.getAllV2(1, 20).subscribe(data => {
      this.categoryList = data.data;
      this.loadingCategories = false;
    }, error => {
      this.toastr.error(error.error.message, '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      this.loadingCategories = false;
    });
  }

  /*categoryDataClicked(event) {
    let item = this.categoryExpenditureData.find(x => x.label == event.element._model.label);
    if (item && item.label != this.category && this.role != this.userRoles.ROLE_CAPTAIN) {
      this.getSubCategoryExpenditure(item.id, this.filterForm);
    } else {
      this.getSubCategoryWiseOrderReport(item.id, this.shipid, this.filterForm);
    }
  }*/

  /*getSubCategoryExpenditure(categoryId, filterForm = null, download = false, format = 'json') {
    if (this.loadingSubCategoryExpenditure) {
      return;
    }
    let colour;
    if (categoryId == this.categoryId) {
      return;
    }
    let category = this.categoryExpenditureData.find(x => x.id == categoryId);
    if (category) {
      this.category = category.label;
      this.categoryId = category.id;
      colour = category.colour;
      this.colour = category.colour;
    }

    this.loadingSubCategoryExpenditure = true;
    this.subCategoryExpenditureData = [];
    this.subCategoryTotal = 0;
    this.subCategoryCount = 0;

    let fromDate = null;
    let toDate = null;
    let shipId = null;
    let portId = null;
    let companyId = null;
    let vendorId = null;
    if (filterForm != null) {
      fromDate = this.datePipe.transform(filterForm.value.from_date, 'yyyy-MM-dd');
      toDate = this.datePipe.transform(filterForm.value.to_date, 'yyyy-MM-dd');
      shipId = filterForm.value.shipid;
      portId = filterForm.value.port_id;
      companyId = filterForm.value.company_id;
      vendorId = filterForm.value.vendor_id;
    }

    this.categoryService.getSubCategoryExpenditureReport(categoryId, companyId, vendorId, shipId, portId, fromDate, toDate)
      .subscribe((data: any) => {
        if (download) {
          const mime = format === 'pdf' ? 'application/pdf' : 'application/vnd.ms-excel';
          this.sharedService.saveAsBlob(
            data,
            mime,
            `report.${format}`
          );
          this.loadingSubCategoryExpenditure = false;
        } else {
          data.data.forEach(data => {
            this.subCategoryExpenditureData.push(
              {
                label: data['name'],
                sub_category_id: data['id'],
                value: data['count'],
                colour: colour,
                amount: data['amount']
              }
            );
            this.subCategoryCount += data['count'];
            this.subCategoryTotal += data['amount'];
          });
          this.loadingSubCategoryExpenditure = false;
        }

      }, error => {
        //this.toastr.error('No Report Found');
      });
  }*/

  getCategoryExpenditure(download = 0, format = 'json', subReport = 0, filterForm = null) {
    if (this.isDateRangeInvalid()) {
      this.toastr.error('"From date" can not be greater than "To date"', '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      return;
    }
    if (this.loadingCategoryExpenditure) {
      return;
    }
    if (!download) {
      this.loadingCategoryExpenditure = true;
    } else {
      this.loaderView = true;
      // this.loadingService.setLoading(true);
    }

    let fromDate = null;
    let toDate = null;
    let categoryId = null;
    let shipId = null;
    let portId = null;
    let companyId = null;
    let vendorId = null;
    if (filterForm != null) {
      if (filterForm.get('from_date').value && filterForm.get('to_date').value == null) {
        filterForm.get('to_date').setValue(this.etd);
      }
      if (filterForm.get('from_date').value == null && filterForm.get('to_date').value) {
        const oldDate = new Date(2016, 0, 1);
        filterForm.value.from_date = this.datePipe.transform(oldDate, 'yyyy-MM-dd');
      }
      fromDate = this.datePipe.transform(filterForm.value.from_date, 'yyyy-MM-dd');
      toDate = this.datePipe.transform(filterForm.value.to_date, 'yyyy-MM-dd');
      categoryId = filterForm.value.category_id;
      shipId = filterForm.value.ship_id;
      portId = filterForm.value.port_id;
      companyId = filterForm.value.company_id;
      vendorId = filterForm.value.vendor_id;
    }

    this.categoryService.getCategoryExpenditureReport(companyId, vendorId, shipId, portId, fromDate, toDate, categoryId, download, format, subReport, this.categoryId)
      .subscribe((response: any) => {
        if (this.role === this.userRoles.ROLE_ADMIN && response && response.data && response.data.length > 0) {
          this.currencyCode = response.data[0].currency;
        }
        if (download) {
          const mime = format === 'pdf' ? 'application/pdf' : 'application/vnd.ms-excel';
          this.sharedService.saveAsBlob(
            response,
            mime,
            `expenditures.${format}`
          );
          this.loaderView = false;
          // this.loadingService.setLoading(false);
        } else {
          if (response.data.length === 0) {
            this.noRecordsFound = true;
            this.toastr.error('Data not found', '',
              {
                closeButton: true,
                timeOut: 2000,
                positionClass: 'toast-bottom-left'
              });
          } else {
            if (response.data.some(x => x.count > 0)) {
              this.noRecordsFound = false;
            } else {
              this.noRecordsFound = true;
            }
          }
          let total = 0;
          let maxOrderCategoryId = null;
          this.categoryExpenditureData = [];
          this.categoryTotal = 0;
          this.totalDeliveryCharge = 0;
          this.totalLaunchCharge = 0;
          this.categoryCount = 0;
          response.data.forEach(data => {
            if (total < data.amount) {
              total = data.amount;
              maxOrderCategoryId = data.id;
            }
            this.totalDeliveryCharge += data.delivery_charge;
            this.totalLaunchCharge += data.lounge_charge;
            this.categoryCount += data.count;
            this.categoryExpenditureData.push(
              {
                label: data.name,
                id: data.id,
                value: data.count,
                colour: data.color_code ? data.color_code : '#2e5888',
                amount: (this.role === this.userRoles.ROLE_COMPANY_ADMIN && this.userDetails.company_details.type == 2) ? this.removeMarginAmnt(data) : data.amount,
                delivery_charge: data.delivery_charge,
                launch_charge: data.lounge_charge
              }
            );
            this.categoryTotal = this.categoryExpenditureData.map(categoryAmout => categoryAmout.amount).reduce((initial, pre) => initial + pre, 0)
          });
          // if (maxOrderCategoryId) {
          //   this.getSubCategoryExpenditure(maxOrderCategoryId, filterForm);
          // }
        }
        this.loadingCategoryExpenditure = false;
      }, error => {
        // this.loadingService.setLoading(false);
        this.toastr.error('Data not found', '',
          {
            closeButton: true,
            timeOut: 2000,
            positionClass: 'toast-bottom-left'
          });
        this.loadingCategoryExpenditure = false;
      });
  }

  getCategoryWiseOrder(shipid, filterForm = null) {
    if (this.isDateRangeInvalid()) {
      this.toastr.error('"From date" can not be greater than "To date"', '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      return;
    }

    if (this.loadingCategoryExpenditure) {
      return;
    }
    this.loadingCategoryExpenditure = true;
    let fromDate = null;
    let toDate = null;
    let reportType: any = null;
    let status: any = null;
    if (filterForm != null) {
      fromDate = this.datePipe.transform(filterForm.value.from_date, 'yyyy-MM-dd');
      toDate = this.datePipe.transform(filterForm.value.to_date, 'yyyy-MM-dd');
      reportType = filterForm.value.reportType;
      status = filterForm.value.status;
    }

    this.categoryService.getCategoryWiseOrderReport(shipid, fromDate, toDate, reportType, status, this.categoryId)
      .subscribe((response: any) => {
        if (response.data.length === 0) {
          this.noRecordsFound = true;
          this.toastr.error('Data not found', '',
            {
              closeButton: true,
              timeOut: 2000,
              positionClass: 'toast-bottom-left'
            });
        } else {
          if (response.data.some(x => x.total > 0)) {
            if (status != null) {
              const arr = [];
              response.data.filter(x => {
                const keys = Object.keys(x);
                keys.forEach(key => {
                  if (key == status && x[key] == 0) {
                    arr.push(x);
                  }
                });
              });
              if (arr && arr.length > 7) {
                this.noRecordsFound = true;
              } else {
                this.noRecordsFound = false;
              }
            } else { this.noRecordsFound = false; }
          } else {
            this.noRecordsFound = true;
          }
        }
        // let total = 0;
        let maxOrderCategoryId = null;
        this.categoryExpenditureData = [];
        this.categoryTotal = 0;
        this.categoryCount = 0;

        response.data.forEach(data => {
          maxOrderCategoryId = data.id;
          if (status) {
            this.categoryCount += data[status];
            this.categoryExpenditureData.push(
              {
                label: data.name,
                id: data.id,
                value: data[status],
                colour: data.color_code ? data.color_code : '#2e5888'
              }
            );
          } else {
            this.categoryCount += data.total;
            this.categoryExpenditureData.push(
              {
                label: data.name,
                id: data.id,
                value: data.total,
                colour: data.color_code ? data.color_code : '#2e5888',
              }
            );
          }
        });
        // if (maxOrderCategoryId) {
        //   this.getSubCategoryWiseOrderReport(maxOrderCategoryId, shipid, filterForm);
        // }
        this.loadingCategoryExpenditure = false;
      }, error => {
        this.toastr.error('Data not found', '',
          {
            closeButton: true,
            timeOut: 2000,
            positionClass: 'toast-bottom-left'
          });
        this.loadingCategoryExpenditure = false;
      });
  }

  /*getSubCategoryWiseOrderReport(categoryId, shipid, filterForm = null) {
    if (this.loadingSubCategoryExpenditure) {
      return;
    }
    let colour;
    // if (categoryId == this.categoryId) {
    //   return;
    // }
    let category = this.categoryExpenditureData.find(x => x.id == categoryId);
    if (category) {
      this.category = category.label;
      this.categoryId = category.id;
      colour = category.colour;
      this.colour = category.colour;
    }

    this.loadingSubCategoryExpenditure = true;
    this.subCategoryExpenditureData = [];
    this.subCategoryTotal = 0;
    this.subCategoryCount = 0;

    let fromDate = null;
    let toDate = null;
    let reportType = null;
    let status = null;
    // let shipId = null;
    if (filterForm != null) {
      fromDate = this.datePipe.transform(filterForm.value.from_date, 'yyyy-MM-dd');
      toDate = this.datePipe.transform(filterForm.value.to_date, 'yyyy-MM-dd');
      reportType = filterForm.value.reportType;
      status = filterForm.value.status;
    }

    this.categoryService.getSubCategoryWiseOrderReport(categoryId, shipid, status, fromDate, toDate, reportType)
      .subscribe((data: any) => {
          data.data.forEach(data => {
            if (status) {
              this.subCategoryExpenditureData.push(
                {
                  label: data['name'],
                  sub_category_id: data['id'],
                  value: data[status],
                  colour: colour,
                }
              );
              this.subCategoryCount += data[status];
            } else {
            this.subCategoryExpenditureData.push(
              {
                label: data['name'],
                sub_category_id: data['id'],
                value: data['total'],
                colour: colour
              }
            );
            this.subCategoryCount += data['total'];
            }
          });
          this.loadingSubCategoryExpenditure = false;
      }, error => {
        //this.toastr.error('No Report Found');
      });
  } */

  getReport() {
    this.categoryExpenditureData = [];
    // this.subCategoryExpenditureData = [];
    if (this.role === this.userRoles.ROLE_CAPTAIN) {
      this.getCategoryWiseOrder(this.shipid, this.filterForm);
    } else {
      this.getCategoryExpenditure(0, 'json', 0, this.filterForm);
    }
  }

  changeStatusValues(event) {
    if (event && event.value == 'true') {
      this.filterForm.patchValue({
        status: null
      });
      this.isOrder = true;
      // this.chartTitle = 'Category Wise Order Report';
      this.subChartTitle = 'Subcategory Wise Order Report';
      this.countType = 'Orders';
    } else {
      this.filterForm.patchValue({
        status: null
      });
      this.isOrder = false;
      // this.chartTitle = 'Category Wise Requisition Report';
      this.subChartTitle = 'Subcategory Wise Requisition Report';
      this.countType = 'Requisitions';
    }
    this.getReport();
  }

  isDateRangeInvalid() {
    const fromDate = this.filterForm.value.from_date;
    const toDate = this.filterForm.value.to_date;
    return fromDate && toDate && fromDate > toDate;
  }

  /*changeShip(event) {
    if (event) {
      this.loadingPorts = true;
      this.portService.getByShipId(event.id).subscribe(data => {
        this.portList = data;
        this.loadingPorts = false;
      }, error => {
        this.toastr.error(error.error);
        this.loadingPorts = false;
      });
    }
  }*/

  getPortList() {
    //const page = 1; // this.page
    this.loadingPorts = true;
    this.portService.getPortsForComparision(1, 500, 1).subscribe(res => {
      this.portList = res.results;
      this.loadingPorts = false;
    }, error => {
      this.toastr.error(error.error.message, '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      this.loadingPorts = false;
    });
  }

  changeCompany(company) {
    if (company && company.type === 1) {
      this.filterForm.patchValue({ vendor_id: null, company_id: company.id });
    } else if (company && company.type === 2) {
      this.filterForm.patchValue({ vendor_id: company.id, company_id: null });
    }
  }

  getCompanyType(type) {
    switch (type) {
      case CompanyType.Buyer:
        return CompanyTypeLabel.Buyer;
      case CompanyType.Seller:
        return CompanyTypeLabel.Seller;
    }
  }

  resetFromDate() {
    if (this.filterForm.get('from_date').value) {
      this.filterForm.get('from_date').setValue(null);
    }
  }

  resetToDate() {
    if (this.filterForm.get('to_date').value) {
      this.filterForm.get('to_date').setValue(null);
    }
  }
  removeMarginAmnt(item) {
    return item.amount - item.margin_amount;
  }
}
