import { environment } from '@environment/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CountryService {
  constructor(private http: HttpClient) {}

  getAll(page: number, limit: number) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/country/?page=${page}&limit=${limit}`).pipe(
        map(dataVal => {
          return dataVal.data;
        })
      );
  }

  // addCountry(country) {
  //   return this.http.post<any>(`${environment.apiUrl}/v1/country/`, country);
  // }

  // editCountry(country: Country) {
  //   return this.http.put(`${environment.apiUrl}/v1/country/`, country);
  // }

  // getByid(id: number) {
  //   return this.http
  //     .get<{ data }>(`${environment.apiUrl}/v1/country/${id}`)
  //     .pipe(
  //       map(dataVal => {
  //         return dataVal.data.results;
  //       })
  //     );
  // }

  getCurrencies() {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/country/currency`).pipe(
        map(dataVal => {
          return dataVal.data;
      })
    );
  }
}
