import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'twoDigitNumber'
})
export class TwoDigitNumberPipe implements PipeTransform {
  transform(value: number): number {
    // if (typeof value !== 'number') {
    //   return value;
    // }
    let substr
    if (String(value).includes('.')) {
      let index = String(value).indexOf('.') + 3;
      substr = String(value).substring(0, index);
    } else {
      substr = String(value);
    }
    return substr;
  }
}