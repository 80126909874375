import { DataTableColumn } from '@app/core/models/data-table';

export const ProductColumns: DataTableColumn[] = [
  // {
  //   prop: 'name',
  //   name: 'Product Name',
  //   type: 'string'
  // },

  // {
  //   prop: 'sub_category',
  //   name: 'Subcategory',
  //   type: 'string'
  // },
  // {
  //   prop: 'impa_code',
  //   name: 'IMPA Code',
  //   type: 'string'
  // },
  // {
  //   prop: 'product_code',
  //   name: 'Shipskart Code',
  //   type: 'string'
  // },
  // {
  //   prop: 'unit',
  //   name: 'Unit - Pack Size',
  //   type: 'string'
  // },
  {
    prop: 'product_type',
    name: 'Product Type',
    type: 'string'
  },
  {
    prop: 'status',
    name: 'Status',
    type: 'string'
  }
];
