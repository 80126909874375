import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class DecryptService {
  private key = CryptoJS.enc.Utf8.parse('9442236383SYITXR');
  private iv = CryptoJS.enc.Utf8.parse('2538562729TGIEMW');

  constructor() { }

  decryptUsingAES256(encryptedText: string): string {
    try {
      const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Hex.parse(encryptedText),
      });
      const decrypted = CryptoJS.AES.decrypt(cipherParams, this.key, {
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      return decrypted.toString(CryptoJS.enc.Utf8).trim();
    } catch (e) {
      console.error('Decryption error', e);
      return '';
    }
  }
}
