import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatus, OrderStatusLabel } from '@app/modules/order/model/order-status';
enum Status {
  Active = 1,
  Inactive = 0
}

@Pipe({ name: 'status' })
export class StatusPipe implements PipeTransform {
  orderStatus = OrderStatus;
  orderLabel = OrderStatusLabel;
  transform(value: number, type: string) {
    if (type == 'order') {
      switch (value) {
        case this.orderStatus.APPROVED:
          return this.orderLabel.APPROVED;
        case this.orderStatus.CONFIRM_BY_VENDOR:
          return this.orderLabel.CONFIRM_BY_VENDOR;
        case this.orderStatus.ORDER_DELIVERED:
          return this.orderLabel.ORDER_DELIVERED;
        case this.orderStatus.ORDER_PARTIAL_DELIVERED:
          return this.orderLabel.ORDER_PARTIAL_DELIVERED;
        case this.orderStatus.OUT_FOR_DELIVERY:
          return this.orderLabel.OUT_FOR_DELIVERY;
        case this.orderStatus.REJECTED:
          return this.orderLabel.REJECTED;
        case this.orderStatus.WAITING_FOR_APPROVAL:
          return this.orderLabel.WAITING_FOR_APPROVAL;
      }
    } else {
      switch (value) {
        case Status.Active:
          return `<span class="label label-success">${Status[value]}</span>`;
        case Status.Inactive:
          return `<span class="label label-error">${Status[value]}</span>`;
      }
    }
  }
}
