import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core"
import { Chart } from 'chart.js';


@Component({
  selector: 'app-chart',
  template: `<div style="width: 100%;">
              <canvas id="canvas" #lineChart></canvas>
            </div>`,
})
export class ChartComponent implements OnInit, AfterViewInit {
  @Input() graphData: any[] = [];
  chart: any;
  @ViewChild('lineChart', { static: false }) linechart: ElementRef;
  ngOnInit() { }
  ngAfterViewInit() { this.createChart() }
  createChart() {
    let monthName = [];
    let dataSet = [];
    if (this.graphData && this.graphData.length > 0) {
      monthName = this.graphData[0].labels;
      this.graphData[0].datasets.forEach((x, i) => {
        dataSet.push({
          label: this.graphData[0].datasets[i].label,
          data: this.graphData[0].datasets[i].data,
          borderColor: this.graphData[0].datasets[i].borderColor,
          fill: false,
          lineTension: 0.3,
          options: {
            tooltips: {
              enabled: true,
              mode: 'single',
              callbacks: {
                label: function (tooltipItems, data) {
                  console.log(tooltipItems);

                  return tooltipItems.yLabel + 'INR';
                }
              }
            },
          }
        })
      })
    }
    this.chart = new Chart(this.linechart.nativeElement, {
      type: 'line',
      data: {
        labels: monthName,
        datasets: dataSet
      },
      options: {
        scales: {
          x: {
            display: true
          },
          y: {
            display: true
          },
        }
      }

    })
  }
}