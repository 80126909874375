import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { SharedService } from '../../shared/service/shared.service';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  token;
  role: number;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private sharedService: SharedService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    if (this.authenticationService.getToken()) {
      this.role = this.sharedService.getUserRole();

      if (route.data.roles && route.data.roles.indexOf(this.role) === -1) {
        // role not authorised so redirect to home page
        this.router.navigate(['/login']);
        return false;
      }
      return true;
    }

    // not logged in so redirect to login page with the return url
    if (!this.router.url.includes('RFQs')) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    }
    // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
