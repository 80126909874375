//import { ToastrService } from 'ngx-toastr';
import { Country } from './../../models/country.model';
import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { CountryService } from '../../services/country.service';
//import { FormService } from '@app/shared/helper/form';
import { UserService } from '@app/modules/user/services/user.service';
import { SharedService } from '@app/shared/service/shared.service';
import { HttpHeaders } from '@angular/common/http';
import { UserRoles } from '@app/core/enums/user-roles';
import { CustomValidators } from '@app/shared/validators/custom-validators';

@Component({
  selector: 'app-country',
  templateUrl: './country-list.component.html',
})
export class CountryListComponent implements OnInit {
  countryForm: FormGroup;
  //countryFormData;
  submitted = false;
  countryList: Country[] = [];
  userList: any[] = [];
  // countryList: any[] = [];
  loading = false;
  toggleCountryView = false;
  country: Country;
  isEditMode: boolean;
  userRoles = UserRoles;
  role;
  errMsg;
  page = 1;
  limit = 10;
  total: number;

  constructor(
    private formBuilder: FormBuilder,
    private countryService: CountryService,
    /* private alertService: ToastrService,
    private formservice: FormService, */
    private userservice: UserService,
    private sharedservice: SharedService
  ) { }

  ngOnInit() {
    this.countryForm = this.formBuilder.group({
      id: [''],
      name: ['', [Validators.required]],
      legal_name: ['', [Validators.required]],
      head_office: ['', [Validators.required]],
      country_id: ['', [Validators.required]],
      city_id: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, CustomValidators.emailValidator]],
      image: [''],
      logo: [''],
      currency_code: ['', [Validators.required]],
      type: ['', [Validators.required]],
      represent_id: ['', [Validators.required]]
    });
    this.role = this.sharedservice.getUserRole();
    // load country listing
    this.countryListedData();
    this.FormEssentialData();
  }

  get form() {
    return this.countryForm.controls;
  }

  async addCountry() {
    /*  this.submitted = true;
      if (!this.countryForm.valid) {
        return;
      } else {
        await this.formservice
          .convertToFormData(this.countryForm.value)
          .then(formData => {
            this.countryService
              .addCountry(formData)
              .pipe(first())
              .subscribe(
                countryData => {
                  this.alertService.success('Successfully added');
                  // this.countryList.push(this.countryForm.value);
                },
                error => {
                  const keys = Object.keys(error.error);
                  // console.log(keys);
                  keys.forEach(key => {
                    this.alertService.error(error.error[key]);
                  });
                }
              );
          })
          .catch(err => {
            this.alertService.error('Somthing Wents Wrong!');
          });
      } */
  }

  edit(countryData) {
    this.isEditMode = true;
    setTimeout(() => {
      this.countryForm.patchValue(countryData);
    });
    this.toggleCountryView = !this.toggleCountryView;
  }

  editCountry() {
    /*  this.submitted = true;
  
      if (!this.countryForm.valid) {
        return;
      } else {
        this.countryService
          .editCountry(this.countryForm.value)
  
          .pipe(first())
          .subscribe(
            countryData => {
              this.alertService.success('Successfully updated');
              this.countryListedData();
            },
            error => {
              const keys = Object.keys(error);
              keys.forEach(key => {
                this.alertService.error(error[key][0]);
              });
            }
          );
      } */
  }

  private countryListedData() {
    this.loading = true;

    this.countryService.getAll(this.page, this.limit).subscribe(data => {
      this.countryList = data.results;
      this.total = data.total;
      this.loading = false;
    });
  }

  private FormEssentialData() {
    const companyId = this.sharedservice.getCompany().id;
    let filter;
    if (this.role !== this.userRoles.ROLE_ADMIN) {
      filter = `{"fname":null,"phone":"","email":"","company_id":[${companyId}],"verified":null}`;
    } else {
      filter = `{"fname":null,"phone":"","email":"","company_id":null,"verified":null}`;
    }
    const headers = new HttpHeaders().set(
      'x-filter',
      filter
    );
    this.userservice.getAll(1, 500, headers, '', 1, 1).subscribe(data => {
      this.userList = data.results;
    });
    // get Country
    this.sharedservice.getCountry().subscribe(data => {
      this.countryList = data.results;
    });
  }

  onPageChange($event) {
    this.page = $event;
    this.countryListedData();
  }

  formReset() {
    this.countryForm.reset();
  }
}
