<fieldset class="rating" [class.read-only]="readOnly">
    <input type="radio" #star_1 (click)="getRatings(star_1.value)" [id]="star1" [attr.name]="'rating'+ id" value="5" /><label class="full" [attr.for]="star1" title="Awesome - 5 stars"></label>
    <input type="radio" #star_2 (click)="getRatings(star_2.value)" [id]="star2" [attr.name]="'rating'+ id" value="4.5" /><label class="half" [attr.for]="star2" title="Pretty good - 4.5 stars"></label>
    <input type="radio" #star_3 (click)="getRatings(star_3.value)" [id]="star3" [attr.name]="'rating'+ id" value="4" /><label class="full" [attr.for]="star3" title="Pretty good - 4 stars"></label>
    <input type="radio" #star_4 (click)="getRatings(star_4.value)" [id]="star4" [attr.name]="'rating'+ id" value="3.5" /><label class="half" [attr.for]="star4" title="Fairly good - 3.5 stars"></label>
    <input type="radio" #star_5 (click)="getRatings(star_5.value)" [id]="star5" [attr.name]="'rating'+ id" value="3" /><label class="full" [attr.for]="star5" title="Fairly good - 3 stars"></label>
    <input type="radio" #star_6 (click)="getRatings(star_6.value)" [id]="star6" [attr.name]="'rating'+ id" value="2.5" /><label class="half" [attr.for]="star6" title="Kinda bad - 2.5 stars"></label>
    <input type="radio" #star_7 (click)="getRatings(star_7.value)" [id]="star7" [attr.name]="'rating'+ id" value="2" /><label class="full" [attr.for]="star7" title="Kinda bad - 2 stars"></label>
    <input type="radio" #star_8 (click)="getRatings(star_8.value)" [id]="star8" [attr.name]="'rating'+ id" value="1.5" /><label class="half" [attr.for]="star8" title="Poorly bad - 1.5 stars"></label>
    <input type="radio" #star_9 (click)="getRatings(star_9.value)" [id]="star9" [attr.name]="'rating'+ id" value="1" /><label class="full" [attr.for]="star9" title="Sucks big time - 1 star"></label>
    <input type="radio" #star_10 (click)="getRatings(star_10.value)" [id]="star10" [attr.name]="'rating'+ id" value="0.5" /><label class="half" [attr.for]="star10" title="Sucks big time - 0.5 stars"></label>
</fieldset>