<ng-template #panelModal>
  <div class="fluid-container">
    <div class="panel-header">
      <h4><span>{{edit?'Edit':'Customise your'}}</span> Dashboard</h4>
      <button type="button"
        aria-label="Close"
        class="close"
        aria-label="Close"
        (click)="modalRef.hide();resetForm()">
        <span aria-hidden="true">&#215;</span>
      </button>
    </div>
    <div class="panel-body">
      <form [formGroup]="dataConfigurationForm"
        (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-md-12 bmargin10"
            *ngIf="!edit">
            <label class="form-label bmargin5 custom-font">Select View<span class="required">*</span></label>
            <ng-select [items]="dataCard"
              bindLabel="id"
              bindValue="id"
              (change)="viewPanelBody($event)"
              formControlName="other_report"
              [multiple]="false"
              [loading]="loadingData"
              placeholder="Select View"
              class="form-control"
              [clearable]="false">
            </ng-select>
          </div>
          <div class="col-md-12 bmargin10"
            *ngIf="isReportOrViewSelected && !isDataCardSelected && !edit">
            <label class="form-label bmargin5 custom-font">Select Report<span class="required">*</span></label>
            <ng-select
              formControlName="report_type"
              (change)="viewPanelBody($event);"
              [multiple]="false"
              placeholder="Select Report"
              class="form-control"              
              [loading]="loadingData"
              [clearable]="false"
              [disabled]="edit">
              <ng-option *ngFor="let item of viewData"
                [value]="item"><span><b>{{item?.report_name}}</b> </span> <br>
                <em>Report: </em> <b> {{convertFormat(item?.report_type)||''}}</b>
              </ng-option>
            </ng-select>
          </div>
          <div class="col-md-12 bmargin10"
            *ngIf=" (isReportOrViewSelected && isDataCardSelected) ||  (isDataCardSelected && edit)">
            <label class="form-label bmargin5 custom-font">Choose Data<span class="required">*</span></label>
            <ng-select [items]="secondayAxisDataCard"
              formControlName="other_axis_ids"
              bindValue="id"
              bindLabel="name"              
              (change)="selectDataCard($event)"
              [multiple]="isDataCardSelected?false:true"
              placeholder="Choose Data"
              [loading]="loadingData"
              class="form-control"
              [closeOnSelect]="true"
              [clearable]="false">
            </ng-select>
          </div>
          <div class="col-md-12 bmargin10">
            <label class="form-label bmargin5 custom-font"
              for="panel-name">Enter Report Name<span class="required">*</span></label>
            <input type="text"
              formControlName="title"
              class="form-control"
              id="panel-name"
              placeholder="Enter Panel Name">
          </div>
          <div class="col-md-12 bmargin10"
            *ngIf="(isReportOrViewSelected && !isDataCardSelected) ||(!isDataCardSelected && edit) ">
            <label class="form-label bmargin5 custom-font">Display Data By<span class="required">*</span></label>
            <ng-select [items]="primaryAxies"
              formControlName="primary_axis_id"
              (change)="changePrimaryAxis($event)"
              bindLabel="name"
              bindValue="id"
              [multiple]="false"
              placeholder="Select Display Data"
              [loading]="loadingData"
              class="form-control"
              [clearable]="false">
            </ng-select>
          </div>
          <div class="col-md-12 bmargin10"
            *ngIf="(isReportOrViewSelected && !isDataCardSelected  )|| (!isDataCardSelected && edit)">
            <label class="form-label bmargin5 custom-font">Choose Data<span class="required">*</span></label>
            <ng-select [items]="secondayAxis"
              formControlName="other_axis_ids"
              bindValue="id"
              bindLabel="name"
              [multiple]="isDataCardSelected?false:true"
              placeholder="Choose Data"
              class="form-control"
              [loading]="loadingData"
              [closeOnSelect]="false"
              [clearable]="false">
            </ng-select>
          </div>
          <div class="col-md-12 bmargin10"
            *ngIf="(isReportOrViewSelected && isDataCardSelected) || (isDataCardSelected && edit)">
            <label class="form-label bmargin5 custom-font">Select Filters</label>
            <ng-select [items]="filtersDataCard"
              bindLabel="name"
              class="custom-text-transform"
              bindValue="id"
              formControlName="filter_ids"
              [multiple]="true"
              placeholder="Select Filters"
              class="form-control"
              [loading]="loadingData"
              [closeOnSelect]="false"
              [clearable]="false">
            </ng-select>
          </div>
          <div class="col-md-12 bmargin10"
            *ngIf="(isReportOrViewSelected && !isDataCardSelected  )|| (!isDataCardSelected && edit)">
            <label class="form-label bmargin5 custom-font">Select Filters</label>
            <ng-select [items]="filters"
              bindLabel="name"
              class="custom-text-transform"
              bindValue="id"
              formControlName="filter_ids"
              [multiple]="true"
              placeholder="Select Filters"
              class="form-control"
              [loading]="loadingData"
              [closeOnSelect]="false"
              [clearable]="false">
            </ng-select>
          </div>
          <div class="col-md-12 text-right bmargin10">
            <button class="btn btn-primary"
              type="submit"
              (click)="modalRef.hide()"
              [disabled]="!(dataConfigurationForm.valid)">Save Panel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>