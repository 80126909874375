import { Component, OnInit, Input, ViewChild } from '@angular/core';
//import {UserService} from '@app/modules/user/services/user.service';
import { SharedService } from '@shared/service/shared.service';
//import {Router} from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { Company } from '@app/modules/company/models/company.modal';
import { RfqService } from '@app/modules/rfq/services/rfq.service';
//import { LoadingService } from '@app/authentication/services/loading.service';
import { OrderRatingComponent } from '@app/shared/components/order-rating/order-rating.component';
import { Utils } from '@app/shared/service/util';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-vendor',
  templateUrl: './dashboard-vendor.component.html',
  styleUrls: ['./dashboard-vendor.component.css'],
  providers: [Utils]
})
export class DashboardVendorComponent implements OnInit {
  //@Input() dashboard;
  constructor(
    // private userService: UserService,
    private rfqService: RfqService,
    private sharedService: SharedService,
    //private router: Router,
    //private loadingService: LoadingService
    public util: Utils,
    private router: Router
  ) { }

  //loading = false;
  loaderView1 = false;
  //orderReportData = [];
  //rfqCount = 0;
  //rating = 0;
  //deliveryStrength = 0;
  //orderReport = false;
  company: Company;
  vendorId: number;
  expiredProducts = [];
  goingToExpireProduct = 0;
  @ViewChild('orderRating', { static: false }) orderRating: OrderRatingComponent;
  @Input() shipList = [];
  @Input() portList = [];
  @Input() categoryList = [];
  ngOnInit() {
    this.company = this.sharedService.getCompany();
    this.vendorId = this.company.id;
    //this.getDashboard();
    this.util.getDashboard();
    this.getExpiredProducts();
  }

  getExpiredProducts() {
    let params = new HttpParams();
    if (this.vendorId) {
      params = params.append('vendor_id', `${this.vendorId}`);
    }
    this.loaderView1 = true;
    this.rfqService.getRfqExpiredProducts(params).subscribe(data => {
      this.expiredProducts = data;
      this.goingToExpireProduct = 0;
      this.expiredProducts.forEach(element => {
        if (element.sub_categories && element.sub_categories.length > 0) {
          element.sub_categories.forEach(item => {
            if (item && item.inr.expired || item.inr.going_to_expired || item.usd.expired || item.usd.going_to_expired) {
              this.goingToExpireProduct += (item.inr.expired + item.inr.going_to_expired + item.usd.expired + item.usd.going_to_expired);
            }
          });
        }
      });
      this.loaderView1 = false;
    }, error => {
      this.loaderView1 = false;
    });
  }

  openRatingModal(vendorId) {
    this.orderRating.openModal(vendorId);
  }
  navigate() {
    if (this.vendorId)
      this.router.navigate(['/RFQs/shipskart-rfq'], { queryParams: { vendor: this.vendorId } });
  }
}
