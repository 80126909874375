import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
  // public lottieConfig: Object;
  // hide:boolean = true;
  constructor() {
    // this.lottieConfig = {
    //   path: 'assets/images/loader.json',
    //   renderer: 'svg',
    //   autoplay: true,
    //   loop: true
    // };
  }

  ngOnInit() {
    // setTimeout(() => {
    //   this.hide=false;
    // }, 10000);
  }

  // onImgError(event) {
  //   event.target.src = '/assets/images/loader-cat.PNG';
  // }
}
