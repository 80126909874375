<div class="table-card">
  <div class="bmargin10">
    <h3 class="report-title bmargin10">{{ data?.report_title | titlecase }}</h3>
    <div class="filter-align">
      <div *ngIf="filterLength">Filters:</div>
      <div>
        <span class="spinner-border mt-1 mr-1 spinner-border-sm"
          *ngIf="isClear"
          role="status"
          aria-hidden="true"></span>      
        <span class="sr-only">Loading...</span>
      </div>
      
      <div *ngFor="let filter of data?.filters"
        class="mb-1"
        [ngStyle]="{'display':filterLength?'inline-block':'none'}">
        <span class="filter-selected ml-1 mb-2"
          *ngIf="filter.filter_value">
          <span class="cross-icon">
            <a class="cross-button" aria-hidden="true" (click)="removeFilter(filter)">&#215;</a>
          </span>          
          {{ filter?.filter?.name }} : {{ filter?.filter_value_in_str || filter?.filter_value }}
        </span>
      </div>
    </div>
  </div>

  <div class="pull-right">

    <span class="spinner-border mt-1 mr-1 spinner-border-sm"
      *ngIf="isDownLoading"
      role="status"
      aria-hidden="true"></span>

    <span class="sr-only">Loading...</span>
    <a class="more-btn more-link"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      href="javascript:void(0)">

      <span class="material-symbols-outlined">
        menu
      </span>
    </a>
    <div class="dropdown-menu float-right"
      aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item"
        href="javascript:void(0)"
        (click)="openFilterModal()"
        *ngIf="data?.filters?.length>0"><i class="fa fa-filter"></i> Filter</a>
      <a class="dropdown-item"
        href="javascript:void(0)"
        *ngIf="data?.user_id"
        (click)="openPanelModal(true)"><i class="fa fa-edit"></i> Edit</a>
      <a class="dropdown-item"
        href="javascript:void(0)"
        (click)="downloadReport()"><i class="fa fa-download"></i>
        Download</a>
      <a class="dropdown-item"
        *ngIf="data?.user_id"
        href="javascript:void(0)"
        (click)="panelDeleteModal(deletePanelModal)"><i class="fa fa-trash"></i> Delete</a>
    </div>
  </div>
</div>
<div class="h-scroll">
  <table class="table table-bordered table-modal-body">
    <thead>
      <tr>
        <th *ngFor="let header of data?.column_keys;let i=index">
          <div
            *ngIf="(data?.axes[i]?.axis_id==33 ||data.axes[i]?.axis_id==11) && (role== userRoles.ROLE_ADMIN||role== userRoles.ROLE_SUBADMIN);else noExpenditure">
            <span *ngIf="data?.axes[i]?.axis_id==33 && (role== userRoles.ROLE_ADMIN||role== userRoles.ROLE_SUBADMIN)"
              class="overflow_data font600"
              [title]="header">{{
              header
              + '(Shipping Company)'
              }} </span>
            <span *ngIf="data?.axes[i]?.axis_id==11 &&(role== userRoles.ROLE_ADMIN||role== userRoles.ROLE_SUBADMIN)"
              class="overflow_data font600"
              [title]="header">{{
              header
              + '(Vendor Company)'
              }} </span>
          </div>
          <ng-template #noExpenditure>
            <span class="overflow_data font600"
              [title]="header">{{ header }}</span>
          </ng-template>
        </th>
        <!-- <th *ngIf='tempTableData.option_id==13'></th> -->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let product of data?.column_values | slice:0:5; let j = index"
        class="tr-hover">
        <td *ngFor="let prod of product; let i = index"
          [ngStyle]="{ 'text-align': getAlignment(product[i]) }">
          <span [ngClass]="{'mouse-hover': data?.navigation_type[j]?true:false}"
            class="overflow_data"
            [title]="i==0?prod:''"
            (click)="fetchData(j)">
            <span *ngIf="(i === 0 && data?.column_keys[0] !='Port'); else notFirst" [ngClass]="{'user-select': i === 0 && ['Products', 'Partially Delivered Products', 'Product Consumption Analysis - Products'].includes(data?.column_keys[0])}">
              {{ prod?.toLocaleString() | titlecase }}
            </span>
            <ng-template #notFirst>              
              {{ (prod ? prod?.toLocaleString() : prod) | twoDigitNumber }}
            </ng-template>
          </span>
        </td>
        <!-- <td *ngIf ='tempTableData.option_id==13'><a href="javascript:void(0)">View Details</a></td> -->
      </tr>
      <tr *ngIf="data?.column_values?.length <=5">
        <ng-container *ngFor="let value of tempTableData?.column_values[0]; let i = index">
          <ng-container *ngIf="!isNumber(value) && (i === 0 || isNumber(tempTableData?.column_values[0][i-1]))">
            <td [attr.colspan]="getCombinedStringsAndColspan(i, tempTableData?.column_values[0]).colspan" class="text-center">
              <span><strong> Total</strong></span>
            </td>
          </ng-container>
          <ng-container *ngIf="isNumber(value)">
            <td class="text-center">
              <span><strong> {{ getNumberValue(i) > 0 ? (getNumberValue(i) | number:'1.2-2') : 0 }}</strong></span>
            </td>
          </ng-container>
        </ng-container>
      </tr>   
      <tr *ngIf="data?.column_values.length==0">
        <td [colSpan]="data.column_keys.length">
          <h1 class="no-data-header text-center">No Data Available</h1>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- </div> -->
  <div class="text-right"
    [ngStyle]="{'visibility': data?.column_values?.length>5 ? 'visible' : 'hidden'}">
    <button type="button"
      class="btn btn-link btn-hover"
      (click)="openModel()">View All</button>
  </div>

  <ng-template #tableData>
    <div class="fluid-container bmargin10">
      <div class="table-modal-header">
        <h4>{{ tempTableData?.report_title }}</h4>
        <button type="button"
          aria-label="Close"
          class="close"
          aria-label="Close"
          (click)="modal.hide();onPageChange('other')">
          <span aria-hidden="true">&#215;</span>
        </button>
      </div>
      <div class="table-modal-body bmargin10">
        <span *ngIf="filterLength">Filters:</span>
        <div *ngFor="let filter of tempTableData?.filters"
          class="bmargin10"
          [ngStyle]="{'display':filterLength?'inline-block':'none'}">
          <span class="filter-selected  ml-1"
            *ngIf="filter.filter_value">
            {{ filter?.filter?.name }}: {{ filter?.filter_value_in_str || filter?.filter_value }}
          </span>
        </div>
        <div class="bmargin10 main-table-container">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th *ngFor="let header of tempTableData?.column_keys;let i=index">
                  <div
                    *ngIf="(tempTableData?.axes[i]?.axis_id==33 ||tempTableData?.axes[i]?.axis_id==11) && (role== userRoles.ROLE_ADMIN||role== userRoles.ROLE_SUBADMIN);else noExpenditure">
                    <span class="font600"
                      *ngIf="tempTableData?.axes[i]?.axis_id==33 && (role== userRoles.ROLE_ADMIN||role== userRoles.ROLE_SUBADMIN)">{{
                      header
                      + ' (Shipping Company)'
                      }} </span>
                    <span class="font600"
                      *ngIf="tempTableData?.axes[i]?.axis_id==11 &&(role== userRoles.ROLE_ADMIN||role== userRoles.ROLE_SUBADMIN)">{{
                      header
                      + ' (Vendor Company)'
                      }} </span>
                  </div>
                  <ng-template #noExpenditure>
                    <span class="font600">{{ header }}</span>
                  </ng-template>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let product of tempTableData?.column_values;let j=index"
                class="tr-hover">
                <td *ngFor="let prod of product let i = index"
                  [ngStyle]="{ 'text-align': getAlignment(product[i]) }"
                  (click)="fetchData(j);modal.hide()">
                  <span class="overflow_data" [ngClass]="{'user-select':i === 0 && tempTableData?.column_keys[0] ==('Products'||'Partially Delivered Products' || 'Product Consumption Analysis - Products')}">{{ (prod ? prod?.toLocaleString() : prod) | twoDigitNumber }}</span>
                </td>
                <!-- <td *ngIf ='tempTableData.option_id==13'><a href="javascript:void(0)">View Details</a></td> -->
              </tr>
              <tr>
                <ng-container *ngFor="let value of tempTableData?.column_values[0]; let i = index">
                  <ng-container *ngIf="!isNumber(value) && (i === 0 || isNumber(tempTableData?.column_values[0][i-1]))">
                    <td [attr.colspan]="getCombinedStringsAndColspan(i, tempTableData?.column_values[0])?.colspan" class="text-center">
                      <span><strong> Total</strong></span>
                    </td>
                  </ng-container>
                  <ng-container *ngIf="isNumber(value)">
                    <td class="text-center">
                      <span><strong> {{ getNumberValue(i) > 0 ? (getNumberValue(i) | number:'1.2-2') : 0 }}</strong></span>
                    </td>
                  </ng-container>
                </ng-container>
              </tr>                          
            </tbody>
          </table>
        </div>
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item d-flex align-items-center mr-2"
              *ngIf="!disablePagination">
              <span class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              <span class="sr-only">Loading...</span>
            </li>
            <li class="page-item">
              <button class="btn btn-outline-dark"
                aria-label="Previous"
                (click)="onPageChange('prev')"
                [disabled]="!(tempTableData?.has_prev && disablePagination)">
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
              </button>
            </li>
            &nbsp;
            <li class="page-item">
              <button class="btn btn-outline-dark"
                href="javascript.void(0)">
                {{ tempTableData.page }}
              </button>
            </li>
            &nbsp;
            <li class="page-item">
              <button class="btn btn-outline-dark"
                aria-label="Next"
                (click)="onPageChange('next')"
                [disabled]="!(tempTableData?.has_next && disablePagination)">
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </ng-template>

  <app-filter [data]="data?.filters"
    [otherAxis]="otherAxis"
    [sharedData]="sharedData"
    #filterData
    (filterApplied)="filterAppied($event)"></app-filter>
  <app-panel [edit]="editPanel"
    [sharedData]="sharedData"
    #panelData
    (callParentMethodEvent)="reloadPage($event)"></app-panel>

  <ng-template #deletePanelModal>
    <div class="modal-body cancelmodal-spc text-center ">
      <h4 class="sure-font">Are you sure to Delete?</h4>
      <div class="actionbuttons tmargin30">
        <button class="btn btn-outline-default cancel-btn"
          (click)="deleteModal.hide()">No
        </button>
        <button class="btn btn-primary"
          (click)="deletePanel()">
          <span class="spinner-border mt-1 mr-1 spinner-border-sm"
            *ngIf="loadingDeletePanel"
            role="status"
            aria-hidden="true"></span>

          <span class="sr-only">Loading...</span> Yes
        </button>
      </div>
    </div>
  </ng-template>
</div>