import { DropdownDirective } from './../dropdown.directive';
import { StatusPipe } from './pipes/status.pipe';
import { CompanyPipe } from './pipes/company.pipe';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgSelectModule } from '@ng-select/ng-select';
import { KeyFilterModule } from 'primeng/keyfilter';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { PriorityPipe } from './pipes/priority.pipe';
import { AlertComponent } from './alert/alert.component';
import { LoadingComponent } from './components/loading/loading.component';
import { RingLoaderComponent } from './components/ring-loader/ring-loader.component';
import { ProductPopupComponent } from './components/product-popup/product-popup.component';
import { RolePipe } from './pipes/role.pipe';
import { ChartModule } from 'angular-highcharts';
import { FooterComponent } from './footer/footer.component';
import { PagingComponent } from './components/paging/paging.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { LottieAnimationViewModule } from 'ng-lottie';
import { OrderRatingComponent } from './components/order-rating/order-rating.component';
import { TabularSearchComponent } from './components/tabular-search/tabular-search.component';
import { VendorKycFormComponent } from './components/vendor-kyc-form/vendor-kyc-form.component';
import { CompanyDetailsComponent } from './components/vendor-kyc-form/company-details/company-details.component';
import { FinancialInformationComponent } from './components/vendor-kyc-form/financial-information/financial-information.component';
import { TechnicalCapabilityComponent } from './components/vendor-kyc-form/technical-capability/technical-capability.component';
import { CalendarModule } from 'primeng/calendar';
import { LineChartComponent } from '../shared/components/linechart/line-chart.component';
import { ChartComponent } from '../shared/components/chart/chart.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputValidation } from './components/input-validation/input-validation.component';
import { TwoDigitNumberPipe } from './pipes/number.pipe';
import { CapitalizePipe } from '@app/modules/po/capitalize.pipe';
import { PieChartComponent } from './new-dashboard-config/piechart/piechart.component';
import { BarChartComponent } from './new-dashboard-config/barchart/barchart.component';
import { CardComponent } from './new-dashboard-config/card/card.component';
import { LineChartsComponent } from './new-dashboard-config/linechart/linechart.component';
import { TableComponent } from './new-dashboard-config/table/table.component';
import { FilterComponent } from './new-dashboard-config/filter/filter.component';
import { PanelComponent } from './new-dashboard-config/panel/panel.component';
import { GeneralFilterComponent } from './new-dashboard-config/general-filter/general-filter.component';
import { CreateFormComponent } from './new-dashboard-config/create-form/create-form.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FeedbackFormComponent } from './new-dashboard-config/feedbackform/feedbackform.component';
import { FeedbackFormListingComponent } from './new-dashboard-config/feedback-form-listing/feedback-form-listing.component';
import { CalendarUiCorrectionDirective } from '@shared/directive/calendar-ui-correction.directive';
import { utcToLocal } from './pipes/utc.pipe';
//import { DisableRightClickDirective } from '@shared/service/disable-right-click.directive';
import { OtpComponent } from './components/otp/otp.component';

const SHARED_COMPONENTS = [
  AlertComponent,
  LoadingComponent,
  RingLoaderComponent,
  LineChartComponent,
  ChartComponent,
  InputValidation,
  PieChartComponent,
  BarChartComponent,
  CardComponent,
  LineChartsComponent,
  TableComponent,
  FilterComponent,
  PanelComponent,
  GeneralFilterComponent,
  FeedbackFormComponent,
  StarRatingComponent,
  FeedbackFormListingComponent,
];

@NgModule({
  declarations: [
    utcToLocal,
    PriorityPipe,
    TwoDigitNumberPipe,
    CompanyPipe,
    StatusPipe,
    RolePipe,
    CapitalizePipe,
    SHARED_COMPONENTS,
    DropdownDirective,
    ProductPopupComponent,
    OrderRatingComponent,
    FooterComponent,
    PagingComponent,
    StarRatingComponent,
    OrderRatingComponent,
    TabularSearchComponent,
    VendorKycFormComponent,
    CompanyDetailsComponent,
    FinancialInformationComponent,
    TechnicalCapabilityComponent,
    GeneralFilterComponent,
    CreateFormComponent,
    // DisableRightClickDirective,
    OtpComponent
  ],
  imports: [
    AutoCompleteModule,
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgSelectModule,
    KeyFilterModule,
    ChartModule,
    NgxDatatableModule,
    DragDropModule,
    LottieAnimationViewModule.forRoot(),
    CalendarModule,
    CalendarUiCorrectionDirective
  ],
  exports: [
    AutoCompleteModule,
    SHARED_COMPONENTS,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule,
    BsDatepickerModule,
    PriorityPipe,
    CompanyPipe,
    StatusPipe,
    CapitalizePipe,
    NgSelectModule,
    DropdownDirective,
    ProductPopupComponent,
    OrderRatingComponent,
    KeyFilterModule,
    RolePipe,
    ChartModule,
    FooterComponent,
    PagingComponent,
    StarRatingComponent,
    LottieAnimationViewModule,
    CalendarModule,
    TwoDigitNumberPipe,
    VendorKycFormComponent,
    utcToLocal
  ],
  providers: [DatePipe, DecimalPipe, TitleCasePipe, TwoDigitNumberPipe]
})
export class SharedModule { }
