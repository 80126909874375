import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewChildren } from "@angular/core";
import { NewDashboard } from "@app/dashboard/models/newdashboard.model";
import { Chart } from 'chart.js';
import { PanelComponent } from "../panel/panel.component";
import { FilterComponent } from "../filter/filter.component";
import { SharedService } from "@app/shared/service/shared.service";
import { AlertService } from "@app/shared/service/alert.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { DashboardService } from "@app/dashboard/dashboard.service";
@Component({
  selector: 'app-line-chart',
  templateUrl: './linechart.component.html',
  styleUrls: ['./linechart.component.css']
})

export class LineChartsComponent implements OnInit {

  @Input() data: NewDashboard;
  @ViewChildren('lineCharts') lineChart: any;
  @ViewChild('panelData', { static: false }) panelData: PanelComponent;
  @ViewChild('filterData', { static: false }) filterData: FilterComponent;
  chart: any;
  editPanel: boolean;
  sharedData: NewDashboard;
  // modalRef: BsModalRef;
  filterLength: boolean;
  otherAxis;
  deleteModal: BsModalRef;
  @Output() callParentMethodEvent = new EventEmitter<string>();
  copyGraphData: NewDashboard;
  isDownloading: boolean;
  loadingDeletePanel: boolean;
  constructor(private sharedService: SharedService, private altService: AlertService,
    private modalService: BsModalService, private toastr: ToastrService,
    private dashboardService: DashboardService,
    private router: Router) { }
  ngOnInit(): void {
    this.otherAxis = this.data;
    this.checkFilter();
  }
  reloadPage(event) {
    setTimeout(() => { this.callParentMethodEvent.emit(event) }, 200);
  }
  checkFilter() {
    this.filterLength = this.data.filters.some(response => {
      return response.filter_value ? true : false;
    })
  }
  async downloadReport() {
    this.isDownloading = true;
    try {
      const res = await this.dashboardService.getReportData(this.data.report_id, null, 1);
      this.sharedService.saveAsBlob(
        res,
        'application/vnd.ms-excel',
        `PO_${this.data.report_title}.xlsx`
      );
      this.isDownloading = false;
      this.toastr.success('Downloaded Successfully.');
    } catch (error) {
      this.isDownloading = false;
      this.toastr.error('Failed to download report.');
    }
  }

  ngAfterViewInit() {
    this.copyGraphData = null;
    this.copyGraphData = JSON.parse(JSON.stringify(this.data));
    // let ctx: any = document.getElementById('barChart') as HTMLElement;
    if (this.copyGraphData.report_type == 'line_graph_group') {
      let count = this.copyGraphData.graph_data[0].data.length;
      for (let i = 0; i < count; i++) {
        for (let j = 0; j < this.copyGraphData.graph_data.length; j++) {
          this.copyGraphData.graph_data[i].data[j] = this.data.graph_data[j].data[i].toFixed(2);
        }
        this.copyGraphData.graph_data[i].backgroundColor = new Array(this.data.graph_data.length).fill(this.data.graph_data[i].backgroundColor[i])
        this.copyGraphData.graph_data[i].label = this.data.column_keys[i + 1];
        this.copyGraphData.graph_data[i].borderWidth = 2;
        this.copyGraphData.graph_data[i].borderColor = this.copyGraphData.graph_data[i].backgroundColor[0]
        this.copyGraphData.graph_data[i].lineTension = 0.0;
      }
      this.copyGraphData.graph_data.splice(count,)
    }
    var data = {
      labels: this.data.labels,
      datasets: this.copyGraphData.graph_data,
      navigation: this.data.navigation_type
    };
    let route = this.router;
    let canvasCharts = [];
    canvasCharts = this.lineChart._results;
    canvasCharts.map((myCanvas, i) => {
      this.chart = new Chart(myCanvas.nativeElement.getContext('2d'),
        {
          type: 'line',
          data: data,
          options: {
            onHover: (event, chartElement) => {
              const target = event.native?.target as HTMLElement;
              if (target) {
                target.style.cursor = chartElement[0] ? 'pointer' : 'default';
              }
            },
            onClick: function (event, elements) {
              if (elements.length) {
                if (this.data.report_type != 'line_graph_group') {
                  const clickedElement = elements[0];
                  const datasetIndex = clickedElement.index;
                  if (data.navigation[datasetIndex]) {
                    if (data.navigation[datasetIndex].split("_").includes('order')) {
                      switch (data.navigation[datasetIndex]) {
                        case "order_list":
                          route.navigateByUrl('/orders')
                          break;
                        case "order_placed":
                          route.navigateByUrl('/orders?page=1&filter=%257B%2522order_status%2522%253A%255B1%255D%257D')
                          break;
                        case "order_out_for_delivery":
                          route.navigateByUrl('/orders?page=1&filter=%257B%2522order_status%2522%253A%255B3%255D%257D')
                          break;
                        case "order_delivered":
                          route.navigateByUrl('/orders?page=1&filter=%257B%2522order_status%2522%253A%255B4%255D%257D')
                          break;
                        case "order_cancelled":
                          route.navigateByUrl('/orders?page=1&filter=%257B%2522order_status%2522%253A%255B6%255D%257D')
                          break;
                        case "order_partially_delivered":
                          route.navigateByUrl('/orders?page=1&filter=%257B%2522order_status%2522%253A%255B5%255D%257D')
                          break;
                      }
                    }
                    else if (data.navigation[datasetIndex].split("_").includes('requisition')) {
                      switch (data.navigation[datasetIndex]) {
                        case "requisition_list":
                          route.navigateByUrl('/requisitions')
                          break;
                        case "requisition_list_waiting_for_approval":
                          route.navigateByUrl('/requisitions?req_status=0&page=1')
                          break;
                        case "requisition_list_sent_for_approval":
                          route.navigateByUrl('/requisitions?req_status=1&page=1')
                          break;
                        case "requisition_list_rejected_by_manager":
                          route.navigateByUrl('/requisitions?req_status=2&page=1')
                          break;
                        case "requisition_list_rejected_by_po":
                          route.navigateByUrl('/requisitions?req_status=3&page=1')
                          break;
                        case "requisition_list_urgent":
                          route.navigateByUrl('/requisitions?priority_type=Urgent&page=1')
                          break;
                      }
                    }
                  }
                }
              }

              // else {
              //   this.toastr.error('Route Not Available!')
              // }
            }
            ,
            plugins: {
              legend: {
                display: true,
                position: 'bottom',

              }
            },
            scales: {
              x: {
                display: true,
                title: {
                  display: this.data.reportType !== 'bar_graph_group',
                  text: `${this.data.column_keys[0]}`,
                  font: {
                    weight: 'bold',
                    size: 16
                  }
                },
                ticks: {
                  maxRotation: 20,
                  autoSkip: false,
                  maxTicksLimit: 10,
                  font: {
                    size: 14
                  },
                  callback: (value) => {
                    // Add ellipsis to long labels
                    if (typeof this.data.labels[value] === 'string') {
                      const maxWidth = 70; // Adjust as needed
                      let label = this.data.labels[value].trim();
                      while (myCanvas.nativeElement.getContext('2d').measureText(label + '...').width > maxWidth) {
                        label = label.slice(0, -1);
                      }
                      return label + '...';
                    } else {
                      return value;
                    }
                  }
                }
              },
              y: {
                title: {
                  display: this.data.reportType !== 'bar_graph_group',
                  text: `${this.data.column_keys[1]}`,
                  font: {
                    weight: 'bold',
                    size: 16
                  }
                },
                beginAtZero: true,
                ticks: {
                  // Uncomment and adjust if you have specific step size logic
                  // stepSize: this.computeStepSize()
                }
              }
            },
            responsive: true,
            maintainAspectRatio: true,
          },
        });
    });
  }
  onResize(event: Event): void {
    if (this.chart) {
      this.chart.resize();
    }
  }
  openFilterModal() {
    this.filterData.openModel();
  }
  openPanelModal(isEdit = false) {
    this.editPanel = isEdit ? true : false;
    this.sharedData = this.copyGraphData;
    this.panelData.openModel();
  }

  panelDeleteModal(template: TemplateRef<any>) {
    this.deleteModal = this.modalService.show(
      template,
      Object.assign({}, { class: "catalogue gray modal-sm" })
    );
    this.altService.backModalClose(this.deleteModal);
  }

  deletePanel() {
    this.loadingDeletePanel = true;
    this.sharedService.deletePanel(this.data.report_id, 1).subscribe(res => {
      if (res) {
        this.toastr.success('Report Deleted Successfully!');
        this.dashboardService.deleteReport(true);
        this.callParentMethodEvent.emit(this.data.report_id.toString());
        this.loadingDeletePanel = false;
        this.deleteModal.hide();
      }
    }, error => {
      this.toastr.error('Something went wrong!')
      this.loadingDeletePanel = false;
      this.deleteModal.hide();
    })
  }

  filterAppied(event) {
    if (this.data.report_id) {
      this.data = event[0]
      this.chart?.destroy()
      this.ngAfterViewInit();
      this.checkFilter();
      this.dashboardService.deleteReport(false);
      this.callParentMethodEvent.emit(this.data.report_id.toString());
    }
  }

  /* Remove Filter */
  removeFilter(event) {
    this.sharedService.removeFilter(event);
    this.callParentMethodEvent.emit(event.report_id.toString());
  }
}
