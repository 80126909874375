import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chatfilter'
})

export class ChatfilterPipe implements PipeTransform {
  transform(value: any[], args = ''): any {
    console.log(value)
    return args ? value.filter(
      item => item.member.fullname.toLowerCase().indexOf(args.toLowerCase()) > -1
    ) : value;
  }
}