import { HttpParams } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LoadingService } from '@app/authentication/services/loading.service';
import { UserRoles } from '@app/core/enums/user-roles';
import { Company } from '@app/modules/company/models/company.modal';
import { OrderService } from '@app/modules/order/service/order.service';
import { Review } from '@app/modules/requisition/models/requisition';
import { AlertService } from '@app/shared/service/alert.service';
import { SharedService } from '@app/shared/service/shared.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-order-rating',
  templateUrl: './order-rating.component.html',
  styleUrls: ['./order-rating.component.css']
})
export class OrderRatingComponent implements OnInit {
  modalRef: BsModalRef;
  @ViewChild('ratingsPopup', { static: false }) ratingPopup: TemplateRef<any>;
  reviewsList: Review[] = [];
  totalReviews = 0;
  totalPackagingRating = 0;
  totalProductQualityRating = 0;
  totalVendorServiceRating = 0;
  totalOverallRating = 0;
  //pageCount = 0;
  isLoading = false;
  vendorId: number;
  page = 1;
  limit = 10;
  role: number;
  userRoles = UserRoles;
  company: Company;
  selectedSort = '';

  constructor(
    private modalService: BsModalService,
    private orderService: OrderService,
    private sharedService: SharedService,
    private loadingService: LoadingService,
    private toastr: ToastrService,
    private alertService: AlertService
  ) {
    this.role = this.sharedService.getUserRole();
    this.company = this.sharedService.getCompany();
  }

  ngOnInit() { }

  openModal(vendorId, paginated = 0, value = null) {
    this.vendorId = vendorId;
    this.isLoading = true;
    const pageNo = this.page;
    let params = new HttpParams()
      .set('page', pageNo.toString())
      .set('limit', this.limit.toString())
      .set('vendor_id', vendorId.toString());

    switch (value) {
      case 'l2o':
        params = params.append('order_by', 'desc');
        params = params.append('order_column', 'created_at');
        this.selectedSort = 'l2o';
        break;
      case 'o2l':
        params = params.append('order_by', 'asc');
        params = params.append('order_column', 'created_at');
        this.selectedSort = 'o2l';
        break;
      case 'l2h':
        params = params.append('order_by', 'asc');
        params = params.append('order_column', 'rate');
        this.selectedSort = 'l2h';
        break;
      case 'h2l':
        params = params.append('order_by', 'desc');
        params = params.append('order_column', 'rate');
        this.selectedSort = 'h2l';
        break;
      default:
        params = params.append('order_by', 'desc');
        params = params.append('order_column', 'created_at');
        this.selectedSort = 'l2o';
    }
    /* if (value === 'l2o') {
      params = params.append('order_by', 'desc');
      params = params.append('order_column', 'created_at');
      this.selectedSort = 'l2o';
    } else if (value === 'o2l') {
      params = params.append('order_by', 'asc');
      params = params.append('order_column', 'created_at');
      this.selectedSort = 'o2l';
    } else if (value === 'l2h') {
      params = params.append('order_by', 'asc');
      params = params.append('order_column', 'rate');
      this.selectedSort = 'l2h';
    } else if (value === 'h2l') {
      params = params.append('order_by', 'desc');
      params = params.append('order_column', 'rate');
      this.selectedSort = 'h2l';
    } else {
      params = params.append('order_by', 'desc');
      params = params.append('order_column', 'created_at');
      this.selectedSort = 'l2o';
    } */
    this.orderService.getVendorRating(params, null).subscribe(data => {
      this.totalReviews = data.total;
      this.reviewsList = data.results;
      this.totalPackagingRating = data.pkg_of_product;
      this.totalProductQualityRating = data.product_quality;
      this.totalVendorServiceRating = data.vendor_service;
      this.totalOverallRating = data.rate;
      if (data.results.length > 0) {
        this.isLoading = false;
        this.loadingService.setLoading(false);
        if (paginated === 0) {
          this.modalRef = this.modalService.show(
            this.ratingPopup,
            Object.assign({ backdrop: 'static', class: 'gray modal-lg' })
          );
          this.alertService.backModalClose(this.modalRef);
        }
      } else {
        this.toastr.error('No reviews found');
        this.loadingService.setLoading(false);
        this.isLoading = false;
      }
    });
  }

  reviewListSort(value) {
    // if (value === 'latest') {
    //   this.openModal(this.vendorId, 1, value);
    //   // this.reviewsList = this.reviewsList.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
    // } else if (value === 'high') {
    //   this.openModal(this.vendorId, 1, value);
    // } else {
    //   this.openModal(this.vendorId, 1, null);
    // }

    this.page = 1;
    switch (value) {
      case 'l2o':
        this.openModal(this.vendorId, 1, value);
        break;
      case 'o2l':
        this.openModal(this.vendorId, 1, value);
        break;
      case 'l2h':
        this.openModal(this.vendorId, 1, value);
        break;
      case 'h2l':
        this.openModal(this.vendorId, 1, value);
        break;
      default:
        this.openModal(this.vendorId, 1, 'l2o');
    }


    /* if (value === 'l2o') {
      this.openModal(this.vendorId, 1, value);
    } else if (value === 'o2l') {
      this.openModal(this.vendorId, 1, value);
    } else if (value === 'l2h') {
      this.openModal(this.vendorId, 1, value);
    } else if (value === 'h2l') {
      this.openModal(this.vendorId, 1, value);
    } else {
      this.openModal(this.vendorId, 1, 'l2o');
    } */
  }

  onPageChange(event, paginated = 1) {
    this.page = event;
    this.openModal(this.vendorId, paginated, this.selectedSort);
  }
}
