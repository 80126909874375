<h1>{{status}}</h1>
<div class="lottie-loading loaderHide" [class.loaderShow]="loading">
  <div class="lottie-loader">
      <img src="/assets/images/loader-cat.PNG" alt="">
      <!-- <lottie-animation-view [options]="lottieConfig" [width]="64" [height]="64">
      </lottie-animation-view> -->
      <h4>Loading...</h4>
      <div class="c-animated-background"></div>
  </div>
</div>
<router-outlet></router-outlet>
