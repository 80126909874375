export enum CompanyType {
  Buyer = 1,
  Seller = 2
}
export const CompanyTypeLabel = {
  Buyer: 'Shipping Company',
  Seller: 'Vendor'
};

export enum CompanyStatus {
  Active = 1,
  Inactive = 0
}


export const CompanyStatusLabel = {
  InActive: 'Inactive',
  Active: 'Active'
};

export enum DocumentTypeEnum {
  PAN_CARD = 'pan_number',
  GST_TAX_ID = 'service_tax_number',
  ADDRESS_PROOF = 'address_prof',
  AGREEMENT = 'agreement',
  OTHER = 'other'

}

export const DocumentType = [
  {
    name: 'PAN CARD',
    value: DocumentTypeEnum.PAN_CARD
  },
  {
    name: 'GST/TAX ID',
    value: DocumentTypeEnum.GST_TAX_ID
  },
  {
    name: 'ADDRESS PROOF',
    value: DocumentTypeEnum.ADDRESS_PROOF
  },
  {
    name: 'AGREEMENT',
    value: DocumentTypeEnum.AGREEMENT
  },
  {
    name: 'Other',
    value: DocumentTypeEnum.OTHER
  },
];

export function getDoctype() {
  this.type = DocumentType;
}

