import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const currentUser = this.authenticationService.currentUserValue;
    const headers = request.headers;
    let clonedRequest = request;
    if (currentUser && currentUser.data.access_token) {
      clonedRequest = request.clone({
        headers: request.headers.set(
          'Authorization',
          `Bearer ${currentUser.data.access_token}`
        )
      });
    }
    return next.handle(clonedRequest);
  }
}
