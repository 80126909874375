import { SharedService } from "@shared/service/shared.service";
import { UserService } from "@app/modules/user/services/user.service";
import { LoadingService } from "@app/authentication/services/loading.service";
import { UserRoles } from '@app/core/enums/user-roles';
import { Injectable } from "@angular/core";
import { Company } from "@app/modules/company/models/company.modal";

@Injectable({
  providedIn: "root",
})
export class Utils {
  dashboard;
  loadingOrders: boolean;
  loading: boolean;
  reqReportData = [];
  orderReportData = [];
  newProductRequestCount = 0;
  reqReport: boolean;
  orderReport: boolean;
  emptybox: boolean = true;
  userRoles = UserRoles;
  role: number;
  company: Company;
  rfqCount: number = 0;
  rating: number = 0;
  deliveryStrength: number = 0;

  constructor(
    private userService: UserService,
    private sharedService: SharedService,
    private loadingService: LoadingService
  ) { }

  getDashboard(download = 0, format = "json", orderReport = 0) {
    this.role = this.sharedService.getUserRole();
    this.company = this.sharedService.getCompany();
    if (!download) {
      this.loadingService.setLoading(true);
    } else if (download && orderReport) {
      this.loadingOrders = true;
    } else {
      this.loading = true;
    }
    this.userService
      .getDashboard(null, download, format, orderReport)
      .subscribe(
        (data) => {
          if (download) {
            const mime =
              format === "pdf" ? "application/pdf" : "application/vnd.ms-excel";
            this.sharedService.saveAsBlob(
              data,
              mime,
              (orderReport ? "orders" : "requisitions") + "." + format
            );
            this.loading = false;
            this.loadingOrders = false;
          } else {
            this.dashboard = data;
            if (!(this.role == this.userRoles.ROLE_COMPANY_ADMIN && this.company.type == 2)) {
              this.requsitionLabel(data);
            }
            this.orderLabel(data);
            this.loadingService;
            this.newProductRequestCount = data.prod_request;
            this.loadingService.setLoading(false);

            if (this.role !== this.userRoles.ROLE_ADMIN) {
              this.reqReportData.forEach(item => {
                if (item.value > 0) {
                  this.reqReport = true;
                }
              });
              this.orderReportData.forEach(item => {
                if (item.value > 0) {
                  this.orderReport = true;
                }
              });
              if (this.reqReport || this.orderReport) {
                this.emptybox = false;
              }
            }
            if (this.role == this.userRoles.ROLE_COMPANY_ADMIN && this.company.type == 2) {
              this.rfqCount = data.rfq;
              this.rating = data.avg_rating;
              this.deliveryStrength = data.delivery_strength;
            }
          }
        },
        (error) => {
          this.loading = false;
          this.loadingOrders = false;
          this.loadingService.setLoading(false);
        }
      );
  }
  requsitionLabel(data) {
    this.reqReportData = [];
    const requsitionData = [
      {
        label: "Waiting for Approval",
        value: data.req,
        colour: "#55c0f8",
        nav_url: "/requisitions?req_status=0",
      },
      {
        label: "Rejected by Manager",
        value: data.rejected_req_manager,
        colour: "#009dff",
        nav_url: "/requisitions?req_status=2",
      },
      {
        label: "Rejected by Purchase Team",
        value: data.rejected_req_po,
        colour: "#0275b6",
        nav_url: "/requisitions?req_status=3",
      },
      {
        label: "Urgent",
        value: data.urgent_req,
        colour: "#004d81",
        nav_url: "/requisitions?priority_type=Urgent",
      },
    ];
    this.reqReportData = [...requsitionData];
    return this.reqReportData;
  }
  orderLabel(data) {
    this.orderReportData = [];
    let orderData = [
      {
        label: "Order Placed",
        value: data.approved,
        colour: "#0094fe",
        nav_url: "/orders?status=1",
      },
      {
        label: "Out For Delivery",
        value: data.out_for_delivery,
        colour: "#55c0f8",
        nav_url: "/orders?status=3",
      },
      {
        label: "Delivered",
        value: data.deliverd,
        colour: "#008f01",
        nav_url: "/orders?status=4",
      },
      {
        label: "Cancelled",
        value: data.canceled,
        colour: "#980d06",
        nav_url: "/orders?status=6",
      },
    ];
    if (this.role == this.userRoles.ROLE_COMPANY_ADMIN && this.company.type == 2) {
      let editedUser = {
        label: "Order Received",
        value: data.approved,
        colour: "#0094fe",
        nav_url: "/orders?status=1"
      };
      orderData = orderData.map(u => u.nav_url !== editedUser.nav_url ? u : editedUser);
    }
    if (!(this.role == this.userRoles.ROLE_COMPANY_ADMIN && this.company.type == 2)) {
      orderData.push({
        label: "Partial Delivered",
        value: data.partial_deliverd,
        colour: "#005494",
        nav_url: "/orders?status=5",
      })
    }
    this.orderReportData = [...orderData];
    return this.orderReportData;
  }

  reqDataClicked(event) {
    return this.dataClicked(event, "req");
  }

  orderDataClicked(event) {
    return this.dataClicked(event, "order");
  }

  dataClicked(event, chartClick) {
    switch (chartClick) {
      case "req":
        const itemreq = this.reqReportData.find(
          (x) => x.label === event.element._model.label
        );
        if (itemreq) {
          window.open(location.origin + itemreq.nav_url, "_blank");
        }
        break;
      case "order":
        const item = this.orderReportData.find(
          (x) => x.label === event.element._model.label
        );
        if (item) {
          window.open(location.origin + item.nav_url, "_blank");
        }
        break;
    }
  }
}
