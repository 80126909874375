import { UserService } from '@app/modules/user/services/user.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MustMatch } from '@app/modules/user/components/user-list/user-list.component';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidators } from '@app/shared/validators/custom-validators';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  ChangePasswordForm: FormGroup;
  token: any = false;
  submitted: boolean;
  loading = false;
  requestPasswordForm: FormGroup;
  isDisabled = true;
  currentYear: number;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private tostr: ToastrService,
    private userService: UserService,
    private router: Router,
  ) {
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit() {
    this.route.params.subscribe(value => {
      this.token = value.token;
    });

    this.requestPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, CustomValidators.emailValidator]],
    });

    this.ChangePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', { validator: this.checkPasswords }],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }
  get form() {
    return this.ChangePasswordForm.controls;
  }

  get reqForm() {
    return this.requestPasswordForm.controls;
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true };
  }

  onChangedPassword() {
    if (!this.ChangePasswordForm.valid) {
      this.submitted = true;
      return false;
    }
    this.userService.changePassword(this.ChangePasswordForm.value, this.token).subscribe(data => {
      this.tostr.success('Password Change Successfully');
      this.router.navigate(['/']);
    }, err => {
      this.tostr.error(err);
      this.router.navigate(['/forget']);
    });
  }

  requestPasswordMail() {
    if (!this.requestPasswordForm.valid) {
      this.submitted = true;
      return false;
    }
    this.isDisabled = false;
    this.userService.requestPassword(this.requestPasswordForm.value).subscribe(data => {
      this.tostr.success('Password Change mail sent Successfully');
      this.isDisabled = true;
      this.requestPasswordForm.reset();
    }, err => {
      this.isDisabled = true;
      this.tostr.error(err);
    });
  }
}

