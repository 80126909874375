import { Component, ElementRef, OnInit, ViewChild, TemplateRef, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { ChatService } from '@app/modules/help-chat/chat/chat.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SharedService } from '@shared/service/shared.service';
import { UserRoles } from '@app/core/enums/user-roles';
import { UserService } from '@app/modules/user/services/user.service';
import { Company } from '@app/modules/company/models/company.modal';
import { CompanyService } from '@app/modules/company/services/company.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { takeWhile } from 'rxjs/operators';
import { LoadingService } from '@app/authentication/services/loading.service';
import { User } from '@app/modules/user/models/user.modal';
import { AlertService } from '@app/shared/service/alert.service';
import { DecryptService } from '@app/shared/service/decrypt.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
  providers: [ChatService, AngularFirestore]
})
export class ChatComponent implements OnInit, AfterViewInit {
  modalRef: BsModalRef;
  loading: boolean;
  loading1: boolean;
  //userId: number;
  companys: Company[] = [];
  //userRoles = UserRoles;
  company: Company;
  companyUsers: User[] = [];
  selectedUser: any;
  // chats: Observable<string[]>;
  messages: any = [];
  //currentDoc: string;
  // private _chatsub: Subscription;
  role: number;
  data: any;
  loadingUsers: boolean;
  userenter = '';
  @ViewChild('messageInput', { static: false }) messageInput: ElementRef;
  @ViewChildren('messageDiv') messageDiv: QueryList<any>;
  inValidFileSize = false;
  showDateTime = true;
  // currentDate = JSON.parse((new Date().getFullYear())+'-'+(new Date().getMonth()+1)+'-'+(new Date().getDate()));

  adminObj = {
    id: 1,
    fname: 'Administrator',
    lname: '',
    email: 'administrator@shipskart.com',
    username: 'admin',
    user_id: 1
  };
  timestamp = new Date().toISOString();
  chats = [];
  page = 1;
  user: User;
  loadingMessages: boolean;
  selectedChat: any;
  //selectedChatId: number;
  message = '';
  chatForm: FormGroup;

  @ViewChild('chatWindow', { static: false })
  private chatWindow: ElementRef;
  attachment: File;
  sending: boolean;

  constructor(
    private chatService: ChatService,
    private fb: FormBuilder,
    private sharedService: SharedService,
    private companyService: CompanyService,
    private userService: UserService,
    private modalService: BsModalService,
    private authService: AuthenticationService,
    private firestore: AngularFirestore,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private decryptService: DecryptService
  ) {
    this.chatForm = this.fb.group({
      message: [''],
      chooseFile: ['']
    });
  }

  resetTimestamp() {
    this.timestamp = new Date().toISOString();
  }

  ngOnInit() {

    this.getChats();
    this.user = this.sharedService.getUserDetails();
    this.role = this.sharedService.getUserRole();
    this.data = this.currentUser().data;
    this.firestore.collection('user_chats').doc(this.user.user_id.toString()).delete();

    const docRef = this.firestore.collection('user_chats').doc(this.user.user_id.toString());
    docRef.snapshotChanges().subscribe(doc => {
      const data: any = doc.payload.data();
      if (data) {
        this.updateChatMessage(data);
      }
    });
    if (this.role === 1) {
      this.companyList();
    }
  }

  updateChatMessage(chatMessage) {
    this.chatService.acknowledgeMessage(chatMessage.id);
    const chat = this.chats.find(x => x.id == chatMessage.chat_id);
    if (chat) {

      chat.last_message = chatMessage;
      if (this.selectedChat != null && chatMessage.chat_id == this.selectedChat.id) {
        this.messages.push(chatMessage);

      } else {
        chat.unread_count += 1;
      }

    } else {
      this.chatService.getChatById(chatMessage.chat_id).subscribe(res => {
        this.chats.push(res);
      });
    }
  }

  getChats() {
    this.loading1 = true;
    this.loadingService.setLoading(true);
    this.chatService.getChats().subscribe(data => {
      const chatsData = JSON.parse(this.decryptService.decryptUsingAES256(data))
      this.chats = chatsData[0]?.['data'];
      this.loading1 = false;
      this.loadingService.setLoading(false);
    });
  }

  get sortedChats() {
    if (this.chats) {
      return this.chats.sort((a, b) => {
        if (b.last_message && a.last_message) {
          return <any>new Date(b.last_message.created_at) - <any>new Date(a.last_message.created_at);
        }
      });
    }
  }

  getChatMessages(chat) {
    this.selectedChat = chat;
    this.resetTimestamp();
    this.messages = [];
    this.message = '';
    this.page = 1;
    chat.unread_count = 0;
    this.chatService.acknowledgeChat(chat.id).subscribe();
    this.getMessages(1);
    this.userenter = '';
    setTimeout(() => {
      this.messageInput.nativeElement.focus();
    }, 0);
    this.messageDiv.changes.pipe(takeWhile(() => this.messages.length === 0, true)).subscribe(() => {
      this.scrollToBottom();
    });
  }

  getMessages(page) {
    this.loadingMessages = true;
    if (this.selectedChat == null) {
      return;
    }
    this.chatService.getMessages({ chat_id: this.selectedChat.id, page: page, timestamp: this.timestamp }).subscribe(data => {
      if (data.items.length > 0) {
        this.messages = [...data.items.reverse(), ...this.messages];
        this.page = page + 1;
        // if(this.messages) {
        //   this.messages.forEach(item => {
        //     const messageDate =  JSON.parse((new Date(item.created_at).getFullYear())+'-'+(new Date(item.created_at).getMonth()+1) +'-' +(new Date(item.created_at).getDate()));
        //     if(messageDate < this.currentDate) {
        //       this.showDateTime = false;
        //       console.log('dsds');
        //     }
        //   });
        // }
      }
      this.loadingMessages = false;
    }, error => {
      this.loadingMessages = false;
    });
  }

  sendMessage() {
    if (this.message.trim().length) {
      this.sendingMessage();
    }
    else {
      this.message = this.message.trim();
      this.sendingMessage();
    }
  }

  sendingMessage() {
    const formData = new FormData();
    formData.append('chat_id', this.selectedChat.id.toString());
    formData.append('message', this.message);
    formData.append('file', this.attachment);

    this.sending = true;
    this.chatService.sendMessage(formData).subscribe(data => {
      this.messages.push(data);
      this.selectedChat.last_message = data;
      this.messageDiv.changes.pipe(takeWhile(() => this.messages.length === 0, true)).subscribe(() => {
        this.scrollToBottom();
      });
      this.message = '';
      this.attachment = null;
      this.sending = false;
    }, error => {
      this.sending = false;
    });
  }

  test(event) {
    if (event.target.scrollTop == 0) {
      this.getMessages(this.page);
    }
  }

  downloadFile(file: any, download = true) {
    this.chatService.downLoadFile(file.id, download).subscribe(data => {
      if (download) {
        const downloadURL = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = file.file_name;
        link.click();
      } else {
        const fileURL = URL.createObjectURL(data);
        window.open(fileURL, '_blank');
      }
    });
  }

  private companyList() {
    this.loading = true;
    this.companyService.getAll(1, 500, 0).subscribe(data => {
      this.companys = data.results;
      this.loading = false;
    });
  }

  private currentUser() {
    return this.authService.currentUserValue;
  }

  selectCompany(company) {
    this.loadingUsers = true;
    this.company = company;
    if (this.company) {
      let companyId = company && company.id ? company.id : company;
      this.userService.getCompanyMembers(200, companyId).subscribe(data => {
        this.companyUsers = data;
        this.loadingUsers = false;
      }, error => {
        this.loadingUsers = false;
      });
    } else {
      this.loadingUsers = false;
      return;
    }
  }

  deleteChat(id) {
    this.chatService.deleteChat(id).subscribe(data => {
      if (this.selectedChat.id == id) {
        this.selectedChat = null;
        this.messages = [];
      }
      let item;
      item = this.chats.find(x => x.id == id);
      if (item) {
        this.chats.splice(this.chats.indexOf(item), 1);
      }
    });
  }

  addNewChat() {
    const formData = new FormData();
    formData.append('receiver_id', this.selectedUser.user_id);

    this.chatService.addChat(formData).subscribe(data => {
      if (!this.chats.some((item) => item.id == data.id)) {
        this.chats.unshift(data);
      }
      this.selectedChat = data;
      this.messages = [];
      this.resetTimestamp();
      this.getMessages(1);
      this.selectedUser = null;
      this.companyUsers = [];
      this.modalRef.hide();
      setTimeout(() => {
        this.messageInput.nativeElement.focus();
      }, 0);
      this.messageDiv.changes.pipe(takeWhile(check => this.messages.length === 0, true)).subscribe(item => {
        this.scrollToBottom();
      });
    });
  }

  addNewChatModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign(
        {
          backdrop: 'static',
          class: 'gray modal-small change-pass'
        }
      )
    );
    this.alertService.backModalClose(this.modalRef);
    if (this.role !== 1) {
      this.selectCompany(this.data.user_details.company_id);
    }
  }

  closeChatModal() {
    this.companyUsers = [];
    this.modalRef.hide();
  }

  onChooseFile(event) {
    this.inValidFileSize = false;
    if (event.target.files[0]) {
      const input = event.target;
      const filesize = input.files[0].size / 1024 / 1024;
      if (filesize > 5) {
        this.inValidFileSize = true;
      } else {
        this.attachment = input.files[0];
      }
    }
  }

  removeFile() {
    this.attachment = null;
    this.chatForm.get('chooseFile').reset();
  }

  ngAfterViewInit() {
    this.messageDiv.changes.pipe(takeWhile(() => this.messages.length === 0, true)).subscribe(() => {
      this.scrollToBottom();
    });
  }

  scrollToBottom() {
    if (this.chatWindow.nativeElement) {
      this.chatWindow.nativeElement.scrollTop = this.chatWindow.nativeElement.scrollHeight;
    }
  }
}
