<a class="back-btn back inline-block bmargin10"
  href="javascript:;"
  (click)="goPreviousUrl()"><span class="fa fa-arrow-left"></span>Back to Dashboard</a>
<div class="container-fluid">
  <div class="heading">
    <h3 class="form-heading">{{formId?role==userRoles.ROLE_SUBADMIN?"Assigned":"Update":"Create"}} Form</h3>
    <div class="custom-control custom-switch custom" *ngIf="role==userRoles.ROLE_ADMIN && !formId">
      <input type="checkbox"
        class="custom-control-input"
        id="customSwitch1" [checked]="isChecked" (click)="toggleSwitch($event.target.checked)">
        <label class="custom-control-label"
          for="customSwitch1">{{guest}}</label>
    </div>
  </div>
  <hr class="heading-horizontal-line">
  <div class="body">
    <form [formGroup]="createForm"
      (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-12 bmargin10 tmargin10">
          <label class="form-label bmargin5"
            for="form-name">Name of Form<span class="required">*</span></label>
          <input type="text"
            class="form-control"
            formControlName="form_title"
            id="form-name"
            placeholder="Enter Name of the Form">
        </div>
        <div class="col-md-12">
          <hr>
        </div>
        <div class="col-md-12 bmargin10"
          *ngIf="!(role==userRoles.ROLE_SUBADMIN)">
          <h5>
            Add Text Field
          </h5>
        </div>
        <div formArrayName="elements"
          class="d-flex w-100 flex-wrap" *ngIf="createForm.get('elements')">
          <div class="tmargin10 bmargin10 col-md-6"
            *ngFor="let control of createForm.get('elements').controls; let i = index"
            [formGroupName]="i">
            <div class="d-flex">
              <div>
                <label for=""
                  class="form-label bmargin5">Mandatory</label>
                <input type="checkbox"
                  formControlName="required"
                  class="priceconvert-checkbox">
              </div>
              <div class="lmargin20">
                <label class="form-label bmargin5"
                  for="field-name">Name of Field - {{(i+1)}}<span class="required">*</span></label>
                <input type="text"
                  formControlName="name"
                  class="form-control"
                  id="field-name"
                  placeholder="Enter Text">
                <input type="hidden"
                  formControlName="type"
                  class="form-control"
                  id="field-name"
                  placeholder="Enter Text">
              </div>
            </div>
          </div>
        </div>
        <div class="text-right col-md-12 bmargin5"
          *ngIf="!(formId && role==userRoles.ROLE_SUBADMIN)">
          <a class="anchor-button-add"
            (click)="addTextFields()">+Add Text Field</a>
          &nbsp;
          <a class="anchor-button-remove"
            (click)="removeTextFields()"
            *ngIf="elements.length">-Remove Field</a>
        </div>
        <div class="col-md-12">
          <hr>
        </div>
        <div class="col-md-12 tmargin10 bmargin10"
          *ngIf="!(role==userRoles.ROLE_SUBADMIN)">
          <h5>
            Add Rating Parameter
          </h5>
        </div>
        <div formArrayName="rating_parameters"
          class="d-flex w-100 flex-wrap" *ngIf="createForm.get('rating_parameters')">
          <div class="col-md-6 bmargin10"
            *ngFor="let control of createForm.get('rating_parameters').controls; let i = index">
            <div [formGroupName]="i" class="flex">
              <div class="d-flex">
                <div>
                  <label for=""
                    class="form-label bmargin5">Mandatory</label>
                  <input type="checkbox"
                    formControlName="required"
                    class="priceconvert-checkbox">
                </div>
                <div class="lmargin20">
                  <label class="form-label bmargin5"
                    for="rating-param">Rating Parameter - {{(i+1)}}<span class="required">*</span></label>
                  <input formControlName="name"
                    type="text"
                    class="form-control"
                    id="rating-param"
                    placeholder="Enter Text">
                  <input type="hidden"
                    hidden="true"
                    formControlName="type"
                    class="form-control"
                    id="field-name"
                    placeholder="Enter Text">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-right bmargin5"
          *ngIf="!(formId && role==userRoles.ROLE_SUBADMIN)">
          <a class="anchor-button-add"
            (click)="addRatingParam()">+Add Text Field</a>
          &nbsp;
          <a class="anchor-button-remove"
            (click)="removeRatingParam()"
            *ngIf="rating_parameters.length">-Remove Field</a>
        </div>
        <div class="col-md-12">
          <hr>
        </div>
        <div class="col-md-12 tmargin10 bmargin10"
          *ngIf="!(role==userRoles.ROLE_SUBADMIN)">
          <h5>
            Add Text Area
          </h5>
        </div>
        <div formArrayName="textarea_parameters"
          class="d-flex w-100 flex-wrap" *ngIf="createForm.get('textarea_parameters')">
          <div class="col-md-6 bmargin10"
            *ngFor="let control of createForm.get('textarea_parameters').controls; let i = index">
            <div [formGroupName]="i">
              <label class="form-label bmargin5"
                for="rating-param">TextArea Parameter - {{(i+1)}}<span class="required">*</span></label>
              <input formControlName="name"
                type="text"
                class="form-control"
                id="rating-param"
                placeholder="Enter Text">
              <input type="hidden"
                formControlName="type"
                class="form-control"
                id="field-name"
                placeholder="Enter Text">
            </div>
          </div>
        </div>
        <div class="col-md-12 text-right bmargin5"
          *ngIf="!(formId && role==userRoles.ROLE_SUBADMIN)">
          <a class="anchor-button-add"
            (click)="addTextArea()">+Add Text Area</a>
          &nbsp;
          <a class="anchor-button-remove"
            (click)="removeTextArea()"
            *ngIf="textarea_parameters.length">-Remove Text Area</a>
        </div>
        <div class="col-md-12">
          <hr>
        </div>
        <div class="col-md-12 bmargin5"
          *ngIf="!(role==userRoles.ROLE_SUBADMIN)">
          <h5>
            Description(s)
          </h5>
        </div>
        <div class="col-md-12 tmargin10 bmargin10">
          <label class="form-label bmargin5"
            for="textarea">Notes<span class="required">*</span></label>
          <textarea class="form-control"
            formControlName="notes"
            id="textarea"
            name=""
            placeholder="Enter Notes"></textarea>
        </div>
        <div class="col-md-12"
          *ngIf="!(role==userRoles.ROLE_SUBADMIN)">
          <hr>
        </div>
        <div class="col-md-12 bmargin5"
          *ngIf="!(role==userRoles.ROLE_SUBADMIN)">
          <h5>
            Add Assignee
          </h5>
        </div>
        <div class="col-md-12 tmargin10 bmargin10"
          *ngIf="!(role==userRoles.ROLE_SUBADMIN)">
          <label class="form-label bmargin5"
            for="assignee">User(s)<span class="required">*</span></label>
          <ng-select [items]="usersData"
            bindLabel="name"
            bindValue="userId"
            [loading]="userDataLoader"
            placeholder="Select User"
            formControlName="user_ids"
            [multiple]="true"
            class="form-control"
            [clearable]="true"
            (change)="selectedData($event)"
            [closeOnSelect]="false">
          </ng-select>
        </div>
        <div class="col-md-12 text-right tmargin10 bmargin10">
          <button class="btn btn-primary"
            type="submit"
            [disabled]="!(createForm.valid)"
            *ngIf="!(formId && role==userRoles.ROLE_SUBADMIN)">
            <ng-container *ngIf="isLoading">
              <span class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"></span>
              <span class="sr-only">Loading...</span>
            </ng-container>&nbsp;{{formId?"Update":"Submit"}}</button>
        </div>
      </div>
    </form>
  </div>
</div>
