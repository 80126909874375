<div  class="responsive overlay {{divCss}}">
    <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
    <div class="overlay-content">
        <div class="left-sidebar desktop">
            <ul class="menu-list">
                <li *ngFor="let item of navItems" [classList]="item.className" [routerLinkActive]="['opened']" [routerLinkActiveOptions]="{exact: true}">
                    <a *ngIf="item.subItems" [ngClass]="{'opened': item.expand === 'show' ? true : false, 'icon-width': item.title === 'Requisitions'}">
                        <img class="sidebar-icon" [src]="item.icon">{{item.title}}
                        <i *ngIf="item.subItems" class="fa fa-angle-right" [@toggleArrow]="item.expand == 'show' ? 'left': 'right'"></i>
                    </a>
                    <a *ngIf="!item.subItems" [routerLink]="item.link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
                        <img class="sidebar-icon" [src]="item.icon">{{item.title}}
                    </a>
                    <ul *ngIf="item.subItems" class="sub-menu">
                        <li *ngFor="let subitem of item.subItems">
                            <a [routerLink]="subitem.link" [queryParams]="subitem.params" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
                                <small *ngIf="subitem.icon" class="icon-{{ subitem.icon }}"></small> {{subitem.title}}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- <div  class="overlay {{divCss}}">
    <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
    <div class="overlay-content">
        <div class="left-sidebar desktop">
            <ul class="menu-list">
                <li *ngFor="let item of navItems" [classList]="item.className" [routerLinkActive]="['opened']" [routerLinkActiveOptions]="{exact: true}">
                    <a *ngIf="item.subItems" [ngClass]="{'opened': item.expand === 'show' ? true : false,'icon-width': item.title === 'Requisitions' }" (click)="toggleSubMenu(item)">
                        <small *ngIf="item.icon" class="icon-{{ item.icon }}"></small> {{item.title}}
                        <i *ngIf="item.subItems" class="fa fa-angle-right" [@toggleArrow]="item.expand == 'show' ? 'left': 'right'"></i>
                    </a>
                    <a *ngIf="!item.subItems" [routerLink]="item.link" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
                        <small *ngIf="item.icon" class="icon-{{ item.icon }}"></small> {{item.title}}
                    </a>
                    <ul *ngIf="item.subItems" class="sub-menu">
                        <li *ngFor="let subitem of item.subItems">
                            <a [routerLink]="subitem.link" [queryParams]="subitem.params" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
                                <small *ngIf="subitem.icon" class="icon-{{ subitem.icon }}"></small> {{subitem.title}}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div> -->
<span class="hamburgr" (click)="openNav()">&#9776; </span>