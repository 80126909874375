   <ngx-datatable-footer>
     <ng-template ngx-datatable-footer-template
       let-rowCount="rowCount"
       let-pageSize="pageSize"
       let-selectedCount="selectedCount"
       let-curPage="curPage"
       let-offset="offset"
       let-isVisible="isVisible">
       <div class="page-count"
         *ngIf="!isLoading && rowCount != 0">
         <span>
           {{pageSize * offset + 1}} - {{(pageSize * curPage) < rowCount ? (pageSize * curPage) : rowCount}} of
         </span>
         {{rowCount}}
       </div>
       <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
         [pagerRightArrowIcon]="'datatable-icon-right'"
         [pagerPreviousIcon]="'datatable-icon-prev'"
         [pagerNextIcon]="'datatable-icon-skip'"
         [page]="curPage"
         [size]="pageSize"
         [count]="rowCount"
         [hidden]="!((rowCount / pageSize) > 1)"
         (change)="table.onFooterPage($event)">
       </datatable-pager>
     </ng-template>
   </ngx-datatable-footer>
