import { NgSelectModule } from '@ng-select/ng-select';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ProductRoutingModule } from './product-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FileUploadModule } from 'primeng/fileupload';
import { EditorModule } from 'primeng/editor';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ProductRequestComponent } from './components/product-request/product-request.component';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'angular-highcharts';
import { NgbPopoverModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import {CalendarUiCorrectionDirective} from '@shared/directive/calendar-ui-correction.directive';

@NgModule({
  declarations: [ProductRoutingModule.COMPONENT, ProductRequestComponent],
    imports: [
        CommonModule,
        SharedModule,
        NgSelectModule,
        RouterModule,
        ReactiveFormsModule,
        ProductRoutingModule,
        NgbPaginationModule,
        NgxDatatableModule,
        FileUploadModule,
        EditorModule,
        AutoCompleteModule,
        CalendarModule,
        ChartModule,
        NgbPopoverModule,
        NgbRatingModule,
        CalendarUiCorrectionDirective,
    ],
  exports: [
    ProductRequestComponent
  ]
})
export class ProductModule { }
