import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
import { LoadingService } from '@app/authentication/services/loading.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OrderService } from '@app/modules/order/service/order.service';
import { HttpParams } from '@angular/common/http';
import { PaginationData } from '@app/core/models/response.model';
import { Order } from '@app/modules/order/model/order.model';
import { Subject } from 'rxjs';
import { Utils } from '@app/shared/service/util';

@Component({
  selector: 'app-dashboard-captain',
  templateUrl: './dashboard-captain.component.html',
  styleUrls: ['./dashboard-captain.component.css'],
  host: {
    "(window:click)": "onDropClick()"
  },
  providers: [Utils]
})
export class DashboardCaptainComponent implements OnInit, OnDestroy {
  //dashboard;
  //reqReport: boolean;
  //orderReport: boolean;
  //emptybox = true;
  userName: string;
  shipName: string;
  //reqReportData = [];
  //orderReportData = [];
  //loading = false;
  //loadingOrders = false;
  @ViewChild('reviewLastOrder', { static: false }) reviewLastOrder: TemplateRef<any>;
  modalRef: BsModalRef;
  order: Order;
  private unsubscribe$ = new Subject<any>();
  toggle: boolean = false;
  @Input() shipList = [];
  @Input() portList = [];
  @Input() categoryList = [];
  constructor(
    private authService: AuthenticationService,
    private loadingService: LoadingService,
    private orderService: OrderService,
    public util: Utils
  ) { }

  ngOnInit() {
    this.userName = this.authService.currentUserValue.data.user_details.user_details.fname;
    this.shipName = this.authService.currentUserValue.data.member.ship_name;
    this.util.getDashboard();
    this.openRateModal();
    //this.getDashboard();
  }


  openRateModal() {
    const params = new HttpParams()
      .set('page', '1')
      .set('details', '0')
      .set('limit', '10');
    // this.loadingService.setLoading(true);
    this.orderService.getAll(params, null).subscribe(
      (orderData: PaginationData) => {
        if (orderData.results && orderData.results.length > 0) {
          this.order = orderData.results.find(x => !x.rated && x.order_status === 4);
        }
        this.loadingService.setLoading(false);
      }, error => {
        this.loadingService.setLoading(false);
      });
  }

  setUserStatus(val = false) {
    // this.authService.getUserStatusValue(val);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  toggleShow(event) {
    this.toggle = !this.toggle;
    event.stopPropagation();

  }
  onDropClick() { // close drop down
    this.toggle = false;
  }
}
