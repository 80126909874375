<div class="box top-index" *ngIf="util.dashboard">
    <div class="row">
        <div class="col-md-12 bmargin46 less-m-margin">
            <div class="captain-detail">
                <div class="captain-head">
                    <h3>Hello, {{userName}}</h3>
                    <p>Captain {{shipName ? '| '+shipName: ''}}</p>
                </div>
                <div>
                    <span (click)="toggleShow($event)" *ngIf="order && !util.emptybox"
                        class="btn card-header ratingwidth">
                        <i class="fa fa-star animated-star"></i>
                        <span class="new head">Rate your last Order</span>&nbsp;
                        <span
                            [class.show]="toggle" class="dropdown-icon"><i class="fa fa-chevron-down"></i>
                            <div *ngIf="toggle"
                                class="ratingcol">
                                <div class="order-content">
                                <div>
                                    <p>Category</p>
                                    <p>: {{order && order.categories && order.categories.length > 0 ? order.categories[0] : 'N/A'}}</p>
                                </div>
                                <div>
                                    <p>Order Id</p>
                                    <p>: {{order && order.order_number ? order.order_number : 'N/A'}}</p>
                                </div>
                                <div>
                                    <p>Delivered On</p>
                                    <p>: {{order && order.delivery_date == null ? 'N/A' : order.delivery_date | date:'dd MMM yyyy'}}</p>
                                </div>
                                <div>
                                    <p>Delivery Port</p>
                                    <p>: {{order && order.port_name ? order.port_name : 'N/A'}}</p>
                                </div>
                                </div>
                            <div class="d-flex btndiv text-muted">
                                <button routerLink="/orders/detail/{{order.id}}"
                                    [queryParams]="{review: '1'}"
                                    class="btn btn-primary btn2"
                                    type="button">Rate Now</button>
                                <button routerLink="/orders" [queryParams]="{ status: 4 }" class="btn btn-default btn2"
                                    type="button">Review All Orders</button>
                            </div>                                                                
                            </div>

                        </span>
                    </span>
                    <a *ngIf="!util.emptybox"
                        routerLink="/dashboard/catalogue"
                        class="btn btn-primary cta-btn create-req"><i class="fa fa-plus-circle plus-sign req-btn"
                            aria-hidden="true"></i> Create Requisition
                    </a>
                </div>
            </div>
            <div *ngIf="util.emptybox" class="empty-dashboard tmargin35">
                <img src="/assets/images/empty-report.svg" alt="no-reports">
                <h2><span>Oops!</span>Looks like you haven't placed a Requisition yet?</h2>
                <div><a routerLink="/dashboard/catalogue" class="btn btn-primary btn-lg cta-btn"><i class="fa fa-plus-circle plus-sign"
                    aria-hidden="true"></i> Create a New Requisition</a></div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 bmargin30" *ngIf="util.reqReport">
            <div class="card new-card">
                <h5 class="card-header">Requisitions
                    <span class="loading-spinner small" *ngIf="util.loading"></span>
                    <span [class.download-option]="util.loading" class="dropdown dropleft">
            <a class="more-btn" data-toggle="dropdown" aria-haspopup="true"
               aria-expanded="false"><span class="more-dots"></span></a>
                    <div class="dropdown-menu float-right" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" (click)="util.getDashboard(1,'xlsx')">Download as Excel</a>
                        <a class="dropdown-item" (click)="util.getDashboard(1,'pdf')">Download as PDF</a>
                    </div>
                    </span>
                </h5>
                <div class="card-body new-body">
                    <app-piechart [graphData]="util.reqReportData" legendPosition="right" (chartClicked)="util.reqDataClicked($event)"></app-piechart>
                </div>
            </div>
        </div>
        <div class="col-md-6 bmargin30" *ngIf="util.orderReport">
            <div class="card new-card orders">
                <h5 class="card-header">Orders
                    <span class="loading-spinner small" *ngIf="util.loadingOrders"></span>
                    <span [class.download-option]="util.loadingOrders" class="dropdown dropleft">
            <a class="more-btn" data-toggle="dropdown" aria-haspopup="true"
               aria-expanded="false"><span class="more-dots"></span></a>
                    <div class="dropdown-menu float-right" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" (click)="util.getDashboard(1,'xlsx', 1)">Download as Excel</a>
                        <a class="dropdown-item" (click)="util.getDashboard(1,'pdf', 1)">Download as PDF</a>
                    </div>
                    </span>
                </h5>
                <div class="card-body new-body">
                    <app-piechart [graphData]="util.orderReportData" legendPosition="right" (chartClicked)="util.orderDataClicked($event)"></app-piechart>
                </div>
            </div>
        </div>
        <div class="col-md-12 tmargin10 mb-3">
            <app-category-expenditure *ngIf="!util.emptybox" [role]="4" [portList]="portList" [categoryList]="categoryList" [shipList]="shipList"></app-category-expenditure>
        </div>
    </div>
</div>