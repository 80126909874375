import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export const appAnimations = [
  trigger('toggleMenu', [
    state('show', style({ height: '*' })),
    state('hide', style({ height: 0 })),
    transition('void => *', [style({ height: 0, overflow: 'hidden' })]),
    transition('* => hide', [
      style({ height: '*' }),
      animate(250, style({ height: 0 }))
    ]),
    transition('hide => show', [
      style({ height: 0 }),
      animate(250, style({ height: '*' }))
    ])
  ]),

  trigger('toggleArrow', [
    state('right', style({ transform: 'rotate(0)' })),
    state('down', style({ transform: 'rotate(90deg)' })),
    transition('right <=> down', animate('100ms ease-in'))
  ])
];
