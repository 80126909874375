import { environment } from '@environment/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Subject, Observable, BehaviorSubject, Subscription } from 'rxjs';

import { Cart } from '../models/cart.model';
import { SharedService } from '@app/shared/service/shared.service';

@Injectable({ providedIn: 'root' })
export class CartService {
  cart: Cart[] = JSON.parse(localStorage.getItem('cart')) || [];
  getCartCount = new BehaviorSubject(this.cart.length);
  getReqCartCount = new BehaviorSubject(0);
  getProvCartCount = new BehaviorSubject(0);
  shipId: number;
  shipIdSub: Subscription;
  constructor(private http: HttpClient, private sharedService: SharedService) {
    this.sharedService.shipIdSubject.subscribe(id => {
      this.shipId = id;
    });
  }

  addToCart(item) {
    //     const CartList = this.cart.find(x => x.id == item.id);
    //     if (!CartList && item.qty > 0){
    //         this.cart.push(item);
    //         localStorage.setItem('cart', JSON.stringify(this.cart));
    //         this.getCartCount.next(this.cart.length);
    //    }else{
    //         // console.log('Already in cart');
    //     }

    this.sharedService.getShip();
    // this.ship_id = this.sharedService.ShipId.value;
    return this.http
      .post<Cart>(`${environment.apiUrl}/v1/cart/`, item)
      .toPromise();
  }

  getCart(shipId: number, provisionProducts = false) {
    if (shipId != null) {
      return this.http
        .get<any>(`${environment.apiUrl}/v1/cart/?ship_id=${shipId}` + (provisionProducts ? '&order=true' : ''))
        .pipe(
          map(dataVal => {
            if (dataVal) {
              this.getCartCount.next(dataVal.data.length);
              if (provisionProducts) {
                this.getProvCartCount.next(dataVal.data.length);
              } else {
                this.getReqCartCount.next(dataVal.data.length);
              }
              return dataVal;
            }
            return [];
          })
        );
    } else {
      throw new Error('No Ship Selected');
    }
  }
  placeOrder(orderValue) {
    return this.http.post(`${environment.apiUrl}/v1/order/`, orderValue).pipe(
      map(order => {
        return order;
      })
    );
  }
  placeProductOrder(orderValue) {
    return this.http.post(`${environment.apiUrl}/v1/order/place-order`, orderValue).pipe(
      map(order => {
        return order;
      })
    );
  }
  removeFormCart(id, deleteRequirement = 0) {
    return this.http.delete(`${environment.apiUrl}/v1/cart/${id}?delete_requirement=${deleteRequirement}`).toPromise();
  }

  cartPortChange(shipId, portId) {
    const headers = new HttpHeaders();
    return this.http.post(`${environment.apiUrl}/v1/cart/change_port/${shipId}/${portId}`, headers).pipe(
      map(data => {
        return data;
      })
    );
  }

  updateCart(company) {
    return this.http.put(`${environment.apiUrl}/v1/cart/`, company);
  }

  getCartSummary(shipId: number) {
    return this.http
      .get<{ data }>(`${environment.apiUrl}/v1/cart/summary?ship_id=${shipId}`)
      .pipe(
        map(data => {
          this.getCartCount.next(data.data.prov_count + data.data.req_count);
          this.getProvCartCount.next(data.data.prov_count);
          this.getReqCartCount.next(data.data.req_count);
          return data.data;
        })
      );
  }

  upload(formData): Observable<any> {
    return this.http
      .post<{ data }>(`${environment.apiUrl}/v1/cart/upload`, formData).pipe(map(data => {
        return data.data;
      }));
  }

  totalOrderAmount(id) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/order/active-order-total-amount/${id}`).pipe(map(data => {
      return data.data;
    })
    );
  }

  exportCart(shipId, isOrder, type, requirement_id) {
    const header = new HttpHeaders().set('x-file', [type]);
    return this.http
      .get(`${environment.apiUrl}/v1/cart/export?ship_id=${shipId}&is_order=${isOrder}&requirement_id=${requirement_id}`, {
        headers: header,
        responseType: 'blob'
      })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  exportInvalidProducts(formData, type = 'xlsx'): Observable<any> {
    const header = new HttpHeaders().set('x-file', [type]);
    return this.http.post(`${environment.apiUrl}/v1/cart/export_invalid`, formData, {
      headers: header,
      responseType: 'blob'
    })
      .pipe(map(data => {
        return data;
      })
      );
  }

  getCartAllRequirements(shipId: number) {
    return this.http.get<any>(`${environment.apiUrl}/v1/cart/all_requirements/${shipId}`)
      .pipe(map(dataVal => {
        return dataVal;
      })
      );
  }

  createCartRequirements(shipId, payload) {
    return this.http.post(`${environment.apiUrl}/v1/cart/all_requirements/${shipId}`, payload);
  }

}
