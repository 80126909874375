import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from './authentication/user.modal';
import { AuthenticationService } from './authentication/services/authentication.service';
import { SwUpdate } from '@angular/service-worker';
import { ConnectionService } from 'ng-connection-service';
import { interval, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from './authentication/services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {
  title = 'shipskart';
  currentUser: User;
  isConnected: boolean;
  status: string;
  loading = false;
  // tslint:disable-next-line:ban-types
  public lottieConfig: Object;
  private subscription: Subscription;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public updates: SwUpdate,
    private connectionService: ConnectionService,
    private tostr: ToastrService,
    private loadingService: LoadingService,
    private changeDedectionRef: ChangeDetectorRef
  ) {
    this.lottieConfig = {
      path: '/assets/images/loader.json',
      renderer: 'svg',
      autoplay: true,
      loop: true
    };
    if (updates.isEnabled) {
      interval(6 * 60 * 60).subscribe(() => updates.checkForUpdate()
        .then(() => console.log('[APP] Checking to new version')));
    }
    this.authenticationService.currentUser.subscribe(
      x => {
        this.currentUser = x;
      }
    );
    // check connection
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.tostr.clear();
        this.tostr.success('Reloading the page', 'You are Online');
        // window.location.reload();
      } else {
        this.tostr.clear();
        this.tostr.error('No Internet Connection', 'You are offline', {
          closeButton: true,
          disableTimeOut: true,
        });
      }
    });
  }

  ngOnInit() {
    this.listenToLoading();
  }

  public checkForUpdates(): void {

    this.updates.available.subscribe(event => this.promptUser());
  }

  private promptUser(): void {
    console.log('[APP] updating to new version');
    this.updates.activateUpdate().then(() => document.location.reload());
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  // .pipe(delay(0))
  listenToLoading(): void {
    this.loadingService.loadingSub
      .subscribe((loading) => {
        this.loading = loading;
      });
  }

  ngAfterContentChecked(): void {
    this.changeDedectionRef.detectChanges();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}





