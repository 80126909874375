<div class="card new-card expenditure">
    <h5 class="card-header">{{ chartTitle }}
        <span class="loading-spinner small" *ngIf="loaderView"></span>
        <span [class.download-option]="loaderView" class="dropdown dropleft" *ngIf="role != userRoles.ROLE_CAPTAIN">
            <a class="more-btn" data-toggle="dropdown" aria-haspopup="true"
               aria-expanded="false"><span class="more-dots"></span></a>
        <div class="dropdown-menu float-right" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" (click)="getCategoryExpenditure(1, 'xlsx', 1,filterForm)">Download as Excel</a>
            <a class="dropdown-item" (click)="getCategoryExpenditure(1, 'pdf', 1,filterForm)">Download as PDF</a>
        </div>
        </span>
    </h5>
    <div class="card-body">
        <div class="row">
            <div class="col-md-12 bottom-space-mr" *ngIf="displayFilter">
                <form class="border-bottom-line" [formGroup]="filterForm" (submit)="getReport()">
                    <div class="row">
                        <div class="form-group custom-form-group cross-icon col-md-2 col-sm-6" [class.error]="isDateRangeInvalid()">
                            <label for="">Start Date</label>
                            <p-calendar appCalendarUiCorrection class="date-pick" formControlName="from_date" placeholder="From Date" dateFormat="dd/mm/yy" [maxDate]="etd" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2030">
                            </p-calendar>
                            <button *ngIf="filterForm.get('from_date').value != null" class="close" (click)="resetFromDate()">&#10006;</button>
                        </div>
                        <div class="form-group custom-form-group cross-icon col-md-2 col-sm-6" [class.error]="isDateRangeInvalid()">
                            <label for="">End Date</label>
                            <p-calendar appCalendarUiCorrection class="date-pick" formControlName="to_date" placeholder="To Date" dateFormat="dd/mm/yy" [maxDate]="etd" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2030">
                            </p-calendar>
                            <button *ngIf="filterForm.get('to_date').value != null" class="close" (click)="resetToDate()">&#10006;</button>
                        </div>
                        <!-- <ng-container *ngIf="toggleMore"> -->
                        <div class="form-group custom-form-group col-md-5 col-sm-6" *ngIf="role != userRoles.ROLE_CAPTAIN">
                            <label for="">Category</label>
                            <ng-select formControlName="category_id" [items]="categoryList" bindLabel="name" bindValue="id" [multiple]="true" placeholder="Select Category" class="hide-cross-btn form-control new-dropdown" [closeOnSelect]="false" [clearOnBackspace]="true" [clearSearchOnAdd]="true"
                                [loading]="loadingCategories">
                            </ng-select>
                        </div>
                        <div class="form-group custom-form-group col-md-3 col-sm-6" *ngIf="role == userRoles.ROLE_ADMIN">
                            <label for="">Select Company</label>
                            <ng-select [items]="companyList" bindLabel="name" bindValue="id" placeholder="Select Company" class="form-control new-dropdown" (change)="changeCompany($event)" [loading]="loadingCompanies">
                                <ng-template ng-option-tmp let-item="item">
                                    {{item.name}} | <b> {{getCompanyType(item.type)}}</b>
                                </ng-template>
                            </ng-select>
                        </div>
                        <div class="form-group custom-form-group col-md-2 col-sm-6" *ngIf="role != userRoles.ROLE_CAPTAIN">
                            <label for="">Select Ship</label>
                            <ng-select formControlName="ship_id" [items]="shipList" bindLabel="name" bindValue="id" placeholder="Select Ship" class="form-control new-dropdown" [loading]="loadingShips">
                            </ng-select>
                        </div>
                        <div class="form-group custom-form-group col-md-2 col-sm-6" *ngIf="role != userRoles.ROLE_CAPTAIN">
                            <label for="">Select Port</label>
                            <ng-select formControlName="port_id" [items]="portList" bindLabel="name" bindValue="id" placeholder="Select Port" class="form-control new-dropdown" [loading]="loadingPorts">
                            </ng-select>
                        </div>
                        <!-- </ng-container> -->
                        <div class="form-group custom-form-group col-md-2 col-sm-6" *ngIf="role == userRoles.ROLE_CAPTAIN">
                            <label for="">Report Type</label>
                            <ng-select formControlName="reportType" [items]="reportType" bindLabel="title" bindValue="value" (change)="changeStatusValues($event);" placeholder="Select Type" class="form-control new-dropdown">
                            </ng-select>
                        </div>
                        <div class="form-group custom-form-group col-md-2 col-sm-6" *ngIf="role == userRoles.ROLE_CAPTAIN">
                            <label for="">Status</label>
                            <ng-select formControlName="status" [items]="isOrder ? orderStatus : requisitionStatus" bindLabel="title" bindValue="value" placeholder="Select Status" class="form-control new-dropdown">
                            </ng-select>
                        </div>
                        <div class="form-group custom-form-group col-md-2 col-sm-6">
                            <button class="filter-btn" type="submit">Apply Filter</button>
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="form-group custom-form-group col-md-4" *ngIf="role != userRoles.ROLE_CAPTAIN">
                            <label for="">Category</label>
                            <ng-select formControlName="category_id" [items]="categoryList" bindLabel="name" bindValue="id" [multiple]="true" placeholder="Select Category" class="hide-cross-btn form-control new-dropdown" [closeOnSelect]="false" [clearOnBackspace]="true" [clearSearchOnAdd]="true"
                                [loading]="loadingCategories">
                            </ng-select>
                        </div> -->
                        <!-- <div class="form-group custom-form-group col-md-2">
                            <button class="filter-btn" type="submit">Apply Filter</button>
                        </div> -->
                    </div>
                    <!-- <div class="ml-2 small" *ngIf="!toggleMore && role != userRoles.ROLE_CAPTAIN">
                        <a href="javascript:;" class="text-decoration-none" (click)="toggleMore=true">Show more filters</a>
                    </div> -->

                </form>

            </div>
            <div class="col-md-12" *ngIf="loadingCategoryExpenditure">
                <div class="loading">Loading...</div>
            </div>
            <div class="col-md-6 bottom-space-mr" *ngIf="!noRecordsFound">
                <h5 class="text-left bmargin10" *ngIf="categoryExpenditureData.length > 0">
                    {{countType}} - Graphical Representation
                </h5>
                <app-piechart class="mb-3" *ngIf="categoryExpenditureData.length > 0" [graphData]="categoryExpenditureData" legendPosition="right"></app-piechart>
                <!-- (chartClicked)="categoryDataClicked($event)" -->
            </div>
            <!-- <div class="col-md-6 mb-3 d-none">
                <div class="loading" *ngIf="loadingSubCategoryExpenditure">Loading...</div>
                <ng-container *ngIf="sub_category_expenditure_data.length > 0">
                    <h5 class="text-left pad-left">
                        <ng-container *ngIf="category"><span [ngStyle]="{'color': colour}">{{category}}</span></ng-container>
                        {{ subChartTitle }}
                    </h5>
                    <app-linechart *ngIf="sub_category_expenditure_data" [graph_data]="sub_category_expenditure_data" type="horizontalBar" [display_legend]="false"></app-linechart>
                </ng-container>
            </div> -->
            <div class="col-md-6 mb-3 tmargin10" *ngIf="categoryExpenditureData.length > 0 && !noRecordsFound">
                <table class="table table-bordered category-table">
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th class="text-center">{{countType == 'Orders' ? 'Order' : 'Requisition' }} Count</th>
                            <th class="text-right" *ngIf="role != userRoles.ROLE_CAPTAIN">Order Value ({{ currencyCode }})</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of categoryExpenditureData" [ngStyle]="{'background-color': data.label == category ? '#ebebeb': 'white'}">
                            <td>{{data.label}}
                                <!-- (click)="role != userRoles.ROLE_CAPTAIN ? getSubCategoryExpenditure(data.id, filterForm) : getSubCategoryWiseOrderReport(data.id, ship_Id, filterForm)" -->
                            </td>
                            <td class="text-center">{{data.value}}</td>
                            <td class="text-right" *ngIf="role != userRoles.ROLE_CAPTAIN">
                                {{data.amount | number:'1.2-2' }}
                                <ng-container *ngIf="(data.delivery_charge + data.launch_charge) > 0 && displayDeliveryCharge">
                                    <br>
                                    <span style="color: #7e7d82;" class="small">
                + {{ data.delivery_charge + data.launch_charge | number:'1.2-2' }}
                <i class="fa fa-info-circle info-icon" aria-hidden="true"></i><span class="info-items">
                  Launch charge ({{ data.launch_charge | number:'1.2-2'}}) + Delivery charge ({{ data.delivery_charge | number:'1.2-2'}})
                </span></span>
                                </ng-container>
                            </td>
                        </tr>
                        <tr>
                            <td><b>Total</b></td>
                            <td class="text-center"><b>{{ categoryCount }}</b></td>
                            <td class="text-right" *ngIf="role != userRoles.ROLE_CAPTAIN">
                                <b>
                {{ categoryTotal  | number:'1.2-2' }}
                <ng-container *ngIf="totalDeliveryCharge + totalLaunchCharge && displayDeliveryCharge">
                  <br>
                  <span style="color: #7e7d82;" class="small">
                + {{ totalDeliveryCharge + totalLaunchCharge | number:'1.2-2' }}
                  <i class="fa fa-info-circle info-icon" aria-hidden="true"></i><span class="info-items">Launch charge ({{ totalLaunchCharge | number:'1.2-2'}}) + Delivery charge ({{ totalDeliveryCharge | number:'1.2-2'}})</span></span>
                </ng-container>
              </b>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-12 tmargin10 bottom-space-mr" *ngIf="categoryExpenditureData.length > 0 && noRecordsFound">
                <div class="records-not-found">
                    <img class="bmargin40" src="/assets/images/product_request_image.svg" alt="">
                    <p>No Records Found!</p>
                </div>
            </div>
            <!-- <div *ngIf="sub_category_expenditure_data.length > 0" class="col-md-6 mb-3 d-none">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th width="250px">Subcategory</th>
                            <th class="text-left">Order Count</th>
                            <th class="text-right" *ngIf="role != userRoles.ROLE_CAPTAIN">Order Value ({{ currencyCode }})</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of sub_category_expenditure_data">
                            <td>{{data.label}}</td>
                            <td class="text-left">{{data.value}}</td>
                            <td class="text-right" *ngIf="role != userRoles.ROLE_CAPTAIN">{{data.amount | number:'1.2-2' }}</td>
                        </tr>
                        <tr>
                            <td><b>Total</b></td>
                            <td class="text-left"><b>{{ sub_category_count }}</b></td>
                            <td class="text-right" *ngIf="role != userRoles.ROLE_CAPTAIN"><b>{{ sub_category_total | number:'1.2-2' }}</b></td>
                        </tr>
                    </tbody>
                </table>
            </div> -->
        </div>
    </div>
</div>
