<div>
  <a class="back-btn back detail inline-bloc" href="javascript:;" (click)="goPreviousUrl()"><span class="fa fa-angle-left"></span>Back to List</a>
  <div class="req-heading">
      <h2 class="info-font bmargin10">Price Comparison</h2>
  </div>
  <div class="price-comparison-wrap">
    <label class="compare-label" for="">Select Product to Compare<span class="mendatory">*</span></label>
    <div class="product-wrapper">
      <div class="product-search-box">
        <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
          <!-- <div class="form-group">
            <ng-select [items]="subCategoryList" formControlName="subcategory_id" (change)="changeSubCategory($event)" bindLabel="name" bindValue="id" [multiple]="false" placeholder="Select Subcategory" class="subcategory hide-cross-btn form-control form-control-sm" [clearSearchOnAdd]="true">
            </ng-select>
          </div> -->
          <div class="search-categories search-field">
              <div class="searchbox" [class.showCross]="selectedAutocomplete">
                  <div [ngClass]="{error : submitted && form.search.errors}">
                      <div class="ng-autocomplete">
                          <p-autoComplete class="long-search" formControlName="search" placeholder="Search Product from Catalogue" [suggestions]="suggestion" (keyup)="showCrossBtn($event)" (completeMethod)="search($event)" (keyup.enter)="searchProduct()" (onSelect)="doOnSelect($event)">
                              <ng-template let-suggestion let-i="index" pTemplate="item" styleClass="wid100">
                                  <div class="search-product ui-helper-clearfix" style="border-bottom:1px solid #D5D5D5;width: 500px;">
                                      <p>{{suggestion.name | titlecase}}</p>
                                      <p class="code">IMPA code: {{suggestion.impa_code || 'N/A'}}</p>
                                      <p class="code">Pack Type: {{suggestion.pack_type || 'N/A'}}</p>
                                      <p class="code">Unit: <span> {{suggestion.unit ||'N/A'}}</span></p>
                                  </div>
                                  <div *ngIf="suggestion.length == i-1">
                                      <button>View All</button>
                                  </div>
                              </ng-template>
                          </p-autoComplete>
                      </div>
                  </div>
                  <div class="search-btns">
                      <button class="find-btn cross-btn cat clear" [class.showCross]="selectedAutocomplete" (click)="resetForm(2)" type="button">&times;</button>
                      <button class="find-btn cat btn btn-primary submit-btn" [disabled]="!selectedAutocomplete"  type="button" (click)="searchProduct()">
                        Search
                        <!-- <span class="fa fa-search"></span> -->
                      </button>
                  </div>
              </div>
          </div>
          <div class="form-group mt-3">
            <ng-select [items]="allPort" formControlName="portId" bindLabel="name" bindValue="id" [multiple]="false" placeholder="Select Port" class="subcategory form-control form-control-sm" [clearSearchOnAdd]="true">
            </ng-select>
          </div>
          <div class="text-left">
            <button class="btn btn-primary" type="submit" (click)="openModel(showProductDetails)" [disabled]="!searchForm.valid">Show Prices</button>
          </div>
        </form>
      </div>
      <div class="price product-details">
        <h4 class="head" *ngIf="product && selectedAutocomplete">Product Details</h4>
        <div class="detail-content" *ngIf="product && selectedAutocomplete ">
          <div class="img-box no-border w-auto">
            <img class="product-img" [src]="(product && product.images && product.images.length != 0 && product.images[0].icon_path) ? product.images[0].icon_path : '/assets/images/placeholder_image.png'" (error)="onImgError($event)" />
          </div>
          <div class="content-box">
            <p>Product Name<span class="p-name">{{product && product.name ? product.name : 'N/A'}}</span></p>
            <p>Shipskart Code: <span>{{product && product.product_code ? product.product_code : 'N/A'}}</span></p>
            <p>IMPA Code:<span> {{product && product.impa_code ? product.impa_code : 'N/A'}}</span></p>
            <p>Pack Type: <span> {{product && product.pack_type ? product.pack_type : 'N/A'}}</span></p>
            <p>Unit: <span> {{product && product.unit.name ? product.unit.name : 'N/A'}}</span></p>

          </div>
        </div>
        <p class="no-product-selected" *ngIf="product == null || !selectedAutocomplete">No Product Selected</p>
      </div>
    </div>
    <div class="vendor-comparison-wrap">
      <div class="comparison-inner" *ngFor="let shipForm of shipForms;let i=index">
          <div class="card">
              <h6 class="card-header"><span *ngIf="this.role !== this.userRoles.ROLE_COMPANY_ADMIN"> Vendor- {{i + 1}}</span> <span *ngIf="this.role == this.userRoles.ROLE_COMPANY_ADMIN">Port- {{i+1}}</span>
                  <!-- <span class="loading-spinner small" *ngIf="shipForm.loadingShip"></span> -->
                  <span class="dropdown dropleft" *ngIf="shipForms.length > 2" [class.download-option]="shipForm.loading">
           <a class="more-btn" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false"><span class="more-dots"></span></a>
                  <div class="dropdown-menu float-right" aria-labelledby="dropdownMenuButton">
                      <!-- <a class="dropdown-item" (click)="getReport(shipForm, 1, 'xlsx');">Download as Excel</a>
                      <a class="dropdown-item" (click)="getReport(shipForm, 1, 'pdf');">Download as PDF</a>
                       -->
                       <a *ngIf="shipForms.length > 2" class="text-danger dropdown-item" (click)="shipForms.splice(shipForms.indexOf(shipForm),1)">Remove from list</a>
                  </div>
                  </span>
              </h6>
              <div class="card-body">
                  <form class="row" [formGroup]="shipForm.form" (submit)="getReport(shipForm)">
                      <div class="form-group custom-form-group mb-2 col-md-6" [class.error]="shipForm.submitted && shipForm.form.controls.vendor_id.errors" >
                        <ng-select formControlName="vendor_id" [items]="companys" bindLabel="name" bindValue="id" (change)="vendorSelected(shipForm, $event)" [multiple]="false" placeholder="Select Vendor" [readonly]="this.role !== this.userRoles.ROLE_ADMIN && this.role !== this.userRoles.ROLE_SUBADMIN" class="hide-cross-btn form-control form-control-sm" [clearSearchOnAdd]="true">
                        </ng-select>
                        <div class="errorbox" *ngIf="shipForm.submitted && shipForm.form.controls.vendor_id.errors">Please select vendor</div>
                        <p *ngIf="role===userRoles.ROLE_ADMIN || role===userRoles.ROLE_SUBADMIN">Default Currency: <span class="text-danger weight600"> {{shipForm.form.get('currency').value?shipForm.form.get('currency').value:''}}</span></p>
                        <p *ngIf="role===userRoles.ROLE_COMPANY_ADMIN">Default Currency: <span class="text-danger weight600"> {{myForm.get('currency').value?myForm.get('currency').value:''}}</span></p>
                      </div>
                      <div class="form-group custom-form-group mb-2 col-md-6" [class.error]="shipForm.submitted && shipForm.form.controls.port_id.errors">
                          <ng-select formControlName="port_id" [items]="shipForm.portList && shipForm.portList.length>0 ? shipForm.portList : null" bindLabel="port" bindValue="port_id" [multiple]="false" placeholder="Select Port" class="hide-cross-btn form-control form-control-sm" [clearSearchOnAdd]="true">
                          </ng-select>
                          <div class="errorbox" *ngIf="shipForm.submitted && shipForm.form.controls.port_id.errors">Please select Port</div>
                      </div>
                      <div class="form-group custom-form-group mb-2 col-md-6" [class.error]="isDateRangeInvalid(shipForm.form)">
                          <span><img src="/assets/images/calendar-icon-light.svg" alt=""></span>
                          <p-calendar appCalendarUiCorrection class="expiry-date" formControlName="from_date" placeholder="From Date" dateFormat="yy-mm-dd" [maxDate]="etd" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2030" #cal>
                          </p-calendar>
                          <button *ngIf="shipForm.form.get('from_date').value != null" class="close shift comparison" (click)="resetFromDate(shipForm.form)">&#10006;</button>
                      </div>
                      <div class="form-group custom-form-group mb-2 col-md-6" [class.error]="isDateRangeInvalid(shipForm.form)">
                          <span><img src="/assets/images/calendar-icon-light.svg" alt=""></span>
                          <p-calendar appCalendarUiCorrection class="expiry-date" formControlName="to_date" placeholder="To Date" dateFormat="yy-mm-dd" [maxDate]="etd" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:2030" #cal2>
                          </p-calendar>
                          <button *ngIf="shipForm.form.get('to_date').value != null" class="close shift comparison" (click)="resetToDate(shipForm.form)">&#10006;</button>
                      </div>
                      <div class="col-md-3 tmargin10">
                          <button class="btn btn-primary submit-btn" [disabled]="!selectedAutocomplete" type="submit">Submit</button>
                      </div>
                  </form>
                  <div class="loading" *ngIf="shipForm.loading">Loading...</div>
                  <ng-container *ngIf="shipForm.loading1">
                      <div class="data-table products scrollbar" *ngIf="shipForm?.priceHistoryData">
                        <div class="current-price-box">
                          <div class="current-price" *ngIf="shipForm?.productPricesInr?.length > 0">
                            <p>Current Available Price</p>
                            <h3>{{shipForm?.productPricesInr[0]?.currency}} {{shipForm?.productPricesInr[0]?.price == null ? 'N/A' : ((shipForm?.productPricesInr[0]?.tax/100 * shipForm?.productPricesInr[0]?.price) + shipForm?.productPricesInr[0]?.price) | number:'1.2-2'}}</h3>
                          </div>
                          <div class="form-group text-left p-1 price" >
                            <label>Select Currency</label>
                            <ng-select [items]="currencyDropdown" [(ngModel)]="shipForm.currency" (change)="currencySwitch(shipForm, $event)" bindLabel="name" bindValue="id" [multiple]="false" [closeOnSelect]="true" placeholder="Select Currency" class="form-control" clearAllText="Clear" [clearable]="false">
                            </ng-select>
                          </div>
                        </div>
                       <!--  <div *ngIf="shipForm.productPricesInr.length > 0">
                          <linechart [months]="shipForm.arr" [price]="shipForm.price"></linechart>
                        </div> -->
                        <div class="scroll-table">
                          <table class="table full-table req-table price update history">
                            <thead>
                                <tr>
                                    <th width="100">Date</th>
                                    <th width="120">Product Price</th>
                                    <th width="80">Tax</th>
                                    <th width="90" *ngIf="role!==userRoles.ROLE_COMPANY_ADMIN">Margin</th>
                                    <th width="120">Price Inclusive Tax</th>
                                </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let row of shipForm.productPricesInr">
                                <td>{{row.created_at | date:'dd MMM yyyy'}}</td>
                                <td>{{row.currency}} {{row.price == null ? '–' : row.price | number:'1.2-3'}}</td>
                                <td>{{row.tax+'%' || '0%'}}</td>
                                <th *ngIf="role!==userRoles.ROLE_COMPANY_ADMIN">{{row.margin+'%'|| '0%'}}</th>
                                <td>{{row.currency}} {{ row.price == null ? '–' : ((row.tax/100 * row.price) + row.price) | number:'1.2-2'}}</td>
                              </tr>
                              <tr class="no-data" *ngIf="shipForm.productPricesInr && shipForm.productPricesInr.length === 0">
                                <td colspan="5">No Prices Available!</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="data-table products scrollbar no-data" *ngIf="!shipForm.priceHistoryData">
                        No data found
                      </div>
                  </ng-container>
          </div>
        </div>
      </div>
    </div>
    <ng-template #showProductDetails>
      <div class="vendor-popup">
      <h4 class="sure-font text-center font22 weight600 tmargin10 bmargin20">Product Details
        <a class="popup-close"
          (click)="modelRef.hide();"
          data-dismiss="modal">&#10006;</a>
      </h4>
      <div class="tabular-detail data-table batch">
        <!-- <table class="table full-table req-table price update new batch-info"> -->
          <!-- <div class="scroll-table"> -->
        <table class="table full-table req-table price update new batch-info">
          <thead>
            <tr>
              <th>Vendor Name</th>
              <th>Currency</th>
              <th>Price</th>
              <th>Tax</th>
              <th>Exp. Date</th>
              <th>Remark</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of selectedProductDetails">
              <td>{{item.vendor_name | titlecase}}</td>
              <td>{{item.currency}}</td>
              <td>{{item.price}}</td>
              <td>{{item.tax}}</td>
              <td>{{item.expiry_date}}</td>
              <td>{{item.remark}}</td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
    <!-- </div> -->
    </ng-template>
    <div class="row">
      <div class="col-md-12">
        <button class="btn btn-primary submit-btn add" (click)="addShipToCompare()"><i class="fa fa-plus-circle plus-sign req-btn"
          aria-hidden="true"></i> <span *ngIf="this.role !== this.userRoles.ROLE_COMPANY_ADMIN"> Add Vendor</span> <span *ngIf="this.role == this.userRoles.ROLE_COMPANY_ADMIN">  Add Port</span></button>
      </div>
    </div>
  </div>
</div>
