import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { DashboardService } from "@app/dashboard/dashboard.service";
import { SharedService } from "@app/shared/service/shared.service";
import { UserRoles } from "@app/core/enums/user-roles";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";
import { DynamicFormModel } from "./feedbackform.interface";

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedbackform.component.html',
  styleUrls: ['./feedbackform.component.css']
})
export class FeedbackFormComponent implements OnInit {
  @Output() closeForm: EventEmitter<void> = new EventEmitter<void>();
  modalRef: BsModalRef;
  @ViewChild('feedBackformModel', { static: false }) feedBackformModel: TemplateRef<any>;
  formId: number;
  dynamicFormData: DynamicFormModel[] = [];
  dynamicForm: FormGroup;
  orderId: any;
  userId: number;
  role: any;
  userRoles = UserRoles;
  orderDetails: any;
  feedbackFormData = [];
  @Output() submitted: EventEmitter<any> = new EventEmitter();
  notes: any;
  isGuest: boolean = false;
  guestId: any;
  hoveredStar = [];
  selectedFile: File;
  hideFile: boolean = true;
  isDisabled: boolean;
  constructor(
    private fb: FormBuilder,
    private bsModalService: BsModalService,
    private dashboardService: DashboardService,
    private sharedService: SharedService,
    private toaster: ToastrService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.initializeFeedbackForm();
  }

  onFileChange(event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      this.selectedFile = inputElement.files[0];
    }
  }

  initializeFeedbackForm(): void {
    const formId = this.activatedRoute.snapshot.queryParamMap.get('form_id');
    const oderId = this.activatedRoute.snapshot.queryParamMap.get('order_id')
    if (formId && !oderId) {
      this.formId = parseInt(formId);
      this.isGuest = true;
    }
    this.getFormdataElement(true);
    if (!this.formId) {
      this.role = this.sharedService.getUserRole();
    }
  }

  private buildDynamicForm(data): FormGroup {
    const formGroupConfig = {};
    if (data.length > 0) {
      data.forEach(control => {
        // Determine if the control is required based on the "required" key
        const isRequired = control.required || false;

        // Add the control to the form with required validation if needed
        formGroupConfig[control.id] = [
          control.value || null,
          isRequired ? Validators.required : null
        ];
      });
    }
    return this.fb.group(formGroupConfig);
  }

  openModal(formId?, orderId?, userId?, guestId?) {
    this.formId = formId || 74;
    this.orderId = orderId;
    this.userId = userId;
    this.guestId = guestId;
    if (!this.isGuest) {
      this.getFormdataElement(true);
    } else {
      this.modalRef = this.bsModalService.show(this.feedBackformModel, { backdrop: 'static', keyboard: false, class: 'gray modal-md' });
    }
    if (this.userId || guestId) {
      this.feedbackFormData.length = 0;
      userId ? this.getFeedbackSubmission() : this.getFeedbackSubmission(guestId)
    }
  }

  generateFormGroup() {
    let formData = new FormData();
    if (this.selectedFile) {
      formData.append('file', this.selectedFile, this.selectedFile.name);
    }
    let data = { json_data: this.dynamicForm.value };
    formData.append('json_data', JSON.stringify(data));
    if (!this.isDisabled) {
      this.dashboardService.submissionForm(this.formId, this.userId, this.orderId, formData, this.isGuest).subscribe(res => {
        if (res) {
          this.submitted.emit();
          this.toaster.success('Form Submitted Successfully');
        }
      });
    }
  }

  fillStars(star: number, controlKey, index: number) {
    if (controlKey && !Number.isNaN(star)) {
      this.dynamicForm.patchValue({
        [controlKey]: star.toString()
      });
    }
  }

  async getFeedbackSubmission(guestId?) {
    this.hideFile = true;
    let guest = guestId || 0;
    const orderResponse = await this.dashboardService.fetchSubmissionData(this.formId, this.userId, this.orderId, guest);
    if (orderResponse) {
      if (orderResponse.data.length > 0) {
        this.orderDetails = await orderResponse.order_detail;
        this.feedbackFormData = orderResponse.data;
        if (this.feedbackFormData && this.feedbackFormData.length > 0) {
          this.hideFile = false;
          for (let [index, feedback] of this.feedbackFormData.entries()) {
            if (feedback.value) {
              this.dynamicForm.patchValue({
                [feedback.feedback_form_element_id]: feedback.value.toString()
              });
              this.dynamicForm.get(`${feedback.feedback_form_element_id}`).disable();
              this.isDisabled = this.dynamicForm.get(`${feedback.feedback_form_element_id}`).disabled;
              if (feedback.element.type === "rating") {
                this.fillStars(parseInt(feedback.value), feedback.feedback_form_element_id.toString(), index);
                this.dynamicFormData = this.dynamicFormData.map(obj => {
                  if (obj.id === feedback.feedback_form_element_id) {
                    obj.value = feedback.value;
                  }
                  return obj;
                });
              }
            }
          }
        }
      } else {
        this.orderDetails = orderResponse.order_detail;
      }
      this.modalRef = this.bsModalService.show(this.feedBackformModel, { backdrop: 'static', keyboard: false, class: 'gray modal-md' });
    }
  }

  getFormdataElement(reInitialize: boolean) {
    if (this.formId) {
      this.dashboardService.getFeedbackForms(this.formId, null, this.isGuest).subscribe(response => {
        this.notes = response.notes;
        this.dynamicFormData = response.form_elements;
        //this.dynamicFormData = filterForm && filterForm.length > 0 && filterForm[0].form_elements && filterForm[0].form_elements.map(element => element);

        if (this.dynamicFormData && this.dynamicFormData.length > 0) {
          this.dynamicFormData = this.dynamicFormData.map(formdata => {
            if (formdata.type == "rating") {
              formdata['value'] = 0;
            }
            return formdata;
          });

          this.dynamicForm = this.buildDynamicForm(this.dynamicFormData);
        }

        if (this.isGuest) {
          this.openModal(this.formId);
        }
      });
    }
  }

  setRating(star: number, controlKey, index: number,): void {
    if (this.dynamicForm.get(controlKey.toString()).value !== null) {
      this.dynamicForm.get(controlKey.toString()).setValue(star.toString());
      if (!this.isDisabled) {
        this.dynamicFormData[index]['value'] = star;
      }
      return;
    } else {
      this.dynamicFormData[index]['value'] = star;
    }


    if (controlKey && !Number.isNaN(star)) {
      this.dynamicForm.get(controlKey.toString()).setValue(star.toString());
    }
  }

  onStarHover(star: number, controlKey, index: number): void {
    this.hoveredStar[index] = star;
  }

  onStarLeave(star: number, controlKey, index: number): void {
    console.log('Mouse left the stars');
    this.hoveredStar[index] = null;
  }

  closeModal() {
    this.modalRef.hide();
    this.closeForm.emit();
  }
}
