import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // this.loadingService.setLoading(true, request.url);
    return next.handle(request).pipe(
      catchError(err => {
        // this.loadingService.setLoading(false, request.url);
        let error = [];
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.deleteLocalData();
          window.location.href = '/login';
        }
        if (err.status === 403) {
          // auto logout if 401 response returned from api
          this.authenticationService.deleteLocalData();
          window.location.href = '/login';
        }
        if (err.status === 422) {
          Object.values(err.error.error).forEach(ele => {
            error.push(ele);
          });
          return throwError(error);
        }
        if (err.status === 503) {
          window.location.href = '/website-maintenance';
        }
        if (err.status === 409) {
          if (err.error.message) {
            error.push(err.error.message.error);
          } else if (err.error.error) {
            error.push(err.error.error.message);
          }
          return throwError(error);
        }
        error = err.error || err.message || 'Something went wrong';
        return throwError(error);
      }));
    // .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
    //   if (evt instanceof HttpResponse) {
    //     //this.loadingService.setLoading(false, request.url);
    //   }
    //   return evt;
    // }));
  }
}
