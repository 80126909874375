import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from '@app/authentication/services/loading.service';
import { TechnicalDetails } from '@app/modules/kyc/models/kyc.modal';
import { KycService } from '@app/modules/kyc/services/kyc.service';
import { AlertService } from '@app/shared/service/alert.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-technical-capability',
  templateUrl: './technical-capability.component.html',
  styleUrls: ['./technical-capability.component.css']
})
export class TechnicalCapabilityComponent implements OnInit {
  @Input() vendorId;
  @Input() vendorEmail;
  @Input() company;
  fileName: string;
  @Output() formFilled: EventEmitter<any> = new EventEmitter();
  @Output() updatePrev: EventEmitter<any> = new EventEmitter();
  @Output() updateNext: EventEmitter<any> = new EventEmitter();
  technicalDetailsForm: FormGroup;
  submitted: boolean;
  formData: FormData;
  technicalDetails: TechnicalDetails;
  inValidSignatureFileSize: boolean;
  docName = [];
  inValidDocumentFileSize = [];
  // selectDocuments = [];
  bankDocuments = [];
  digitalSignatureFile = '';
  requiredCheck = false;
  modalRef: BsModalRef;
  @ViewChild('kycInProcess', { static: false })
  kycInProcess: any;
  today = new Date();

  constructor(
    private formBuilder: FormBuilder,
    private kycService: KycService,
    private loadingService: LoadingService,
    private toastr: ToastrService,
    private bsModalService: BsModalService,
    private alertService: AlertService
  ) {
    this.formData = new FormData();
  }

  ngOnInit() {
    this.generateTechnicalDetailsForm();
    this.getTechnicalDetails(this.vendorId, this.vendorEmail);
  }

  generateTechnicalDetailsForm() {
    this.technicalDetailsForm = this.formBuilder.group({
      email: [''],
      representation: [''],
      name: ['', [Validators.required]],
      designation: ['', [Validators.required]],
      qa_certificates_detail: this.formBuilder.array([]),
    });
  }

  get qaCertificatesDetail(): FormArray {
    return this.technicalDetailsForm.get('qa_certificates_detail') as FormArray;
  }

  addQaCertificatesDetailItem(qaCert1 = null, document = null, ind): any {
    if ((this.technicalDetailsForm.get('qa_certificates_detail') as FormArray).length > 4) {
      this.toastr.error('Maximum 5 certificates are allowed', 'Can not add certificate');
      return false;
    } else {
      const qaCertificatesDetail = this.technicalDetailsForm.get('qa_certificates_detail') as FormArray;
      if (qaCertificatesDetail) {
        const abstractControl = this.formBuilder.group({});
        abstractControl.addControl('qa_cert_' + (ind + 1), new FormControl(qaCert1 ? qaCert1 : ''));
        if (document) {
          abstractControl.addControl('document', new FormControl(document ? document : ''));
        }
        qaCertificatesDetail.push(abstractControl);
      }
      // this.selectDocuments[ind] = false;
    }
  }

  removeQaCertificatesDetailItem() {
    (this.technicalDetailsForm.get('qa_certificates_detail') as FormArray).removeAt(this.qaCertificatesDetail.controls.length - 1);
    // this.docName.splice(this.qaCertificatesDetail.controls.length - 1, 1);
  }

  requiredCheckBox(event) {
    if (event.target.checked === true) {
      this.requiredCheck = true;
    } else {
      this.requiredCheck = false;
    }
  }

  goPrevious() {
    this.updatePrev.emit();
  }

  resetForm() {
    if (this.technicalDetails) {
      this.technicalDetailsForm.reset();
      const qaCertificatesDetail = this.technicalDetailsForm.get('qa_certificates_detail') as FormArray;
      qaCertificatesDetail.clear();
      this.docName = [];
      this.fileName = '';
      this.getTechnicalDetails(this.vendorId, this.vendorEmail, 1);
    } else {
      this.technicalDetailsForm.reset();
      this.requiredCheck = false;
      this.fileName = '';
      this.docName = [];
      this.formData = new FormData();
      this.technicalDetailsForm.patchValue({
        representation: ''
      });
    }
  }

  submitTechnicalDetails() {
    this.submitted = true;
    this.technicalDetailsForm.get('email').setValue(this.vendorEmail);
    if (!this.technicalDetailsForm.valid || !this.requiredCheck) {
      // || this.selectDocuments.includes(false)
      return false;
    } else {
      Object.keys(this.technicalDetailsForm.value).forEach(key => {
        this.formData.append(key, this.technicalDetailsForm.value[key]);
      });
      const FormValue = this.technicalDetailsForm.value;
      this.formData.append('vendor_id', this.vendorId && this.vendorId != '0' ? this.vendorId.toString() : '');
      this.formData.delete('qa_certificates_detail');
      if (FormValue.qa_certificates_detail != null) {
        this.formData.append('qa_certificates_detail', JSON.stringify(this.technicalDetailsForm.get('qa_certificates_detail').value));
      }
      this.loadingService.setLoading(true);
      this.kycService.technicalDetails(this.formData).subscribe(res => {
        this.loadingService.setLoading(false);
        this.toastr.success('Kyc form submitted successfully!');
        this.submitted = false;
        this.modalRef = this.bsModalService.show(this.kycInProcess, Object.assign({ backdrop: 'static', class: 'verify-modal' }));
        this.alertService.backModalClose(this.modalRef);
      }, error => {
        this.loadingService.setLoading(false);
        this.formData = new FormData();
        this.toastr.error('Somthing went wrong, Please try again!');
      });
    }
  }

  getTechnicalDetails(vendorId, vendorEmail, hideLoader = 0) {
    if (hideLoader === 0) {
      this.loadingService.setLoading(true);
    }
    const params = new HttpParams()
      .set('vendor_id', vendorId && vendorId != '0' ? vendorId.toString() : '')
      .set('email', vendorEmail ? vendorEmail : '');
    this.kycService.getTechnicalDetails(params).subscribe((res: TechnicalDetails) => {
      if (res) {
        this.loadingService.setLoading(false);
        this.technicalDetails = res;
        this.formFilled.emit(this.technicalDetails);
        this.technicalDetailsForm.patchValue(res);
        if (res.qa_certificates_detail && res.qa_certificates_detail.length > 0) {
          res.qa_certificates_detail.forEach((x, i) => {
            // tslint:disable-next-line:no-string-literal
            this.addQaCertificatesDetailItem(x['qa_cert_' + (i + 1)], x.document, i);
            if (x.document) {
              this.bankDocuments[i] = res.s3_url + x.document;
              // this.docName[i] = x.document;
              // this.selectDocuments[i] = true;
            }
          });
        }
        if (res.digital_signature) {
          this.digitalSignatureFile = res.s3_url + res.digital_signature;
          this.formData.set('digital_signature', res.digital_signature);
        }
        this.requiredCheck = true;
      }
    }, error => {
      this.loadingService.setLoading(false);
      this.addQaCertificatesDetailItem(null, null, 0);
    });
  }

  selectedSignatureFileName(files: FileList) {
    this.inValidSignatureFileSize = false;
    if (files.length > 0) {
      const filesize = files[0].size / 1024 / 1024;
      if (filesize > 3) {
        this.inValidSignatureFileSize = true;
        this.fileName = '';
      } else {
        this.fileName = files[0].name;
        this.formData.set('digital_signature', files[0], files[0].name);
      }
    }
  }

  selectedDocumentFileName(files: FileList, ind) {
    this.inValidDocumentFileSize[ind] = false;
    const key = 'qa_cert_' + (ind + 1);
    if (files.length > 0) {
      const filesize = files[0].size / 1024 / 1024;
      if (filesize > 3) {
        this.inValidDocumentFileSize[ind] = true;
        this.docName[ind] = '';
      } else {
        this.docName[ind] = files[0].name;
        this.formData.set(key, files[0], files[0].name);
        // this.selectDocuments[ind] = true;
      }
    }
  }
}
