import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { ProductService } from '@app/modules/product/services/product.service';
import { CategoryService } from '@app/modules/category/services/category.service';
import { Product } from '@app/modules/requisition/models/requisition';
import { ProductPopupComponent } from '@app/shared/components/product-popup/product-popup.component';
import { ToastrService } from 'ngx-toastr';
import { DecryptService } from '@app/shared/service/decrypt.service';

@Component({
  selector: 'app-catelog-product',
  templateUrl: './catelog-product.component.html',
  styleUrls: ['./catelog-product.component.css']
})
export class CatelogProductComponent implements OnInit {
  selectedCategory: number;
  pageLoading: boolean;
  noProduct: string;
  shipId: any;
  submitted: boolean;
  selectCategoryId: number;
  disableSuggestion = true;
  searchFormREQ: FormGroup;
  selectedAutocomplete: string = null;
  suggestion: Product[];
  @Input() OrderId: number;
  @Input() CategoryId: number;
  @Output() ProductAdded: EventEmitter<any> = new EventEmitter();
  @Output() HideModal: EventEmitter<any> = new EventEmitter();
  productList = [];
  page = 1;
  limit = 10;
  total: number;
  cart: any[] = [];
  subCategories: any;
  productDetail: any;
  @ViewChild('productPopup', { static: false })
  productPopup: ProductPopupComponent;
  constructor(
    private productService: ProductService,
    private categoryService: CategoryService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private decrypt: DecryptService
  ) { }

  ngOnInit() {
    this.getSubcategory();
    this.buildDropdownForm();
  }
  searchProduct() {
    this.submitted = true;
    if (this.searchFormREQ.valid) {
      this.selectedAutocomplete = this.searchFormREQ.value.search;
      this.searchFormREQ.get('search').setValue(this.selectedAutocomplete);
      this.getProduct(this.searchFormREQ.value.search);
      this.suggestion = [];
    }
  }
  get form() {
    return this.searchFormREQ.controls;
  }

  resetForm() {
    this.searchFormREQ.reset();
    this.searchProduct();
  }

  buildDropdownForm() {
    this.searchFormREQ = this.formBuilder.group({
      search: [''],
      category_id: [null]
    });
  }
  search(event) {
    this.productService
      .getProductSuggestion(0, event.query, this.selectedCategory)
      .subscribe(data => {
        const tempJson = JSON.parse(this.decrypt.decryptUsingAES256(data))
        tempJson[0]['data'].map(x => {
          x.title = x._source.title;
          x.product_code = x._source.product_code;
          x.impa_code = x._source.impa_code;
          x.pack_type = x._source.pack_type;
          x.brand_name = x._source.brand_name;
          x.unit = x._source.unit || (x._source.unit && x._source.unit.name) ? x._source.unit || x._source.unit.name : null;;
        });
        this.suggestion = tempJson[0]['data'];
      }, error => {
        this.suggestion = [];
      });
  }

  showCrossBtn(event) {
    this.selectedAutocomplete = event.target.value;
  }

  doOnSelect(event) {
    this.searchFormREQ.get('search').setValue(event.title);
    this.getProduct(event.title);
    this.suggestion = [];
  }
  setCategory($event) {
    this.selectedCategory = $event.id;
    this.disableSuggestion = false;
    this.searchFormREQ.reset();
    this.getProduct();
  }
  getProduct(search = null) {
    this.pageLoading = true;
    const page = this.page;
    const limit = this.limit;
    this.productService
      .getByCategoryId(this.selectedCategory, 0, page, limit, search,)
      .subscribe(
        data => {
          const tempJson = JSON.parse(this.decrypt.decryptUsingAES256(data));
          this.productList = tempJson[0]['data']['results'];
          this.total = tempJson[0]['data']['total'];

          this.noProduct = this.total === 0 ? 'No Product Found' : '';

          this.productList.map(product => {
            let qty = 0;
            const cart = this.cart.find(x => x.id == product.id);
            if (cart) {
              qty = cart.quantity;
            }
            product.quantity = qty;
            product.product_id = product.id;
            product.ship_id = this.shipId;
          });
          this.pageLoading = false;
        }, error => {
          this.pageLoading = false;
          this.noProduct = 'No Product Found';
        }
      );
  }
  updateCart() {
    throw new Error('Method not implemented.');
  }

  increaseVal(i) {
    i.quantity++;
    let variant;
    if (i.variants && i.variants.length > 0) {
      variant = i.variants.find(x => x.id == i.id && x.impa_code == i.impa_code);
      variant.quantity++;
    } else {
      variant = i;
    }
    variant.product_id = i.id;
    if (variant) {
      this.addItemToCart(variant);
    }
  }

  decreaseVal(i) {
    if (i.quantity > 0) {
      i.quantity--;

      if (i.quantity == 0) {
        this.remove(i);
        return 'deleted';
      }
      this.addItemToCart(i);
    }
  }

  SubmitItems() {
    if (this.cart && this.cart.length > 0) {
      this.ProductAdded.emit(this.cart);
    } else {
      this.toastr.error('Quantity should not be zero!', '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      return;
    }
  }

  addItemToCart(item) {
    if (item.quantity > 0) {
      const Index = this.cart.findIndex(x => x.id == item.id && x.impa_code == item.impa_code);
      if (Index >= 0) {
        this.cart.splice(Index, 1);
      }
      this.cart.push(item);
    }
  }
  remove(item) {
    const Index = this.cart.findIndex(x => x.id == item.id && x.impa_code == item.impa_code);
    if (Index >= 0) {
      this.cart.splice(Index, 1);
    }
  }

  getSubcategory() {
    this.categoryService.getByIdV2(this.CategoryId).subscribe(data => {
      this.subCategories = data.sub_categories;
    });
  }
  onPageChange($event) {
    this.page = $event;
    this.getProduct(this.selectedAutocomplete);
  }

  onPackChange(productId, variantProducts, currentProduct) {
    const variantProduct = variantProducts.find(x => x.id === parseFloat(productId));
    currentProduct.id = variantProduct.id;
    currentProduct.product_id = variantProduct.id;
    currentProduct.name = variantProduct.name;
    currentProduct.product_code = variantProduct.product_code;
    currentProduct.impa_code = variantProduct.impa_code;
    currentProduct.pack_type = variantProduct.pack_type;
    currentProduct.vendor = variantProduct.vendor;
    currentProduct.rfq_submitted = variantProduct.rfq_submitted;
    currentProduct.quantity = variantProduct.quantity ? variantProduct.quantity : 0;
  }

  openPopup(product) {
    this.productDetail = product;
    this.productPopup.openModal();
  }

  hideModal() {
    this.HideModal.emit();
  }
}
