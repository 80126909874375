import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CompanyType } from '@app/modules/company/models/company.enum';
import { UserRoles, Privilages } from '@app/core/enums/user-roles';
import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { CartService } from '@app/modules/cart/services/cart.service';
import { SharedService } from '@app/shared/service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { ChatService } from '@app/modules/help-chat/chat/chat.service';
import { DataTablePagination } from '@app/core/models/data-table';
import { OrderService } from '@app/modules/order/service/order.service';
import { Order } from '@app/modules/order/model/order.model';
import { RfqService } from '@app/modules/rfq/services/rfq.service';
import * as FileSaver from 'file-saver';
import { UserService } from '@app/modules/user/services/user.service';
import { User } from '@app/modules/user/models/user.modal';
import { Company } from '@app/modules/company/models/company.modal';
import { AlertService } from '@app/shared/service/alert.service';
import { MustMatch } from '@app/modules/user/components/user-list/user-list.component';
import { DashboardUpdatesComponent } from '@app/dashboard/dashboard-updates/dashboard-updates.component';
import { FormDataToJsonService } from '@app/shared/service/form-data-to-json.service';
import { DecryptService } from '@app/shared/service/decrypt.service';
import { S3Service } from '@app/shared/service/aws-bucket.service';
import { environment } from '@environment/environment';


enum RoutesUrl {
  CHAT = '/chat'
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild('dashboardComponent', { static: false }) dashboardComponent: DashboardUpdatesComponent;
  userDisplay: User;
  cartCount = 0;
  role: number;
  modalRef: BsModalRef;
  company: Company;
  userRoles = UserRoles;
  companyType = CompanyType;
  changePasswordFrom: FormGroup;
  submitted: boolean;
  //removeClass = false;
  showNotification = false;
  showProfile = false;
  notificationCount = 0;
  error;
  notifications: any = [];
  canPlaceOrder: boolean;
  canPlaceReq: boolean;
  isShowChatButton = true;

  reqCount = 0;
  provCount = 0;
  chatMessageCount = 0;
  chats = [];

  page = new DataTablePagination();
  pageNo;
  userRole;
  checkLoadMore = true;
  showLoadMore = true;
  loading = false;
  readAll = false;
  // downloadButton = true;
  imageUrl: any = '';
  placeholderImage = '/assets/images/captain-profile.svg';
  notSame = false;
  isTextFieldType: boolean;
  isTextFieldNewType: boolean;
  isTextFieldConfirmType: boolean;
  password;
  showHelp = false;
  inValidFileSize: boolean;
  isLoading: boolean;
  privilages = Privilages;
  subAdminPrivilagesIds: [number];
  subAdminPrivilageAssigned: boolean = false;
  env
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private cartService: CartService,
    private shareService: SharedService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private chatService: ChatService,
    private orderService: OrderService,
    private rfqService: RfqService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private alertService: AlertService,
    private formDataToJson: FormDataToJsonService,
    private descryptService: DecryptService,
    private awsService: S3Service
  ) {
    this.env = environment
  }

  ngOnInit() {
    this.cartService.getCartCount.next(0);
    this.cartService.getReqCartCount.next(0);
    this.cartService.getProvCartCount.next(0);
    this.role = this.shareService.getUserRole();
    this.getUserData();
    if (this.role == this.userRoles.ROLE_SUBADMIN) {
      this.subAdminPrivilagesIds = this.shareService.getSubAdminsPrivileges();
      if (this.subAdminPrivilagesIds && this.subAdminPrivilagesIds.length > 0)
        this.subAdminPrivilageAssigned = this.subAdminPrivilagesIds.includes(this.privilages.CREATE_REQUISITION);
    }
    this.changePasswordFrom = this.formBuilder.group({
      old_password: ['', [Validators.required, this.passwordLength()]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      new_password: ['', { validator: this.checkingPasswords }]
    },
      {
        validator: MustMatch('password', 'new_password')
      });
    // this.page.limit = 10;
    this.page.offset = 1;
    this.pageNo = this.page.offset;
    // this.pageNo = this.buildParms();
    this.getNotification();
    const currentUser = this.authenticationService.currentUserValue;
    this.company = this.shareService.getCompany();
    if (currentUser) {
      this.userName();
      const shipId = this.shareService.getShip();
      if (shipId) {
        this.cartService.getCartSummary(shipId).subscribe(res => {
          this.cartService.getReqCartCount.subscribe(resData => {
            this.reqCount = resData;
          });
          this.cartService.getProvCartCount.subscribe(resData => {
            this.provCount = resData;
          });
        });
      }
      this.userService.profileImage.subscribe(async (profileImage) => {
        //this.imageUrl = profileImage
        if (profileImage) {
          const imagePromises = await this.awsService.getS3Object(this.env.bucket, profileImage).then(res => res);
          this.imageUrl = imagePromises;
        }
      });
    }
    if (!this.imageUrl) {
      this.showPlaceholderImage();
    }
    this.getCartCount();
    if (this.router.url === RoutesUrl.CHAT) {
      this.isShowChatButton = false;
    }

    this.getMessageCount();
    // if (this.role === this.userRoles.ROLE_CAPTAIN || (this.role === this.userRoles.ROLE_COMPANY_ADMIN && this.company.type === 1) ||
    //   this.role === this.userRoles.ROLE_USER) {
    //   this.downloadButton = false;
    // } else {
    //   this.downloadButton = true;
    // }
  }
  getUserData() {
    this.canPlaceOrder = this.authService.currentUserValue.data.user_details.can_place_order;
    this.canPlaceReq = this.authService.currentUserValue.data.user_details.can_place_req;
  }

  showPlaceholderImage() {
    if (this.role === this.userRoles.ROLE_ADMIN) {
      this.placeholderImage = '/assets/images/admin-profile.svg';
    } else if (this.role === this.userRoles.ROLE_CAPTAIN) {
      this.placeholderImage = '/assets/images/captain-profile.svg';
    } else if (this.role === this.userRoles.ROLE_COMPANY_ADMIN && this.company.type === 2) {
      this.placeholderImage = '/assets/images/vendor-profile.svg';
    } else {
      this.placeholderImage = '/assets/images/manager-profile.svg';
    }
  }

  async downloadManual() {
    if (this.role === this.userRoles.ROLE_CAPTAIN) {
      const pdfUrl = 'manuals/ship-captain-manual.pdf';
      const image = await this.awsService.getS3Object(this.env.bucket, pdfUrl).then(res => res);
      const pdfName = 'Captain_Manual.pdf';
      window.open(image)
      //FileSaver.saveAs(pdfUrl, pdfName);
    } else if ((this.role === this.userRoles.ROLE_COMPANY_ADMIN && this.company.type === 1) || this.role === this.userRoles.ROLE_USER) {
      const pdfUrl = 'manuals/ship-manager-manual.pdf';
      const image = await this.awsService.getS3Object(this.env.bucket, pdfUrl).then(res => res);
      const pdfName = 'Manager_Manual.pdf';
      window.open(image)
      //FileSaver.saveAs(pdfUrl, pdfName);
    } else if (this.role === this.userRoles.ROLE_COMPANY_ADMIN && this.company.type === 2) {
      const pdfUrl = 'manuals/Revised_Vendors_User_Manual.pdf';
      const image = await this.awsService.getS3Object(this.env.bucket, pdfUrl).then(res => res);
      const pdfName = 'Vendor_Manual.pdf';
      window.open(image)
      //FileSaver.saveAs(pdfUrl, pdfName);
    } else {
      return;
    }
  }

  changePassModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign(
        {
          backdrop: 'static',
          class: 'gray modal-small change-pass'
        }
      )
    );
    this.alertService.backModalClose(this.modalRef);
    this.submitted = false;
    this.error = false;
    this.changePasswordFrom.reset();
  }
  get form() {
    return this.changePasswordFrom.controls;
  }
  chPassword() {
    this.submitted = true;
    if (this.changePasswordFrom.valid) {
      if (this.checkingPasswords()) {
        return;
      }
      this.loading = true;
      const formData = new FormData();
      Object.keys(this.changePasswordFrom.value).forEach(key => {
        formData.append(key, this.changePasswordFrom.value[key]);
      });
      const formDataJson = this.formDataToJson.convertFormDataToJson(formData);
      this.shareService.changePassword(formDataJson).subscribe(
        data => {
          this.submitted = false;
          this.loading = false;
          this.modalRef.hide();
          this.toastr.success('Successfully Updated', 'Password', {
            closeButton: true,
            timeOut: 2000,
            positionClass: 'toast-top-center'
          });
          this.getLoggedOut();
        },
        error => {
          this.loading = false;
          this.error = error.error.message;
        }
      );
    } else {
      return;
    }
  }
  getLoggedOut() {
    this.authenticationService.logout();
    this.userService.profileImage.next(null);
    this.router.navigate(['/login']);
    return false;
  }

  getCartCount() {
    this.cartService.getCartCount.subscribe(res => {
      this.cartCount = res;
    });
    this.cartService.getProvCartCount.subscribe(res => {
      this.provCount = res;
    });
    this.cartService.getReqCartCount.subscribe(res => {
      this.reqCount = res;
    });
  }

  private userName() {
    this.userRole = this.shareService.getUserDetails().designation_id;
    if (this.role == this.userRoles.ROLE_CAPTAIN) {
      let currentLoggedUserName = localStorage.getItem('currentUser');
      let currentUserName = JSON.parse(currentLoggedUserName)
      this.userDisplay = currentUserName.data.ship_ids[0];
    } else {
      this.userDisplay = this.shareService.getUserDetails().user_details;
    }
  }

  onPageChange() {
    this.checkLoadMore = true;
    this.pageNo = this.pageNo + 1;
    this.getNotification();
    return this.pageNo;
  }

  // buildParms() {
  //   this.page = this.commonService.CheckPage(this.page);
  //   this.pageNo = this.page.offset;
  //   return this.pageNo;
  // }

  getNotification() {
    this.shareService.getNotification(this.pageNo, 20).subscribe(data => {
      if (data.total_pages < 1 || (this.pageNo == data.total_pages && this.pageNo != 1)) {
        this.showLoadMore = false;
        return;
      } else {
        if (data.total < 20) {
          this.showLoadMore = false;
        }
        this.notifications = this.checkLoadMore ? [...this.notifications, ...data.results] : [...data.results];
        this.notificationCount = data.unread_count;
      }
    });
  }

  generateLink(type, id) {
    let url
    if (type == 'rfq' || type.includes('product_price_expired')) {
      return;
    } else if (type.includes('price_comparison_page')) {
      var quotation_number;
      if (type.includes('@@@')) {
        quotation_number = type.split('@@@')[1]
      }
      url = '/requisitions/detail/price-comparison/' + id + (quotation_number ? `?quotation=${quotation_number}` : '')
      this.router.navigateByUrl(url);
      // return '/requisitions/detail/price-comparison/' + id + (quotation_number ? `?quotation=${quotation_number}` : '');
    }
    else if (type == 'form_to_sub_admin') {
      // this.router.navigateByUrl('/');
      this.dashboardComponent.viewFormModal(this.dashboardComponent.viewCreatedForms);
    }
    else if (type == 'form_to_user') {
      this.router.navigateByUrl('/');
    }
    else {
      switch (type) {
        case 'order':
          url = '/orders/detail/' + id
          this.router.navigateByUrl(url)
          break;
        case 'rfq_updated':
          url = '/requisitions';
          this.router.navigateByUrl(url);
          break;
        case 'requisition_assigned_to':
          url = '/requisitions/detail/' + id;
          this.router.navigateByUrl(url);
          break;
        case 'new_user':
          url = '/user/edit/' + id;
          this.router.navigateByUrl(url)
          break;
        case 'user_added':
          url = '/user/edit/' + id;
          this.router.navigateByUrl(url)
          break;
        case 'ship_added':
          url = '/ship/details/' + id;
          this.router.navigateByUrl(url);
          break;
        case 'requisition':
          url = '/requisitions/detail/' + id;
          this.router.navigateByUrl(url);
          break;
        case 'assigned_for_review_to':
          url = '/requisitions/detail/' + id;
          this.router.navigateByUrl(url);
          break;
        case 'company_info':
          url = '/company/info';
          this.router.navigateByUrl(url);
          break;
      }
    }
  }

  sendToLink(type, id) {
    if (type.includes('product_price_expired')) {
      const str = type.split(/[_,]+/);
      if (str.length > 3) {
        // let portId = id;
        // let subCatId = JSON.parse(str[3]);
        const vendorId = JSON.parse(str[3]);
        let url = `/RFQs?vendor_id=${vendorId}`;
        url = decodeURIComponent(url);
        this.router.navigateByUrl(url);
      } else {
        this.router.navigateByUrl('/RFQs');
      }
    }
  }

  checkRfq(id) {
    if (this.userRole === 6) {
      this.rfqService.getById(id).subscribe(data => {
        this.router.navigate(['/RFQs']);
      }, error => {
        this.toastr.success('The selected RFQ has been processed.', '',
          {
            closeButton: true,
            timeOut: 2000,
            positionClass: 'toast-bottom-left'
          });
      });
    } else {
      this.router.navigate(['/RFQs']);
    }
  }

  generateUrl(id) {
    this.loading = true;
    this.orderService.getByid(id).subscribe((data: Order) => {
      if (data.vendor_id != null) {
        this.router.navigate(['/orders/detail/' + id]);
        this.loading = false;
      } else {
        this.router.navigate(['/requisitions/detail/' + id]);
        this.loading = false;
      }
    });
  }

  updateNotification(id) {
    this.checkLoadMore = false;
    this.shareService.updateNotification(id).subscribe(data => {
      this.getNotification();
    });
  }

  readAllNotification() {
    if (this.notificationCount > 0) {
      this.loading = true;
      this.shareService.updateNotification(0).subscribe(data => {
        this.showNotification = false;
        this.getNotification();
        this.readAll = true;
        this.loading = false;
      });
    }
  }

  openCart() {
    if ((!this.canPlaceOrder || !this.canPlaceReq) || this.role == this.userRole.ROLE_ADMIN) {
      if (this.canPlaceOrder) {
        this.openProvisionCart();
      } else {
        this.openReqCart();
      }
    }
  }

  openProvisionCart() {
    this.router.navigate(['/dashboard/cart'], { queryParams: { provisions: true } });
  }
  openReqCart() {
    this.router.navigate(['/dashboard/cart']);
  }

  getMessageCount() {
    this.chatService.getChats().subscribe(data => {
      const chatData = this.descryptService.decryptUsingAES256(data)
      const chat = JSON.parse(chatData);
      this.chats = chat[0]['data'];
      if (this.chats) {
        for (const chat of this.chats) {
          if (chat.unread_count > 0) {
            this.chatMessageCount += 1;
          }
        }
      }
    });
  }

  // checkPasswords(): ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: any } => {
  //     return control.value != this.password ? { notSame: true } : null;
  //   };
  // }

  passwordLength(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return control.value && control.value.length < 6 ? { notEnoughLength: true } : null;
    };
  }

  checkingPasswords() {
    if (this.changePasswordFrom.get('password').value != null && this.changePasswordFrom.get('new_password').value != null &&
      this.changePasswordFrom.get('password').value !== this.changePasswordFrom.get('new_password').value) {
      this.notSame = true;
      return true;
    }
    this.notSame = false;
    return false;
  }

  hideBlackShade() {
    if (this.showNotification) {
      this.showNotification = !this.showNotification;
      this.showProfile = false;
    } else {
      this.showProfile = !this.showProfile;
      this.showNotification = false;
    }
  }

  onChooseFile(event) {
    const reader = new FileReader();
    const file = event.target.files[0];
    this.inValidFileSize = false;
    if (event.target.files && event.target.files[0]) {
      const filesize = event.target.files[0].size / 1024 / 1024;
      if (filesize > 5) {
        this.inValidFileSize = true;
        // this.fileName = '';
      } else {
        this.isLoading = true;
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.imageUrl = reader.result;
        };
        this.cd.markForCheck();
        const formData = new FormData();
        formData.append('img', file);
        formData.append('id', this.authService.currentUserValue.data.user_details.user_id);
        this.userService.uploadProfile(formData).subscribe(res => {
          this.userService.userProfileImage = this.imageUrl;
          this.isLoading = false;
          this.toastr.success('Profile Uploaded Successfully.', '',
            {
              closeButton: true,
              timeOut: 2000,
              positionClass: 'toast-bottom-left'
            });
        }, error => {
          this.isLoading = false;
          this.toastr.error('Something went wrong! Please upload again.', '',
            {
              closeButton: true,
              timeOut: 2000,
              positionClass: 'toast-bottom-left'
            });
        });
      }
    }
  }

  togglemyPasswordFieldType() {
    this.isTextFieldType = !this.isTextFieldType;
  }
  togglemyNewPasswordFieldType() {
    this.isTextFieldNewType = !this.isTextFieldNewType;
  }
  togglemyConfirmPasswordFieldType() {
    this.isTextFieldConfirmType = !this.isTextFieldConfirmType;
  }
  showCart() {
    if (this.company.type && this.company.type == this.companyType.Seller) {
      return false
    } else if (this.role == this.userRoles.ROLE_ADMIN || ((this.role == this.userRoles.ROLE_SUBADMIN) && this.subAdminPrivilageAssigned)) {
      return true;
    } else if ((this.role == this.userRoles.ROLE_CAPTAIN) || (this.role == this.userRoles.ROLE_COMPANY_ADMIN) || (this.role == this.userRoles.ROLE_USER)) {
      return true
    }
    // else if (this.role == this.userRoles.ROLE_ADMIN) {
    //   return true
    // }
  }
}
