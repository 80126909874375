import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DisableRightClickService {
  constructor() {
    this.detectDevTools();
  }

  detectDevTools() {
    if (environment.production) {
      // Block right-click context menu
      document.addEventListener('contextmenu', (event) => event.preventDefault());

      // Block specific key combinations
      document.addEventListener('keydown', (event) => {
        if (
          event.key === 'F12' || // F12 key
          (event.ctrlKey && event.shiftKey && event.key === 'I') || // Ctrl+Shift+I
          (event.ctrlKey && event.shiftKey && event.key === 'J') || // Ctrl+Shift+J
          (event.ctrlKey && event.key === 'U') // Ctrl+U
        ) {
          event.preventDefault();
        }
      });

      // Detect DevTools by checking if the console is opened or if the window is resized in suspicious ways
      const devToolsDetect = () => {
        const threshold = 160;
        const widthThreshold = window.outerWidth - window.innerWidth > threshold;
        const heightThreshold = window.outerHeight - window.innerHeight > threshold;
        if (widthThreshold || heightThreshold) {
          document.addEventListener('contextmenu', function (event) {
            // Check the conditions
            event.preventDefault();
            return false;
          });
        }
      };
      // Check at an interval for DevTools
      setInterval(devToolsDetect, 500); // Check every 500ms
      // Optionally: Detect installed browser extensions that might enable DevTools or help bypass protections
      const detectExtensions = () => {
        const extensionKeywords = ['extension', 'chrome-extension', 'moz-extension'];
        const allScripts: any = document.getElementsByTagName('script');
        for (let script of allScripts) {
          if (extensionKeywords.some((keyword) => script.src.includes(keyword))) {
            break; // You can take an appropriate action here, such as notifying or reloading the page
          }
        }
      };
      detectExtensions(); // Run this function on page load
    }
  }
}
