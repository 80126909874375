<!-- <div class="loading" *ngIf="loading">Loading...</div> -->
<!-- <app-loading class="loaderHide" [class.loaderShow]="loading"></app-loading> -->
<div class="box top-index" *ngIf="util.dashboard">
    <h3 class="font-weight-bolder bmargin30">{{util.role==util.userRoles.ROLE_COMPANY_ADMIN ? 'MANAGER' : 'OFFICER'}} DASHBOARD</h3>
    <div class="row">
        <div class="col-md-12" *ngIf="util.emptybox">
            <div class="empty-dashboard manager">
                <img src="/assets/images/empty-report.svg" alt="no-reports">
                <h2><span>Oops!</span>Looks like you haven't received any Requisition yet?</h2>
            </div>
        </div>
        <div class="col-md-6 bmargin30" *ngIf="util.reqReport">
            <div class="card new-card">
                <h5 class="card-header">Requisitions
                    <span class="loading-spinner small" *ngIf="util.loading"></span>
                    <span [class.download-option]="util.loading" class="dropdown dropleft">
            <a class="more-btn" data-toggle="dropdown" aria-haspopup="true"
               aria-expanded="false"><span class="more-dots"></span></a>
                    <div class="dropdown-menu float-right" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" (click)="util.getDashboard(1,'xlsx')">Download as Excel</a>
                        <a class="dropdown-item" (click)="util.getDashboard(1,'pdf')">Download as PDF</a>
                    </div>
                    </span>
                </h5>
                <div class="card-body new-body">
                    <app-piechart [graphData]="util.reqReportData" legendPosition="right" (chartClicked)="util.reqDataClicked($event)"></app-piechart>
                </div>
            </div>
        </div>
        <div class="col-md-6 bmargin30" *ngIf="util.orderReport">
            <div class="card new-card">
                <h5 class="card-header">Orders
                    <span class="loading-spinner small" *ngIf="util.loadingOrders"></span>
                    <span [class.download-option]="util.loadingOrders" class="dropdown dropleft">
            <a class="more-btn" data-toggle="dropdown" aria-haspopup="true"
               aria-expanded="false"><span class="more-dots"></span></a>
                    <div class="dropdown-menu float-right" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item" (click)="util.getDashboard(1,'xlsx', 1)">Download as Excel</a>
                        <a class="dropdown-item" (click)="util.getDashboard(1,'pdf', 1)">Download as PDF</a>
                    </div>
                    </span>
                </h5>
                <div class="card-body new-body">
                    <app-piechart [graphData]="util.orderReportData" legendPosition="right" (chartClicked)="util.orderDataClicked($event)"></app-piechart>
                </div>
            </div>
        </div>
        <div class="col-md-12 tmargin10">
            <app-category-expenditure *ngIf="!util.emptybox" [role]="2" [portList]="portList" [categoryList]="categoryList" [shipList]="shipList"></app-category-expenditure>
        </div>
        <div class="col-md-12 tmargin40">
            <app-ship-comparison [portList]="portList" [categoryList]="categoryList" [shipList]="shipList"></app-ship-comparison>
        </div>
    </div>
</div>