import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector:"input-validation",
  template:`<div class="errorbox"
              *ngIf="errorMessage">
            {{errorMessage}}
            </div>`
})

export class InputValidation{

  @Input() control: FormControl;
  @Input() submit: FormControl;
  get errorMessage() {
    for (const propertyName in this.control.errors) {
      if (
        this.control.errors.hasOwnProperty(propertyName) &&
        (this.control.dirty || this.control.touched) || this.submit
      ) {
        return this.getValidatorErrorMessage(
          propertyName,
          this.control.errors[propertyName]
        );
      }
    }
    return null;
  }

  getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      required: 'This field can not be blank.',
      invalidNumber: 'Input should be an integer value',
      invalidPhone: 'Invalid phone number',
      invalidEmailAddress: 'Invalid email address',
      numericAllowed: 'Only numeric values are allowed',
      emailTaken: 'This email is already taken!',
      minlength: `Minimum length should be ${validatorValue.requiredLength} characters`,
      maxlength: `Maximum length should be ${validatorValue.requiredLength} characters`,
      mustMatch:`Password and Confirmation Password did't matched`,
      invalidValue:'Enter valid value'
    };
    return config[validatorName];
  }
}