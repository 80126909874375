import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AppConfig, ConfigService } from '@app/authentication/services/config.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, AfterViewInit {
  onConfigChanged: Subscription;
  appConfig: AppConfig;
  constructor(private configService: ConfigService) { }

  ngOnInit() {}

  ngAfterViewInit(): void {
    // Subscribe to all the settings change events
    this.onConfigChanged = this.configService.onAppConfigChanged.subscribe(
      (config: AppConfig) => {
        this.appConfig = config;
        // this.cdr.detectChanges();
      }
    );
  }
}
