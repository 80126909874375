export enum OrderStatus {
  ALL = -1,
  WAITING_FOR_APPROVAL,
  APPROVED,
  CONFIRM_BY_VENDOR,
  OUT_FOR_DELIVERY,
  ORDER_DELIVERED,
  ORDER_PARTIAL_DELIVERED,
  REJECTED
}

export const OrderStatusLabel = {
  REQUISITION: 'Requisition',
  WAITING_FOR_APPROVAL:
    '<span class="status-tag awaiting">Order Sent to Vendor</span>',
  APPROVED: '<span class="status-tag approved">Order Placed</span>',
  OUT_FOR_DELIVERY:
    '<span class="status-tag out-for-delivery">Out For Delivery</span>',
  CONFIRM_BY_VENDOR:
    '<span class="status-tag vendor-confirm">Confirmed By Vendor</span>',
  ORDER_DELIVERED: '<span class="status-tag delivered">Order Delivered</span>',
  ORDER_PARTIAL_DELIVERED:
    '<span class="status-tag partial-delivered">Partially Delivered</span>',
  REJECTED: '<span class="status-tag rejected">Rejected By Vendor</span>'
};
export const OrderStatusVendorLabel = {
  REQUISITION: 'Requisition',
  WAITING_FOR_APPROVAL:
    '<span class="status-tag awaiting">Waiting For Approval</span>',
  APPROVED: '<span class="status-tag approved">Order Received</span>',
  OUT_FOR_DELIVERY:
    '<span class="status-tag out-for-delivery">Out For Delivery</span>',
  CONFIRM_BY_VENDOR:
    '<span class="status-tag vendor-confirm">Order Confirmed</span>',
  ORDER_DELIVERED: '<span class="status-tag delivered">Order Delivered</span>',
  ORDER_PARTIAL_DELIVERED:
    '<span class="status-tag partial-delivered">Partially Delivered</span>',
  REJECTED: '<span class="status-tag rejected">Order Cancelled</span>'
};
