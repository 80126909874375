import { OnChanges, AfterViewInit } from '@angular/core';

import { Component, OnInit, Input, SimpleChange, SimpleChanges } from '@angular/core';
import { Chart } from 'angular-highcharts';


@Component({
  selector: 'app-highchart-bar',
  templateUrl: './highchart-bar.component.html',
  styleUrls: ['./highchart-bar.component.css']
})
export class HighchartBarComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() dashboard: SimpleChange;
  data: any = {};
  chartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'bar'
    },
    title: {
      text: 'Category wise expenditure'
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    plotOptions: {
      bar: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
      }
    },
    series: this.data,
  };

  total: number;
  label: any;
  chart: Chart;

  constructor() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dashboard.currentValue) {
      this.generateChart();
      this.data = {
        name: 'Percentage',
        type: undefined,
        data: changes.dashboard.currentValue
      };
    }
    this.init();
    this.chart.addSeries(this.data, true, true);
  }
  ngOnInit() {

  }
  ngAfterViewInit() {

  }



  generateChart() {
    if (!this.dashboard) {
      return false;
    }
  }

  init() {
    const chart = new Chart(this.chartOptions);

    this.chart = chart;

    chart.ref$.subscribe(console.log);
  }


}
