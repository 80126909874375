<form [formGroup]="technicalDetailsForm" (ngSubmit)="submitTechnicalDetails()">
<div class="kyc-form">
  <h3 class="bmargin20">Section 3: Technical Capability and Information on Goods/Services Offered</h3>
  <div class="company-details remove-border">
    <label class="bmargin5 label-text">Quality Assurance Certificate (Provide Latest Copy)</label>
      <ng-container formArrayName="qa_certificates_detail">
      <div class="row" *ngFor="let qaCert of technicalDetailsForm.get('qa_certificates_detail')['controls']; index as i;" [formGroupName]="i">
        <div class="col-md-8">
          <div class="form-fields-wrap certificate">
            <div class="form-group kyc-field bmargin5" [ngClass]="{error: submitted && qaCertificatesDetail.controls[i].get('qa_cert_'+ (i+1)).errors}">
              <input type="text" [formControlName]="'qa_cert_'+ (i+1)" class="form-control" placeholder="Enter Certificate Name" maxlength="255">
              <!-- <input-validation [control]="qaCertificatesDetail.controls[i].get('qa_cert_'+ (i+1))" [submit]="submitted"></input-validation> -->
              <!-- <div class="errorbox" *ngIf="submitted && qaCertificatesDetail.controls && qaCertificatesDetail.controls.length > 0 && qaCertificatesDetail.controls[i].get('qa_cert_'+ (i+1)).errors">
                <span>Enter certificate name</span>
              </div> -->
            </div>
            <div class="form-group kyc-field bmargin5" [ngClass]="{ error: submitted && inValidDocumentFileSize[i] }">
              <div class="filename-box">
                <div class="select-file upload">
                  <img src="/assets/images/file-upload-icon.svg" alt="">
                  <input class="form-control" accept=".pdf,.jpg,.jpeg,.png" type="file" (change)="selectedDocumentFileName($event.target.files, i)"> Attach Files <span class="btn-span">Browse</span>
                </div>
                <span *ngIf="!bankDocuments[i] || docName[i]" class="filename">{{docName[i] || 'No Files Chosen'}}</span>
              </div>
              <a *ngIf="bankDocuments[i]" [href]="bankDocuments[i]" target="_blank" class="rmargin10 float-left file-view signature" download>View Certificate</a>
              <div class="add-row">
                <!-- push-center -->
                <!-- <a *ngIf="bankDocuments[i]" [href]="bankDocuments[i]" target="_blank" class="rmargin10 float-left file-view" download>View Certificate</a> -->
                <!-- <button type="button" class="close cross" *ngIf="i > 0" (click)="removeQaCertificatesDetailItem(i)">&#10006;</button>
                <a href="javascript:void(0)" (click)="addQaCertificatesDetailItem(null, null, i+1)">+ Add More</a> -->
              </div>
              <div class="errorbox" *ngIf="submitted && inValidDocumentFileSize[i]">
                <!--  [ngClass]="{ error: submitted && !selectDocuments[i] || inValidDocumentFileSize[i] }"
                  <span *ngIf="submitted && !selectDocuments[i]">Select File&nbsp;</span> -->
                <span *ngIf="inValidDocumentFileSize[i]">File is larger than <strong>3mb !!</strong></span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-4"></div> -->
      </div>
      <div class="row">
        <div class="col-md-6 right">
          <span class="add-row fix">
            <button type="button" class="close cross" *ngIf="qaCertificatesDetail.controls && qaCertificatesDetail.controls.length > 1" (click)="removeQaCertificatesDetailItem()">&#10006;</button>
            <a href="javascript:void(0)" (click)="addQaCertificatesDetailItem(null, null, qaCertificatesDetail.controls.length)">+ Add More</a>
          </span>
        </div>
      </div>
      </ng-container>
      <div class="row">
        <div class="col-md-5">
          <div class="form-group kyc-field bmargin20">
            <label>International Offices/Representatives</label>
            <input type="text" formControlName="representation" class="form-control" placeholder="Enter Text" maxlength="255">
          </div>
        </div>
    </div>
  </div>
</div>
<div class="kyc-form grey-bg">
  <div class="company-details remove-border">
    <label for="agree" class="custom-checkbox tick">
      <input type="checkbox" id="agree" [checked]="requiredCheck" (change)="requiredCheckBox($event)">
      <span class="shape"></span> Certification:<br>
      I, the undersigned, warrant that the information provided in this form is correct, and in the event of changes details will be provided as soon as possible.
    </label>
    <span class="errorbox d-block" *ngIf="submitted && !requiredCheck">Check the undersigned checkbox</span>
    <div class="row">
      <div class="col-md-12">
        <div class="form-fields-wrap agreed">
          <div class="form-group kyc-field bmargin10" [ngClass]="{error: submitted && technicalDetailsForm.get('name').errors}">
            <label>Name<span class="mendatory">*</span></label>
            <input type="text" formControlName="name" class="form-control" placeholder="Enter Name" maxlength="150">
            <input-validation [control]="technicalDetailsForm.get('name')" [submit]="submitted"></input-validation>
            <!-- <div class="errorbox" *ngIf="submitted && technicalDetailsForm.get('name').errors">
              <span>Enter name</span>
            </div> -->
          </div>
          <div class="form-group kyc-field bmargin10" [ngClass]="{error: submitted && technicalDetailsForm.get('designation').errors}">
            <label>Designation<span class="mendatory">*</span></label>
            <input type="text" formControlName="designation" class="form-control" placeholder="Enter Title" maxlength="255">
            <input-validation [control]="technicalDetailsForm.get('designation')" [submit]="submitted"></input-validation>
            <!-- <div class="errorbox" *ngIf="submitted && technicalDetailsForm.get('designation').errors">
              <span>Enter designation</span>
            </div> -->
          </div>
          <div class="form-group kyc-field bmargin10" [ngClass]="{ error: inValidSignatureFileSize}">
            <label>Digital Signature</label>
            <div class="filename-box">
              <div class="select-file upload bmargin5">
                <img src="/assets/images/file-upload-icon.svg" alt="">
                <input class="form-control" accept=".pdf,.jpg,.jpeg,.png" type="file" (change)="selectedSignatureFileName($event.target.files)"> Attach Files <span class="btn-span">Browse</span>
              </div>
              <span *ngIf="!digitalSignatureFile || fileName" class="filename d-block">{{fileName || 'No Files Chosen'}}</span>
            </div>
            <div class="filename" *ngIf="digitalSignatureFile">
              <a *ngIf="digitalSignatureFile" [href]="digitalSignatureFile" target="_blank" class="file-view" download>View Signature</a>
            </div>
            <div class="errorbox" *ngIf="inValidSignatureFileSize">
              <span>File is larger than <strong>3mb !!</strong></span>
            </div>
          </div>
        </div>
        <label class="label-text grey-label">Date: {{today | date:'dd MMMM, yyyy'}}</label> 
      </div>
    </div>
  </div>
</div>
<div class="submit-btn-box tmargin20 bmargin10">
  <button type="button" class="btn empty-cart back" (click)="goPrevious()">Back</button>
  <button type="button" class="btn empty-cart back" (click)="resetForm()">Reset</button>     
  <button type="submit" class="buttn place save alignText">Final Submit</button>
</div>
</form>
<ng-template #kycInProcess class="modal-sm">
  <div class="modal-body text-center padding20">
      <img class="mt-4" src="/assets/images/vendor-profile.svg" alt="image">
      <h4 class="sure-font mt-4">Thank you for submitting your kyc!</h4>
      <h6 class="mt-4">
          Your kyc verification is under approval. You will receive an email confirmation once your kyc has been approved.
      </h6>
      <hr/>
      <h5 class="small mt-4">
          In case of an urgent requirement, feel free to reach us at <b class="text-nowrap">contact@shipskart.com</b>
      </h5>
      <a *ngIf="company" class="btn btn-block btn-primary mt-4" routerLink="/kyc/details" [queryParams]="{vendor_id: vendorId, email: vendorEmail}" (click)="modalRef.hide()" type="button">Okay</a>
      <a *ngIf="!company" class="btn btn-block btn-primary mt-4" routerLink="/" (click)="modalRef.hide()" type="button">Okay</a>
    </div>
</ng-template>