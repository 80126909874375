import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from '../message.service';
import { Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'app-success-message',
  templateUrl: './success-message.component.html',
  styleUrls: ['./success-message.component.css']
})
export class SuccessMessageComponent implements OnInit {
  messageText = [];

  constructor(private msgService: MessageService) {

  }

  ngOnInit() {
    this.msgService.message
      .pipe(map(data => {
        const hdMsg = data[0].headMessage;
        const baseMsg = data[1].secondMessage;
        const msgArray = [];
        msgArray.push(hdMsg, baseMsg);
        return msgArray;
      }))
      .subscribe(data => {
        this.messageText = data;
      });

  }
}
