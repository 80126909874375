import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabular-search',
  templateUrl: './tabular-search.component.html',
  styleUrls: ['./tabular-search.component.css']
})
export class TabularSearchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  /*ngAfterViewInit(): void {
    fromEvent(this.search.nativeElement, 'keydown')
      .pipe(
        debounceTime(550),
        map(x => x['target']['value'])
      )
      .subscribe(value => {
        this.updateFilter(value);
      });
  }

  updateFilter(val: any) {
    const value = val.toString().toLowerCase().trim();
    const count = 5;
    const keys = Object.keys(this.temp[0]);
    this.rows = this.temp.filter(item => {
      for (let i = 0; i < count; i++) {
        if (
          (item[keys[i]] &&
            item[keys[i]]
              .toString()
              .toLowerCase()
              .indexOf(value) !== -1) ||
          !value
        ) {
          return true;
        }
      }
    });
  }*/

}
