import { UserRoles } from '@app/core/enums/user-roles';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'role' })
export class RolePipe implements PipeTransform {
  userRoles = UserRoles;
  transform(value: any) {
    return this.userRoles[value];
  }
}


