import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';
import { UserRoles } from '@app/core/enums/user-roles';
import { DashboardService } from '@app/dashboard/dashboard.service';
import { UserService } from '@app/modules/user/services/user.service';
import { SharedService } from '@app/shared/service/shared.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.css']
})
export class CreateFormComponent implements OnInit {
  canSubmit: boolean = false;
  createForm: FormGroup;
  usersData = [];
  selectedUserData = [];
  userDataLoader: boolean;
  feedbackFormResponse: any;
  formId: string;
  role: number;
  userRoles = UserRoles;
  isLoading = false;
  guest = "Shipskart User";
  isChecked: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private user: UserService,
    private dashboardService: DashboardService,
    private tostr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
  ) {
    this.role = this.sharedService.getUserRole();
    this.userDataLoader = true;
    this.user.getAll(1, 1000, null, '', 0, 1).subscribe(data => {
      if (data) {
        let providedData = [];
        providedData = data.results.filter((response) => {
          // console.log(response)
          if (response) {
            if (response.user_role.length > 0) {
              if (response.user_role[0].role_id == 5)
                return true;
            }
          }
        })
        this.usersData = providedData.map((response) => {
          if (response) {
            let fullname = `${response.fname} ${response.lname ? response.lname : ''} `
            let userId = response.id
            let name = { name: fullname, userId: userId }
            return name
          }
        })
        // console.log(this.usersData);
        this.userDataLoader = false;
      }
    })
  }

  ngOnInit(): void {
    this.formId = this.route.snapshot.queryParamMap.get('form_id');
    if (this.formId) {
      this.dashboardService.getFeedbackForms(parseInt(this.formId)).subscribe(res => {
        this.createFormUpdate(res);
      })
    }
    this.customFeedbackForm();
  }

  // It initialises the blueprint of our custom feedback form.
  customFeedbackForm() {
    this.createForm = this.formBuilder.group({
      form_title: this.formId && this.role === this.userRoles.ROLE_SUBADMIN ? [{ value: null, disabled: true }, [Validators.required]] : [null, [Validators.required]],
      elements: this.formId ? this.formBuilder.array([]) : this.formBuilder.array([this.newTextField()]),
      rating_parameters: this.formId ? this.formBuilder.array([]) : this.formBuilder.array([this.newRating()]),
      textarea_parameters: this.formId ? this.formBuilder.array([]) : this.formBuilder.array([this.newTextArea()]),
      notes: this.formId && this.role === this.userRoles.ROLE_SUBADMIN ? [{ value: null, disabled: true }, [Validators.required]] : [null, [Validators.required]],
      user_ids: this.formId && this.role === this.userRoles.ROLE_SUBADMIN ? [{ value: null, disabled: true }, [Validators.required]] : [null, [Validators.required]]
    })
  }

  goPreviousUrl() {
    this.router.navigateByUrl('/')
  }

  //Textfield Start
  get elements(): FormArray {
    return this.createForm.get('elements') as FormArray;
  }
  newTextField(name?): FormGroup {
    return this.isChecked ? this.formBuilder.group({
      id: [null],
      name: [name, [Validators.required]],
      type: ['textfield'],
      required: true
    }) : this.formBuilder.group({
      id: [null],
      name: ['', [Validators.required]],
      type: ['textfield'],
      required: false
    })
  }
  // Append formcontrol to the fieldName form array.
  addTextFields(name?) {
    this.elements.push(this.newTextField(name));
  }

  // Remove formcontrol from the fieldName form array.
  removeTextFields() {
    this.elements.removeAt(this.elements.length - 1)
  }

  //Textfield End
  //Rating Parameter Start
  get rating_parameters(): FormArray {
    return this.createForm.get('rating_parameters') as FormArray;
  }

  newRating(): FormGroup {
    return this.formBuilder.group({
      id: [null],
      name: ['', [Validators.required]],
      type: 'rating',
      required: false
    })
  }
  // Append formcontrol to the rating parameter form array.
  addRatingParam() {
    this.rating_parameters.push(this.newRating());
  }

  // Remove formcontrol from the rating parameter form array.
  removeRatingParam() {
    this.rating_parameters.removeAt(this.rating_parameters.length - 1);
  }

  //Rating Parameter End

  //Text area Start
  get textarea_parameters(): FormArray {
    return this.createForm.get('textarea_parameters') as FormArray;
  }

  newTextArea(): FormGroup {
    return this.formBuilder.group({
      id: [null],
      name: ['', [Validators.required]],
      type: 'textarea',
      required: false
    })
  }

  addTextArea() {
    this.textarea_parameters.push(this.newTextArea())
  }

  removeTextArea() {
    this.textarea_parameters.removeAt(this.textarea_parameters.length - 1)
  }
  //Text area End
  // Executes after submission of the form.
  onSubmit() {
    this.isLoading = true;
    let payload = this.generatePayload();
    if (this.formId) {
      payload['form_id'] = parseInt(this.formId);

      this.dashboardService.updateForm(payload).subscribe(res => {
        if (res) {
          this.tostr.success('Feedback Form Updated Successfully!');
          this.router.navigateByUrl('/');
          this.createForm.reset();
          this.isLoading = false;
        }
      }, error => {
        this.tostr.error(error.error || error.message || "Something Went Wrong!")
        this.isLoading = false;
      })
    } else {
      this.dashboardService.submitFeedbackForm(payload).subscribe(data => {
        this.tostr.success('Form Created Successfully', 'Success');
        this.createForm.reset();
        this.customFeedbackForm();
        this.router.navigateByUrl('/')
        this.isLoading = false;
      }, error => {
        this.tostr.error(error.error || error.message || "Something Went Wrong!")
        this.isLoading = false;
      })
    }

  }

  // Assigns data according to the selected roles or department or user
  selectedData(val) {
    this.selectedUserData = val;
  }

  // Patchdata in form

  createFormUpdate(res) {
    let textIndex = 0
    let ratingIndex = 0
    let textAreaIndex = 0
    this.createForm.patchValue({ form_title: res.form_title, notes: res.notes, user_ids: res.sub_admin_users.map(res => res.id) })
    res.form_elements.forEach((ele) => {
      switch (ele.type) {
        case "textfield":
          this.addTextFields();
          const textfield = this.elements.at(textIndex) as FormGroup;
          if (textfield) {
            ++textIndex
            textfield.patchValue({
              name: ele.name,
              type: ele.type,
              id: ele.id,
              required: ele.required
            });
            if (this.role === this.userRoles.ROLE_SUBADMIN) {
              const items = this.createForm.get('elements') as FormArray;
              // Loop through each control in the FormArray and enable it
              items.controls.forEach(control => {
                control.disable();
              });
            }
          }
          break;

        case "rating":
          this.addRatingParam();
          const rating = this.rating_parameters.at(ratingIndex) as FormGroup;
          if (rating) {
            ++ratingIndex
            rating.patchValue({
              name: ele.name,
              type: ele.type,
              id: ele.id,
              required: ele.required
            });
            if (this.role === this.userRoles.ROLE_SUBADMIN) {
              const items = this.createForm.get('rating_parameters') as FormArray;
              // Loop through each control in the FormArray and enable it
              items.controls.forEach(control => {
                control.disable();
              });
            }
          }
          break;

        case "textarea":
          this.addTextArea();
          const textarea = this.textarea_parameters.at(textAreaIndex) as FormGroup;
          if (textarea) {
            ++textAreaIndex
            textarea.patchValue({
              name: ele.name,
              type: ele.type,
              id: ele.id
            });
            if (this.role === this.userRoles.ROLE_SUBADMIN) {
              const items = this.createForm.get('textarea_parameters') as FormArray;
              // Loop through each control in the FormArray and enable it
              items.controls.forEach(control => {
                control.disable();
              });
            }
          }
          break;
        default:
          console.error('Unsupported element type:', ele.type);
          break;
      }
    })
  }

  generatePayload() {
    const elementFormArray = this.createForm.get('elements').value;
    const ratingFormArray = this.createForm.get('rating_parameters').value;
    const textAreaFormArray = this.createForm.get('textarea_parameters').value;
    let payload = {
      form_title: this.createForm.get('form_title').value,
      elements: [...elementFormArray, ...ratingFormArray, ...textAreaFormArray],
      notes: this.createForm.get('notes').value,
      user_ids: this.createForm.get('user_ids').value
    }
    return payload;
  }

  toggleSwitch(isChecked: boolean) {
    if (isChecked) {
      this.isChecked = isChecked;
      this.guest = "Guest User";
      const formArray = this.createForm.get('elements') as FormArray;
      formArray.clear();
      let contolName = ['Name', 'Designation', 'Company Name']
      for (let i = 0; i < contolName.length; i++) {
        this.addTextFields(contolName[i])
      }
    } else {
      this.isChecked = isChecked;
      this.guest = "Shipskart User";
      const formArray = this.createForm.get('elements') as FormArray;
      formArray.clear();
      this.addTextFields()
    }
  }
}
