import {
  Component, OnInit, Input, ViewChildren, QueryList, ElementRef, OnChanges, SimpleChange, SimpleChanges, EventEmitter, Output
} from '@angular/core';

declare var Chart: any;
declare var $: any;
@Component({
  selector: 'app-piechart',
  template: `<p-chart class="report-chart" type="doughnut" *ngIf="data && total" #pieChart [data]="data"
               [options]="options" (onDataSelect)="graphClicked($event)">
              </p-chart>`,
  styleUrls: ['./piechart.component.css']
})

export class PiechartComponent implements OnInit, OnChanges {
  @Input() graphData: any[] = [];

  // graph options
  @Input() displayTitle = true;
  @Input() showGraphLabels = false;
  @Input() chartTitle = '';
  @Input() titlePosition = 'top';
  @Input() displayLegend = true;
  @Input() legendPosition = 'top';
  @Input() inPercent = false;
  @Input() dashboard: SimpleChange;
  @Input() removeZeros = false;

  @ViewChildren('pieChart') charts: QueryList<ElementRef>;
  data: any;
  total: number;

  options: any;
  @Output() chartClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.graph_data) {
      this.generateChart();
    }
    this.initChart();
  }

  ngOnInit() {
    this.generateChart();
  }

  generateChart() {
    if (!this.graphData) {
      return;
    }

    this.total = 0;
    const dataSet = [];
    const label = [];
    const colour = [];

    this.graphData.forEach(
      data => {
        this.total += data.value;
      }
    );

    this.graphData.forEach(
      data => {
        const value = this.inPercent ? (data.value / this.total * 100).toFixed(2) : data.value;
        if (!this.removeZeros || value > 0) {
          dataSet.push(value);
          label.push(data.label);
          colour.push(data.colour);
        }
      }
    );
    this.data = {
      labels: label,
      datasets: [
        {
          data: dataSet,
          backgroundColor: colour,
          hoverBackgroundColor: colour
        }
      ]
    };

    this.options = {
      showAllTooltips: this.showGraphLabels,
      hover: {
        onHover: (e, elements) => {
          $(e.currentTarget).css('cursor', elements[0] ? 'pointer' : 'default');
          /* without jquery it can be like this:
            var el = document.getElementById("canvas1");
            el.style.cursor = e[0] ? "pointer" : "default";
          */
        }
      },
      title: {
        display: this.displayTitle,
        text: this.chartTitle,
        fontSize: 12,
        position: this.titlePosition,
      },
      legend: {
        display: this.displayLegend,
        position: this.legendPosition,
        labels: {
          generateLabels: (chart) => this.generateLabels(chart)
        },
      },
    };
  }

  generateLabels(chart) {
    {
      const data = chart.data;
      if (data.labels.length && data.datasets.length) {
        return data.labels.map((label, i) => {
          const meta = chart.getDatasetMeta(0);
          const ds = data.datasets[0];
          const arc = meta.data[i];
          const custom = arc && arc.custom || {};
          const getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
          const arcOpts = chart.options.elements.arc;
          const fill = custom.backgroundColor ?
            custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
          const stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
          const bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

          // We get the value of the current label
          const value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];
          return {
            // Instead of `text: label,`
            // We add the value to the string
            text: label + ' : ' + value,
            fillStyle: fill,
            strokeStyle: stroke,
            lineWidth: bw,
            hidden: isNaN(ds.data[i]) || meta.data[i]?.hidden,
            index: i
          };
        });
      } else {
        return [];
      }
    }
  }

  graphClicked(event) {
    this.chartClicked.emit(event);
  }

  initChart() {
    Chart.pluginService.register({
      beforeDraw: (chart) => {
        if (chart.config.options.showAllTooltips) {
          // create an array of tooltips
          // we can't use the chart tooltip because there is only one tooltip per chart
          chart.pluginTooltips = [];
          chart.config.data.datasets.forEach((dataset, i) => {
            chart.getDatasetMeta(i).data.forEach((sector, j) => {
              chart.pluginTooltips.push(new Chart.Tooltip({
                _chart: chart.chart,
                _chartInstance: chart,
                _data: chart.data,
                _options: chart.options.tooltips,
                _active: [sector]
              }, chart));
            });
          });

          // turn off normal tooltips
          chart.options.tooltips.enabled = false;
        }
      },
      afterDraw: (chart, easing) => {
        if (chart.config.options.showAllTooltips) {
          // we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
          if (!chart.allTooltipsOnce) {
            if (easing !== 1) {
              return;
            }
            chart.allTooltipsOnce = true;
          }
          // turn on tooltips
          chart.options.tooltips.enabled = true;
          Chart.helpers.each(chart.pluginTooltips, (tooltip) => {
            tooltip.initialize();
            tooltip.update();
            // we don't actually need this since we are not animating tooltips
            tooltip.pivot();
            tooltip.transition(easing).draw();
          });
          chart.options.tooltips.enabled = false;
        }
      }
    });
  }
}
