<app-alert></app-alert>

<a class="toggleValue" (click)="formReset();isEditMode=false;toggleCountryView = !toggleCountryView">{{toggleCountryView ? 'Add Country' : 'View Countries'}}</a>
<div class="viewbox" *ngIf="toggleCountryView">
    <h3 class="primary-font bmargin30">Countries</h3>
    <div class="load-listing" *ngIf="loading">
        Loading Data
    </div>
    <div class="data-table" *ngIf="!loading">
        <table class="table table-striped full-table">
            <tr>
                <th width="150">Country Name</th>
                <th>Country Legal Name</th>
                <th>Head Office</th>
                <th>Phone Number</th>
                <th width="200">Email-ID</th>
                <th>Currency Code</th>
                <th width="20">Action</th>
            </tr>
            <!-- <tr class="filters">
      <th><input type="text" class="form-control" /></th>
      <th>

      </th>
      <th>
        <select class="form-control">
          <option value="">Category</option>
        </select>
      </th>
      <th>

      </th>
      <th>

      </th>
      <th>
        <select class="form-control">
          <option value="">Country</option>
        </select>
      </th>
      <th></th>
    </tr> -->
            <tr *ngFor="let countryDetail of countryList">
                <td>
                    <a [routerLink]="[countryDetail.id]">{{countryDetail.name}}</a>
                </td>
                <td>
                    {{countryDetail.legal_name}}
                </td>
                <td>
                    {{countryDetail.head_office}}
                </td>
                <td>{{countryDetail.phone}}</td>
                <td>
                    {{countryDetail.email}}
                </td>
                <td>
                    {{countryDetail.currency_code}}
                </td>
                <td>
                    <a (click)="edit(countryDetail);">edit</a>
                </td>
            </tr>
        </table>

    </div>
    <ngb-pagination [collectionSize]="total" [(page)]="page" [maxSize]="limit" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="onPageChange($event)"></ngb-pagination>

    <!-- <ul class="pagination" *ngIf="!loading">
    <li class="page-item">
      <button [disabled]="page > 1 ? false : true" class="page-link" (click)="prevPage()">
        «
      </button>
    </li>
    <li>
      <ul class="pagination">
        <li class="page-item" [ngClass]="{'active': page === 1}" (click)="pageNumber('1')"><a class="page-link">1</a>
        </li>
        <li class="page-item" [ngClass]="{'active': page === 2}" (click)="pageNumber('2')"><a class="page-link">2</a>
        </li>
        <li class="page-item" [ngClass]="{'active': page === 3}" (click)="pageNumber('3')"><a class="page-link">3</a>
        </li>
      </ul>
    </li>
    <li class="page-item">
      <button [disabled]="page >= 3 ? true : false" class="page-link" (click)="nextPage()">
        »
      </button>
    </li>
  </ul> -->
</div>
<div class="viewbox" *ngIf="!toggleCountryView">
    <h3 class="primary-font bmargin30">{{isEditMode ? 'Edit country': 'Add new country'}}</h3>
    <div class="form-container bg-light-gray" [ngClass]="{formError:errMsg}">
        <h4 class="secondary-font bmargin20">Country Detail</h4>
        <form [formGroup]="countryForm" (ngSubmit)="isEditMode ? editCountry(): addCountry()">
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group" [ngClass]="{error : submitted && form.name.errors}">
                        <label for="">Country Name</label>
                        <input formControlName="id" [(ngModel)]="country.id" type="hidden" class="form-control" />
                        <input formControlName="name" [(ngModel)]="country.name" type="text" class="form-control" />
                        <div class="errorbox" *ngIf="submitted && form.name.errors">
                            <span *ngIf="!form.name.valid">Enter valid name</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group" [ngClass]="{error : submitted && form.legal_name.errors}">
                        <label for="">Country Legal Name</label>
                        <input formControlName="legal_name" [(ngModel)]="country.legal_name" type="text" class="form-control" />
                        <div class="errorbox" *ngIf="submitted && form.legal_name.errors">
                            <span *ngIf="!form.legal_name.valid">Enter valid legal name</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group" [ngClass]="{error : submitted && form.head_office.errors}">
                        <label for="">Head Office</label>
                        <input formControlName="head_office" [(ngModel)]="country.head_office" type="text" class="form-control" />
                        <div class="errorbox" *ngIf="submitted && form.head_office.errors">
                            <span *ngIf="!form.head_office.valid">Enter valid office address</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group" [ngClass]="{error : submitted && form.country_id.errors}">
                        <label for="">Country</label>
                        <select formControlName="country_id" id="" [(ngModel)]="country.country_id" class="form-control">
              <option value="1">India</option>
              <option *ngFor="let country of countryList" [value]="country.id">{{country.name}}</option>
            </select>
                        <div class="errorbox" *ngIf="submitted && form.country_id.errors">
                            <span *ngIf="!form.country_id.valid">Select country name</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group" [ngClass]="{error : submitted && form.city_id.errors}">
                        <label for="">City</label>
                        <select formControlName="city_id" id="" [(ngModel)]="country.city_id" class="form-control">
              <option value="1">Faridabad</option>
              <option value="2">Noida</option>
              <option value="3">New Delhi</option>
            </select>
                        <div class="errorbox" *ngIf="submitted && form.city_id.errors">
                            <span *ngIf="!form.city_id.valid">Select city name</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group" [ngClass]="{error : submitted && form.phone.errors}">
                        <label for="">Phone</label>
                        <input formControlName="phone" pKeyFilter="pnum" [(ngModel)]="country.phone" class="form-control" type="text" maxlength="16">
                        <div class="errorbox" *ngIf="submitted && form.phone.errors">
                            <span *ngIf="!form.phone.valid">Enter valid phone no</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group" [ngClass]="{error : submitted && form.email.errors}">
                        <label for="">Email-ID</label>
                        <input formControlName="email" [(ngModel)]="country.email" class="form-control" type="email">
                        <div class="errorbox" *ngIf="submitted && form.email.errors">
                            <span *ngIf="!form.email.valid">Enter valid Email-ID</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group" [ngClass]="{error : submitted && form.image.errors}">
                        <label for="">Banner Image</label>
                        <input formControlName="image" class="form-control" type="file">
                        <div class="errorbox" *ngIf="submitted && form.image.errors">
                            <span *ngIf="!form.image.valid">Select banner image</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group" [ngClass]="{error : submitted && form.logo.errors}">
                        <label for="">Country Logo</label>
                        <input formControlName="logo" class="form-control" type="file">
                        <div class="errorbox" *ngIf="submitted && form.logo.errors">
                            <span *ngIf="!form.logo.valid">Select logo</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group" [ngClass]="{error : submitted && form.currency_code.errors}">
                        <label for="">Currency code</label>
                        <input formControlName="currency_code" [(ngModel)]="country.currency_code" class="form-control" type="text">
                        <div class="errorbox" *ngIf="submitted && form.currency_code.errors">
                            <span *ngIf="!form.currency_code.valid">Enter currency code</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group" [ngClass]="{error : submitted && form.type.errors}">
                        <label for="">Type</label>
                        <select formControlName="type" id="" [(ngModel)]="country.type" class="form-control">
              <option value="1">Country</option>
              <option value="2">Vendor</option>
            </select>
                        <div class="errorbox" *ngIf="submitted && form.type.errors">
                            <span *ngIf="!form.type.valid">Select type of country</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group" [ngClass]="{error : submitted && form.represent_id.errors}">
                        <label for="">Represent id</label>
                        <select formControlName="represent_id" [(ngModel)]="country.represent_id" id="" class="form-control">
              <option *ngFor="let user of userList" [value]="user.id">{{user.username}}</option>

            </select>
                        <div class="errorbox" *ngIf="submitted && form.represent_id.errors">
                            <span *ngIf="!form.represent_id.valid">Select representive</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 tmargin10 text-right">
                    <button class="btn btn-primary" type="submit">{{isEditMode ? 'Update' : 'Submit'}}</button>
                </div>
            </div>
        </form>
    </div>

</div>