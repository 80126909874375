<header class="bg-white">
    <div class="headerbox">
        <div class="logo">
            <a routerLink="/">
                <div class="logo-bg" title="Shipskart"></div>
            </a>
        </div>
        <span *ngIf="showNotification || showProfile" class="blackshade" [ngClass]="{'opened':showNotification || showProfile, 'white': showProfile}" (click)="hideBlackShade()"></span>
        <ul class="rightmenu">
            <li *ngIf="showCart()">
                <a title="Cart" class="cart" (click)="openCart()">
                    <span class="cart-val cart-icon">
                        <img src="/assets/images/shopping-cart-icon.svg" alt="Cart-icon">
                        <sup [class.note]="provCount+reqCount > 99" *ngIf="(canPlaceOrder && provCount > 0) || (canPlaceReq && reqCount > 0) || (canPlaceOrder && canPlaceReq && (provCount > 0 || reqCount > 0))"><span>{{ canPlaceOrder && canPlaceReq ? provCount+reqCount : canPlaceOrder && !canPlaceReq ? provCount : !canPlaceOrder && canPlaceReq ? reqCount : '' }}</span></sup>
                    </span>
                </a>
                <ng-container *ngIf="canPlaceOrder && canPlaceReq">
                    <ul class="inner-list cart-popup cart">
                        <li>
                            <div>
                                <a (click)="openProvisionCart()">Provision Products&nbsp;&nbsp;<span *ngIf="provCount > 0" class="badge badge-danger new">{{ provCount }}</span></a>
                            </div>
                        </li>
                        <li>
                            <div>
                                <a (click)="openReqCart()">Other Requisitions&nbsp;&nbsp;<span *ngIf="reqCount > 0" class="badge badge-danger new">{{ reqCount }}</span></a>
                            </div>
                        </li>
                    </ul>
                </ng-container>
            </li>
            <li>
                <a title="Notifications" (click)="showNotification = !showNotification;showProfile = false;"><span class="cart-val notify"><img
              src="/assets/images/notifications-icon.svg"> <sup [class.note]="notificationCount>99"
              *ngIf="notificationCount"><span>{{notificationCount>99 ? '99+' : notificationCount}}</span></sup></span></a>
                <div class="available-notifications" [ngClass]="{'opened':showNotification}">
                    <h3 class="notifications-head">Notification Center <span *ngIf="notificationCount>0" class="note-count" [class.note]="notificationCount>99">{{notificationCount>99 ? '99+' : notificationCount}}</span>
                    </h3>
                    <ul class="notification-list" *ngIf="notifications">
                        <li class="notify">
                            <a href="javascript:void(0)" (click)="readAllNotification()" class="read-all" [class.done]="!notificationCount">Mark all as read
                                <ng-container *ngIf="loading">
                                    &nbsp;&nbsp;<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span class="sr-only">Loading...</span>
                                  </ng-container>
                                </a>
                        </li>
                        <li class="sidebar-scroll">
                            <ul>
                                <li *ngFor="let notification of notifications">
                                    <a class="{{(notification.read_status == 1 || readAll) ? '' : 'read'}}" (click)="showNotification = !showNotification;updateNotification(notification.id);sendToLink(notification.notification.content_type,notification.notification.content_id);notification.notification.content_type == 'requisitions' ? generateUrl(notification.notification.content_id) : (notification.notification.content_type == 'rfq' ? checkRfq(notification.notification.content_id) : '')"
                                        (click)="generateLink(notification.notification.content_type,notification.notification.content_id)">
                                        <div class="note-wrap" [ngClass]="{'requisition' : notification.notification.content_type === 'requisitions', 'order' : notification.notification.content_type === 'order', 'rfqs' : notification.notification.content_type === 'rfq' || notification.notification.content_type === 'rfq_updated', 'ship-added': notification.notification.content_type === 'ship_added', 'user' : notification.notification.content_type === 'new_user' || notification.notification.content_type === 'user_added', 'cancel' : notification.notification.title == 'Product Price Expired' || notification.notification.title == 'Order Rejected' }">
                                            <div class="note-icon"></div>
                                            <div class="note-content">
                                                <p class="title">{{notification.notification.title != null ? notification.notification.title : ''}}</p>
                                                <p class="title subtitle">{{notification.notification.message}}</p>
                                                <span class="title note-date">{{(notification.notification.created_at) | utctolocal | date: 'MMM dd, yyyy | HH:mm:ss'}}</span>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li class="text-center" *ngIf="showLoadMore"><a (click)="onPageChange()" class="load-more-text" href="javascript:void(0)">Load More...</a></li>
                                <li class="no-notifications" *ngIf="notificationCount == 0">No Notifications</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </li>
            <li>
                <a title="Chat Messenger" routerLink="/chat"><span class="cart-val chat-icon"><img
                src="/assets/images/chat-notification-icon.svg" alt="Chat-icon"><sup [class.note]="chatMessageCount>99"
                *ngIf="chatMessageCount"><span>{{chatMessageCount}}</span></sup></span></a>
            </li>
            <li>
                <span class="seperator-line"><img src="/assets/images/seperator-line.svg" alt="seperator"></span>
                <a title="User Profile" class="user-image" (click)="showProfile = !showProfile"><span class="userimg">
                    <img *ngIf="!imageUrl" [src]="placeholderImage" alt="user-profile" />
                    <img *ngIf="imageUrl" [src]="imageUrl" alt="user-profile" />
                    </span>
                    <span class="username"><small>Hello,</small><br><span style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{(userDisplay?.fname ||userDisplay?.name) | titlecase}}</span></span>
                    <span class="profile-dropdown fa fa-angle-down"></span>
                </a>
                <div class="available-notifications profile" [ngClass]="{'opened': showProfile}">
                    <div class="profile-picture">
                        <button type="button" class="close-profile" (click)="showProfile = false">&#10006;</button>
                        <input type="file" #file class="d-none" accept=".jpg,.jpeg,.png" (change)="onChooseFile($event)">
                        <button type="button" (click)="file.click()" [disabled]="isLoading" title="Choose Profile">
                            <img *ngIf="!imageUrl" [src]="placeholderImage" alt="user-profile" />
                            <img *ngIf="imageUrl" [src]="imageUrl" alt="user-profile" />
                            <img src="/assets/images/camera.svg" alt="">
                        </button>
                        <!-- <h2>{{ role === userRoles.ROLE_ADMIN ? 'Shipskart Admin' : role === userRoles.ROLE_COMPANY_ADMIN && company.type == 1 ? 'Manager '+userDisplay.fname :
                            role === userRoles.ROLE_COMPANY_ADMIN && company.type == 2 ? 'Vendor '+userDisplay.fname : role === userRoles.ROLE_CAPTAIN ? 'Captain '+userDisplay.fname : 'Officer '+userDisplay.fname}}</h2> -->
                        <h2>{{ role === userRoles.ROLE_ADMIN ? 'Shipskart Admin' : (userDisplay?.fname ||userDisplay?.name)  | titlecase}}</h2>
                        <p class="user-email">{{ userDisplay?.email}}</p>
                        <p *ngIf="role !== userRoles.ROLE_ADMIN" class="user-email weight500">{{ (userDisplay?.user_role && userDisplay?.user_role.length > 0 && userDisplay?.user_role[0]?.designations ? userDisplay?.user_role[0]?.designations?.name : '') | titlecase}}</p>
                        <p>{{ (role === userRoles.ROLE_ADMIN ? 'Shipskart Marine Pvt. Ltd.' : company.name) | titlecase}}</p>
                    </div>
                    <div class="profile-options">
                        <ul class="options-list">
                            <li>
                                <a routerLink="/company/info">
                                    <img src="/assets/images/my-account.svg" alt="">
                                    My Account
                                </a>
                            </li>
                            <li>
                                <a (click)="changePassModal(changePassword)">
                                    <img src="/assets/images/change-password.svg" alt="">
                                    Change Password
                                </a>
                            </li>
                            <!-- <li>
                                <a routerLink="/company/info">
                                    <img src="/assets/images/edit-profile.svg" alt="">
                                    Edit Profile
                                </a>
                            </li> -->
                            <li *ngIf="role !== userRoles.ROLE_ADMIN && role !== userRoles.ROLE_SUBADMIN">
                                <!-- [class.not-clicked]="downloadButton" -->
                                <a (click)="downloadManual()" target="_blank">
                                    <img src="/assets/images/user-manual.svg" alt="">
                                    Download User Manual
                                    <img class="download-icon" src="/assets/images/download-icon.svg" alt="">
                                </a>
                                <a href="https://www.youtube.com/watch?v=5Xw8-ypuXpI" target="_blank" *ngIf="role === userRoles.ROLE_COMPANY_ADMIN && company.type === 2">
                                    <img src="/assets/images/youtube.svg" alt="">
                                    View Demo Tutorial
                                </a>
                            </li>
                            <li [class.show]="showHelp" *ngIf="role !== userRoles.ROLE_ADMIN && role !== userRoles.ROLE_SUBADMIN">
                                <a (click)="showHelp = !showHelp">
                                    <img src="/assets/images/help-support.svg" alt="">
                                    Help & Support
                                    <i class="fa fa-chevron-down"></i>
                                </a>
                                <div class="help-section">
                                    <h4>Email Us: <a href="mailto:operations@shipskart.com"><span class="fa fa-envelope"></span> operations@shipskart.com</a></h4>
                                </div>
                            </li>
                        </ul>
                        <button class="logout" type="button" (click)="getLoggedOut()">Sign Out</button>
                    </div>
<!--                    <div class="profile-footer"></div>-->
                </div>
                <!-- <a class="user-options" routerLink="/company/info"><span class="username">
                    {{userDisplayName | titlecase}}</span><span class="profile-dropdown fa fa-angle-down"></span></a> -->
                <!-- <ul class="inner-list">
                    <li><a (click)="changePassModal(changePassword)">Change Password</a></li>
                    <li>
                        <a (click)="getLoggedOut()">Logout</a>
                    </li>
                </ul> -->
            </li>
        </ul>
    </div>
    <ng-template #changePassword>
        <div class="modal-body text-left">
            <button type="button" class="close cross-butn" aria-label="Close" (click)="changePasswordFrom.reset();modalRef.hide();">
                <span aria-hidden="true">&times;</span>
            </button>
            <div *ngIf="error" class="alert alert-danger alert-dismissible ng-star-inserted">{{error}}</div>
            <h4 class="sure-font text-center">Change Password</h4>
            <form autocomplete="off" [formGroup]="changePasswordFrom" (ngSubmit)="chPassword()">
                <div class="row">
                    <div class="col-md-12 tmargin20">
                        <div class="form-group" [ngClass]="{'error':submitted && form.old_password.errors, 'notsame':submitted && form.new_password.errors?.required && form.old_password.errors?.notEnoughLength}">
                            <label>Current Password</label>
                            <input formControlName="old_password" [type]="isTextFieldType ? 'text' : 'password'" placeholder="Current Password" class="form-control password-field" />
                            <button class="eye-icon-btn user" (click)="togglemyPasswordFieldType()" type="button">
                                <i [class]="isTextFieldType ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                            </button>
                            <input-validation [control]="form.old_password" [submit]="submitted"></input-validation>
                            <!-- <div class="errorbox" *ngIf="submitted && form.old_password.errors?.required">
                                <span *ngIf="form.old_password.errors">Please enter valid Password</span>
                            </div>
                            <div class="errorbox" *ngIf="submitted && form.old_password.errors?.notEnoughLength">
                                <span>Password should not be less than 6 characters</span>
                            </div> -->
                        </div>

                    </div>
                    <div class="col-md-12">
                        <div class="form-group" [ngClass]="{'error':submitted && form.password.errors, 'notsame':submitted && form.password.errors?.required && form.password.errors?.notEnoughLength}">
                            <label>New Password</label>
                            <input formControlName="password" [type]="isTextFieldNewType ? 'text' : 'password'" placeholder="New Password" (keyup)="checkingPasswords()" class="form-control password-field" [(ngModel)]="password" />
                            <button class="eye-icon-btn user" (click)="togglemyNewPasswordFieldType()" type="button">
                                <i [class]="isTextFieldNewType ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                            </button>
                            <input-validation [control]="form.password" [submit]="submitted"></input-validation>
                            <!-- <div class="errorbox" *ngIf="submitted && form.password.errors?.required">
                                <span *ngIf="form.password.errors">Please enter valid Password</span>
                            </div>
                            <div class="errorbox" *ngIf="submitted && form.password.errors?.notEnoughLength">
                                <span>Password should not be less than 6 characters</span>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group" [ngClass]="{'error':submitted && form.new_password.errors || notSame}">
                            <label>Confirm Password</label>
                            <input formControlName="new_password" [type]="isTextFieldConfirmType ? 'text' : 'password'" placeholder="Confirm Password" (keyup)="checkingPasswords()" class="form-control password-field" />
                            <button class="eye-icon-btn user" (click)="togglemyConfirmPasswordFieldType()" type="button">
                                <i [class]="isTextFieldConfirmType ? 'fa fa-eye' : 'fa fa-eye-slash'" aria-hidden="true"></i>
                            </button>
                            <input-validation [control]="form.new_password" [submit]="submitted"></input-validation>
                            <!-- <div class="errorbox" *ngIf="submitted && form.new_password.errors?.required">
                                <span *ngIf="form.new_password.errors">Please enter valid Confirm Password</span>
                            </div>
                            <div class="errorbox" *ngIf="submitted && notSame">
                                <span>Password not matched</span>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-12 text-right pad-top">                        
                        <button class="btn btn-outline-primary" (click)="modalRef.hide()" type="button">Cancel</button>
                        <button class="btn btn-primary"
                            type="submit"
                            [disabled]="loading">Update Password
                            <span class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                                *ngIf="loading"></span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </ng-template>
    <!-- <a class="chat-button" *ngIf="isShowChatButton" routerLink="/chat" title="Chat Messenger"><img src="/assets/images/messenger1.svg" alt="chat-icon"> <sup *ngIf="chatMessageCount"><span>{{chatMessageCount}}</span></sup></a> -->
</header>
