<div>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Request a Specific Product</h4>
        <button type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-spc">
        <form [formGroup]="newProductForm"
            (ngSubmit)="addNewProductRequest()">
            <div class="row">
                <div class="col-md-12"
                    *ngIf="showReqField && !updateSpecific">
                    <div class="form-group"
                        [ngClass]="{error : newProductFormSubmitted && newProductForm.controls.requirement_id.errors}">
                        <label for="">Select Requisition Name<span class="mendatory">*</span></label>
                        <ng-select formControlName="requirement_id"
                            bindLabel="name"
                            bindValue="requirement_id"
                            [multiple]="false"
                            placeholder="Select Requisition"
                            class="form-control"
                            clearAllText="Clear"
                            [clearable]="false"
                            (change)="changeRequisition($event)">
                            <ng-option *ngFor="let item of requisitionList"
                                [value]="item"><span [title]="item?.requirement_name"
                                    class="requirement-name-span"><b>{{item?.name}}</b> {{item?.category_name}}</span>
                                <br>
                                <em>Created By: </em> <b> {{item?.creator_name? item?.creator_name: ''}} / {{item?.rank?
                                    item?.rank: ''}}</b>
                            </ng-option>
                        </ng-select>
                        <input-validation [control]="newProductForm.controls.requirement_id"
                            [submit]="newProductFormSubmitted"></input-validation>
                    </div>
                </div>
                <div class="col-md-12 searchbox">
                    <div class="form-group"
                        [ngClass]="{error : newProductFormSubmitted && newProductForm.controls.name.errors}">
                        <label for="">Product Name<span class="mendatory">*</span></label>
                        <input type="hidden"
                            formControlName="source"
                            [(ngModel)]="source" />
                        <input type="hidden"
                            formControlName="req_id"
                            [(ngModel)]="reqId" />
                        <div class="ng-autocomplete">
                            <p-autoComplete formControlName="name"
                                placeholder="Product Name"
                                [suggestions]="suggestion"
                                (completeMethod)="search($event)"
                                (keypress)="searchProduct()"
                                (onSelect)="doOnSelect($event)">
                                <ng-template let-suggestion
                                    let-i="index"
                                    pTemplate="item"styleClass="wid100">
                                    <div class="search-product ui-helper-clearfix"
style="border-bottom:1px solid #D5D5D5;width: 250px;">
                                        <p>{{suggestion?.name | titlecase}}</p>
                                        <p class="code">Brand: {{suggestion?.brand_name ||'N/A'}}</p>
                                        <p class="code">Impa code: {{suggestion?.impa_code}}</p>
                                        <p class="code">Unit: {{suggestion?.unit}}</p>
                                        <p class="code">Pack Type: {{suggestion?.pack_type}}</p>
                                    </div>
                                </ng-template>
                            </p-autoComplete>
                        </div>
                        <!--              <input formControlName="name"
                                 type="text"
                                 class="form-control" placeholder="Product name" />-->
                        <input-validation [control]="newProductForm.controls.name"
                            [submit]="newProductFormSubmitted "></input-validation>
                        <!-- <div class="errorbox" *ngIf="newProductFormSubmitted && newProductForm.controls.name.errors">
                            <span>Enter valid name</span>
                        </div> -->
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group"
                        [ngClass]="{error : newProductFormSubmitted && newProductForm.controls.description.errors}">
                        <label for="">Description<span class="mendatory">*</span></label>
                        <textarea class="form-control"
                            rows="5"
                            formControlName="description"></textarea>
                        <input-validation [control]="newProductForm.controls.description"
                            [submit]="newProductFormSubmitted"></input-validation>
                        <!-- <div class="errorbox" *ngIf="newProductFormSubmitted && newProductForm.controls.description.errors">
                            <span>Enter valid description</span>
                        </div> -->
                    </div>
                </div>
                <div class="col-md-12" *ngIf="updateSpecific">
                    <div class="form-group"
                        [ngClass]="{error : newProductFormSubmitted && newProductForm.controls.category_id.errors}">
                         <label for="">Category<span class="mendatory">*</span></label>
                    <!--    //<select name=""
                        //    id=""
                        //    formControlName="category_id"
                        //    [(ngModel)]="product.category_id"
                        //    class="form-control"
                        //    (ngModelChange)="getSubCategory($event)">
                        //    <option *ngFor="let category of categoryList"
                        //        [value]="category.id">{{category.name}}</option>
                        //</select>-->

                        <ng-select formControlName="category_id"
                            [items]="categoryList"
                            bindLabel="name"
                            bindValue="id"
                            [multiple]="false"
                            placeholder="Select Category"
                            class="form-control"
                            clearAllText="Clear"
                            [clearable]="false"
                            (change)="changeCategory($event)">
                        </ng-select>
                        <input-validation [control]="newProductForm.controls.category_id"
                            [submit]="newProductFormSubmitted"></input-validation>
                        <div class="errorbox"
                            *ngIf="newProductFormSubmitted && newProductForm.controls.category_id.errors">
                            <span>Select category</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group"
                        [ngClass]="{error : newProductFormSubmitted && newProductForm.controls.sub_category_id.errors}">
                        <label for="">Subcategory<span class="mendatory">*</span></label>
                        <!-- <select name="" id="" formControlName="category_id" [(ngModel)]="product.category_id" class="form-control"
              (ngModelChange)="getSubCategory($event)">
              <option *ngFor="let category of categoryList" [value]="category.id">{{category.name}}</option>
            </select> -->
                        <ng-select formControlName="sub_category_id"
                            [items]="subCategoryList"
                            bindLabel="name"
                            bindValue="id"
                            [multiple]="false"
                            placeholder="Select Subcategory"
                            class="form-control"
                            clearAllText="Clear"
                            [clearable]="false"
                            (change)="changeSubCategory($event)">
                        </ng-select>
                        <input-validation [control]="newProductForm.controls.sub_category_id"
                            [submit]="newProductFormSubmitted"></input-validation>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group"
                        [ngClass]="{error : newProductFormSubmitted && (newProductForm.controls.unit_id.errors || newProductForm.controls.pack_type.errors)}">
                        <label for="">Unit (Pack type)<span class="mendatory">*</span></label>
                        <div class="select-unit clearfix">
                            <input type="text"
                                [(ngModel)]="packType"
                                placeholder="Unit"
                                formControlName="pack_type"
                                value="1"
                                class="form-control"
                                maxlength="20">
                            <ng-select formControlName="unit_id"
                                [items]="units"
                                bindLabel="name"
                                bindValue="id"
                                [multiple]="false"
                                placeholder="Select unit"
                                class="form-control"
                                clearAllText="Clear"
                                [clearable]="false"
                                (change)="changeUnit($event)">
                            </ng-select>
                        </div>
                        <input-validation [control]="newProductForm.controls.unit_id"
                            [submit]="newProductFormSubmitted"></input-validation>
                        <input-validation [control]="newProductForm.controls.pack_type"
                            [submit]="newProductFormSubmitted"></input-validation>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="form-group"
                        [ngClass]="{error : newProductFormSubmitted && newProductForm.controls.img_name.errors}">
                        <label for="">Image</label>
                        <input type="file"
                            formControlName="img_name"
                            (change)="selectedfileName($event.target.files)"
                            class="form-control">
                        <p class="file-upload-info">Max file size : 5 MB</p>
                        <input-validation [control]="newProductForm.controls.img_name"
                            [submit]="newProductFormSubmitted"></input-validation>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group"
                        [ngClass]="{error : newProductFormSubmitted && newProductForm.controls.quantity.errors}">
                        <label for="">Quantity<span class="mendatory">*</span></label>
                        <input type="text"
                            pKeyFilter="pnum"
                            placeholder="Quantity"
                            formControlName="quantity"
                            class="form-control"
                            maxlength="10"
                            [(ngModel)]="quantity"
                            (change)="changeQuantity($event)">
                        <input-validation [control]="newProductForm.controls.quantity"
                            [submit]="newProductFormSubmitted"></input-validation>
                    </div>
                </div>
                <div class="col-md-12"
                    *ngIf="!portId && isProvisionCart">
                    <div class="form-group">
                        <label for="">Select Port<span class="mendatory">*</span></label>
                        <ng-select [items]="portList"
                            bindLabel="name"
                            [(ngModel)]="currentPortId"
                            [ngModelOptions]="{standalone: true}"
                            bindValue="id"
                            [multiple]="false"
                            [placeholder]="loading2 ? 'Loading...' : 'Select Port'"
                            class="form-control"
                            clearAllText="Clear"
                            [clearable]="false"
                            [loading]="loading2">
                        </ng-select>
                    </div>
                </div>
                <input type="hidden"
                    formControlName="ship_id"
                    [(ngModel)]="shipId" />
                <div class="col-md-12 tmargin10 text-right">
                    <button class="btn btn-primary"
                        type="submit"
                        [disabled]="progress"
                        value="">Submit Request <span class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                            *ngIf="progress"></span></button>
                </div>
            </div>
        </form>
    </div>
</div>
