<form [formGroup]="financialDetailsForm" (ngSubmit)="submitFinancialDetails()">
<div class="kyc-form">
  <h3>Section 2: Financial Information</h3>
  <div class="company-details remove-border">
    <div class="row">
      <div class="col-md-8 col-sm-12 pad-right">
        <label class="bmargin5 label-text">Annual Value of Total Sales for the last 3 years<span class="mendatory">*</span></label>
        <div class="total-sales-box">
          <div class="form-fields-wrap financial-year">    
            <label class="grey-label label-text"></label>
            <div class="form-group kyc-field year no-margin">
              <label class="grey-label label-text">YEAR</label>
            </div>
            <div class="form-group kyc-field sales no-margin">
              <label class="grey-label label-text"><span class="d-flex currency-dropdown">
                TOTAL SALES 
                <ng-select [items]="currencyDropdown" formControlName="currencies" (change)="addCurrency($event)" bindLabel="name" bindValue="id" [multiple]="false" placeholder="Select Currency" class="dropdown" [clearOnBackspace]="true" [clearSearchOnAdd]="true" [clearable]="false">
                </ng-select>
              </span>
              </label>
            </div>
          </div>
          <ng-container formArrayName="annual_sale_details">
          <div class="form-fields-wrap financial-year financialsize" *ngFor="let annualSaleItem of this.financialDetailsForm.get('annual_sale_details')['controls']; index as sl" [formGroupName]="sl">    
            <label class="grey-label label-text">Year- {{sl+1}}</label>
            <div class="form-group kyc-field year" [ngClass]="{error: submitted && annualSaleDetails.controls[sl].get('year').errors}">
              <input type="text" formControlName="year" class="form-control" placeholder="Enter Year" onkeypress="return /[0-9]/i.test(event.key)" maxlength="4">
              <input-validation [control]="annualSaleDetails.controls[sl].get('year')" [submit]="submitted"></input-validation>
              <!-- <div class="errorbox" *ngIf="submitted && annualSaleDetails.controls[sl].get('year').errors">
                <span>Enter a valid year</span>
              </div> -->
            </div>
            <div class="form-group kyc-field sales" [ngClass]="{error: submitted && annualSaleDetails.controls[sl].get('total_sales').errors}">
              <input type="text" formControlName="total_sales" class="form-control" [placeholder]="selectedCurrency?selectedCurrency:'USD'" pKeyFilter="pnum" maxlength="15">
              <input-validation [control]="annualSaleDetails.controls[sl].get('total_sales')" [submit]="submitted"></input-validation>
              <!-- <div class="errorbox" *ngIf="submitted && annualSaleDetails.controls[sl].get('total_sales').errors">
                <span>Enter total sales</span>
              </div> -->
            </div>
          </div>
          </ng-container>
        </div>
      </div>
      <!-- <div class="col-md-4 col-sm-12 pad-left">
        <label class="bmargin5 label-text">Currency Dealing In<span class="mendatory">*</span></label>
        <div class="form-group kyc-field" [ngClass]="{error: submitted && financialDetailsForm.get('currencies').errors}">
          <ng-select [items]="currencyDropdown" formControlName="currencies" bindLabel="name" bindValue="id" [multiple]="true" placeholder="Select Currency" class="form-control new-dropdown multiple" [closeOnSelect]="false" [clearOnBackspace]="true" [clearSearchOnAdd]="true">
          </ng-select>
          <input-validation [control]="financialDetailsForm.get('currencies')" [submit]="submitted"></input-validation>
          <div class="errorbox" *ngIf="submitted && financialDetailsForm.get('currencies').errors">
            <span>Select currency</span>
          </div>
        </div>
      </div> -->
    </div>
    <div class="row">
      <div class="col-md-5 pad-right">
        <div class="form-group kyc-field" [ngClass]="{error: submitted && financialDetailsForm.get('beneficiary_name').errors}">
          <label for="">Beneficiary Name<span class="mendatory">*</span></label>
          <input type="text" formControlName="beneficiary_name" class="form-control" placeholder="Enter Name" maxlength="255">
          <input-validation [control]="financialDetailsForm.get('beneficiary_name')" [submit]="submitted"></input-validation>
          <!-- <div class="errorbox" *ngIf="submitted && financialDetailsForm.get('beneficiary_name').errors">
            <span>Enter beneficiary name</span>
          </div> -->
        </div>
      </div>
      <div class="col-md-5 pad-left">
        <div class="form-group kyc-field" [ngClass]="{error: submitted && financialDetailsForm.get('bank_name').errors}">
          <label for="">Bank Name<span class="mendatory">*</span></label>
          <input type="text" formControlName="bank_name" class="form-control" placeholder="Enter Bank Name" maxlength="255">
          <input-validation [control]="financialDetailsForm.get('bank_name')" [submit]="submitted"></input-validation>
          <!-- <div class="errorbox" *ngIf="submitted && financialDetailsForm.get('bank_name').errors">
            <span>Enter bank name</span>
          </div> -->
        </div>
      </div>
      <div class="col-md-5 pad-right">
        <div class="form-fields-wrap bmargin15">
          <div class="form-group kyc-field wid50" [ngClass]="{error: submitted && financialDetailsForm.get('iban_swift').errors}">
            <label>IBAN/Account No.</label>
            <input type="text" formControlName="iban_swift" class="form-control" placeholder="Enter IBAN/SWIFT" maxlength="20">
            <input-validation [control]="financialDetailsForm.get('iban_swift')" [submit]="submitted"></input-validation>
            <!-- <div class="errorbox" *ngIf="submitted && financialDetailsForm.get('iban_swift').errors">
              <span>Enter valid Iban/Swift</span>
            </div> -->
          </div>
          <div class="form-group kyc-field wid50" [ngClass]="{error: submitted && financialDetailsForm.get('ifsc_code').errors}">
            <label>Swift/IFSC Code</label>
            <input type="text" formControlName="ifsc_code" class="form-control" placeholder="IFSC Code" maxlength="16">
            <input-validation [control]="financialDetailsForm.get('ifsc_code')" [submit]="submitted"></input-validation>
            <!-- <div class="errorbox" *ngIf="submitted && financialDetailsForm.get('ifsc_code').errors">
              <span>Enter valid IFSC code</span>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-md-5 pad-left">
        <label class="label-text bmargin0">Upload Supporting Document <span class="mendatory">*</span></label>
        <div class="form-group kyc-field" [ngClass]="{ error: submitted && inValidBankFileSize}">
          <div class="select-file upload bmargin5">
            <img src="/assets/images/file-upload-icon.svg" alt="">
            <input class="form-control" accept=".pdf,.jpg,.jpeg,.png" (change)="selectedBankFileName($event.target.files)" type="file"> Attach Files <span class="btn-span">Browse</span>
          </div>
          <div class="filename">
            <span *ngIf="!bankDocuments || fileName" class="filename rmargin10">{{fileName || 'No Files Chosen'}}</span>
            <a *ngIf="bankDocuments" [href]="bankDocuments" target="_blank" class="d-block tmargin5 rmargin10 file-view" download>View Document</a>
          </div>
          <div class="errorbox" *ngIf="submitted && !fileName">
            <span>Please attach document.</span>
          </div>
          <div class="errorbox" *ngIf="submitted && inValidBankFileSize">
            <span>File is larger than <strong>3mb !!</strong></span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group kyc-field bmargin30" [ngClass]="{error: submitted && financialDetailsForm.get('credit_period').errors}">
          <label>Credit Period<span class="mendatory">*</span></label>
          <ng-select [items]="creditPeriods" formControlName="credit_period" bindLabel="value" bindValue="value" placeholder="Select Credit Period" class="form-control new-dropdown" clearAllText="Clear" [clearable]="false">
          </ng-select>
          <input-validation [control]="financialDetailsForm.get('credit_period')" [submit]="submitted"></input-validation>
          <!-- <div class="errorbox" *ngIf="submitted && financialDetailsForm.get('credit_period').errors">
            <span>Select credit period</span>
          </div> -->
        </div>
      </div>
      <div class="col-md-12">
        <label class="bmargin5 label-text">Please attach a listing of reference sources for services rendered by your firm within the last 12 months.</label>
        <div class="tabular-detail data-table">
          <div class="scroll-table">
            <table class="table full-table req-table price update new">
              <thead>
                  <tr>
                    <th width="50">Sr. No</th>
                    <th>Name of Company</th>
                    <th>Address</th>
                    <th>Contact Person</th>
                    <th *ngIf="financialDetailsForm.get('previous_service_details')['controls'].length == 0"><a href="javascript:void(0)" (click)="addPreviousServiceDetailsItem()">+ Add Row</a></th> 
                  </tr>
              </thead>
              <tbody formArrayName="previous_service_details">
                <tr *ngFor="let previousService of financialDetailsForm.get('previous_service_details')['controls']; index as i;" [formGroupName]="i">
                  <td>{{i+1}}</td>
                  <td><input type="text" formControlName="company_name" class="table-field" placeholder="Enter Company Name" maxlength="100"></td>
                  <td><input type="text" formControlName="company_address" class="table-field" placeholder="Enter Address" maxlength="150"></td>
                  <td class="border-right"><input type="text" formControlName="contact_person" class="table-field" placeholder="Enter Name" maxlength="100"></td>
                  <td class="add-td">
                    <div class="add-row">
                      <button type="button" class="close cross" *ngIf="i > 0" (click)="removePreviousServiceDetailsItem(i)">&#10006;</button>
                      <a href="javascript:void(0)" (click)="addPreviousServiceDetailsItem()">+ Add Row</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="submit-btn-box tmargin20 bmargin10">
  <button type="button" class="btn empty-cart back" (click)="goPrevious()">Back</button>
  <button type="button" class="btn empty-cart back" (click)="resetForm()">Reset</button>     
  <button type="submit" class="buttn place save alignText">Save and Continue</button>
</div>
</form>