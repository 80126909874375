import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CopyLinkService {
  async copyToClipboard(text: string): Promise<boolean> {
    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch (error) {
      console.error('Unable to copy to clipboard', error);
      return false;
    }
  }
}
