import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IndexedDbService {
  private dbName = 'excelDataDB';
  private objectStoreName = 'excelDataStore';
  private dbVersion = 1;
  private db: IDBDatabase;
  constructor() {
    //this.openDatabase()
  }

  openDatabase(): void {
    const request = indexedDB.open(this.dbName, this.dbVersion);

    request.onerror = (event) => {
      console.error("Failed to open database:", event.target);
    };

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      console.log("Upgrading database...");

      // Create object store if it doesn't exist
      if (!db.objectStoreNames.contains(`${this.objectStoreName}`)) {
        db.createObjectStore(`${this.objectStoreName}`, { keyPath: 'id', autoIncrement: true });
      }
    };

    request.onsuccess = (event) => {
      console.log("Database opened successfully.");
      this.db = (event.target as IDBOpenDBRequest).result;
    };
  }


  /**
   * Saves the provided data to the IndexedDB object store.
   * @param {any[]} data - An array containing the data to be saved to IndexedDB.
   * @returns {Promise<void>} A promise that resolves once the data is saved successfully.
   */
  async saveToIndexedDB(data: any[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this.db.objectStoreNames.contains(this.objectStoreName)) {
        const errorMessage = `Object store '${this.objectStoreName}' not found in the database.`;
        console.error(errorMessage);
        reject(new Error(errorMessage));
        return;
      }

      const transaction = this.db.transaction([this.objectStoreName], 'readwrite');
      const objectStore = transaction.objectStore(this.objectStoreName);
      const addRequest = objectStore.add(data);

      addRequest.onerror = (event) => {
        console.error('Error adding item to object store:', event.target);
        reject(event.target);
      };

      addRequest.onsuccess = (event) => {
        console.log('Data successfully added to object store:', event.target);
        resolve();
      };
    });
  }



  /**
   * deleteDatabase(): delete database after create requisition
   * databaseName: 
    */
  deleteDatabase(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.db) {

        this.db.close()
      }
      const request = indexedDB.deleteDatabase(this.dbName);
      request.onsuccess = () => {
        console.log(`Database '${this.dbName}' deleted successfully.`);
        resolve();
      };

      request.onerror = () => {
        console.error(`Error deleting database '${this.dbName}':`, request.error);
        reject(request.error);
      };
    });
  }


  /**
 * Retrieves all data from the IndexedDB object store.
 * @returns {Promise<any[]>} A promise that resolves with an array containing the retrieved data.
 */
  async getAllData(): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      // Open the IndexedDB database
      const dbRequest = indexedDB.open(this.dbName);
      dbRequest.onerror = (event: any) => {
        console.error('Error opening database:', event.target.error);
        reject(event.target.error);
      };

      dbRequest.onsuccess = (event: any) => {
        const db = event.target.result;

        // Create a read-only transaction on the specified object store
        const transaction = db.transaction([this.objectStoreName], 'readonly');
        const objectStore = transaction.objectStore(this.objectStoreName);

        // Retrieve all data from the object store
        const getRequest = objectStore.getAll();
        getRequest.onsuccess = (event: any) => {
          const data = event.target.result;
          resolve(data);
        };

        getRequest.onerror = (event: any) => {
          console.error('Error retrieving data from object store:', event.target.error);
          reject(event.target.error);
        };
      };
    });
  }

  updateData(data) {

  }
}