import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewChildren } from "@angular/core";
import { NewDashboard } from "@app/dashboard/models/newdashboard.model";
import { Chart } from "chart.js";
import { PanelComponent } from "../panel/panel.component";
import { FilterComponent } from "../filter/filter.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SharedService } from "@app/shared/service/shared.service";
import { ToastrService } from "ngx-toastr";
import { AlertService } from "@app/shared/service/alert.service";
import { Router } from "@angular/router";
import { DashboardService } from "@app/dashboard/dashboard.service";
@Component({
  selector: 'app-bar-chart',
  templateUrl: './barchart.component.html',
  styleUrls: ['./barchart.component.css']
})

export class BarChartComponent implements OnInit {
  @Input() data: NewDashboard;
  @ViewChildren('barCharts') barChart: any;
  chart: Chart
  @ViewChild('panelData', { static: false }) panelData: PanelComponent;
  @ViewChild('filterData', { static: false }) filterData: FilterComponent;
  editPanel: boolean;
  otherAxis
  sharedData: NewDashboard;
  // modalRef: BsModalRef;
  deleteModal: BsModalRef;
  filterLength: boolean;
  copyGraphData: NewDashboard;
  isDownloading: boolean;
  @Output() callParentMethodEvent = new EventEmitter<string>();
  loadingDeletePanel: boolean;
  constructor(private bsModalService: BsModalService,
    private router: Router,
    private sharedService: SharedService,
    private toastr: ToastrService, private altService: AlertService,
    private dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
    this.otherAxis = this.data;
    this.checkFilter();
  }
  reloadPage(event) {
    setTimeout(() => { this.callParentMethodEvent.emit(event) }, 200)
  }
  checkFilter() {
    this.filterLength = this.data.filters.some(response => {
      return response.filter_value ? true : false;
    })
  }
  async downloadReport() {
    this.isDownloading = true;
    try {
      const res = await this.dashboardService.getReportData(this.data.report_id, null, 1);
      this.sharedService.saveAsBlob(
        res,
        'application/vnd.ms-excel',
        `PO_${this.data.report_title}.xlsx`
      );
      this.isDownloading = false;
      this.toastr.success('Downloaded Successfully.');
    } catch (error) {
      this.toastr.error('Failed to download report.');
      this.isDownloading = false;
    }
  }
  // ngAfterViewInit() {
  //   try {
  //     if (!this.data || !this.data.graph_data) {
  //       console.error("Data or graph_data is not defined");
  //       return;
  //     }

  //     this.copyGraphData = JSON.parse(JSON.stringify(this.data));

  //     if (this.copyGraphData.report_type === 'bar_graph_group') {
  //       this.formatGraphData();
  //     }

  //     setTimeout(() => {
  //       this.initializeCharts();
  //     }, 500);
  //   } catch (error) {
  //     console.error("Error in ngAfterViewInit:", error);
  //   }
  // }

  // private formatGraphData() {
  //   const dataLength = this.copyGraphData.graph_data.length;
  //   const count = Math.min(this.copyGraphData.graph_data[0]?.data.length || 0, dataLength);

  //   for (let i = 0; i < count; i++) {
  //     for (let j = 0; j < dataLength; j++) {
  //       if (this.data.graph_data[j] && this.data.graph_data[j].data[i] !== undefined) {
  //         this.copyGraphData.graph_data[i].data[j] = this.data.graph_data[j].data[i].toFixed(2);
  //       } else {
  //         console.warn(`Data index out of bounds: i=${i}, j=${j}`);
  //       }
  //     }

  //     if (this.data.graph_data[i] && this.data.graph_data[i].backgroundColor) {
  //       const backgroundColor = this.data.graph_data[i].backgroundColor;
  //       this.copyGraphData.graph_data[i].backgroundColor = Array(dataLength).fill(backgroundColor[i]);
  //       this.copyGraphData.graph_data[i].label = this.data.column_keys[i + 1];
  //       this.copyGraphData.graph_data[i].borderWidth = 2;
  //       this.copyGraphData.graph_data[i].borderColor = this.copyGraphData.graph_data[i].backgroundColor[0];
  //     } else {
  //       console.warn(`BackgroundColor index out of bounds: i=${i}`);
  //     }
  //   }

  //   this.copyGraphData.graph_data.splice(count);
  // }

  // private initializeCharts() {
  //   if (!this.data || !this.copyGraphData) {
  //     console.error("Data or copyGraphData is not defined");
  //     return;
  //   }

  //   const data = {
  //     labels: this.data.labels,
  //     datasets: this.copyGraphData.graph_data,
  //     navigation: this.data.navigation_type,
  //     reportType: this.data.report_type
  //   };
  //   const barPercentage = data.labels.length === 1 ? 0.1 : 0.4;
  //   const categoryPercentage = data.labels.length === 1 ? 0.1 : 0.8;

  //   const canvasCharts = this.barChart?._results || [];
  //   const route = this.router;
  //   canvasCharts.forEach((myCanvas) => {
  //     if (!myCanvas.nativeElement) {
  //       console.error("Canvas element is not defined");
  //       return;
  //     }

  //     this.chart = new Chart(myCanvas.nativeElement.getContext('2d'), {
  //       type: 'bar',
  //       data: data,
  //       options: {
  //         hover: {
  //           onHover: function (e) {
  //             const point = this.getElementAtEvent(e);
  //             e.target.style.cursor = point.length ? 'pointer' : 'default';
  //           }
  //         },
  //         onClick: function (event, elements) {
  //           if (data.reportType !== 'bar_graph_group' && elements.length) {
  //             const clickedElement = elements[0];
  //             const datasetIndex = clickedElement._index;
  //             const navigationPath = data.navigation[datasetIndex];

  //             if (navigationPath) {
  //               route.navigateByUrl(this.getNavigationUrl(navigationPath));
  //             }
  //           }
  //         },
  //         legend: {
  //           display: true,
  //           position: 'bottom'
  //         },
  //         scales: {
  //           xAxes: [{
  //             display: true,
  //             barPercentage: barPercentage,
  //             categoryPercentage: categoryPercentage,
  //             scaleLabel: {
  //               display: data.reportType !== 'bar_graph_group',
  //               labelString: this.data.column_keys[0],
  //               fontStyle: "bold",
  //               fontSize: 16
  //             },
  //             ticks: {
  //               maxRotation: 20,
  //               autoSkip: false,
  //               maxTicksLimit: 10,
  //               font: {
  //                 size: 14
  //               },
  //               callback: (value) => {
  //                 return this.truncateLabel(value, myCanvas);
  //               }
  //             },
  //           }],
  //           yAxes: [{
  //             scaleLabel: {
  //               display: data.reportType !== 'bar_graph_group',
  //               labelString: this.data.column_keys[1],
  //               fontStyle: "bold",
  //               fontSize: 16
  //             },
  //           }]
  //         },
  //         responsive: true,
  //         maintainAspectRatio: true,
  //       }
  //     });
  //   });
  // }

  // private getNavigationUrl(path: string): string {
  //   const routes: { [key: string]: string } = {
  //     "order_list": '/orders',
  //     "order_placed": '/orders?page=1&filter=%257B%2522order_status%2522%253A%255B1%255D%257D',
  //     "order_out_for_delivery": '/orders?page=1&filter=%257B%2522order_status%2522%253A%255B3%255D%257D',
  //     "order_delivered": '/orders?page=1&filter=%257B%2522order_status%2522%253A%255B4%255D%257D',
  //     "order_cancelled": '/orders?page=1&filter=%257B%2522order_status%2522%253A%255B6%255D%257D',
  //     "order_partially_delivered": '/orders?page=1&filter=%257B%2522order_status%2522%253A%255B5%255D%257D',
  //     "requisition_list": '/requisitions',
  //     "requisition_list_waiting_for_approval": '/requisitions?req_status=0&page=1',
  //     "requisition_list_sent_for_approval": '/requisitions?req_status=1&page=1',
  //     "requisition_list_rejected_by_manager": '/requisitions?req_status=2&page=1',
  //     "requisition_list_rejected_by_po": '/requisitions?req_status=3&page=1',
  //     "requisition_list_urgent": '/requisitions?priority_type=Urgent&page=1'
  //   };

  //   return routes[path] || '';
  // }

  // private truncateLabel(value: string, myCanvas: any): string {
  //   const maxWidth = 100; // Adjust as needed
  //   let label = value.trim();

  //   const context = myCanvas.nativeElement.getContext('2d');
  //   const ellipsisWidth = context.measureText('...').width;

  //   if (context.measureText(label).width > maxWidth) {
  //     while (context.measureText(label + '...').width > maxWidth - ellipsisWidth) {
  //       label = label.slice(0, -1);
  //     }
  //     return label + '...';
  //   }

  //   return label;
  // }





  ngAfterViewInit() {
    this.copyGraphData = JSON.parse(JSON.stringify(this.data));
    // let ctx: any = document.getElementById('barChart') as HTMLElement;
    const tempGraphData = this.data.graph_data[0];
    if (this.copyGraphData.report_type == 'bar_graph_group') {
      let count = this.copyGraphData.graph_data[0].data.length;
      if (count <= this.data.graph_data.length) {
        for (let i = 0; i < count; i++) {
          for (let j = 0; j < this.copyGraphData.graph_data.length; j++) {
            this.copyGraphData.graph_data[i].data[j] = this.data.graph_data[j].data[i].toFixed(2);
          }
          this.copyGraphData.graph_data[i].backgroundColor = new Array(this.data.graph_data.length).fill(this.data.graph_data[i].backgroundColor[i])
          this.copyGraphData.graph_data[i].label = this.data.column_keys[i + 1];
        }
        this.copyGraphData.graph_data.splice(count,)
      }
      // console.log(this.copyGraphData)
    }
    console.log(this.data)
    setTimeout(() => {
      var data = {
        labels: this.data.labels,
        datasets: this.copyGraphData.graph_data,
        navigation: this.data.navigation_type,
        reportType: this.data.report_type
      };
      let route = this.router;
      let canvasCharts = [];
      canvasCharts = this.barChart._results;
      canvasCharts.map((myCanvas, i) => {
        this.chart = new Chart(myCanvas.nativeElement.getContext('2d'),
          {
            type: 'bar',
            data: data,
            options: {
              interaction: {
                mode: 'nearest',
                intersect: false
              },
              onHover: (event, chartElement) => {
                const target = event.native?.target as HTMLElement;
                if (target) {
                  target.style.cursor = chartElement[0] ? 'pointer' : 'default';
                }
              },
              onClick: (event, elements) => {
                if (this.data.reportType != 'bar_graph_group') {
                  const clickedElement = elements[0];
                  if (clickedElement) {
                    const datasetIndex = clickedElement.index;
                    if (this.data.navigation[datasetIndex]) {
                      const navigationItem = this.data.navigation[datasetIndex];
                      if (navigationItem.includes('order')) {
                        switch (navigationItem) {
                          case "order_list":
                            route.navigateByUrl('/orders');
                            break;
                          case "order_placed":
                            route.navigateByUrl('/orders?page=1&filter=%257B%2522order_status%2522%253A%255B1%255D%257D');
                            break;
                          case "order_out_for_delivery":
                            route.navigateByUrl('/orders?page=1&filter=%257B%2522order_status%2522%253A%255B3%255D%257D');
                            break;
                          case "order_delivered":
                            route.navigateByUrl('/orders?page=1&filter=%257B%2522order_status%2522%253A%255B4%255D%257D');
                            break;
                          case "order_cancelled":
                            route.navigateByUrl('/orders?page=1&filter=%257B%2522order_status%2522%253A%255B6%255D%257D');
                            break;
                          case "order_partially_delivered":
                            route.navigateByUrl('/orders?page=1&filter=%257B%2522order_status%2522%253A%255B5%255D%257D');
                            break;
                        }
                      } else if (navigationItem.includes('requisition')) {
                        switch (navigationItem) {
                          case "requisition_list":
                            route.navigateByUrl('/requisitions');
                            break;
                          case "requisition_list_waiting_for_approval":
                            route.navigateByUrl('/requisitions?req_status=0&page=1');
                            break;
                          case "requisition_list_sent_for_approval":
                            route.navigateByUrl('/requisitions?req_status=1&page=1');
                            break;
                          case "requisition_list_rejected_by_manager":
                            route.navigateByUrl('/requisitions?req_status=2&page=1');
                            break;
                          case "requisition_list_rejected_by_po":
                            route.navigateByUrl('/requisitions?req_status=3&page=1');
                            break;
                          case "requisition_list_urgent":
                            route.navigateByUrl('/requisitions?priority_type=Urgent&page=1');
                            break;
                        }
                      }
                    }
                  }
                }
              },
              plugins: {
                legend: {
                  display: true,
                  position: 'bottom'
                }
              },
              scales: {
                x: {
                  display: true,
                  title: {
                    display: this.data.reportType != 'bar_graph_group',
                    text: `${this.data.column_keys[0]}`,
                    font: {
                      weight: 'bold',
                      size: 16
                    }
                  },
                  ticks: {
                    maxRotation: 20,
                    autoSkip: false,
                    maxTicksLimit: 10,
                    font: {
                      size: 14
                    },
                    callback: (value) => {
                      // Add ellipsis to long labels
                      if (typeof this.data.labels[value] === 'string') {
                        const maxWidth = 70; // Adjust as needed
                        let label = this.data.labels[value].trim();
                        while (myCanvas.nativeElement.getContext('2d').measureText(label + '...').width > maxWidth) {
                          label = label.slice(0, -1);
                        }
                        return label + '...';
                      } else {
                        return value;
                      }
                    }
                  }
                },
                y: {
                  title: {
                    display: this.data.reportType != 'bar_graph_group',
                    text: `${this.data.column_keys[1]}`,
                    font: {
                      weight: 'bold',
                      size: 16
                    }
                  },
                  beginAtZero: true,
                  ticks: {
                    // Uncomment and adjust if you have specific step size logic
                    // stepSize: this.computeStepSize()
                  }
                }
              },
              responsive: true,
              maintainAspectRatio: true
            }
          });
      });
    }, 500);
  }

  onResize(event: Event): void {
    if (this.chart) {
      this.chart.resize();
    }
  }
  openFilterModal() {
    this.filterData.openModel();
  }
  openPanelModal(isEdit = false) {
    this.editPanel = isEdit ? true : false;
    this.sharedData = this.data;
    this.panelData.openModel();
  }

  panelDeleteModal(template: TemplateRef<any>) {
    this.deleteModal = this.bsModalService.show(
      template,
      Object.assign({}, { class: "catalogue gray modal-sm" })
    );
    this.altService.backModalClose(this.deleteModal);
  }

  deletePanel() {
    this.loadingDeletePanel = true;
    this.sharedService.deletePanel(this.data.report_id, 1).subscribe(res => {
      if (res) {
        this.toastr.success('Report Deleted Successfully!');
        this.dashboardService.deleteReport(true);
        this.callParentMethodEvent.emit(this.data.report_id.toString());
        this.loadingDeletePanel = false;
        this.deleteModal.hide();
      }
    }, error => {
      this.toastr.error('Something went wrong!')
      this.loadingDeletePanel = false;
      this.deleteModal.hide();
    })
  }

  filterAppied(event) {
    if (this.data.report_id) {
      this.data = event[0]
      this.chart?.destroy();
      this.ngAfterViewInit();
      this.dashboardService.deleteReport(false);
      this.callParentMethodEvent.emit(this.data.report_id.toString());
      this.checkFilter();
    }
  }
  /* Remove Filter */
  removeFilter(event) {
    this.sharedService.removeFilter(event);
    this.callParentMethodEvent.emit(event.report_id.toString());
  }
}
